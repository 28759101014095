import { DeletionRule } from 'reducers/deletionRulesReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetDeletionRules = (dispatch: Dispatch) => http.get<DeletionRule>(actions.GET_DELETION_RULES, dispatch);
const debouncedGetDeletionRules = defaultDebounce(regularGetDeletionRules);

export const getDeletionRules = () => debouncedGetDeletionRules;
export const forceGetDeletionRules = () => regularGetDeletionRules;

export function insertDeletionRule(deletionRule: DeletionRule) {
  return http.post<void>(actions.INSERT_DELETION_RULE, deletionRule);
}

export function updateDeletionRule(deletionRule: DeletionRule) {
  return http.post<void>(actions.UPDATE_DELETION_RULE, deletionRule);
}

export function deleteDeletionRule(deletionRule: DeletionRule) {
  return http.post<void>(actions.DELETE_DELETION_RULE, deletionRule);
}
