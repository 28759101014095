import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { availableOperations } from 'reducers/userReducer';
import { canPerformAction } from 'actions/userActions';
import {
  getCfgServices,
  getCfgDicomServices,
  restartAll,
  restartFeed,
  isInDocker,
} from '../../actions/configurationsActions';
import { getCfgServicesState, getCfgDicomServicesState, getUser } from '../../reducers/selectors';
import CfgServicesTable from './CfgServicesTable';
import CfgDicomServicesTable from './CfgDicomServicesTable';
import useToaster from '../../actions/useToaster';
import { useAppDispatch } from 'hooks';
import ConfirmationPopup from 'components/popups/ConfirmationPopup';

function ConfigurationsPage() {
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  const user = useSelector(getUser);
  const authorized = canPerformAction(user, availableOperations.CAN_CHANGE_CONFIGURATIONS);
  const [docker, setDocker] = useState('none');
  const [showPopUp, setShowPopUp] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getCfgServices());
    dispatch(getCfgDicomServices());
    isInDocker().then(response => {
      if (response) {
        return setDocker('inline');
      }
      return setDocker('none');
    });
  }, []);

  const cfgServices = useSelector(getCfgServicesState);
  const cfgDicomServices = useSelector(getCfgDicomServicesState);

  const editableCfgServices = cfgServices.filter(cfgService => cfgService.editable === true);
  const nonEditableCfgServices = cfgServices.filter(cfgService => cfgService.editable === false);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <h3>Configurations</h3>
        <div style={{ textAlign: 'end' }}>
          <Button
            size="sm"
            className="restart-configurations"
            disabled={!authorized}
            onClick={() => {
              restartAll()
                .then(() => {
                  toaster.success('Services restarted correctly');
                })
                .catch(error => {
                  console.error(error);
                  toaster.error('Error restarting services');
                });
            }}
          >
            <FontAwesomeIcon icon={faRedo} style={{ marginRight: '5px' }} />
            Restart Services
          </Button>
          <Button
            size="sm"
            className="restart-feed"
            variant="warning"
            style={{ marginLeft: '2em', display: docker }}
            disabled={!authorized}
            onClick={() => {
              setShowPopUp(true);
            }}
          >
            <FontAwesomeIcon icon={faRedo} style={{ marginRight: '5px' }} />
            Restart FEED
          </Button>
        </div>
        <Collapsible trigger="Editable services configurations">
          <div>
            <strong>NB: </strong>Remember to restart the services in order to apply the new configurations
          </div>
          <CfgServicesTable authorized={authorized} cfgServices={editableCfgServices} />
        </Collapsible>
        <Collapsible trigger="Non editable services configurations">
          <CfgServicesTable authorized={authorized} cfgServices={nonEditableCfgServices} />
        </Collapsible>
        <Collapsible trigger="Dicom services configurations">
          <div>
            <strong>NB: </strong>Remember to restart DICOM services to apply changes
          </div>
          <CfgDicomServicesTable authorized={authorized} cfgDicomServices={cfgDicomServices} />
        </Collapsible>
        {showPopUp && (
          <ConfirmationPopup
            title="Reboot Feed"
            message="You are rebooting the FEED. Are you sure?"
            onClose={() => setShowPopUp(false)}
            onOk={() => restartFeed()}
          ></ConfirmationPopup>
        )}
      </Card.Body>
    </Card>
  );
}

export default ConfigurationsPage;
