import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Button, Modal } from 'react-bootstrap';
import { canPerformAction } from 'actions/userActions';
import { availableOperations } from 'reducers/userReducer';
import PrefetchConfigurationForm from './PrefetchConfigurationForm';
import PrefetchTable from './PrefetchTable';
import { getUser } from '../../reducers/selectors';
import * as http from '../../http';

interface PrefetchConfiguration {
  enabled: boolean;
  periodInMinutes: number;
  timeInterval: string;
}

function PrefetchPage() {
  const user = useSelector(getUser);
  const [prefetchConfiguration, setPrefetchConfiguration] = useState<PrefetchConfiguration | null>(null);
  const [showPopup, setShowPopup] = useState(false);
  const authorized = canPerformAction(user, availableOperations.CAN_MODIFY_PREFETCH_CONFIGURATIONS);

  return (
    <div>
      <Card className="page-panel-common">
        <Card.Body>
          {authorized && (
            <Button
              size="sm"
              variant="primary"
              style={{ float: 'right', marginLeft: '2em', color: 'ebedef' }}
              onClick={() =>
                getConfiguration().then(cfg => {
                  setPrefetchConfiguration(cfg);
                  setShowPopup(!showPopup);
                })
              }
            >
              Prefetch configuration
            </Button>
          )}
          <h3>Prefetch</h3>
          <PrefetchTable />
        </Card.Body>
      </Card>

      {showPopup && <PrefetchConfigurationPopup configuration={prefetchConfiguration} onClose={() => setShowPopup(false)} />}
    </div>
  );
}

const PrefetchConfigurationPopup = ({ configuration, onClose }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>Prefetch configuration</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <PrefetchConfigurationForm configuration={configuration} onClose={onClose} />
    </Modal.Body>
  </Modal>
);

const getConfiguration = () => {
  return http.get<PrefetchConfiguration>(`/rest/react/getPrefetchConfiguration`);
};

export default PrefetchPage;
