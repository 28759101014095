import { PeriodicScript, Script } from 'reducers/scriptReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetAllScripts = (dispatch: Dispatch) => http.get<Script[]>(actions.SCRIPTS, dispatch);
const debouncedGetAllScripts = defaultDebounce(regularGetAllScripts);

export const getAllScripts = () => debouncedGetAllScripts;
export const forceGetAllScripts = () => regularGetAllScripts;

export const loadScript = (scriptName: string) => {
  return http.get<Script>(`${actions.SCRIPTS.path}/${scriptName}`);
};

export const loadSnippet = (snippetName: string) => {
  console.log('snippetName:', snippetName);
  return http.get<string>(`${actions.SCRIPT_SNIPPETS.path}/${snippetName}`, undefined, res => res.text());
};

export const loadPeriodicScripts = () => {
  return http.get<PeriodicScript[]>(actions.PERIODIC_SCRIPTS);
};

export const savePeriodicScripts = (scripts: PeriodicScript[]) => {
  return http.post<void>(actions.PERIODIC_SCRIPTS, scripts);
};

export const saveNewScript = (script: Script) => {
  return http.post<void>(actions.SCRIPTS, script);
};

export const updateScript = (script: Script) => {
  return http.put<void>(actions.SCRIPTS, script);
};

export const deleteScript = (scriptName: string) => {
  return http.del<void>(`${actions.SCRIPTS.path}/${scriptName}`);
};
