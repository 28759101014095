export function toDicomDate(date: Date): string {
  if (!date || !(date instanceof Date)) return '';

  const yyyy = pad(date.getFullYear(), 4);
  const mm = pad(date.getMonth() + 1, 2);
  const dd = pad(date.getDate(), 2);
  return yyyy + mm + dd;
}

export function fromDicomDate(dicomDate: string | null): Date | null {
  if (!dicomDate) return null;

  const year = parseInt(dicomDate.slice(0, 4), 10);
  const month = parseInt(dicomDate.slice(4, 6), 10) - 1;
  const day = parseInt(dicomDate.slice(6, 8), 10);
  return new Date(year, month, day);
}

export function fromDicomTime(dicomTime: string | null): Date | null {
  if (!dicomTime) return null;

  const time = new Date(1970, 0, 1);
  
  const hh = dicomTime.slice(0, 2) || '00';
  time.setHours(parseInt(hh, 10));
  
  const mm = dicomTime.slice(2, 4) || '00';
  time.setMinutes(parseInt(mm, 10));

  const ss = dicomTime.slice(4, 6) || '00';
  time.setSeconds(parseInt(ss, 10));
  return time;
}

function pad(num: number, size: number): string {
  let string = num.toString();
  while (string.length < size) string = `0${string}`;
  return string;
}

export function toPersonName(firstName: string | null, lastName?: string | null): string {
  if (lastName && firstName) {
    return `${lastName}^${firstName}`;
  }

  if (lastName) return lastName;

  if (firstName) return `^${firstName}`;

  return '';
}

export function formatDicomName(name?: string): string {
  if (name) {
    let tokens = name?.split('^');
    return tokens.join(' ');
  }
  return '';
}