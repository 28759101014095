import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, FormControl, InputGroup, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import * as aiQueueActions from '../../../actions/aiQueueActions';
import { canPerformAction } from '../../../actions/userActions';
import { availableOperations } from '../../../reducers/userReducer';
import Pager from '../../Pager';
import AiQueueRow from './AiQueueRow';
import { getPending } from 'reducers/selectors';
import { useSelector } from 'react-redux';

export default function AiQueueTable({ params, queue, modules, modulesNames, user }) {
  const dispatch = useAppDispatch();
  const [queueFiltered, setQueueFiltered] = useState(queue);
  const [freeFilterValue, setFreeFilterValue] = useState('');
  const [algorithmFilterValue, setAlgorithmFilterValue] = useState(params.get("aiModuleFk"));
  const [statusFilterValue, setStatusFilterValue] = useState('');
  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(0);
  const [refreshPeriod, setRefreshPeriod] = useState(5);
  const pending = useSelector(getPending)?.aiQueue;
  const items = queueFiltered.length;
  const pages = Math.ceil(items / pageSize);
  const from = pageSize * page;
  const to = Math.min(items, pageSize * page + pageSize);
  const rows = queueFiltered.slice(from, to);
  const canManageAiModules = canPerformAction(user, availableOperations.CAN_MANAGE_AI_MODULES);
  
  useEffect(() => {
    applyFilter(freeFilterValue, algorithmFilterValue, statusFilterValue);
  }, [queue]);

  useEffect(() => {
    if (refreshPeriod === 0)
      return;

    const intervalId = setInterval(() => {
      dispatch(aiQueueActions.forceGetAiQueue());
    }, refreshPeriod * 1000);

    return () => {
      clearInterval(intervalId);
  };
  }, [refreshPeriod]);

  function handleChangePageSize(event) {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
  }

  function createAlgorithmOptions() {
    let options: JSX.Element[] = [
      <option key="any" value="">
        Any AI module
      </option>,
    ];
    modules.forEach(module =>
      options.push(
        <option key={ module.pk } value={ module.pk }>
          { JSON.parse(module.info).name }
        </option>
      )
    );
    return options;
  }

  function applyFilter(freeFilter, algorithmFilter, statusFilter) {
    setFreeFilterValue(freeFilter);
    setAlgorithmFilterValue(algorithmFilter);
    setStatusFilterValue(statusFilter);

    let i = 0;
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    let filteredQueue = new Array();
    queue.forEach(element => {
      const free = filterFree(element, freeFilter);
      const algorithm = filterAlgorithm(element, algorithmFilter);
      const status = filterStatus(element, statusFilter);
      if (free && algorithm && status) {
        filteredQueue[i] = element;
        i++;
      }
    });
    setQueueFiltered(filteredQueue);
  }

  function filterFree(element, freeFilter) {
    if (includes(element.studyInstanceUid, freeFilter))
      return true;
    if (includes(modulesNames[element.aiModuleFk], freeFilter))
      return true;
    if (includes(element.date, freeFilter))
      return true;
  }

  const includes = (param, text) => {
    return param != null && param.toUpperCase().includes(text);
  }

  function filterAlgorithm(element, algorithmFilter) {
    if (!algorithmFilter || algorithmFilter === '') return true;
    if (element.aiModuleFk.toString() === algorithmFilter) return true;
    return false;
  }

  function filterStatus(element, statusFilter) {
    if (!statusFilter || statusFilter === '') return true;
    if (element.status === statusFilter) return true;
    return false;
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'inline-block', width: '18em' }}>
            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                aria-label="Default"
                onChange={event => {
                  setPage(0);
                  applyFilter(event.target.value.toUpperCase(), algorithmFilterValue, statusFilterValue);
                }}
                aria-describedby="inputGroup-sizing-default"
                id="search-job-text-field"
              />
              <InputGroup.Append>
                <InputGroup.Text className="basic-addon2" id="inputGroup-sizing-default">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>

          <select
            className="selectBoxNum selectFilter"
            defaultValue={params.get("aiModuleFk") == null ? "any" : params.get("aiModuleFk")}
            onChange={e => {
              setPage(0);
              applyFilter(freeFilterValue, e.target.value, statusFilterValue);
              window.history.pushState(modulesNames[e.target.value], modulesNames[e.target.value], "/feed/aiModulesQueue?aiModuleFk=" + e.target.value.toString());
            }}
          >
            {createAlgorithmOptions()}
          </select>

          <select
            className="selectBoxNum selectFilter"
            defaultValue="any"
            onChange={e => {
              setPage(0);
              applyFilter(freeFilterValue, algorithmFilterValue, e.target.value);
            }}
          >
            <option value="">Any status</option>
            <option value="ENQUEUED">ENQUEUED</option>
            <option value="PROCESSING">PROCESSING</option>
            <option value="PAUSED">PAUSED</option>
            <option value="DONE">DONE</option>
            <option value="ERROR">ERROR</option>
          </select>
        </div>
        { items > 0 ? (
          <div>
            Showing items {from + 1} - {to} of {items}
          </div>
        ) : (
          <div>No items in queue</div>
        )}
        <div>
          Page size:
          <select className="selectBoxNum" value={pageSize} onChange={e => handleChangePageSize(e)}>
            <option value="12">12</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>

      <div style={{ display: 'flex' }}>

        <ButtonGroup size="sm">
          <Button
            disabled={pending}
            onClick={() => dispatch(aiQueueActions.forceGetAiQueue())}
          >
            Refresh
          </Button>
          <DropdownButton size="sm" as={ButtonGroup} title={ refreshPeriod === 0 ? 'off' : refreshPeriod + 's'} id="bg-nested-dropdown">
            <Dropdown.Item eventKey="1" onClick={e => setRefreshPeriod(0) }>off</Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={e => setRefreshPeriod(5) }>5s</Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={e => setRefreshPeriod(10) }>10s</Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>
      </div>

      <table className="queue-table">
        <thead>
          <tr>
            <th style={{ width: '25%' }}>Study Instance UID</th>
            <th style={{ width: '15%' }}>AI Module</th>
            <th style={{ width: '15%' }}>Date created</th>
            <th style={{ width: '15%' }}>Date updated</th>
            <th style={{ width: '10%' }}>Attempts</th>
            <th style={{ width: '10%' }}>Status</th>
            <th style={{ }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          { rows.length > 0 ? (
            rows.map((item, idx) => (
              <AiQueueRow
                key={`${item.studyUid}_${item.destination}`}
                item={item}
                modulesNames={modulesNames}
                authenticated={canManageAiModules}
              />
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={6}>
                {freeFilterValue === '' && algorithmFilterValue === '' && statusFilterValue === ''
                  ? 'ALL DONE!'
                  : 'NO MATCH!'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pager
        pages={pages}
        currentPage={page + 1}
        onPrev={() => setPage(Math.max(0, page - 1))}
        onNext={() => setPage(Math.min(Math.max(0, pages - 1), page + 1))}
        setPage={newPage => () => setPage(newPage)}
      />
    </div>
  );
}
