import Pager from 'components/Pager';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Card, Collapse, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getScriptsOfType, getUser, getWatchedFolders } from 'reducers/selectors';
import { availableOperations } from 'reducers/userReducer';
import WatchedFolderRow from './WatchedFolderRow';
import WatchedFolderEditSection from './WatchedFolderEditSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import useToaster from 'actions/useToaster';
import * as watchedFoldersActions from 'actions/watchedFoldersActions';
import { WatchedFolder } from 'reducers/watchedFoldersReducer';
import { getAllScripts } from 'actions/scriptActions';
import { RootState } from 'reducers';
import { canPerformAction } from 'actions/userActions';

const PAGE_SIZE = 5;

const WatchedFoldersPage = () => {
  const authorized = canPerformAction(useSelector(getUser), availableOperations.CAN_MANAGE_WATCHED_FOLDERS);
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  const watchedFolders = useSelector(getWatchedFolders);
  const scripts = useSelector(state => getScriptsOfType(state as RootState, 'FILE_HANDLER'));

  const [editSectionParams, setEditSectionParams] = useState<{ show: boolean; editingFolder: null | WatchedFolder }>({
    show: false,
    editingFolder: null,
  });
  const [deletePopupParams, setDeletePopupParams] = useState<{ show: boolean; deletingFolder: null | WatchedFolder }>({
    show: false,
    deletingFolder: null,
  });

  const [page, setPage] = useState<number>(0);
  const pages = Math.ceil(watchedFolders.length / PAGE_SIZE);
  const from = PAGE_SIZE * page;
  const to = Math.min(watchedFolders.length, PAGE_SIZE * page + PAGE_SIZE);
  const rows = watchedFolders.slice(from, to);

  useEffect(() => {
    dispatch(watchedFoldersActions.getWatchedFolders());
    dispatch(getAllScripts());
  }, []);

  function upsertFolder(folder: WatchedFolder) {
    if (folder.pk === null) {
      watchedFoldersActions
        .insertFolder(folder)
        .then(() => {
          dispatch(watchedFoldersActions.forceGetWatchedFolders());
          toaster.success('Successfully added watched folder');
        })
        .catch(err => {
          console.error(err);
          toaster.error('Error adding deletion rule');
        });
    } else {
      watchedFoldersActions
        .updateFolder(folder)
        .then(() => {
          dispatch(watchedFoldersActions.forceGetWatchedFolders());
          toaster.success('Successfully added watched folder');
        })
        .catch(err => {
          console.error(err);
          toaster.error('Error adding deletion rule');
        });
    }
    setEditSectionParams({ show: false, editingFolder: folder });
  }

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <div style={{ marginBottom: '2em' }}>
          <h3 style={{ display: 'inline' }}>Watched folders</h3>
          <div style={{ float: 'right' }}>
            <Button
              size="sm"
              disabled={!authorized}
              onClick={() => setEditSectionParams({ show: true, editingFolder: null })}
            >
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '5px' }} />
              Add new watched folder
            </Button>
          </div>
        </div>
        <table className="queue-table">
          <thead>
            <tr>
              <th style={{ width: '15%' }}>Queue</th>
              <th style={{ width: '30%' }}>Folder</th>
              <th style={{ width: '9%' }}>Check subfolders</th>
              <th style={{ width: '9%' }}>Reprocess</th>
              <th style={{ width: '15%' }}>Script</th>
              <th style={{ width: '10%' }}>Workers</th>
              <th style={{ width: '10%' }}>Enabled</th>
              <th style={{ width: '5%' }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {rows.map(watchedFolder => (
              <WatchedFolderRow
                key={watchedFolder.pk}
                watchedFolder={watchedFolder}
                setEditParams={setEditSectionParams}
                setDeleteParams={setDeletePopupParams}
              />
            ))}
          </tbody>
        </table>
        <small style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '0.5rem' }}>
          NB: remember to restart the services after updating this table
        </small>
        <Pager
          pages={pages}
          currentPage={page + 1}
          onPrev={() => setPage(Math.max(0, page - 1))}
          onNext={() => setPage(Math.min(Math.max(0, pages - 1), page + 1))}
          setPage={(newPage: number) => () => setPage(newPage)}
        />
        <Collapse in={editSectionParams.show}>
          <div>
            <WatchedFolderEditSection
              watchedFolder={editSectionParams.editingFolder}
              scripts={scripts}
              onCancel={() => setEditSectionParams({ show: false, editingFolder: editSectionParams.editingFolder })}
              onSave={upsertFolder}
            />
          </div>
        </Collapse>
      </Card.Body>

      <Modal show={deletePopupParams.show} onHide={() => setDeletePopupParams({ show: false, deletingFolder: null })}>
        <Modal.Header>
          <Modal.Title>Are you sure you want to delete this watched folder?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeletePopupParams({ show: false, deletingFolder: null })}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              if (deletePopupParams.deletingFolder) {
                watchedFoldersActions
                  .deleteFolder(deletePopupParams.deletingFolder)
                  .then(() => {
                    dispatch(watchedFoldersActions.forceGetWatchedFolders());
                    toaster.success('Successfully deleted watched folder');
                  })
                  .catch(err => {
                    console.error(err);
                    toaster.error('Error deleting deletion rule');
                  });
              }
              setDeletePopupParams({ show: false, deletingFolder: null });
            }}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default WatchedFoldersPage;
