import * as userActions from 'actions/userActions';
import JSDButton from 'components/popups/JSDButton';
import { useAppDispatch } from 'hooks';
import React, { useState } from 'react';
import { Button, Col, Form, FormControl, FormGroup, FormLabel, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import App from '../../App';
import { getUser } from '../../reducers/selectors';
import GoogleLogin from './GoogleLogin';
import PasswordTextBox from './PasswordTextBox';

function Login() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<{ present: boolean; message?: string }>({ present: false });
  const [showGoogleLogin, setShowGoogleLogin] = useState<boolean>(false);

  function login() {
    userActions
      .login(username, password)
      .then(res => {
        dispatch({
          type: 'SET_USER',
          payload: {
            ...res,
            authenticated: true,
          },
        });
        setUsername('');
        setPassword('');
      })
      .catch(() => {
        setError({ present: true, message: 'Login failed' });
      });
  }

  return (
    <React.Fragment>
      {!user.authenticated ? (
        <React.Fragment>
          <div className="background-login" />
          <div className="login-box">
            <div className="login-card">
              <div className="feed-img" onDoubleClick={() => setShowGoogleLogin(true)} />
              <Form
                style={{ marginTop: '2em' }}
                onKeyPress={e => {
                  if (e.charCode === 13) {
                    login();
                  }
                }}
              >
                <FormGroup as={Row} className="login-row">
                  <FormLabel column sm={2} md={{ offset: 1 }} className="login-label">
                    Username
                  </FormLabel>
                  <Col sm={8}>
                    <FormControl
                      size="sm"
                      type="text"
                      placeholder="Username"
                      onChange={e => {
                        setUsername(e.target.value);
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup as={Row} className="login-row">
                  <Form.Label column sm={4} md={{ offset: 1 }} className="login-label">
                    Password
                  </Form.Label>
                  <Col sm={8}>
                    <PasswordTextBox
                      placeholder="Password..."
                      password={password}
                      setPassword={e => {
                        setPassword(e.target.value);
                      }}
                    />
                  </Col>
                </FormGroup>
                {error.present && (
                  <div style={{ textAlign: 'center' }}>
                    <strong style={{ color: '#f86c6b' }}>{error.message}</strong>
                  </div>
                )}
                <Button variant="outline-primary" className="login-button" onClick={() => login()}>
                  Log in
                </Button>
                {showGoogleLogin && <GoogleLogin setError={setError} setShowGoogleLogin={setShowGoogleLogin} />}
              </Form>
            </div>
            <JSDButton isVisible={false} />
          </div>
        </React.Fragment>
      ) : (
        <App dispatch={dispatch} />
      )}
    </React.Fragment>
  );
}

export default Login;
