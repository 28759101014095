import React, { useState, useEffect } from 'react';
import useToaster from 'actions/useToaster';
import { FormControl, Form } from 'react-bootstrap';
import { disableUser, enableUser, updateUsersInformation, canPerformAction } from 'actions/userActions';
import { availableOperations, Role, User } from 'reducers/userReducer';
import EditableTextCell from 'components/EditableTextCell';

type UsersSettingsRowType = {
  user: User;
  roles: Role[];
  currentUser: User;
  getAllUsers: () => void;
};

function UsersSettingsRow({ user, roles, currentUser, getAllUsers }: UsersSettingsRowType) {
  const toaster = useToaster();

  const [newUsername, setNewUsername] = useState(user.username);
  const [newRoleName, setNewRoleName] = useState(user.role!.name);

  const canManageUsers = canPerformAction(currentUser, availableOperations.CAN_MANAGE_USERS);
  const canChangeUserRole = currentUser.role?.privileged;

  useEffect(() => {
    setNewUsername(user.username);
    setNewRoleName(user.role!.name);
  }, []);

  useEffect(() => {
    saveRole();
  }, [newRoleName]);

  function saveRole() {
    if (newRoleName === user.role!.name) {
      return;
    }
    const newRoleTemp = roles.find(role => role.name === newRoleName);
    if (newRoleTemp) {
      updateUsersInformation(newRoleTemp.pk, newUsername, user.username, user.role!.privileged)
        .then(() => {
          getAllUsers();
          if (user.username! === currentUser.username) {
            toaster.info(`You updated your role. Next time you log in or refresh it will change`);
          }
          toaster.success('User updated successfully');
        })
        .catch(error => {
          console.log(error.message);
          toaster.error(error.message);
          setNewUsername(user.username);
          setNewRoleName(user.role!.name);
        });
    }
  }

  function saveUsername() {
    if (newUsername === '') {
      return;
    }
    if (newUsername === user.username) {
      return;
    }
    const newRoleTemp = roles.find(role => role.name === newRoleName);
    if (newRoleTemp) {
      updateUsersInformation(newRoleTemp.pk, newUsername, user.username, user.role!.privileged)
        .then(() => {
          getAllUsers();
        })
        .catch(error => {
          console.log(error.message);
          toaster.error(error.message);
          setNewUsername(user.username);
          setNewRoleName(user.role!.name);
        });
    }
  }

  return (
    <tr key={user.username}>
      {canManageUsers ? (
        <td>
          <EditableTextCell
            value={newUsername}
            onChange={newValue => {
              setNewUsername(newValue);
            }}
            onSave={() => {
              saveUsername();
            }}
          />
        </td>
      ) : (
        <td>{newUsername}</td>
      )}
      <td>
        <FormControl
          as="select"
          size="sm"
          style={{ width: '75%' }}
          disabled={!canChangeUserRole}
          value={newRoleName}
          onChange={e => setNewRoleName(e.target.value)}
        >
          <option value={user.role!.name} disabled>
            {user.role!.name}
          </option>
          {roles &&
            roles.length > 0 &&
            roles.map(role => {
              return (
                <option key={role.name} value={role.name}>
                  {role.name}
                </option>
              );
            })}
        </FormControl>
      </td>
      <td style={{ padding: '0' }}>
        <div style={{ display: 'inline' }}>
          <Form.Check
            type="switch"
            id={`action-switch-enable-${user.username}`}
            label=""
            disabled={!canManageUsers}
            checked={user.enabled}
            onChange={() => {
              if (user.enabled) {
                disableUser(user.username!, user.role!.privileged)
                  .then(() => {
                    getAllUsers();
                    if (user.username! === currentUser.username) {
                      toaster.info('You disabled your user. You will remain logged only for this session');
                    }
                    toaster.success('User disabled successfully');
                  })
                  .catch(error => {
                    console.log(error.message);
                    toaster.error(error.message);
                  });
              } else {
                enableUser(user.username!)
                  .then(() => {
                    getAllUsers();
                    toaster.success('User enabled successfully');
                  })
                  .catch(error => {
                    console.log(error.message);
                    toaster.error('Error enabling user');
                  });
              }
            }}
            style={{ display: 'inline' }}
          />
        </div>
      </td>
    </tr>
  );
}

export default UsersSettingsRow;
