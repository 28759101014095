import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const RedirectionPopup = ({ message, path, onClose }) => {
  return (
    <Modal show>
      <Modal.Body>
        <p>{message}</p>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClose} variant="outline-secondary" style={{ minWidth: '6em' }}>
            Cancel
          </Button>
          <Button as={Link} to={path} style={{ minWidth: '6em', marginLeft: '0.7em' }}>
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RedirectionPopup;