import React, { useState } from 'react';
import { Form, FormGroup, Row, Button, Col } from 'react-bootstrap';
import { updatePassword } from 'actions/userActions';
import useToaster from '../../actions/useToaster';
import PasswordTextBox from './PasswordTextBox';
import { User } from 'reducers/userReducer';

function ChangePasswordModal({ user }: { user: User }) {
  const toaster = useToaster();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  function change() {
    if (oldPassword === '' || newPassword === '' || repeatNewPassword === '') {
      setErrorMessage('You must fill the form before proceeding');
      return;
    }
    if (newPassword !== repeatNewPassword) {
      setErrorMessage('The new password and the repeated password are not equal');
      return;
    }

    updatePassword(user.username, oldPassword, newPassword)
      .then(() => {
        setErrorMessage('');
        toaster.success('Updated password correctly');
        setOldPassword('');
        setNewPassword('');
        setRepeatNewPassword('');
      })
      .catch(error => {
        console.error(error);
        setErrorMessage('Error updating password');
      });
  }

  return (
    <div>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Old password
        </Form.Label>
        <Col sm={8}>
          <PasswordTextBox
            placeholder="Old password..."
            password={oldPassword}
            setPassword={e => setOldPassword(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          New password
        </Form.Label>
        <Col sm={8}>
          <PasswordTextBox
            placeholder="New password..."
            password={newPassword}
            setPassword={e => setNewPassword(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Repeat new password
        </Form.Label>
        <Col sm={8}>
          <PasswordTextBox
            placeholder="Repeat new password..."
            password={repeatNewPassword}
            setPassword={e => setRepeatNewPassword(e.target.value)}
          />
        </Col>
      </FormGroup>
      {errorMessage !== '' && (
        <div style={{ textAlign: 'center' }}>
          <strong style={{ color: '#f86c6b' }}>{errorMessage}</strong>
        </div>
      )}
      <div style={{ textAlign: 'right' }}>
        <br />
        <Button
          size="sm"
          variant="outline-secondary"
          style={{ marginRight: '5px' }}
          onClick={() => {
            setOldPassword('');
            setNewPassword('');
            setRepeatNewPassword('');
          }}
        >
          Cancel
        </Button>
        <Button size="sm" style={{ width: '9em' }} variant="primary" onClick={change}>
          Apply changes
        </Button>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
