import { AiQueue } from 'reducers/aiQueueReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetAiQueue = (dispatch: Dispatch) => http.get<AiQueue[]>(actions.GET_AI_QUEUE, dispatch);
const debouncedGetAiQueue = defaultDebounce(regularGetAiQueue);

export const getAiQueue = () => debouncedGetAiQueue;
export const forceGetAiQueue = () => regularGetAiQueue;

export const reprocessEnqueuedItem = item => dispatch => {
  const body = {
    pk: item.pk,
  };
  http.post<AiQueue[]>(actions.REPROCESS_AI_QUEUED_ITEM, body, dispatch);
  dispatch(forceGetAiQueue());
};

export const pauseEnqueuedItem = item => dispatch => {
  const body = {
    pk: item.pk,
  };
  http.post<void>(actions.PAUSE_AI_QUEUED_ITEM, body, dispatch);
  dispatch(forceGetAiQueue());
};
