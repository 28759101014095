import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface GloryMachine {
  name: string;
  ip: string;
  username: string;
  password: string;

  // parameters coming from web socket events
  lastHeartBeat?: number;
  status?: {
    status: string;
    description: string;
  };
  error?: string;
}

export interface GloryHeartbeatEvent {
  event: 'GloryHeartbeatEvent';
  deviceName: string;
}

export interface GloryStatusChangeEvent {
  event: 'GloryStatusChangeEvent';
  deviceName: string;
  status: string;
  statusDescription: string;
}

type GloryEvent = GloryHeartbeatEvent | GloryStatusChangeEvent;

type GloryMachineReducerType = {
  type: typeof actions.GET_GLORY_MACHINES_FULFILLED | typeof actions.WS_EVENT;
  response: AxiosResponse<GloryMachine[]>;
  payload?: GloryHeartbeatEvent | GloryStatusChangeEvent;
};
// eslint-disable-next-line @typescript-eslint/default-param-last
function gloryMachines(state: GloryMachine[] = [], action: GloryMachineReducerType): GloryMachine[] {
  switch (action.type) {
    case actions.GET_GLORY_MACHINES_FULFILLED:
      return action.response.data;
    case actions.WS_EVENT:
      return wsAction(action, state);
    default:
      return state;
  }
}

function wsAction(action: GloryMachineReducerType, machines: GloryMachine[]): GloryMachine[] {
  if (action.payload) {
    const gloryEvent: GloryEvent = action.payload;
    const copy = [...machines];
    const currentMachine = copy.find(machine => machine.name === gloryEvent.deviceName);

    if (currentMachine) {
      if (gloryEvent.event === 'GloryHeartbeatEvent') {
        currentMachine.lastHeartBeat = Date.now();
      } else if (gloryEvent.event === 'GloryStatusChangeEvent') {
            currentMachine.status = {
              status: gloryEvent.status,
              description: gloryEvent.statusDescription,
            };
      }
      return copy;
    }
  }

  return machines;
}

export default gloryMachines;
