import { AxiosResponse, AxiosError } from 'axios';

export const GET_QUEUE_PENDING = 'GET_QUEUE_PENDING';
export const GET_QUEUE_FULFILLED = 'GET_QUEUE_FULFILLED';
export const GET_QUEUE_REJECTED = 'GET_QUEUE_REJECTED';

export const GET_AI_QUEUE_PENDING = 'GET_AI_QUEUE_PENDING';
export const GET_AI_QUEUE_FULFILLED = 'GET_AI_QUEUE_FULFILLED';
export const GET_AI_QUEUE_REJECTED = 'GET_AI_QUEUE_REJECTED';

export const GET_FILE_QUEUE_PENDING = 'GET_FILE_QUEUE_PENDING';
export const GET_FILE_QUEUE_FULFILLED = 'GET_FILE_QUEUE_FULFILLED';
export const GET_FILE_QUEUE_REJECTED = 'GET_FILE_QUEUE_REJECTED';

export const GET_FEED_STATUS_PENDING = 'GET_FEED_STATUS_PENDING';
export const GET_FEED_STATUS_FULFILLED = 'GET_FEED_STATUS_FULFILLED';
export const GET_FEED_STATUS_REJECTED = 'GET_FEED_STATUS_REJECTED';

export const INCREASE_PRIORITY_PENDING = 'INCREASE_PRIORITY_PENDING';
export const INCREASE_PRIORITY_FULFILLED = 'INCREASE_PRIORITY_FULFILLED';
export const INCREASE_PRIORITY_REJECTED = 'INCREASE_PRIORITY_REJECTED';

export const RESUME_ENQUEUED_ITEM_PENDING = 'RESUME_ENQUEUED_ITEM_PENDING';
export const RESUME_ENQUEUED_ITEM_FULFILLED = 'RESUME_ENQUEUED_ITEM_FULFILLED';
export const RESUME_ENQUEUED_ITEM_REJECTED = 'RESUME_ENQUEUED_ITEM_REJECTED';

export const REPROCESS_AI_QUEUED_ITEM_REJECTED = 'REPROCESS_AI_QUEUED_ITEM_REJECTED';
export const REPROCESS_AI_QUEUED_ITEM_FULFILLED = 'REPROCESS_AI_QUEUED_ITEM_FULFILLED';
export const REPROCESS_AI_QUEUED_ITEM_PENDING = 'REPROCESS_AI_QUEUED_ITEM_PENDING';

export const PAUSE_AI_QUEUED_ITEM_REJECTED = 'PAUSE_AI_QUEUED_ITEM_REJECTED';
export const PAUSE_AI_QUEUED_ITEM_FULFILLED = 'PAUSE_AI_QUEUED_ITEM_FULFILLED';
export const PAUSE_AI_QUEUED_ITEM_PENDING = 'PAUSE_AI_QUEUED_ITEM_PENDING';

export const REPROCESS_FILE_QUEUED_ITEM_REJECTED = 'REPROCESS_FILE_QUEUED_ITEM_REJECTED';
export const REPROCESS_FILE_QUEUED_ITEM_FULFILLED = 'REPROCESS_FILE_QUEUED_ITEM_FULFILLED';
export const REPROCESS_FILE_QUEUED_ITEM_PENDING = 'REPROCESS_FILE_QUEUED_ITEM_PENDING';

export const PAUSE_FILE_QUEUED_ITEM_REJECTED = 'PAUSE_FILE_QUEUED_ITEM_REJECTED';
export const PAUSE_FILE_QUEUED_ITEM_FULFILLED = 'PAUSE_FILE_QUEUED_ITEM_FULFILLED';
export const PAUSE_FILE_QUEUED_ITEM_PENDING = 'PAUSE_FILE_QUEUED_ITEM_PENDING';

export const PAUSE_ENQUEUED_ITEM_PENDING = 'PAUSE_ENQUEUED_ITEM_PENDING';
export const PAUSE_ENQUEUED_ITEM_FULFILLED = 'PAUSE_ENQUEUED_ITEM_FULFILLED';
export const PAUSE_ENQUEUED_ITEM_REJECTED = 'PAUSE_ENQUEUED_ITEM_REJECTED';

export const DELETE_ENQUEUED_ITEM_PENDING = 'DELETE_ENQUEUED_ITEM_PENDING';
export const DELETE_ENQUEUED_ITEM_FULFILLED = 'DELETE_ENQUEUED_ITEM_FULFILLED';
export const DELETE_ENQUEUED_ITEM_REJECTED = 'DELETE_ENQUEUED_ITEM_REJECTED';

export const SEARCH_STUDIES_PENDING = 'SEARCH_STUDIES_PENDING';
export const SEARCH_STUDIES_FULFILLED = 'SEARCH_STUDIES_FULFILLED';
export const SEARCH_STUDIES_REJECTED = 'SEARCH_STUDIES_REJECTED';

export const SEARCH_SERIES_PENDING = 'SEARCH_SERIES_PENDING';
export const SEARCH_SERIES_FULFILLED = 'SEARCH_SERIES_FULFILLED';
export const SEARCH_SERIES_REJECTED = 'SEARCH_SERIES_REJECTED';

export const GET_NODES_PENDING = 'GET_NODES_PENDING';
export const GET_NODES_FULFILLED = 'GET_NODES_FULFILLED';
export const GET_NODES_REJECTED = 'GET_NODES_REJECTED';

export const GET_CONDITIONS_PENDING = 'GET_CONDITIONS_PENDING';
export const GET_CONDITIONS_FULFILLED = 'GET_CONDITIONS_FULFILLED';
export const GET_CONDITIONS_REJECTED = 'GET_CONDITIONS_REJECTED';

export const GET_NODES_ENCODINGS_PENDING = 'GET_NODES_ENCODINGS_PENDING';
export const GET_NODES_ENCODINGS_FULFILLED = 'GET_NODES_ENCODINGS_FULFILLED';
export const GET_NODES_ENCODINGS_REJECTED = 'GET_NODES_ENCODINGS_REJECTED';

export const IS_LAST_TOUCHED_NODE = 'IS_LAST_TOUCHED_NODE';
export const IS_LAST_TOUCHED_RULE = 'IS_LAST_TOUCHED_RULE';

export const GET_DELETION_RULES_PENDING = 'GET_DELETION_RULES_PENDING';
export const GET_DELETION_RULES_FULFILLED = 'GET_DELETION_RULES_FULFILLED';
export const GET_DELETION_RULES_REJECTED = 'GET_DELETION_RULES_REJECTED';

export const GET_AI_MODULES_PENDING = 'GET_AI_MODULES_PENDING';
export const GET_AI_MODULES_FULFILLED = 'GET_AI_MODULES_FULFILLED';
export const GET_AI_MODULES_REJECTED = 'GET_AI_MODULES_REJECTED';

export const SET_SELECTED_AI_MODULE = 'SET_SELECTED_AI_MODULE';

export const GET_FORWARD_RULES_PENDING = 'GET_FORWARD_RULES_PENDING';
export const GET_FORWARD_RULES_FULFILLED = 'GET_FORWARD_RULES_FULFILLED';
export const GET_FORWARD_RULES_REJECTED = 'GET_FORWARD_RULES_REJECTED';

export const GET_ALL_SCRIPTS_PENDING = 'GET_ALL_SCRIPTS_PENDING';
export const GET_ALL_SCRIPTS_FULFILLED = 'GET_ALL_SCRIPTS_FULFILLED';
export const GET_ALL_SCRIPTS_REJECTED = 'GET_ALL_SCRIPTS_REJECTED';

export const GET_PREFETCH_QUEUE_PENDING = 'GET_PREFETCH_QUEUE_PENDING';
export const GET_PREFETCH_QUEUE_FULFILLED = 'GET_PREFETCH_QUEUE_FULFILLED';
export const GET_PREFETCH_QUEUE_REJECTED = 'GET_PREFETCH_QUEUE_REJECTED';

export const GET_WORKLIST_NODES_PENDING = 'GET_WORKLIST_NODES_PENDING';
export const GET_WORKLIST_NODES_FULFILLED = 'GET_WORKLIST_NODES_FULFILLED';
export const GET_WORKLIST_NODES_REJECTED = 'GET_WORKLIST_NODES_REJECTED';

export const GET_PROXY_CONFIGURATION_PENDING = 'GET_PROXY_CONFIGURATION_PENDING';
export const GET_PROXY_CONFIGURATION_FULFILLED = 'GET_PROXY_CONFIGURATION_FULFILLED';
export const GET_PROXY_CONFIGURATION_REJECTED = 'GET_PROXY_CONFIGURATION_REJECTED';

export const UPDATE_NODE_PENDING = 'UPDATE_NODE_PENDING';
export const UPDATE_NODE_FULFILLED = 'UPDATE_NODE_FULFILLED';
export const UPDATE_NODE_REJECTED = 'UPDATE_NODE_REJECTED';

export const SERIES_DISPOSAL_PENDING = 'DELETE_SERIES_PENDING';
export const SERIES_DISPOSAL_FULFILLED = 'DELETE_SERIES_FULFILLED';
export const SERIES_DISPOSAL_REJECTED = 'DELETE_SERIES_REJECTED';
export const SERIES_DISPOSAL_ACKNOWLEDGED = 'DELETE_SERIES_ACKNOWLEDGED';

export const SERIES_DISPOSAL_STUDY_IN_QUEUE = 'SERIES_DISPOSAL_STUDY_IN_QUEUE';
export const SERIES_DISPOSAL_ABORT = 'SERIES_DISPOSAL_ABORT';
export const SERIES_DISPOSAL_SHOW_CONFIRMATION_POPUP = 'SERIES_DISPOSAL_SHOW_CONFIRMATION_POPUP';

export const SET_SELECTED_STUDY = 'SET_SELECTED_STUDY';

export const SET_SELECTED_STUDY_SIZE = 'SET_SELECTED_STUDY_SIZE';
export const SET_STUDY_TABLE_PAGE = 'SET_STUDY_TABLE_PAGE';
export const SET_SEARCH_FIELD = 'SET_SEARCH_FIELD';

export const UPDATE_QUEUE_TABLE = 'UPDATE_QUEUE_TABLE';
export const UPDATE_PREFETCH_TABLE = 'UPDATE_PREFETCH_TABLE';
export const HIDE_TOASTER = 'HIDE_TOASTER';

export const WS_EVENT = 'WS_EVENT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_RECONNECTED = 'WS_RECONNECTED';

export const GET_PRINTERS_PENDING = 'GET_PRINTERS_PENDING';
export const GET_PRINTERS_FULFILLED = 'GET_PRINTERS_FULFILLED';
export const GET_PRINTERS_REJECTED = 'GET_PRINTERS_REJECTED';

export const GET_READERS_PENDING = 'GET_READERS_PENDING';
export const GET_READERS_FULFILLED = 'GET_READERS_FULFILLED';
export const GET_READERS_REJECTED = 'GET_READERS_REJECTED';

// TO DELETE:
export const GET_PRINTERS_STATUSES_PENDING = 'GET_PRINTERS_STATUSES_PENDING';
export const GET_PRINTERS_STATUSES_FULFILLED = 'GET_PRINTERS_STATUSES_FULFILLED';
export const GET_PRINTERS_STATUSES_REJECTED = 'GET_PRINTERS_STATUSES_REJECTED';

export const GET_BURNERS_STATUS_PENDING = 'GET_BURNERS_STATUS_PENDING';
export const GET_BURNERS_STATUS_FULFILLED = 'GET_BURNERS_STATUS_FULFILLED';
export const GET_BURNERS_STATUS_REJECTED = 'GET_BURNERS_STATUS_REJECTED';

export const GET_BURNERS_JOBS_PENDING = 'GET_BURNERS_JOBS_PENDING';
export const GET_BURNERS_JOBS_FULFILLED = 'GET_BURNERS_JOBS_FULFILLED';
export const GET_BURNERS_JOBS_REJECTED = 'GET_BURNERS_JOBS_REJECTED';

export const POLLING_ON = 'POLLING_ON';
export const POLLING_OFF = 'POLLING_OFF';
export const GET_CFG_SERVICES_PENDING = 'GET_CFG_SERVICES_PENDING';
export const GET_CFG_SERVICES_FULFILLED = 'GET_CFG_SERVICES_FULFILLED';
export const GET_CFG_SERVICES_REJECTED = 'GET_CFG_SERVICES_REJECTED';

export const GET_CFG_DICOM_SERVICES_PENDING = 'GET_CFG_DICOM_SERVICES_PENDING';
export const GET_CFG_DICOM_SERVICES_FULFILLED = 'GET_CFG_DICOM_SERVICES_FULFILLED';
export const GET_CFG_DICOM_SERVICES_REJECTED = 'GET_CFG_DICOM_SERVICES_REJECTED';

export const SET_USER = 'SET_USER';

export const SET_STRONG_PASSWORD = 'SET_STRONG_PASSWORD';
export const SET_PASSWORD_SCORE = 'SET_PASSWORD_SCORE';

export const ADD_USER_ACTION = 'ADD_USER_ACTION';
export const REMOVE_USER_ACTION = 'REMOVE_USER_ACTION';
export const DELETE_EVERY_ACTION = 'DELETE_EVERY_ACTION';
export const ADD_EVERY_ACTION = 'ADD_EVERY_ACTION';

export const GET_STUDIES_TO_BURN = 'GET_STUDIES_TO_BURN';
export const ADD_STUDY_TO_BURN = 'ADD_STUDY_TO_BURN';
export const DELETE_STUDY_TO_BURN = 'DELETE_STUDY_TO_BURN';
export const DELETE_ALL_STUDIES_TO_BURN = 'DELETE_ALL_STUDIES_TO_BURN';

export const GET_GLORY_MACHINES_PENDING = 'GET_GLORY_MACHINES_PENDING';
export const GET_GLORY_MACHINES_FULFILLED = 'GET_GLORY_MACHINES_FULFILLED';
export const GET_GLORY_MACHINES_REJECTED = 'GET_GLORY_MACHINES_REJECTED';

export const GET_WATCHED_FOLDERS_PENDING = 'GET_WATCHED_FOLDERS_PENDING';
export const GET_WATCHED_FOLDERS_FULFILLED = 'GET_WATCHED_FOLDERS_FULFILLED';
export const GET_WATCHED_FOLDERS_REJECTED = 'GET_WATCHED_FOLDERS_REJECTED';

export interface ActionCatalog {
  path: string;
  pending?: PendingAction;
  fulfilled?: FulfilledAction<any>;
  rejected?: RejectedAction;
}

type PendingAction = (payload?: any) => { type: string };

type RejectedAction = (error?: AxiosError) => { type: string; error?: AxiosError };

export type ObjectAction<T> = {
  type: string;
  payload: T;
};

export type FulfilledAction<T> = (
  request: T,
  response: AxiosResponse<T>
) => { type: string; request: T; response?: AxiosResponse<T>; payload?: T };

export const GET_QUEUE: ActionCatalog = {
  path: `/rest/react/queue`,
  pending: () => ({ type: GET_QUEUE_PENDING }),
  fulfilled: (request, response) => ({ type: GET_QUEUE_FULFILLED, request, response }),
  rejected: error => ({ type: GET_QUEUE_REJECTED, error }),
};

export const GET_FEED_STATUS: ActionCatalog = {
  path: `/rest/react/storageStatistics`,
  pending: () => ({ type: GET_FEED_STATUS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_FEED_STATUS_FULFILLED, request, response }),
  rejected: error => ({ type: GET_FEED_STATUS_REJECTED, error }),
};

export const INCREASE_PRIORITY: ActionCatalog = {
  path: `/rest/react/increasePriority`,
  pending: payload => ({ type: INCREASE_PRIORITY_PENDING, payload }),
  fulfilled: (request, response) => ({ type: INCREASE_PRIORITY_FULFILLED, request, response }),
  rejected: error => ({ type: INCREASE_PRIORITY_REJECTED, error }),
};

export const RESUME_ENQUEUED_ITEM: ActionCatalog = {
  path: `/rest/react/resumeEnqueuedItem`,
  pending: payload => ({ type: RESUME_ENQUEUED_ITEM_PENDING, payload }),
  fulfilled: (request, response) => ({ type: RESUME_ENQUEUED_ITEM_FULFILLED, request, response }),
  rejected: error => ({ type: RESUME_ENQUEUED_ITEM_REJECTED, error }),
};

export const PAUSE_ENQUEUED_ITEM: ActionCatalog = {
  path: `/rest/react/pauseEnqueuedItem`,
  pending: payload => ({ type: PAUSE_ENQUEUED_ITEM_PENDING, payload }),
  fulfilled: (request, response) => ({ type: PAUSE_ENQUEUED_ITEM_FULFILLED, request, response }),
  rejected: error => ({ type: PAUSE_ENQUEUED_ITEM_REJECTED, error }),
};

export const DELETE_ENQUEUED_ITEM: ActionCatalog = {
  path: `/rest/react/deleteEnqueuedItem`,
  pending: payload => ({ type: DELETE_ENQUEUED_ITEM_PENDING, payload }),
  fulfilled: (request, response) => ({ type: DELETE_ENQUEUED_ITEM_FULFILLED, request, response }),
  rejected: error => ({ type: DELETE_ENQUEUED_ITEM_REJECTED, error }),
};

export const SEARCH_STUDIES: ActionCatalog = {
  path: `/rest/react/searchStudies`,
  pending: () => ({ type: SEARCH_STUDIES_PENDING }),
  fulfilled: (request, response) => ({ type: SEARCH_STUDIES_FULFILLED, request, response }),
  rejected: () => ({ type: SEARCH_STUDIES_REJECTED }),
};

export const SEARCH_SERIES: ActionCatalog = {
  path: `/rest/react/searchSeries`,
  pending: () => ({ type: SEARCH_SERIES_PENDING }),
  fulfilled: (request, response) => ({ type: SEARCH_SERIES_FULFILLED, request, response }),
  rejected: () => ({ type: SEARCH_SERIES_REJECTED }),
};

export const GET_NODES: ActionCatalog = {
  path: `/rest/react/getNodes`,
  pending: () => ({ type: GET_NODES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_NODES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_NODES_REJECTED }),
};

export const GET_CONDITIONS: ActionCatalog = {
  path: `/rest/react/getConditions`,
  pending: () => ({ type: GET_CONDITIONS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_CONDITIONS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_CONDITIONS_REJECTED }),
};

export const GET_DELETION_RULES: ActionCatalog = {
  path: `/rest/react/getDeletionRules`,
  pending: () => ({ type: GET_DELETION_RULES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_DELETION_RULES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_DELETION_RULES_REJECTED }),
};

export const GET_AI_MODULES: ActionCatalog = {
  path: `/rest/react/getAiModules`,
  pending: () => ({ type: GET_AI_MODULES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_AI_MODULES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_AI_MODULES_REJECTED }),
};

export const GET_AI_QUEUE: ActionCatalog = {
  path: `/rest/react/getAiQueue`,
  pending: () => ({ type: GET_AI_QUEUE_PENDING }),
  fulfilled: (request, response) => ({ type: GET_AI_QUEUE_FULFILLED, request, response }),
};

export const GET_FILE_QUEUE: ActionCatalog = {
  path: `/rest/react/getFileQueue`,
  pending: () => ({ type: GET_FILE_QUEUE_PENDING }),
  fulfilled: (request, response) => ({ type: GET_FILE_QUEUE_FULFILLED, request, response }),
};

export const GET_NODES_ENCODINGS: ActionCatalog = {
  path: `/rest/react/getNodesEncodings`,
  pending: () => ({ type: GET_NODES_ENCODINGS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_NODES_ENCODINGS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_NODES_ENCODINGS_REJECTED }),
};

export const GET_FORWARD_RULES: ActionCatalog = {
  path: `/rest/react/getForwardRules`,
  pending: () => ({ type: GET_FORWARD_RULES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_FORWARD_RULES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_FORWARD_RULES_REJECTED }),
};

export const GET_PREFETCH_QUEUE: ActionCatalog = {
  path: `/rest/react/getPrefetchQueue`,
  pending: () => ({ type: GET_PREFETCH_QUEUE_PENDING }),
  fulfilled: (request, response) => ({ type: GET_PREFETCH_QUEUE_FULFILLED, request, response }),
  rejected: () => ({ type: GET_PREFETCH_QUEUE_REJECTED }),
};

export const GET_WORKLIST_NODES: ActionCatalog = {
  path: `/rest/react/getWorklistNodes`,
  pending: () => ({ type: GET_WORKLIST_NODES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_WORKLIST_NODES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_WORKLIST_NODES_REJECTED }),
};

export const GET_PROXY_CONFIGURATION: ActionCatalog = {
  path: `/rest/react/getProxyConfiguration`,
  pending: () => ({ type: GET_PROXY_CONFIGURATION_PENDING }),
  fulfilled: (request, response) => ({
    type: GET_PROXY_CONFIGURATION_FULFILLED,
    request,
    response,
  }),
  rejected: () => ({ type: GET_PROXY_CONFIGURATION_REJECTED }),
};

export const GET_CFG_SERVICES: ActionCatalog = {
  path: `/rest/react/getCfgServices`,
  pending: () => ({ type: GET_CFG_SERVICES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_CFG_SERVICES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_CFG_SERVICES_REJECTED }),
};

export const GET_CFG_DICOM_SERVICES: ActionCatalog = {
  path: `/rest/react/getCfgDicomServices`,
  pending: () => ({ type: GET_CFG_DICOM_SERVICES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_CFG_DICOM_SERVICES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_CFG_DICOM_SERVICES_REJECTED }),
};

export const REPROCESS_AI_QUEUED_ITEM: ActionCatalog = {
  path: `/rest/react/reprocessAiQueuedItem`,
  pending: payload => ({ type: REPROCESS_AI_QUEUED_ITEM_PENDING, payload }),
  fulfilled: (request, response) => ({ type: REPROCESS_AI_QUEUED_ITEM_FULFILLED, request, response }),
  rejected: error => ({ type: REPROCESS_AI_QUEUED_ITEM_REJECTED, error }),
};

export const PAUSE_AI_QUEUED_ITEM: ActionCatalog = {
  path: `/rest/react/pauseAiQueuedItem`,
  pending: payload => ({ type: PAUSE_AI_QUEUED_ITEM_PENDING, payload }),
  fulfilled: (request, response) => ({ type: PAUSE_AI_QUEUED_ITEM_FULFILLED, request, response }),
  rejected: error => ({ type: PAUSE_AI_QUEUED_ITEM_REJECTED, error }),
};

export const REPROCESS_FILE_QUEUED_ITEM: ActionCatalog = {
  path: `/rest/react/reprocessFileQueuedItem`,
  pending: payload => ({ type: REPROCESS_FILE_QUEUED_ITEM_PENDING, payload }),
  fulfilled: (request, response) => ({ type: REPROCESS_FILE_QUEUED_ITEM_FULFILLED, request, response }),
  rejected: error => ({ type: REPROCESS_FILE_QUEUED_ITEM_REJECTED, error }),
};

export const UPDATE_NODE: ActionCatalog = {
  path: `/rest/react/updateNode`,
};

export const UPDATE_PREFETCH_STUDY: ActionCatalog = {
  path: `/rest/react/updateStudyStatus`,
};

export const INSERT_NODE: ActionCatalog = {
  path: `/rest/react/insertNode`,
};

export const INSERT_WORKLIST_NODE: ActionCatalog = {
  path: `/rest/react/insertWorklistNode`,
};

export const UPDATE_WORKLIST_NODE: ActionCatalog = {
  path: `/rest/react/updateWorklistNode`,
};

export const DELETE_WORKLIST_NODE: ActionCatalog = {
  path: `/rest/react/deleteWorklistNode`,
};

export const INSERT_FORWARD_RULE: ActionCatalog = {
  path: `/rest/react/insertForwardRule`,
};

export const UPDATE_FORWARD_RULE: ActionCatalog = {
  path: `/rest/react/updateForwardRule`,
};

export const DELETE_FORWARD_RULE: ActionCatalog = {
  path: `/rest/react/deleteForwardRule`,
};

export const DICOM_ECHO: ActionCatalog = {
  path: `/rest/react/dicomEcho`,
};

export const INSERT_CONDITION: ActionCatalog = {
  path: `/rest/react/insertCondition`,
};

export const UPDATE_CONDITION: ActionCatalog = {
  path: `/rest/react/updateCondition`,
};

export const DELETE_CONDITION: ActionCatalog = {
  path: `/rest/react/deleteCondition`,
};

export const INSERT_DELETION_RULE: ActionCatalog = {
  path: `/rest/react/insertDeletionRule`,
};

export const UPDATE_DELETION_RULE: ActionCatalog = {
  path: `/rest/react/updateDeletionRule`,
};

export const DELETE_DELETION_RULE: ActionCatalog = {
  path: `/rest/react/deleteDeletionRule`,
};

export const UPDATE_AI_MODULE: ActionCatalog = {
  path: `/rest/react/updateAiModule`,
};

export const SCRIPTS: ActionCatalog = {
  path: `/rest/react/scripts`,
  pending: () => ({ type: GET_ALL_SCRIPTS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_ALL_SCRIPTS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_ALL_SCRIPTS_REJECTED }),
};

export const SCRIPT_SNIPPETS: ActionCatalog = {
  path: `${process.env.PUBLIC_URL}/script-snippets`,
};

export const PERIODIC_SCRIPTS: ActionCatalog = {
  path: `/rest/react/scripts/periodic`,
};

export const RESTART_SERVICES: ActionCatalog = {
  path: `/rest/react/restart`,
};

export const RESTART_ALL: ActionCatalog = {
  path: `/rest/react/restartAll`,
};

export const RESTART_FEED: ActionCatalog = {
  path: `/rest/react/restartFeed`,
};

export const IS_IN_DOCKER: ActionCatalog = {
  path: `/rest/react/isInDocker`,
};

export const GET_PRINTERS: ActionCatalog = {
  path: `/rest/react/getCdBurners`,
  pending: () => ({ type: GET_PRINTERS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_PRINTERS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_PRINTERS_REJECTED }),
};

export const GET_READERS: ActionCatalog = {
  path: `/rest/react/getCdReaders`,
  pending: () => ({ type: GET_READERS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_READERS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_READERS_REJECTED }),
};

export const GET_BURNERS_STATUS: ActionCatalog = {
  path: `/rest/react/getBurnersStatus`,
  pending: () => ({ type: GET_BURNERS_STATUS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_BURNERS_STATUS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_BURNERS_STATUS_REJECTED }),
};

export const GET_BURNERS_JOBS: ActionCatalog = {
  path: `/rest/react/burnersJobs`,
  pending: () => ({ type: GET_BURNERS_JOBS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_BURNERS_JOBS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_BURNERS_JOBS_REJECTED }),
};

export const UPDATE_CFG_SERVICES: ActionCatalog = {
  path: `/rest/react/updateCfgServices`,
};

export const UPDATE_CFG_DICOM_SERVICES: ActionCatalog = {
  path: `/rest/react/updateCfgDicomServices`,
};

export const UPDATE_PASSWORD: ActionCatalog = {
  path: `/rest/react/updatePassword`,
};

export const DISABLE_USER: ActionCatalog = {
  path: `/rest/react/disableUser`,
};

export const ENABLE_USER: ActionCatalog = {
  path: `/rest/react/enableUser`,
};

export const UPDATE_USERS_INFORMATION: ActionCatalog = {
  path: `/rest/react/updateUsersInformation`,
};

export const INSERT_NEW_USER: ActionCatalog = {
  path: `/rest/react/insertNewUser`,
};

export const INSERT_NEW_ROLE: ActionCatalog = {
  path: `/rest/react/insertNewRole`,
};

export const UPDATE_ROLE: ActionCatalog = {
  path: `/rest/react/updateRole`,
};

export const DELETE_ROLE: ActionCatalog = {
  path: `/rest/react/deleteRole`,
};

export const GET_STRONG_PASSWORD: ActionCatalog = {
  path: `/rest/react/getStrongPassword`,
};

export const IMPORT_STUDY: ActionCatalog = {
  path: `/rest/react/importStudy`,
};

export const GET_STORED_INSTANCES: ActionCatalog = {
  path: `/rest/react/getStoredInstances`,
};

export const GET_GLORY_MACHINES: ActionCatalog = {
  path: `/rest/react/getGloryMachines`,
  pending: () => ({ type: GET_GLORY_MACHINES_PENDING }),
  fulfilled: (request, response) => ({ type: GET_GLORY_MACHINES_FULFILLED, request, response }),
  rejected: () => ({ type: GET_GLORY_MACHINES_REJECTED }),
};

export const INSERT_GLORY_MACHINE: ActionCatalog = {
  path: `/rest/react/insertGloryMachine`,
};

export const UPDATE_GLORY_MACHINE: ActionCatalog = {
  path: `/rest/react/updateGloryMachine`,
};

export const DELETE_GLORY_MACHINE: ActionCatalog = {
  path: `/rest/react/deleteGloryMachine`,
};

export const TEST_PAYMENT: ActionCatalog = {
  path: `/rest/react/testPayment`,
};

export const TEST_CANCEL_PAYMENT: ActionCatalog = {
  path: `/rest/react/testCancelPayment`,
};

export const SERIES_DISPOSE_CHECK: ActionCatalog = {
  path: `/rest/react/disposeSeriesCheck`,
};

export const WATCHED_FOLDERS: ActionCatalog = {
  path: `/rest/react/watched-folders`,
  pending: () => ({ type: GET_WATCHED_FOLDERS_PENDING }),
  fulfilled: (request, response) => ({ type: GET_WATCHED_FOLDERS_FULFILLED, request, response }),
  rejected: () => ({ type: GET_WATCHED_FOLDERS_REJECTED }),
};
