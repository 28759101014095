import React, { useState } from 'react';
import { InputGroup, FormControl, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function PasswordTextBox({ placeholder, password, setPassword }) {
  const [inputType, setInputType] = useState(true);
  return (
    <React.Fragment>
      <InputGroup>
        <FormControl
          size="sm"
          type={inputType ? 'password' : 'text'}
          placeholder={placeholder}
          value={password}
          onChange={setPassword}
        />
        <InputGroup.Append>
          <Button
            tabIndex={-1}
            className="button-show-password"
            onClick={() => setInputType(!inputType)}
            size="sm"
            variant="outline-secondary"
          >
            {inputType ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </React.Fragment>
  );
}

export default PasswordTextBox;
