import { PrefetchStudy } from 'reducers/prefetchReducer';
import { Queue } from 'reducers/queueReducer';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetQueue = dispatch => http.get<Queue>(actions.GET_PREFETCH_QUEUE, dispatch);
const debouncedGetQueue = defaultDebounce(regularGetQueue);

// action creators
export const getPrefetchQueue = () => debouncedGetQueue;
export const forceGetPrefetchQueue = () => regularGetQueue;

export function updateStudy(study) {
  return http.post<PrefetchStudy>(actions.UPDATE_PREFETCH_STUDY, study);
}
