import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface Script {
  name: string;
  type: 'MORPHING' | 'SERVLET' | 'TIMER' | 'COOL_DOWN' | 'FILE_HANDLER' | 'FORWARD_FILTER' | null;
  snippet?: boolean;
  content?: string;
}

export interface PeriodicScript {
  name: string;
  period: number;
  enabled: boolean;
}

type GetAllScriptsFulfilled = {
  type: typeof actions.GET_ALL_SCRIPTS_FULFILLED;
  response: AxiosResponse<Script[]>;
};

function scripts(state: Script[] = [], action: GetAllScriptsFulfilled): Script[] {
  switch (action.type) {
    case actions.GET_ALL_SCRIPTS_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export default scripts;
