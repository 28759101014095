import { loadPeriodicScripts, savePeriodicScripts } from 'actions/scriptActions';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { PeriodicScript } from 'reducers/scriptReducer';
import useToaster from '../../actions/useToaster';
import EditableTextCell from '../EditableTextCell';

export default function PeriodicScriptsPopup({ onClose }: { onClose: () => void }) {
  const toaster = useToaster();
  const [periodicScripts, setPeriodicScripts] = useState<PeriodicScript[]>([]);

  useEffect(() => {
    loadPeriodicScripts().then(res => {
      setPeriodicScripts(res);
    });
  }, []);

  return (
    <Modal show onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Periodic scripts (timers)</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {periodicScripts.length === 0 && (
          <p>
            {`There isn't any periodic script in the catalog`}
            <br />
            <br />
            <br />
            <br />
          </p>
        )}

        {periodicScripts.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th style={{ width: '25%' }}>Period (seconds)</th>
                <th style={{ width: '15%' }} />
              </tr>
            </thead>
            <tbody>
              {periodicScripts.map(script => (
                <ScriptRow
                  key={script.name}
                  script={script}
                  onChange={dstScript => {
                    const copy = [...periodicScripts];
                    const foundScript = copy.find(srcScript => srcScript.name === dstScript.name);
                    if (foundScript) {
                      foundScript.enabled = dstScript.enabled;
                      foundScript.period = dstScript.period;
                    }
                    setPeriodicScripts(copy);
                  }}
                />
              ))}
            </tbody>
          </table>
        )}

        <div style={{ textAlign: 'right' }}>
          <p>
            <strong>NB: </strong>Remember to restart the system to apply changes
          </p>
          <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              savePeriodicScripts(periodicScripts)
                .then(() => {
                  toaster.success('Periodic scripts updated');
                  onClose();
                })
                .catch(err => {
                  console.log('error updating periodic scripts', err);
                  toaster.error('Error updating periodic scripts');
                });
            }}
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function ScriptRow({ script, onChange }: { script: PeriodicScript; onChange: (_: PeriodicScript) => void }) {
  return (
    <tr>
      <td>{script.name}</td>
      <td style={{ maxWidth: '5em' }}>
        <EditableTextCell
          value={script.period}
          type="number"
          onChange={newValue => onChange({ ...script, period: newValue })}
          onSave={() => {}}
        />
      </td>
      <td>
        <Button
          style={{ width: '4em' }}
          variant={script.enabled ? 'success' : 'light'}
          size="sm"
          onClick={() => onChange({ ...script, enabled: !script.enabled })}
        >
          {script.enabled ? 'ON' : 'OFF'}
        </Button>
      </td>
    </tr>
  );
}
