import React, { useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import ScriptType from './ScriptType';

export default function NewScriptPopup({ onClose, onConfirm, scripts }) {
  const [name, setName] = useState('');
  const [type, setType] = useState('ND');

  function validName() {
    return (
      name.length > 0 &&
      !scripts.map(script => script.name.toLowerCase()).includes(name.toLowerCase())
    );
  }

  function validType() {
    return type !== 'ND';
  }

  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Create new script</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Col as={Form.Label} sm={4}>
              Script name
            </Col>
            <Col sm={8}>
              <Form.Control
                size="sm"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                isInvalid={validName() ? false : true}
              />
            </Col>
          </Form.Group>
          <Form.Group>
            <Col as={Form.Label} sm={4}>
              Type
            </Col>
            <Col sm={8}>
              <Form.Control
                size="sm"
                as="select"
                value={type}
                onChange={e => setType(e.target.value)}
              >
                <option disabled value="ND">
                  select type
                </option>
                <option value={ScriptType.MORPHING}>MORPHING</option>
                <option value={ScriptType.SERVLET}>SERVLET</option>
                <option value={ScriptType.TIMER}>TIMER</option>
                <option value={ScriptType.COOL_DOWN}>COOL DOWN</option>
                <option value={ScriptType.FILE_HANDLER}>FILE HANDLER</option>
                <option value={ScriptType.FORWARD_FILTER}>FORWARD FILTER</option>
              </Form.Control>
              <p className="form-text">{getHelp(type)}</p>
            </Col>
          </Form.Group>
        </Form>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!validName() || !validType()}
            onClick={e => {
              const data = {
                name,
                type,
              };
              onConfirm(data);
            }}
          >
            Create
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function getHelp(type) {
  switch (type) {
    case ScriptType.MORPHING:
      return 'Called when a study is forwarded. Can be used to change metadata of the outgoing study';
    case ScriptType.SERVLET:
      return 'Called on incoming HTTP requests to /script/{SCRIPT_NAME}';
    case ScriptType.TIMER:
      return 'Called regularly. Must be configured in Timers section';
    case ScriptType.COOL_DOWN:
      return 'Called when a study cools down';
    case ScriptType.FILE_HANDLER:
      return 'Called when a new file is detected inside a watched folder';
    case ScriptType.FORWARD_FILTER:
      return 'Called for each forwarded instance (if configured). Can be used to filter out some instances';
    default:
      return '';
  }
}
