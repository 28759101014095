import { Dispatch } from 'redux';
import { ActionCatalog } from './actions/actionCatalog';
import { logout } from './actions/userActions';

interface HttpResponse<T> extends Response {
  data?: T;
}

export const get = <T>(
  action: ActionCatalog | string,
  dispatch?: Dispatch,
  formatter?: (resp: HttpResponse<T>) => any
): Promise<T> => {
  return sendRequest('GET', action, undefined, dispatch, formatter);
};

export const post = <T>(
  action: ActionCatalog | string,
  request?: any,
  dispatch?: Dispatch,
  formatter?: (resp: HttpResponse<T>) => any
): Promise<T> => {
  return sendRequest('POST', action, request, dispatch, formatter);
};

export const put = <T>(
  action: ActionCatalog | string,
  request?: any,
  dispatch?: Dispatch,
  formatter?: (resp: HttpResponse<T>) => any
): Promise<T> => {
  return sendRequest('PUT', action, request, dispatch, formatter);
};

export const del = <T>(
  action: ActionCatalog | string,
  request?: any,
  dispatch?: Dispatch,
  formatter?: (resp: HttpResponse<T>) => any
): Promise<T> => {
  return sendRequest('DELETE', action, request, dispatch, formatter);
};

const sendRequest = <T>(
  method: string,
  action: ActionCatalog | string,
  request?: any,
  dispatch?: Dispatch,
  formatter?: (resp: HttpResponse<T>) => any
): Promise<T> => {
  const processableAction: ActionCatalog = prepareAction(action);
  const respFormatter: (res: HttpResponse<T>) => any = prepareFormatter(formatter);

  if (dispatch && processableAction.pending) dispatch(processableAction.pending(request));

  const options = {
    method,
    body: JSON.stringify(request),
    headers: {
      'Content-type': 'application/json',
    },
  };
  // eslint-disable-next-line zeero-lint/no-fetch
  const promise: Promise<HttpResponse<T>> = fetch(new Request(processableAction.path), options);
  return promise
    .then(response => {
      if (response.status === 401) {
        // unauthorized, the session_token was not valid
        logout();
        throw new Error('unauthorized');
      } else if (response.status === 403) {
        // forbidden, the session_token does not inclued the necessary actions to perform this fetch request
        throw new Error('forbidden');
      }
      return respFormatter(response);
    })
    .then(response => {
      if (dispatch && processableAction.fulfilled) dispatch(processableAction.fulfilled(request, response));
      if (response.error) throw new Error(response.error.code + ' ' + response.error.message);

      if (response.data) {
        return response.data;
      } else {
        return response;
      }
    })
    .catch(err => {
      console.log(processableAction.path + ' ' + err);
      if (dispatch && processableAction.rejected) dispatch(processableAction.rejected(err));
      throw new Error(err);
    });
};

function prepareAction(action: ActionCatalog | string) {
  let processableAction: ActionCatalog;
  if (typeof action === 'string') {
    processableAction = {
      path: action,
      fulfilled: undefined,
      pending: undefined,
      rejected: undefined,
    };
  } else {
    processableAction = action;
  }
  return processableAction;
}

function prepareFormatter<T>(formatter?: (res: HttpResponse<T>) => any) {
  if (formatter) {
    return formatter;
  }

  if (isVoid<T>()) {
    return (res: HttpResponse<T>) => {};
  }

  return (res: HttpResponse<T>) =>
    res.text().then(text => {
      if (text.trim() === '') {
        // handle empty body
        return {};
      } else {
        return JSON.parse(text);
      }
    });
}

// Utility type to check if T is void
type IsVoid<T> = T extends void ? true : false;

// Helper function to check if a type is void at runtime
function isVoid<T>(): boolean {
  // TypeScript type guards do not work at runtime, this function is only to satisfy the compiler
  return false as IsVoid<T>;
}
