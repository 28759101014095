import { useAppDispatch } from 'hooks';

/**
 * Usage example: useToaster().info('hello')
 */
export default function useToaster() {
  const dispatch = useAppDispatch();

  const toaster = {
    success(message) {
      dispatch({ type: 'SUCCESS', message });
    },
    info(message) {
      dispatch({ type: 'INFO', message });
    },
    warning(message) {
      dispatch({ type: 'WARNING', message });
    },
    error(message) {
      dispatch({ type: 'ERROR', message });
    },
  };

  return toaster;
}
