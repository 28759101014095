import React, { Component } from 'react';
import { Button, ButtonGroup, Col, Form, Row, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import DatePicker from 'react-datepicker';

class SearchForm extends Component<any, any> {
  dateRef: React.RefObject<unknown>;

  initialVal: number;

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setModality = this.setModality.bind(this);
    this.setStudyDate = this.setStudyDate.bind(this);
    this.handleStudyDateChange = this.handleStudyDateChange.bind(this);
    this.dateRef = React.createRef();
    this.initialVal = 0;
  }

  setModality(e) {
    this.props.onChange('modality', e.target.name);
  }

  setStudyDate(e) {
    const date = new Date();
    switch (e.target.name) {
      case 'today':
        this.props.onChange('studyDate', date);
        break;
      case 'yesterday':
        date.setDate(date.getDate() - 1);
        this.props.onChange('studyDate', date);
        break;
      case 'anyday':
        this.props.onChange('studyDate', '');
        break;
      default:
        break;
    }
  }

  setAdvancedFieldsVisible(visible) {
    this.props.onChange('advanced', visible);
  }

  handleStudyDateChange(e) {
    if (!e) {
      this.props.onChange('studyDate', '');
    } else {
      const newDate = new Date(e);
      this.props.onChange('studyDate', newDate);
      if (this.initialVal === 0) {
        this.initialVal = 1;
      }
    }
  }

  handleChange(e) {
    const field = e.target.name;
    const { value } = e.target;
    this.props.onChange(field, value);
  }

  handleSubmit(e) {
    e.preventDefault();
    let searchMode = e.nativeEvent.submitter.name;
    this.props.onSubmit(searchMode);
  }

  handleKeyDown(event: { key: string }): void {
    if (event.key === 'Enter') {
      this.handleStudyDateChange((this.dateRef.current! as HTMLInputElement).value);
    }
  }

  render() {
    const { firstName, lastName, modality, studyDate } = this.props.fields;
    const { patientId, studyUid, accNum } = this.props.fields;
    const { advanced } = this.props.fields;

    return (
      <Form onSubmit={this.handleSubmit}>
        <Row>
          {!advanced && (
            <React.Fragment>
              <Form.Group as={Col} sm={3}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={lastName}
                  placeholder="Last name"
                  name="lastName"
                  onChange={this.handleChange}
                  tabIndex={1}
                />
              </Form.Group>
              <Form.Group as={Col} sm={3}>
                <Form.Control
                  size="sm"
                  type="text"
                  value={firstName}
                  placeholder="First name"
                  name="firstName"
                  onChange={this.handleChange}
                  tabIndex={2}
                />
              </Form.Group>
            </React.Fragment>
          )}

          {advanced && (
            <Form.Group as={Col} sm={6}>
              <Form.Control
                size="sm"
                type="text"
                value={patientId}
                placeholder="Patient ID"
                name="patientId"
                onChange={this.handleChange}
                tabIndex={1}
              />
            </Form.Group>
          )}

          <Col sm={4}>
            <ToggleButtonGroup
              type="checkbox"
              size="sm"
              value={advanced}
              onChange={e => this.setAdvancedFieldsVisible(!advanced)}
              style={{
                marginBottom: '0.5rem',
              }}
            >
              <ToggleButton variant="outline-secondary" value="">
                ID filters
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>

        {!advanced && (
          <Row>
            <Form.Group as={Col} sm={6}>
              <Form.Control
                size="sm"
                type="text"
                value={modality}
                placeholder="Modality"
                name="modality"
                onChange={date => this.handleChange(date)}
                tabIndex={3}
              />
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <ButtonGroup size="sm">
                <Button name="CT" onClick={this.setModality} variant="outline-secondary">
                  CT
                </Button>
                <Button name="MR" onClick={this.setModality} variant="outline-secondary">
                  MR
                </Button>
                <Button name="MG" onClick={this.setModality} variant="outline-secondary">
                  MG
                </Button>
                <Button name="CR" onClick={this.setModality} variant="outline-secondary">
                  CR
                </Button>
                <Button name="DX" onClick={this.setModality} variant="outline-secondary">
                  DX
                </Button>
                <Button name="US" onClick={this.setModality} variant="outline-secondary">
                  US
                </Button>
              </ButtonGroup>
            </Form.Group>
          </Row>
        )}

        {advanced && (
          <Row>
            <Form.Group as={Col} sm={6}>
              <Form.Control
                type="text"
                size="sm"
                value={studyUid}
                placeholder="Study Instance UID"
                name="studyUid"
                onChange={this.handleChange}
                tabIndex={3}
              />
            </Form.Group>
          </Row>
        )}

        {!advanced && (
          <Row>
            <Form.Group as={Col} sm={6}>
              <div>
                <DatePicker
                  ref={this.dateRef}
                  selected={this.initialVal === 0 ? "" : studyDate && studyDate.toISOString().substring(0, 10)}
                  onChange={date => this.handleStudyDateChange(date)}
                  placeholderText="Study date"
                  tabIndex={4}
                  dateFormat="dd/MM/yyyy"
                  className="form-control form-control-sm"
                  formatWeekDay={(day: string) => day.substring(0, 3)}
                  onKeyDown={this.handleKeyDown}
                />
              </div>
            </Form.Group>
            <Form.Group as={Col} sm={6}>
              <ButtonGroup>
                <Button name="today" size="sm" onClick={this.setStudyDate} variant="outline-secondary">
                  Today
                </Button>
                <Button name="yesterday" size="sm" onClick={this.setStudyDate} variant="outline-secondary">
                  Yesterday
                </Button>
                <Button name="anyday" size="sm" onClick={this.setStudyDate} variant="outline-secondary">
                  Any date
                </Button>
              </ButtonGroup>
            </Form.Group>
          </Row>
        )}

        {advanced && (
          <Row>
            <Form.Group as={Col} sm={6}>
              <Form.Control
                type="text"
                size="sm"
                value={accNum}
                placeholder="Accession Number"
                name="accNum"
                onChange={this.handleChange}
                tabIndex={4}
              />
            </Form.Group>
          </Row>
        )}

        <Row>
          <Col sm={2} style={{ marginBottom: '0.5em', textAlign: 'left' }}>
            <Button style={{ width: '9em' }} tabIndex={5} type="submit" variant="primary" name="local" size="sm">
              Search FEED
            </Button>
          </Col>
          {this.props.proxyConfiguration && this.props.proxyConfiguration.enabled === true && (
            <Col sm={2} style={{ marginBottom: '0.5em', textAlign: 'left' }}>
              <Button style={{ width: '9em' }} tabIndex={6} type="submit" variant="primary" name="remote" size="sm">
                Search PACS
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    );
  }
}

export default SearchForm;
