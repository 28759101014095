import { GloryMachine } from 'reducers/gloryMachinesReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import * as http from '../http';

const regularGetGloryMachines = (dispatch: Dispatch) => http.get<GloryMachine[]>(actions.GET_GLORY_MACHINES, dispatch);

export const getGloryMachines = () => regularGetGloryMachines;

export const insertGloryMachine = (gloryMachine: GloryMachine) => {
  return http.post<void>(actions.INSERT_GLORY_MACHINE, gloryMachine);
};

export const updateGloryMachine = (gloryMachine: GloryMachine) => {
  return http.put<void>(actions.UPDATE_GLORY_MACHINE, gloryMachine);
};

export const deleteGloryMachine = (gloryMachine: GloryMachine) => {
  return http.del<void>(actions.DELETE_GLORY_MACHINE, gloryMachine);
};
