import React from 'react';
import { useSelector } from 'react-redux';
import { BurnerJob } from 'reducers/printersReducer';
import { getStudiesToBurn } from 'reducers/selectors';
import { formatDicomName } from '../../../dicom';
import OverlayTooltip from './OverlayTooltip';

type OverlayTitleType = {
  selectedJob: BurnerJob | null,
  isReprinting: boolean,
}

const OverlayTitle = ({ selectedJob, isReprinting }: OverlayTitleType) => {
  const studiesToBurn = useSelector(getStudiesToBurn);
  
  return (
    <div
      style={{
        maxWidth: '100%',
        fontSize: '15px',
        display: 'inline-block',
        textAlign: 'center',
        width: '100%',
      }}
    >
      {isReprinting ? (
        selectedJob !== null ? (
          <OverlayTooltip text={formatDicomName(selectedJob.patientName)} />
        ) : (
          <div />
        )
      ) : studiesToBurn.length === 0 ? (
        <div />
      ) : (
        <OverlayTooltip text={studiesToBurn[0].patientName} />
      )}
    </div>
  );
};

export default OverlayTitle;
