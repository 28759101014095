import React, { useState } from 'react';
import { Form, Button, Modal, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faArrowRight, faWrench, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { Condition } from 'reducers/nodesReducer';
import { canPerformAction } from 'actions/userActions';
import { availableOperations } from 'reducers/userReducer';
import {
  insertCondition,
  forceGetConditions,
  updateCondition,
  deleteCondition,
} from '../../actions/conditionsActions';
import { getUser, isTouchedNode, isTouchedRule } from '../../reducers/selectors';
import useToaster from '../../actions/useToaster';
import ConditionPopup from './conditionsPopup/ConditionPopup';
import { useAppDispatch } from 'hooks';

function ConditionsTable({ rules, conditions, selectedNode, selectedRule }) {
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  const user = useSelector(getUser);
  const isLastTouchedNode = useSelector(isTouchedNode);
  const isLastTouchedRule = useSelector(isTouchedRule);

  const [showConditions, setShowConditions] = useState(false);
  const [editingCondition, setEditingCondition] = useState<Condition | null>(null);
  const [deletingCondition, setDeletingCondition] = useState<Condition | null>(null);
  const [showNewConditionPopup, setShowNewConditionPopup] = useState(false);

  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_NODES);

  const selectedNodeConditions = conditions
    .filter(() => selectedNode != null)
    .filter(condition => condition.sourceNodeAet === selectedNode.aeTitle);

  const selectedRuleConditions = conditions
    .filter(() => selectedRule != null)
    .filter(
      condition =>
        condition.sourceNodeAet === selectedRule!.from &&
        condition.destinationNodeAet === selectedRule!.to
    );

  return (
    <div style={{ marginTop: '5em' }}>
      <strong>Conditions</strong>
      <Form.Check
        custom
        id="showConditions"
        type="checkbox"
        style={{ float: 'right', margin: '0' }}
        checked={showConditions}
        onChange={() => setShowConditions(!showConditions)}
        label="Show All"
      />
      <table className="queue-table">
        <thead>
          <tr>
            <th style={{ width: '8%' }}>From</th>
            <th style={{ width: '2%' }} />
            <th style={{ width: '8%' }}>To</th>
            <th style={{ width: '14%' }}>Property</th>
            <th style={{ width: '6%' }}>Criteria</th>
            <th style={{ width: '20%' }}>Reference Value</th>
            <th style={{ width: '6%' }} />
            <th style={{ width: '5%' }} />
          </tr>
        </thead>
        <tbody>
          {showConditions && conditions.length === 0 && (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={8}>
                No condition configured
              </td>
            </tr>
          )}

          {!showConditions && selectedNode == null && selectedRule == null && (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={8}>
                Select a node or a rule
              </td>
            </tr>
          )}

          {!showConditions &&
            isLastTouchedNode &&
            selectedNode &&
            selectedNodeConditions.length === 0 && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={8}>
                  Node <strong>{selectedNode.aeTitle}</strong> has no conditions in any rule
                </td>
              </tr>
            )}
          {!showConditions &&
            isLastTouchedRule &&
            selectedRule &&
            selectedRuleConditions.length === 0 && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={8}>
                  Rule{' '}
                  <strong>
                    {selectedRule.from} → {selectedRule.to}
                  </strong>{' '}
                  has no conditions
                </td>
              </tr>
            )}

          {!showConditions &&
            isLastTouchedNode &&
            selectedNode &&
            selectedNodeConditions.length > 0 &&
            selectedNodeConditions.map((condition, index) => (
              <ConditionRow
                key={condition.pk}
                condition={condition}
                authorized={authorized}
                onEdit={() => setEditingCondition(condition)}
                onDelete={() => setDeletingCondition(condition)}
              />
            ))}

          {!showConditions &&
            isLastTouchedRule &&
            selectedRule &&
            selectedRuleConditions.length > 0 &&
            selectedRuleConditions.map((condition, index) => (
              <ConditionRow
                key={condition.pk}
                condition={condition}
                authorized={authorized}
                onEdit={() => setEditingCondition(condition)}
                onDelete={() => setDeletingCondition(condition)}
              />
            ))}

          {showConditions &&
            conditions.length > 0 &&
            conditions.map((condition, index) => (
              <ConditionRow
                key={condition.pk}
                condition={condition}
                authorized={authorized}
                onEdit={() => setEditingCondition(condition)}
                onDelete={() => setDeletingCondition(condition)}
              />
            ))}
        </tbody>
      </table>
      <Button
        size="sm"
        variant="primary"
        style={{
          float: 'right',
          marginLeft: '2em',
        }}
        disabled={!authorized}
        onClick={() => setShowNewConditionPopup(true)}
      >
        Add new condition
        <FontAwesomeIcon icon={faPlusCircle} style={{ marginLeft: '5px' }} />
      </Button>

      {showNewConditionPopup && (
        <NewConditionPopup
          onClose={() => setShowNewConditionPopup(false)}
          onSave={condition => {
            console.log('new condition', condition);
            insertCondition(condition)
              .then(() => {
                dispatch(forceGetConditions());
                setShowNewConditionPopup(false);
                toaster.success('New condition added');
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error creating condition');
              });
          }}
          rules={rules}
        />
      )}

      {editingCondition && (
        <EditConditionPopup
          rules={rules}
          condition={editingCondition}
          onClose={() => setEditingCondition(null)}
          onSave={condition => {
            console.log('update condition', condition);
            updateCondition(condition)
              .then(() => {
                dispatch(forceGetConditions());
                setEditingCondition(null);
                toaster.success('Updated condition');
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error updating condition');
              });
          }}
        />
      )}

      {deletingCondition && (
        <DeleteConditionPopup
          condition={deletingCondition}
          onClose={() => setDeletingCondition(null)}
          onConfirmDelete={() => {
            console.log('deleting condition', deletingCondition);
            const { pk } = deletingCondition;
            const cond = {
              pk,
            };
            deleteCondition(cond as Condition)
              .then(() => {
                dispatch(forceGetConditions());
                setDeletingCondition(null);
                toaster.success('Condition deleted');
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error deleting condition');
              });
          }}
        />
      )}
    </div>
  );
}

const ConditionRow = ({ condition, authorized, onEdit, onDelete }) => {
  const property =
    condition.property.charAt(0) + condition.property.slice(1).toLowerCase().replace(/_/g, ' ');
  const criteria = condition.criteria.charAt(0) + condition.criteria.slice(1).toLowerCase();
  return (
    <tr>
      <td title={condition.sourceNodeAet}>{condition.sourceNodeAet}</td>
      <td>
        <FontAwesomeIcon icon={faArrowRight} />
      </td>
      <td title={condition.destinationNodeAet}>{condition.destinationNodeAet}</td>
      <td title={property}>{property}</td>
      <td title={criteria}>{criteria}</td>
      <td title={condition.refValue}>{condition.refValue}</td>
      <td title={condition.pk}>
        {condition.enabled ? (
          <Badge variant="success">Enabled</Badge>
        ) : (
          <Badge variant="danger">Disabled</Badge>
        )}
      </td>
      <td>
        <div style={{ width: '100%', textAlign: 'right', overflow: 'visible' }}>
          {authorized && (
            <FontAwesomeIcon icon={faWrench} className="clickable-icon" onClick={onEdit} />
          )}
          {authorized && (
            <FontAwesomeIcon icon={faTrash} className="clickable-icon" onClick={onDelete} />
          )}
        </div>
      </td>
    </tr>
  );
};

const NewConditionPopup = ({ rules, onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>New condition</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ConditionPopup
        isInEditMode={false}
        condition={null}
        rules={rules}
        onClose={onClose}
        onSave={onSave}
      />
    </Modal.Body>
  </Modal>
);

const EditConditionPopup = ({ rules, condition, onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>
        Editing condition from {condition.sourceNodeAet} to {condition.destinationNodeAet}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ConditionPopup
        isInEditMode
        rules={rules}
        condition={condition}
        onClose={onClose}
        onSave={onSave}
      />
    </Modal.Body>
  </Modal>
);

const DeleteConditionPopup = ({ condition, onClose, onConfirmDelete }) => (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Deleting condition</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Deleting condition from {condition.sourceNodeAet} to {condition.destinationNodeAet}
      </p>
      <p>Are you sure?</p>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirmDelete}>
          Confirm delete
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default ConditionsTable;
