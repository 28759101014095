import React from 'react';
import { Badge } from 'react-bootstrap';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BarLoader } from 'react-spinners';
import CustomDate from '../../../CustomDate';
import { ENQUEUED, PROCESSING, ERROR, DONE, SUBMITTED } from '../../../constants';
import TableActionButton from 'components/reusableComponents/buttons/TableActionButton';
import { useAppDispatch } from 'hooks';
import * as fileQueueActions from '../../../actions/fileQueueActions';

const FileQueueRow = ({ item, authenticated }) => {
  const dispatch = useAppDispatch();
  const reprocessItem = it => {
    dispatch(fileQueueActions.reprocessEnqueuedItem(it));
    dispatch(fileQueueActions.forceGetFileQueue());
  };

  const statusLabel = ({ status, window }) => {
    if (status === PROCESSING) {
      return <BarLoader color="#01bf44" height={4} width={70} />;
    }
    if (status === ERROR) {
      return <Badge variant="danger">{status}</Badge>;
    }
    if (status === ENQUEUED) {
      return <Badge variant="warning">{status}</Badge>;
    }
    if (status === SUBMITTED) {
      return <Badge variant="info">{status}</Badge>;
    }
    if (status === DONE)
      return (
        <Badge variant="success" title={window}>
          {status}
        </Badge>
      );
    return <Badge variant="secondary">{status}</Badge>;
  };

  return (
    <tr>
      <td style={{ verticalAlign: 'inherit' }}>{tooltipped(item.file)}</td>
      <td style={{ verticalAlign: 'inherit' }}>{item.queue}</td>
      <td style={{ verticalAlign: 'inherit' }}>{CustomDate.fromString(item.enqueuedOn).toDateTimeString(true)}</td>
      <td style={{ verticalAlign: 'inherit' }}>{CustomDate.fromString(item.updatedOn).toDateTimeString(true)}</td>
      <td style={{ verticalAlign: 'inherit' }}>{statusLabel(item)}</td>
      <td style={{ verticalAlign: 'inherit' }}>{item.attempts}</td>
      <td style={{ verticalAlign: 'inherit' }}>{tooltipped(item.outcome)}</td>
      <td style={{ display: 'flex' }}>
        <TableActionButton
          action={reprocessItem}
          args={item}
          disabled={!authenticated || !['DONE', 'PAUSED'].includes(item.status)}
          icon="faRepeat"
          tooltip="Re-process this item"
        />
      </td>
    </tr>
  );
};

const tooltipped = text => (
  <OverlayTrigger placement="bottom" overlay={tooltip(text)} delay={300}>
    <div>{text}</div>
  </OverlayTrigger>
);

const tooltip = text => (
  <Tooltip id="tooltip" style={{ color: 'red' }}>
    {text}
  </Tooltip>
);

export default FileQueueRow;
