import { canPerformAction } from 'actions/userActions';
import React, { Component } from 'react';
import { Button, Card, Col, ListGroup, ListGroupItem, OverlayTrigger, Popover, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { availableOperations } from 'reducers/userReducer';
import * as http from '../../http';
import {
  componentsSelector,
  getSeries,
  getStudies,
  getStudiesToBurn,
  getUser,
  nodesSelectors,
} from '../../reducers/selectors';
import SeriesTable from './SeriesTable';
import StudyTable from './StudyTable';

import { Series } from 'reducers/seriesReducer';
import * as actions from '../../actions/actionCatalog';
import { addStudyToBurn } from '../../actions/burnActions';
import * as search from '../../actions/searchActions';
import CustomDate from '../../CustomDate';
import * as dicom from '../../dicom';
import ForwardOverlay from './ForwardOverlay';
import ImportStudyPopup from './ImportStudyPopup';
import SearchForm from './SearchForm';
import SeriesDisposalComponent from './seriesDisposal/SeriesDisposalComponent';
import { StudySituations } from './StudySituations';

class SearchPage extends Component<any, any> {
  seriesToDelete: string[];

  constructor(props) {
    super(props);

    this.state = {
      showImportPopup: false,
      showTooWeakFiltersLabel: false,
    };
    this.seriesToDelete = [];
    this.search = this.search.bind(this);
    this.importStudy = this.importStudy.bind(this);
    this.importFinished = this.importFinished.bind(this);
    this.getStudySituation = this.getStudySituation.bind(this);
    this.getStudySituationLabel = this.getStudySituationLabel.bind(this);
    this.getStudySituationClass = this.getStudySituationClass.bind(this);
    this.validateSearch = this.validateSearch.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(search.getProxyConfiguration);

    if (this.props.studies.length === 0) this.search('local');
  }

  onDeleteSeries(seriesUids) {
    this.seriesToDelete = seriesUids;
    this.props.dispatch({ type: actions.SERIES_DISPOSAL_SHOW_CONFIRMATION_POPUP });
  }

  handleStudyTablePageChanged(page) {
    this.props.dispatch({ type: actions.SET_STUDY_TABLE_PAGE, payload: page });
  }

  handleStudySelection(study) {
    if (!study) return;

    this.props.dispatch({ type: actions.SET_SELECTED_STUDY, payload: study });
    this.props.dispatch(search.getStudySeries(study ? study.studyUid : null, study.isRemote ? 'remote' : 'local'));

    let promise = search.getStoredInstances(study.studyUid);
    promise.then(storedInstances => {
      const { selectedStudy } = this.props;
      study.localInstances = storedInstances.localInstances;
      study.remoteInstances = storedInstances.remoteInstances;
      if (study.studyUid === selectedStudy.studyUid)
        this.props.dispatch({ type: actions.SET_SELECTED_STUDY, payload: study });
    });
  }

  handleSearchFieldChanged(field, value) {
    this.props.dispatch({ type: actions.SET_SEARCH_FIELD, payload: { field, value } });
  }

  importStudy() {
    const { selectedStudy } = this.props;
    this.setState({ ...this.state, showImportPopup: true });
    search.importStudy(selectedStudy.studyUid);
  }

  importFinished() {
    const { selectedStudy } = this.props;
    this.setState({ showImportPopup: false });
    this.handleStudySelection(selectedStudy);
  }

  getStudySituation() {
    const { selectedStudy } = this.props;
    if (selectedStudy.isRemote === false) return StudySituations.ALREADY_PRESENT_LOCALLY;

    if (!selectedStudy.localInstances && !selectedStudy.remoteInstances) return StudySituations.UNKNOWN;

    if (selectedStudy.localInstances === selectedStudy.remoteInstances) return StudySituations.ALREADY_PRESENT_LOCALLY;

    if (selectedStudy.localInstances > 0 && selectedStudy.localInstances < selectedStudy.remoteInstances)
      return StudySituations.PARTIALLY_PRESENT_LOCALLY;

    if (selectedStudy.localInstances === 0) return StudySituations.NOT_PRESENT_LOCALLY;
  }

  getStudySituationLabel() {
    let studySituation = this.getStudySituation();
    switch (studySituation) {
      case StudySituations.UNKNOWN:
        return '';
      case StudySituations.ALREADY_PRESENT_LOCALLY:
        return 'Study already present locally';
      case StudySituations.PARTIALLY_PRESENT_LOCALLY:
        return 'Study partially present locally';
      case StudySituations.NOT_PRESENT_LOCALLY:
        return 'Study not present locally';
    }
  }

  getStudySituationClass() {
    let studySituation = this.getStudySituation();
    switch (studySituation) {
      case StudySituations.UNKNOWN:
        return '';
      case StudySituations.ALREADY_PRESENT_LOCALLY:
        return 'text-primary';
      case StudySituations.PARTIALLY_PRESENT_LOCALLY:
        return 'text-warning';
      case StudySituations.NOT_PRESENT_LOCALLY:
        return 'text-primary';
    }
  }

  toaster = {
    success: message => this.props.dispatch({ type: 'SUCCESS', message }),
    info: message => this.props.dispatch({ type: 'INFO', message }),
    warning: message => this.props.dispatch({ type: 'WARNING', message }),
    error: message => this.props.dispatch({ type: 'ERROR', message }),
  };

  checkPatientId() {
    const { selectedStudy, studiesToBurn } = this.props;
    let notSameId = false;
    studiesToBurn.forEach(element => {
      if (element.patientId !== selectedStudy.patientId) notSameId = true;
    });
    return notSameId;
  }

  checkStudyUID() {
    const { selectedStudy, studiesToBurn } = this.props;
    let sameStudyUID = false;
    studiesToBurn.forEach(element => {
      if (element.studyUid === selectedStudy.studyUid) sameStudyUID = true;
    });
    return sameStudyUID;
  }

  addSeriesToDisk(series) {
    const { selectedStudy } = this.props;
    let totalSeries = this.props.getSeries(selectedStudy.studyUid);

    let selectedSeries: Series[] = [];
    series.forEach(seriesUid => {
      let newSeries: Series = {
        seriesUid: seriesUid,
        modality: '',
        description: '',
        instances: -1,
        instanceAvailability: '',
      };

      selectedSeries.push(newSeries);
    });

    this.addStudyToDisk(selectedSeries, totalSeries.length);
  }

  addStudyToDisk(series, totalSeries?) {
    const { selectedStudy, dispatch } = this.props;
    if (!totalSeries) totalSeries = series.length;

    let seriesUids = series.map(s => s.seriesUid);
    const url = `/rest/react/getPrintedDataSize?studyUid=${selectedStudy.studyUid}${
      series ? '&seriesUid=' + seriesUids.join('&seriesUid=') : ''
    }`;
    http
      .get<number>(url)
      .then(size => {
        dispatch({
          type: 'SET_SELECTED_STUDY_SIZE',
          payload: size,
        });
        const studyBurn = {
          studyUid: selectedStudy.studyUid,
          patientId: selectedStudy.patientId,
          patientName: selectedStudy.patientName,
          birthDate: selectedStudy.birthDate,
          accessionNumber: selectedStudy.accessionNumber,
          studyDate: selectedStudy.studyDate,
          studyTime: selectedStudy.studyTime,
          description: selectedStudy.description,
          totalSeries: totalSeries,
          series: series,
          size: size + '',
        };
        dispatch(addStudyToBurn(studyBurn));
        if (this.checkPatientId()) {
          this.toaster.error('This study belongs to another patient than those present on the current disk order');
        } else if (this.checkStudyUID()) {
          this.toaster.warning('Study already added to disk order');
        } else {
          this.toaster.success('Study correctly added to disk order');
        }
      })
      .catch(err => {
        console.log(err);
        this.toaster.error('Study not found, will not be added to the disk order');
      });
  }

  validateSearch(keys, searchMode) {
    if (searchMode === 'local') return true;

    if (keys.PatientName || keys.PatientID || keys.StudyDate || keys.AccessionNumber || keys.StudyInstanceUID) return true;

    return false;
  }

  search(searchMode) {
    const { firstName, lastName, studyDate, modality, studyUid, patientId, accNum, advanced } = this.props.searchFormState;

    interface Keys {
      StudyInstanceUID?: string;
      PatientID?: string;
      AccessionNumber?: number;
      StudyDate?: string;
      PatientName?: string;
      ModalitiesInStudy?: string;
    }

    const keys: Keys = {};
    if (advanced) {
      keys.StudyInstanceUID = studyUid;
      keys.PatientID = patientId;
      keys.AccessionNumber = accNum;
    } else {
      keys.StudyDate = dicom.toDicomDate(studyDate);
      keys.PatientName = dicom.toPersonName(firstName, lastName);
      keys.ModalitiesInStudy = modality;
    }

    this.setState({ ...this.state, showTooWeakFiltersLabel: false });
    let isValid = this.validateSearch(keys, searchMode);

    if (isValid) this.props.dispatch(search.searchStudies(keys, searchMode));
    else this.setState({ ...this.state, showTooWeakFiltersLabel: true });
  }

  recap() {
    const { selectedStudy, user } = this.props;

    if (!selectedStudy) return <div />;

    const authorized = canPerformAction(user, availableOperations.CAN_PRINT);
    return (
      <div>
        <Card className="patientDetail" style={{ fontSize: 'smaller', marginBottom: '1.5em' }}>
          <Card.Header style={{ padding: '0.5rem' }}>
            <div style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center' }}>
              Selected study
              <div style={{ width: '100%', float: 'right', display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  disabled={!authorized || this.getStudySituation() !== StudySituations.ALREADY_PRESENT_LOCALLY}
                  variant="primary"
                  size="sm"
                  onClick={() => this.addStudyToDisk(this.props.getSeries(selectedStudy.studyUid))}
                  style={{ marginRight: '0.5em' }}
                >
                  Add study to disk
                </Button>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="left"
                  overlay={ForwardOverlayPopup(selectedStudy.studyUid)}
                >
                  <Button
                    variant="primary"
                    size="sm"
                    disabled={
                      !canPerformAction(user, availableOperations.CAN_FORWARD_STUDY) ||
                      this.getStudySituation() !== StudySituations.ALREADY_PRESENT_LOCALLY
                    }
                  >
                    Forward Study
                  </Button>
                </OverlayTrigger>

                {this.getStudySituation() !== StudySituations.ALREADY_PRESENT_LOCALLY ? (
                  <Button variant="primary" size="sm" style={{ marginLeft: '0.5em' }} onClick={this.importStudy}>
                    Import Study
                  </Button>
                ) : (
                  ''
                )}
                <ImportStudyPopup show={this.state.showImportPopup} onOk={this.importFinished} />
              </div>
            </div>
          </Card.Header>
          <ListGroup>
            <ListGroupItem style={{ padding: '0.5rem' }}>
              <div style={{ position: 'absolute', right: 0, marginRight: '1em' }}>
                <strong>Patient</strong>
              </div>
              <div>
                {selectedStudy.patientName},{' '}
                {CustomDate.fromDate(dicom.fromDicomDate(selectedStudy.birthDate)).toDateString()}
              </div>
              <div>Patient ID: {selectedStudy.patientId} - </div>
            </ListGroupItem>
            <ListGroupItem style={{ padding: '0.5rem' }}>
              <div style={{ position: 'absolute', right: 0, marginRight: '1em' }}>
                <strong>Study</strong>
              </div>
              <div>{selectedStudy.description}</div>
              <div>Study date: {CustomDate.fromDicomDate(selectedStudy.studyDate).toDateString()}</div>
              <div>Study UID: {selectedStudy.studyUid}</div>
              <div>Accession number: {selectedStudy.accessionNumber}</div>
              {selectedStudy.isRemote === true && <div>Local images: {selectedStudy.localInstances}</div>}
              {selectedStudy.isRemote === true && (
                <div>
                  Remote images:{' '}
                  {selectedStudy.remoteInstances !== undefined ? (
                    selectedStudy.remoteInstances
                  ) : (
                    <Spinner size="sm" animation="border" variant="info" />
                  )}
                </div>
              )}
              {selectedStudy.isRemote === true && (
                <div style={{ fontWeight: 'bold' }} className={this.getStudySituationClass()}>
                  {this.getStudySituationLabel()}
                </div>
              )}
            </ListGroupItem>
          </ListGroup>
        </Card>
      </div>
    );
  }

  render() {
    const waitingForStudies = this.props.isPending('studies');
    const waitingForSeries = this.props.isPending('series');

    return (
      <div>
        <Card className="page-panel-common">
          <Card.Body>
            <Row>
              <Col sm={6}>
                <div style={{ maxWidth: '700px' }}>
                  <h3>DICOM browser &nbsp;</h3>

                  <SearchForm
                    onSubmit={this.search}
                    fields={this.props.searchFormState}
                    onChange={(field, value) => this.handleSearchFieldChanged(field, value)}
                    proxyConfiguration={this.props.proxyConfiguration}
                  />
                  {this.state.showTooWeakFiltersLabel && (
                    <div style={{ fontSize: '0.8em', position: 'absolute' }} className="text-danger">
                      Too weak filters! Please specify at least one of: PatientName, PatientID, StudyDate, AccessionNumber,
                      StudyInstanceUID
                    </div>
                  )}
                </div>
              </Col>
              <Col sm={6}>{this.recap()}</Col>
            </Row>
            <Row>
              <Col sm={6}>
                <StudyTable
                  studies={this.props.studies}
                  selectedStudy={this.props.selectedStudy}
                  page={this.props.studyTableState.page}
                  onSelect={studyUid => this.handleStudySelection(studyUid)}
                  onChangePage={page => this.handleStudyTablePageChanged(page)}
                  pending={waitingForStudies}
                />
              </Col>

              <Col sm={6}>
                <SeriesTable
                  series={this.props.getSeries(this.props.selectedStudy ? this.props.selectedStudy.studyUid : null)}
                  onDelete={seriesUids => this.onDeleteSeries(seriesUids)}
                  onAddSelectedSeriesToDisk={selectedSeries => this.addSeriesToDisk(selectedSeries)}
                  key={this.props.selectedStudy && this.props.selectedStudy.studyUid}
                  pending={waitingForSeries}
                  user={this.props.user}
                  proxy={this.props.proxyConfiguration}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {this.props.selectedStudy && this.seriesToDelete && (
          <SeriesDisposalComponent selectedStudy={this.props.selectedStudy.studyUid} selectedSeries={this.seriesToDelete} />
        )}
      </div>
    );
  }
}

const ForwardOverlayPopup = studyUid => (
  <Popover id="forward-popover">
    <ForwardOverlay studyUid={studyUid} />
  </Popover>
);

const mapStateToProps = state => {
  return {
    isPending: nodesSelectors.isPending(state),
    studies: getStudies(state),
    user: getUser(state),
    studyTableState: componentsSelector.getStudyTableState(state),
    selectedStudy: componentsSelector.getSelectedStudy(state),
    searchFormState: componentsSelector.getSearchFormState(state),
    getSeries: studyUid => getSeries(state)(studyUid),
    proxyConfiguration: nodesSelectors.getProxyConfiguration(state),
    studiesToBurn: getStudiesToBurn(state),
  };
};

export default connect(mapStateToProps)(SearchPage);
