import { faCloud } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CustomDate from '../../CustomDate';
import * as dicom from '../../dicom';
import Pager from '../Pager';

export default class StudyTable extends Component<any, any> {
  constructor(props) {
    super(props);
    this.getCheckboxStyle = this.getCheckboxStyle.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.studies.length !== this.props.studies.length) {
      this.selectStudy(this.props.studies[0]);
    }
  }  

  getCheckboxStyle(idx) {
    return this.state.selection.includes(idx) ? 'row-checkbox checked' : 'row-checkbox';
  }

  setPage(page) {
    this.props.onChangePage(page);
  }

  selectStudy(study) {
    this.props.onSelect(study);    
  }

  render() {
    const pageSize = 12;
    const { studies, pending, page, selectedStudy } = this.props;

    const pages = Math.ceil(studies.length / pageSize);
    const from = pageSize * page;
    const to = Math.min(studies.length, pageSize * page + pageSize);
    const rows = studies.slice(from, to);  

    return (
      <div style={{ marginTop: '2.1rem' }}>
        <table className="queue-table selectable">
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Patient</th>
              <th style={{ width: '40%' }}>Study</th>
              <th style={{ width: '12%' }} />
              <th style={{ width: '9em' }}>Date</th>
              <th style={{ width: '2em' }}></th>
            </tr>
          </thead>
          <tbody>
            {pending && studies.length === 0 ? (
              <tr>
                <td colSpan={5}>Loading...</td>
              </tr>
            ) : (
              rows.map(study => (
                <tr
                  onClick={e => this.selectStudy(study)}
                  key={study.studyUid}
                  className={
                    selectedStudy && selectedStudy.studyUid === study.studyUid ? 'selected' : ''
                  }
                >
                  <td>{tooltipped(study.patientName)}</td>
                  <td>{tooltipped(study.description)}</td>
                  <td>{study.modalities && study.modalities.join(', ')}</td>
                  <td>{format(study.studyDate, study.studyTime)}</td>
                  <td>{study.isRemote && 
                    <FontAwesomeIcon
                      icon={faCloud}
                    />}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <Pager
          pages={pages}
          currentPage={page + 1}
          onPrev={() => this.setPage(Math.max(0, page - 1))}
          onNext={() => this.setPage(Math.min(Math.max(0, pages - 1), page + 1))}
          setPage={newPage => () => this.setPage(newPage)}
        />
      </div>
    );
  }
}

const tooltipped = text => (
  <OverlayTrigger placement="bottom" overlay={tooltip(text)} delay={300}>
    <div>{text}</div>
  </OverlayTrigger>
);

const tooltip = text => <Tooltip id="tooltip">{text}</Tooltip>;

function format(dicomDate, dicomTime) {
  const date = dicom.fromDicomDate(dicomDate);
  const time = dicom.fromDicomTime(dicomTime);
  if (time && date) {
    date.setHours(time.getHours());
    date.setMinutes(time.getMinutes());
    date.setSeconds(time.getSeconds());
  }
  return CustomDate.fromDate(date).toDateTimeString();
}
