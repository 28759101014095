import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BurnerJob, Printer } from '../../../reducers/printersReducer';
import { Reader } from '../../../reducers/readersReducer';
import { getPrinters, getReaders, getStudiesToBurn } from '../../../reducers/selectors';
import BurnOptions from './BurnOptions';
import BurnStudiesButton from './BurnStudiesButton';
import OverlayTitle from './OverlayTitle';
import StudiesToBeBurnt from './StudiesToBeBurnt';
import * as http from '../../../http';

type BurnOverlayProps = {
  job: BurnerJob | null;
};

function BurnOverlayComponent({ job }: BurnOverlayProps) {
  const studiesToBurn = useSelector(getStudiesToBurn);

  const [requestPrinters, setRequestPrinters] = useState<Printer[]>(useSelector(getPrinters));
  const [requestReaders, setRequestReaders] = useState<Reader[]>(useSelector(getReaders));
  const [selectedPrinter, setSelectedPrinter] = useState<Printer | null>(null);
  const [selectedReader, setSelectedReader] = useState<Reader | null>(null);
  const [selectedJob, setSelectedJob] = useState<BurnerJob | null>(job);
  const [isReprinting, setIsReprinting] = useState(false);

  useEffect(() => {
    if (requestReaders.length > 0) {
      setSelectedReader(requestReaders[0]);
    }
  }, [requestReaders]);

  useEffect(() => {
    fetchReaders();
    fetchPrinters();
  }, []);

  useEffect(() => {
    if (job !== null) {
      setSelectedJob(job);
      setIsReprinting(true);
    } else {
      setIsReprinting(false);
    }
  }, []);

  const fetchReaders = (): void => {
    http
      .get<Reader[]>(`/rest/react/getCdReaders`)
      .then(json => {
        setRequestReaders(json);
        if (json.length > 0) {
          setSelectedReader(json.find(el => el.enabled)!);
        } else {
          setSelectedReader(null);
        }
      })
      .catch(err => console.log(err));
  };

  const fetchPrinters = (): void => {
    http
      .get<Printer[]>(`/rest/react/getCdBurners`)
      .then(json => {
        setRequestPrinters(json);
        if (json.length > 0) {
          setSelectedPrinter(json[0]);
        } else {
          setSelectedPrinter(null);
        }
      })
      .catch(err => console.log(err));
  };

  return studiesToBurn.length === 0 && selectedJob === null ? (
    <div id="specialTextBurnOverlay">No study to burn</div>
  ) : (
    <div>
      <OverlayTitle selectedJob={selectedJob} isReprinting={isReprinting}></OverlayTitle>
      <StudiesToBeBurnt selectedJob={selectedJob} isReprinting={isReprinting}></StudiesToBeBurnt>
      <BurnOptions
        requestPrinters={requestPrinters}
        requestReaders={requestReaders}
        selectedPrinter={selectedPrinter}
        setSelectedPrinter={setSelectedPrinter}
        selectedReader={selectedReader}
        setSelectedReader={setSelectedReader}
      ></BurnOptions>
      <BurnStudiesButton
        selectedPrinter={selectedPrinter}
        selectedReader={selectedReader}
        selectedJob={selectedJob}
        isReprinting={isReprinting}
      ></BurnStudiesButton>
    </div>
  );
}

export default BurnOverlayComponent;
