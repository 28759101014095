import React, { useEffect } from 'react';
import * as queueActions from '../../actions/queueActions';
import * as prefetchActions from '../../actions/prefetchActions';
import * as fileQueueActions from '../../actions/fileQueueActions';
import * as aiQueueActions from '../../actions/aiQueueActions';

import { nodesSelectors, getAiQueue, getFileQueue, getFeedStatus, getQueue } from '../../reducers/selectors';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons';
import PieChartQueue from './PieChartQueue';
import { FeedStatusComponent } from './FeedStatusComponent';
import { useAppDispatch } from 'hooks';
import { useSelector } from 'react-redux';

const DashboardPage = () => {
  const isPending = useSelector(nodesSelectors.isPending);
  const queue = useSelector(getQueue);
  const feedStatus = useSelector(getFeedStatus);
  const prefetch = useSelector(nodesSelectors.getPrefetchQueue);
  const aiQueue = useSelector(getAiQueue);
  const fileQueue = useSelector(getFileQueue);
  const dispatch = useAppDispatch();

  useEffect(() => {
    refreshStatus();
    const intervalId = setInterval(() => {
      refreshStatus();
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  function refreshStatus() {
    dispatch(queueActions.getQueue());
    dispatch(queueActions.getFeedStatus());
    dispatch(prefetchActions.getPrefetchQueue());
    dispatch(aiQueueActions.getAiQueue());
    dispatch(fileQueueActions.getFileQueue());
  }

  return (
    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
      <div className="dashboard-container">
        <div className="dashboard-firstrow">
          <PieChartQueue
            title="Prefetch queue"
            pending={isPending('prefetchQueue')}
            label="TO DO"
            enqueued={countEntries(prefetch!, 'TO_DO')}
            label2="PAUSE"
            paused={countEntries(prefetch!, 'PAUSE')}
            label3="DONE"
            done={countEntries(prefetch!, 'DONE')}
            label4="ERROR"
            error={countEntries(prefetch!, 'ERROR')}
          />
          <PieChartQueue
            title="Forward queue"
            pending={isPending('queue')}
            label="ENQUEUED"
            enqueued={countEntries(queue!, 'ENQUEUED')}
            label2="PAUSED"
            paused={countEntries(queue!, 'PAUSED')}
            label3="DONE"
            done={countEntries(queue!, 'DONE')}
            label4="ERROR"
            error={countEntries(queue!, 'ERROR')}
          />
        </div>
        <div className="dashboard-secondrow">
          <PieChartQueue
            key="ai"
            title="AI queue"
            pending={isPending('aiQueue')}
            label="ENQUEUED"
            enqueued={countEntries(aiQueue!, 'ENQUEUED')}
            label2="PAUSED"
            paused={countEntries(aiQueue!, 'PAUSED')}
            label3="DONE"
            done={countEntries(aiQueue!, 'DONE')}
            label4="ERROR"
            error={countEntries(aiQueue!, 'ERROR')}
          />
          <PieChartQueue
            key="file"
            title="File queue"
            pending={isPending('fileQueue')}
            label="ENQUEUED"
            enqueued={countEntries(fileQueue!, 'ENQUEUED')}
            label2="PAUSED"
            paused={countEntries(fileQueue!, 'PAUSED')}
            label3="DONE"
            done={countEntries(fileQueue!, 'DONE')}
            label4="ERROR"
            error={countEntries(fileQueue!, 'ERROR')}
          />
        </div>
        <div className="dashboard-thirdrow">
          <FeedStatusComponent
            title="Studies on feed"
            pending={isPending('feedStatus')}
            label="Total"
            studiesCount={feedStatus!.studies!}
            classname={faDatabase}
            label2="Occupied space"
            spaceOccupied={formatBytes(feedStatus!.storageSpace)}
            color="#20a8d8"
          />
        </div>
      </div>
    </div>
  );
};

export function formatBytes(bytes?: string): string {
  if (!bytes) return '--';
  const numberBytes = parseFloat(bytes);
  if (numberBytes >= 1024 * 1024 * 1024) {
    const mbs = numberBytes / (1024 * 1024 * 1024);
    return `${mbs.toFixed(2)} GB`;
  }

  if (numberBytes >= 1024 * 1024) {
    const mbs = numberBytes / (1024 * 1024);
    return `${mbs.toFixed(2)} MB`;
  }

  const kbs = numberBytes / 1024;
  return `${kbs.toFixed(2)} KB`;
}
const countEntries = (queue: Array<any> | null, status: string): number => {
  return queue == null ? 0 : queue.filter(element => element.status === status).length;
};

export default DashboardPage;
