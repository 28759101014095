import { fromDicomDate } from "dicom";

export default class CustomDate {
    date: Date | null;

    private constructor(date: Date | null) {
        this.date = date;
    }

    /**
     * Returns a new CustomDate from a regular Date.
     * Null values are handled too
     */
    static fromDate(date: Date | null) {
        return date ? new CustomDate(date) : new CustomDate(null)
    }

    /**
     * Returns a new CustomDate from an ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ)
     * Null values are handled too
     */
    static fromString(isoString: string | null) {
        const date = isoString ? new Date(isoString) : null;
        return new CustomDate(date);
    }

    /**
     * Returns a new CustomDate from a regular Date.
     * Null values are handled too
     */
     static fromDicomDate(dicomDate: string | null) {
        const date = fromDicomDate(dicomDate);
        return new CustomDate(date);
    }

    getDate(): Date | null {
        return this.date;
    }

    /**
    * Returns a string in the form of YYYY/MM/DD or empty
    */
    toDateString(): string {
        if (!this.date) return '';

        const year = this.date.getFullYear();
        const month = pad(this.date.getMonth() + 1);
        const day = pad(this.date.getDate());
        return `${day}/${month}/${year}`;
    }

    /**
    * Returns a string in the form of hh:mm(:ss) or hh:mm
    */
    toTimeString(showSeconds: boolean = false): string {
        if (!this.date) return '';

        const hours = pad(this.date.getHours());
        const minutes = pad(this.date.getMinutes());
        const seconds = pad(this.date.getSeconds());
        return showSeconds ? `${hours}:${minutes}:${seconds}` : `${hours}:${minutes}`;
    };

    /**
     * Returns a string in the form of YYYY/MM/DD hh:mm(:ss) or empty
     */
    toDateTimeString(showSeconds: boolean = false): string {
        const date = this.toDateString();
        const time = this.toTimeString(showSeconds);
        return `${date} ${time}`;
    };

    compare(other: CustomDate) : number {
        const a = this.date ? this.date.getTime() : 0
        const b = other.date ? other.date.getTime() : 0
        return b - a;
      }
}

function pad(n) {
    return `00${n}`.slice(-2);
}