import { FileQueue } from 'reducers/fileQueueReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetFileQueue = (dispatch: Dispatch) => http.get<FileQueue[]>(actions.GET_FILE_QUEUE, dispatch);
const debouncedGetFileQueue = defaultDebounce(regularGetFileQueue);
export const getFileQueue = () => debouncedGetFileQueue;
export const forceGetFileQueue = () => regularGetFileQueue;

export const reprocessEnqueuedItem = item => dispatch => {
  const body = {
    pk: item.pk,
  };
  http.post<void>(actions.REPROCESS_FILE_QUEUED_ITEM, body);
  dispatch(forceGetFileQueue());
};
