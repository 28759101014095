import * as actions from '../actions/actionCatalog';

type SeriesDisposalOutcome = {
  series: {
    uid: string;
    deleted: boolean;
  }[];
  studyPaused: boolean;
  studyResumed: boolean;
};

type SeriesDisposal = {
  popups: {
    showConfirmationPopup: boolean;
    showStudyInQueuePopup: boolean;
    showProgressPopup: boolean;
    showRecapPopup: boolean;
  };
  pending: boolean;
  outcome: SeriesDisposalOutcome | null;
  seriesUids: string[];
  isStudyInQueue: boolean;
};

const defaultPopusState = {
  showConfirmationPopup: false,
  showStudyInQueuePopup: false,
  showProgressPopup: false,
  showRecapPopup: false,
};

const seriesDisposalInitialState: SeriesDisposal = {
  popups: defaultPopusState,
  pending: false,
  outcome: null,
  seriesUids: [],
  isStudyInQueue: false,
};

type SeriesDisposalShowConfirmationPopup = {
  type: typeof actions.SERIES_DISPOSAL_SHOW_CONFIRMATION_POPUP;
};

type SeriesDisposalStudyInQueue = {
  type: typeof actions.SERIES_DISPOSAL_STUDY_IN_QUEUE;
};

type SeriesDisposalPending = {
  type: typeof actions.SERIES_DISPOSAL_PENDING;
  payload: {
    seriesUids: string[];
  };
};

type SeriesDisposalFulfilled = {
  type: typeof actions.SERIES_DISPOSAL_FULFILLED;
  payload: {
    outcome: SeriesDisposalOutcome;
  };
};

type SeriesDisposalAcknowledged = {
  type: typeof actions.SERIES_DISPOSAL_ACKNOWLEDGED;
};

type SeriesDisposalRejected = {
  type: typeof actions.SERIES_DISPOSAL_REJECTED;
};

type SeriesDisposalAbort = {
  type: typeof actions.SERIES_DISPOSAL_ABORT;
};

type SeriesDisposalAction =
  | SeriesDisposalShowConfirmationPopup
  | SeriesDisposalStudyInQueue
  | SeriesDisposalPending
  | SeriesDisposalFulfilled
  | SeriesDisposalAcknowledged
  | SeriesDisposalRejected
  | SeriesDisposalAbort;

// eslint-disable-next-line @typescript-eslint/default-param-last
export const seriesDisposal = (state: SeriesDisposal = seriesDisposalInitialState, action: SeriesDisposalAction) => {
  switch (action.type) {
    case actions.SERIES_DISPOSAL_SHOW_CONFIRMATION_POPUP:
      return {
        ...state,
        popups: { ...state.popups, showConfirmationPopup: true },
      };
    case actions.SERIES_DISPOSAL_STUDY_IN_QUEUE:
      return {
        ...state,
        isStudyInQueue: true,
        popups: { ...state.popups, showConfirmationPopup: false, showStudyInQueuePopup: true },
      };
    case actions.SERIES_DISPOSAL_PENDING:
      return {
        ...state,
        popups: {
          ...state.popups,
          showConfirmationPopup: false,
          showStudyInQueuePopup: false,
          showProgressPopup: true,
        },
        pending: true,
        outcome: null,
        seriesUids: action.payload.seriesUids,
      };
    case actions.SERIES_DISPOSAL_FULFILLED:
      return {
        ...state,
        pending: false,
        outcome: action.payload.outcome,
        popups: {
          showConfirmationPopup: false,
          showStudyInQueuePopup: false,
          showProgressPopup: false,
          showRecapPopup: true,
        },
      };
    case actions.SERIES_DISPOSAL_ACKNOWLEDGED:
      return { ...state, isStudyInQueue: false, outcome: null, popups: defaultPopusState };
    case actions.SERIES_DISPOSAL_ABORT:
      return { ...state, isStudyInQueue: false, outcome: null, popups: defaultPopusState };
    case actions.SERIES_DISPOSAL_REJECTED:
      return { ...state, isStudyInQueue: false, outcome: null, popups: defaultPopusState };
    default:
      return state;
  }
};
