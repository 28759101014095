import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Form, Row, Col, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { getReaderNames } from 'reducers/selectors';
import * as readerActions from '../../actions/readerActions';
import { useAppDispatch } from 'hooks';

interface Elm {
  pk: number;
  displayName: string;
  displayOrder: string | number;
  decompressImages: boolean;
  enabled: boolean;
  file?: unknown;
}
const ReaderModal = ({
  EditMode = false,
  element = {
    pk: 0,
    displayName: '',
    displayOrder: '',
    decompressImages: false,
    enabled: false,
    file: undefined,
  },
}: {
  EditMode?: boolean;
  element?: Elm;
}) => {
  const isInEditMode: boolean = EditMode;
  const readerButton = usePromiseTracker({ area: 'add_reader_button' });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useAppDispatch();

  const toaster = {
    success: message => dispatch({ type: 'SUCCESS', message }),
    info: message => dispatch({ type: 'INFO', message }),
    warning: message => dispatch({ type: 'WARNING', message }),
    error: message => dispatch({ type: 'ERROR', message }),
  };
  const names = useSelector(getReaderNames);
  const schema = yup.object().shape({
    displayName: yup
      .string()
      .test('test-name', 'This name already exists, choose another one', (val: string) => {
        if (names.indexOf(val) === -1) {
          return true;
        }
        return false;
      })
      .required('This field is required'),
    displayOrder: yup
      .number()
      .typeError('This field must be a number')
      .required('This field is required')
      .min(0, 'This field must be greater or equal to 0')
      .integer(),
    decompressImages: yup.boolean().required('This field is required'),
    enabled: yup.boolean().required('This field is required'),
    file: yup.mixed().required('An archive that contains a viewer is required'),
  });

  const editSchema = yup.object().shape({
    displayName: yup
      .string()
      .test('test-name', 'This name already exists, choose another one', (val: string) => {
        if (names.indexOf(val) === -1 || val === element.displayName) {
          return true;
        }
        return false;
      })
      .required('This field is required'),
    displayOrder: yup
      .number()
      .typeError('This field must be a number')
      .required('This field is required')
      .min(0, 'This field must be greater or equal to 0')
      .integer(),
    decompressImages: yup.boolean().required('This field is required'),
    enabled: yup.boolean().required('This field is required'),
    file: yup.mixed(),
  });

  const submitReader = values => {
    if (isInEditMode) {
      axios
        .post(`/rest/react/updateCdReader`, values, {
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(res => {
          dispatch(readerActions.getReaders());
          toaster.success(`Printer succesfully edited`);
        })
        .catch(e => toaster.error(e.message));
    } else {
      const formData = new FormData();
      formData.append('displayName', values.displayName);
      formData.append('displayOrder', values.displayOrder);
      formData.append('decompressImages', values.decompressImages);
      formData.append('enabled', values.enabled);
      formData.append('selectedFile', values.file);

      trackPromise(
        axios
          .post(`/rest/react/insertCdReader`, formData, {
            headers: {
              'Content-type': 'multipart/form-data',
            },
          })
          .then(res => {
            console.log(res);
            dispatch(readerActions.getReaders());
            toaster.success('Reader added successfully');
          })
          .catch(e => {
            toaster.error(e.message);
          }),
        'add_reader_button'
      );
    }
  };

  return (
    <React.Fragment>
      {isInEditMode ? (
        <FontAwesomeIcon style={{ marginRight: '0.5em' }} icon={faWrench} onClick={handleShow} />
      ) : !readerButton.promiseInProgress ? (
        <Button onClick={handleShow} className="align-self-end" size="sm" variant="primary">
          Add Reader
        </Button>
      ) : (
        <Button variant="primary" size="sm" className="align-self-end" disabled>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            style={{ marginRight: '0.3em' }}
          />
          Add Reader
        </Button>
      )}
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isInEditMode ? (
              <span>You are currently editing the reader named {element.displayName}</span>
            ) : (
              <span>You are currently adding a new reader</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validateOnChange={false}
            validationSchema={isInEditMode ? editSchema : schema}
            onSubmit={(values, actions) => {
              submitReader(values);
              actions.setSubmitting(false);
              handleClose();
            }}
            initialValues={element}
          >
            {({
              setFieldValue,
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
            }) => (
              <Form id="reader-form" onSubmit={handleSubmit} encType="multipart/form-data">
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Display Name
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      size="sm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.displayName}
                      name="displayName"
                      isInvalid={touched.displayName && !!errors.displayName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.displayName}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Display order
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="number"
                      size="sm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.displayOrder}
                      name="displayOrder"
                      isInvalid={touched.displayOrder && !!errors.displayOrder}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.displayOrder}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Decompress images
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      custom
                      type="checkbox"
                      onChange={handleChange}
                      checked={values.decompressImages}
                      name="decompressImages"
                      style={{ marginTop: '10px' }}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Enabled
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      custom
                      type="checkbox"
                      onChange={handleChange}
                      checked={values.enabled}
                      name="enabled"
                      style={{ marginTop: '10px' }}
                    />
                  </Col>
                </Form.Group>
                {isInEditMode ? (
                  <div />
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                      Upload Viewer
                    </Form.Label>
                    <Col sm={8}>
                      <Form.File
                        name="file"
                        type="file"
                        accept=".zip"
                        onChange={event => {
                          setFieldValue('file', event.currentTarget.files![0]);
                        }}
                        onBlur={handleBlur}
                        isInvalid={touched.file && !!errors.file}
                        feedback={errors.file}
                      />
                    </Col>
                  </Form.Group>
                )}
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          {isInEditMode ? (
            <Button variant="primary" type="submit" form="reader-form">
              Submit Edit
            </Button>
          ) : (
            <Button variant="primary" type="submit" form="reader-form">
              Add Reader
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ReaderModal;
