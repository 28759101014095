import React, { Component } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare, faTrash, faCloud } from '@fortawesome/free-solid-svg-icons';
import { canPerformAction } from '../../actions/userActions';
import { availableOperations } from '../../reducers/userReducer';
import Pager from '../Pager';
import toggle from '../../toggle';

export default class SeriesTable extends Component<any, any> {
  constructor(props, context) {
    super(props, context);
    this.getCheckboxStyle = this.getCheckboxStyle.bind(this);
    this.state = {
      selection: [],
      page: 0,
    };
  }

  getCheckboxStyle(idx) {
    return this.state.selection.includes(idx) ? faCheckSquare : faSquare;
  }

  toggleSelection(seriesUid) {
    this.setState(prevState => {
      return { selection: toggle(prevState.selection, seriesUid) };
    });
  }

  toggleAll() {
    const series = this.props.series || [];

    if (this.state.selection.length === series.length) {
      this.setState({ selection: [] });
      return;
    }

    const selection = series.map(s => s.seriesUid);
    this.setState({ selection });
  }

  allSelected() {
    const series = this.props.series || [];
    return this.state.selection.length === series.length && series.length > 0;
  }

  render() {
    const { user, series = [], pending } = this.props;
    const pageSize = 12;
    const { page } = this.state;
    const pages = Math.ceil(series.length / pageSize);
    const from = pageSize * page;
    const to = Math.min(series.length, pageSize * page + pageSize);
    const rows = series.slice(from, to);

    return (
      <div>
        {deleteSeriesButton(this.state, this.props, user)}
        {addSeriesToDiskButton(this.state, this.props, user)}
        <table className="queue-table selectable">
          <thead>
            <tr>
              <th style={{ width: '38px' }}>
                <FontAwesomeIcon
                  size="lg"
                  onClick={e => this.toggleAll()}
                  icon={this.allSelected() ? faCheckSquare : faSquare}
                />
              </th>

              <th>Series description</th>
              <th style={{ width: '10%' }} />
              <th style={{ width: '30%' }} />
              <th style={{ width: '10%' }}>#</th>
              <th style={{ width: '10%' }} />
            </tr>
          </thead>
          <tbody>
            {pending && series.length === 0 ? (
              <tr>
                <td colSpan={6}>Loading...</td>
              </tr>
            ) : (
              rows.map(s => (
                <tr
                  onClick={e => this.toggleSelection(s.seriesUid)}
                  key={s.seriesUid}
                  className={this.state.selection.includes(s.seriesUid) ? 'selected' : ''}
                >
                  <td>
                    <FontAwesomeIcon icon={this.getCheckboxStyle(s.seriesUid)} />
                  </td>
                  <td>{tooltipped(s.description)}</td>
                  <td>{s.modality}</td>
                  <td>{s.AETitle}</td>
                  <td>{s.instances}</td>
                  <td>{status(s)}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <Pager
          pages={pages}
          currentPage={page + 1}
          onPrev={() => this.setState({ page: Math.max(0, page - 1) })}
          onNext={() => this.setState({ page: Math.min(Math.max(0, pages - 1), page + 1) })}
          setPage={newPage => () => this.setState({ page: newPage })}
        />
      </div>
    );
  }
}

const status = series => {
  switch (series.instanceAvailability) {
    case 'NEARLINE':
      return cloud();
    case 'MISMATCH':
      return mismatch();
    case 'READ_ONLY':
      return <div style={{ fontSize: '11px' }}>read-only</div>;
    default:
      return <div>&nbsp;</div>;
  }
};

const cloud = () => (
  <OverlayTrigger
    placement="bottom"
    overlay={tooltip(
      'this series is present remotely but might be present ALSO locally. You should perform local search manually if you want to check.'
    )}
    delay={300}
  >
    <FontAwesomeIcon icon={faCloud} />
  </OverlayTrigger>
);

const mismatch = () => (
  <OverlayTrigger
    placement="bottom"
    overlay={tooltip(
      'the data of this series does not match the data present in the central PACS (different pid/seriesUid/studyUid)'
    )}
    delay={300}
  >
    <div className="mismatch-series" style={{ textAlign: 'center' }}>
      &nbsp;
    </div>
  </OverlayTrigger>
);

const tooltipped = text => (
  <OverlayTrigger placement="bottom" overlay={tooltip(text)} delay={300}>
    <div>{text}</div>
  </OverlayTrigger>
);

const tooltip = text => <Tooltip id="tooltip">{text}</Tooltip>;

const addSeriesToDiskButton = (state, props, user) => {
  let isVisible =
    state.selection.length > 0 &&
    props.series &&
    props.series.length > 0 &&
    props.series[0].instanceAvailability === 'ONLINE' &&
    canPerformAction(user, availableOperations.CAN_PRINT);

  return (
    <Button
      size="sm"
      style={{
        marginLeft: '0.6em',
        marginBottom: '0.6em',
        position: 'relative',
        float: 'right',
        visibility: isVisible ? 'visible' : 'hidden',
      }}
      onClick={() => props.onAddSelectedSeriesToDisk(state.selection)}
    >
      Add selected series to disk ({state.selection.length})
    </Button>
  );
};

const deleteSeriesButton = (state, props, user) => {
  let isVisible =
    state.selection.length > 0 &&
    props.series &&
    props.series.length > 0 &&
    props.series[0].instanceAvailability === 'ONLINE' &&
    canPerformAction(user, availableOperations.CAN_DELETE_SERIES);

  return (
    <Button
      size="sm"
      style={{ marginLeft: '0.6em', position: 'relative', float: 'right', visibility: isVisible ? 'visible' : 'hidden' }}
      onClick={() => props.onDelete(state.selection)}
    >
      Delete series ({state.selection.length})
    </Button>
  );
};
