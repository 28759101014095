import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmationPopup = ({ title, message, onClose, onOk, okHighlightVariant="danger" }) => {
  return (
    <Modal show>
    <Modal.Header>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
        <Modal.Body>      
        <p>{message}</p>        
        <div style={{ textAlign: 'right' }}>
            <Button onClick={onClose} variant="outline-secondary" style={{ width: '80px', marginRight: '5px' }} >
            Cancel
            </Button>
            <Button variant={okHighlightVariant} onClick={onOk} style={{ width: '80px' }} >
            Ok
            </Button>
        </div>
        </Modal.Body>
    </Modal>
  );
};

export default ConfirmationPopup;