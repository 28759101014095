import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';
import { Series } from './seriesReducer';

export type Study = {
  patientId: string;
  patientName: string;
  birthDate: string;
  studyUid: string;
  accessionNumber: string;
  modalities: string[];
  studyDate: string;
  studyTime: string;
  description: string;
  instanceAvailability: string;
  StudyInstanceUID: string;
  size?: string;
};

export interface StudyState {
  [key: string]: Study;
}

interface SearchStudiesPending {
  type: typeof actions.SEARCH_STUDIES_PENDING;
  request: {
    StudyInstanceUID: string;
  };
  response: AxiosResponse<Study[]>;
}

interface SearchStudiesFulfilled {
  type: typeof actions.SEARCH_STUDIES_FULFILLED;
  request: {
    StudyInstanceUID: string;
  };
  response: AxiosResponse<Study[]>;
}

interface SearchSeriesFulfilled {
  type: typeof actions.SEARCH_SERIES_FULFILLED;
  request: {
    StudyInstanceUID: string;
  };
  response: AxiosResponse<Series[]>;
}

type StudyTableAction = SearchStudiesFulfilled | SearchSeriesFulfilled | SearchStudiesPending;

// eslint-disable-next-line @typescript-eslint/default-param-last
function studies(state: StudyState = {}, action: StudyTableAction): StudyState {
  const studyUid = action.request && action.request.StudyInstanceUID ? action.request.StudyInstanceUID : undefined;
  switch (action.type) {
    case actions.SEARCH_STUDIES_PENDING:
      return { };
    case actions.SEARCH_STUDIES_FULFILLED:
      // return an indexed object instead of the array
      return action.response.data.reduce((accumulator, current) => {
        accumulator[current.studyUid] = current;
        return accumulator;
      }, {});
    case actions.SEARCH_SERIES_FULFILLED:
      // remove the study from the list if it has no series
      if (action.response.data.length === 0 && studyUid) {
        const { [studyUid]: value, ...rest } = state;
        return rest;
      }
      return state;
    default:
      return state;
  }
}

export default studies;
