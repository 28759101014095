import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, FormControl, Col, Row } from 'react-bootstrap';
import { getNodes, getScripts } from '../../../reducers/selectors';
import ScriptType from 'components/scripts/ScriptType';

function EditForwardRule({ rule, onClose, onSave }) {
  const nodes = useSelector(getNodes);
  const scripts = useSelector(getScripts);
  const [temporalWindow, setTemporalWindow] = useState(rule.temporalWindow);
  const [queue, setQueue] = useState(rule.queue);
  const [morphingScript, setMorphingScript] = useState(rule.morphingScript);
  const [filterScript, setFilterScript] = useState(rule.filterScript);

  const save = () => {
    const sourceNode = nodes.find(node => node.aeTitle === rule.from);
    const destinationNode = nodes.find(node => node.aeTitle === rule.to);
    const updatedRule = {
      source: sourceNode!.pk,
      destination: destinationNode!.pk,
      window: temporalWindow,
      queue,
      morphingScript: morphingScript ? morphingScript : null,
      filterScript: filterScript ? filterScript : null,
    };
    onSave(updatedRule);
  };

  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Source node
        </Form.Label>
        <Col sm={8}>
          <FormControl size="sm" as="select" value={rule.from} disabled>
            <option value={rule.from}>{rule.from}</option>
          </FormControl>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Destination node
        </Form.Label>
        <Col sm={8}>
          <FormControl size="sm" as="select" value={rule.to} disabled>
            <option value={rule.to}>{rule.to}</option>
          </FormControl>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Temporal window
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            type="text"
            placeholder="HH:MM-HH:MM"
            value={temporalWindow}
            onChange={e => setTemporalWindow(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Queue
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            isInvalid={queue ? false : true}
            type="text"
            placeholder="Q1"
            value={queue}
            onChange={e => setQueue(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Morphing script
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            as="select"
            value={morphingScript}
            onChange={e => setMorphingScript(e.target.value)}
          >
            <option value="">-</option>
            {scripts
              .filter(scr => scr.type === ScriptType.MORPHING.toString())
              .map((scr, i) => (
                <option key={i} value={scr.name}>
                  {scr.name}
                </option>
              ))}
          </FormControl>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Filter script
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            as="select"
            value={filterScript}
            onChange={e => setFilterScript(e.target.value)}
          >
            <option value="">-</option>
            {scripts
              .filter(scr => scr.type === ScriptType.FORWARD_FILTER.toString())
              .map((scr, i) => (
                <option key={i} value={scr.name}>
                  {scr.name}
                </option>
              ))}
          </FormControl>
        </Col>
      </Form.Group>

      <br />
      <p>
        Studies received from the Source node will be forwarded to the Destination node during the (optional)
        temporal window. <br/>
        The metadata will be updated applying the (optional) morphing script. <br/>
        Some instances may be filtered out according to the (optional) forward-filter script.
      </p>

      <div style={{ textAlign: 'right' }}>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button style={{ width: '8em' }} variant="primary" onClick={save}>
          Save
        </Button>
      </div>
    </Form>
  );
}

export default EditForwardRule;
