import { useAppDispatch } from "hooks";
import React, { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { componentsSelector, getNodes, getQueue, getUser, nodesSelectors } from "reducers/selectors";
import * as nodesActions from '../../actions/nodesActions';
import * as queueActions from '../../actions/queueActions';
import QueueTable from "./queueTable/QueueTable";

const QueuePage = () => {
  const dispatch = useAppDispatch();
  const queue = useSelector(getQueue);
  const nodes = useSelector(getNodes);
  const isPending = useSelector(nodesSelectors.isPending)("queue");

  const queueTableState = useSelector(componentsSelector.getQueueTableState);
  const user = useSelector(getUser)
  
  useEffect(() => {
    dispatch(queueActions.getQueue());
    dispatch(nodesActions.getNodes());
  }, []);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <h4>Current Queue Status</h4>
        <QueueTable
          queue={queue}
          pending={isPending}
          nodes={nodes}
          pageSize={queueTableState.pageSize}
          page={queueTableState.page}
          compact={queueTableState.compact}
          selectedItems={queueTableState.selectedItems} 
          user={user}
        />          
      </Card.Body>
    </Card>
  );
}

export default QueuePage;