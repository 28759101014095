import * as Forge from 'node-forge';
import { availableOperations, Role, User, UserAction } from 'reducers/userReducer';
import * as http from '../http';
import * as actions from './actionCatalog';

export function login(username: string, password: string) {
  const secretPassword = encrypt(password);
  return http.post<User>('/rest/react/session/login', { username, password: secretPassword });
}

export function googleLogin(token: string) {
  return http.post<User>('/rest/react/session/google-login', token);
}

export function sessionTokenLogin() {
  return http.post<User>('/rest/react/session/token-login');
}

export function logout() {
  http.post<void>('/rest/react/session/logout').then(() => window.location.reload());
}

export function refreshSession() {
  return http.post<void>('/rest/react/session/refresh');
}

export function updatePassword(username: string | undefined, oldPassword: string, newPassword: string) {
  const secretOldPassword = encrypt(oldPassword);
  const secretNewPassword = encrypt(newPassword);
  const body = {
    username,
    oldPassword: secretOldPassword,
    newPassword: secretNewPassword,
  };
  return http.post<void>(actions.UPDATE_PASSWORD, body);
}

export function disableUser(username: string, privileged: boolean | undefined) {
  return http.post<void>(actions.DISABLE_USER, { username, privileged });
}

export function enableUser(username: string) {
  return http.post<void>(actions.ENABLE_USER, { username });
}

export function updateUsersInformation(
  rolePk: number | undefined,
  newUsername: string | undefined,
  oldUsername: string | undefined,
  privileged: boolean | undefined
) {
  const body = { newUsername, rolePk, oldUsername, privileged };
  return http.post<void>(actions.UPDATE_USERS_INFORMATION, body);
}

export function insertNewUser(username: string, password: string, rolePk: number, enabled: boolean) {
  const secretPassword = encrypt(password);
  const body = { username, password: secretPassword, roleFk: rolePk, enabled };
  return http.post<void>(actions.INSERT_NEW_USER, body);
}

export function insertNewRole(newRole) {
  return http.post<void>(actions.INSERT_NEW_ROLE, { ...newRole });
}

export function updateRole(role) {
  return http.post<void>(actions.UPDATE_ROLE, { ...role });
}

export function deleteRole(rolepk: number) {
  return http.post<void>(actions.DELETE_ROLE, { rolepk });
}

export function getStrongPassword() {
  return http.get<boolean>(actions.GET_STRONG_PASSWORD);
}

export function getAllRoles() {
  return http.get<Role[]>(`/rest/react/getAllRoles`);
}

export function getAllUsers() {
  return http.get<User[]>(`/rest/react/getAllUsers`);
}

export function getAllActions() {
  return http.get<UserAction[]>(`/rest/react/getAllActions`);
}

export function encrypt(password) {
  const mdSha256 = Forge.md.sha256.create();
  mdSha256.update(password);
  return mdSha256.digest().toHex();
}

export function isGoogleSession(user: User) {
  return user.role && user.role.name === 'G00GLE';
}

export function getPagesVisibility(user: User) {
  return {
    aiModules: canPerformAction(user, availableOperations.AI_MODULES_VISIBLE),
    browser: canPerformAction(user, availableOperations.DICOM_BROWSER_VISIBLE),
    configurations: canPerformAction(user, availableOperations.CONFIGURATIONS_VISIBLE),
    dashboard: canPerformAction(user, availableOperations.DASHBOARD_VISIBLE),
    deletionRules: canPerformAction(user, availableOperations.DELETION_RULES_VISIBLE),
    fileQueue: canPerformAction(user, availableOperations.FILE_QUEUE_VISIBLE),
    jsdButton: canPerformAction(user, availableOperations.JSD_BUTTON_AVAILABLE),
    logs: canPerformAction(user, availableOperations.LOGS_VISIBLE),
    nodes: canPerformAction(user, availableOperations.NODES_VISIBLE),
    paymentDevices: canPerformAction(user, availableOperations.PAYMENTS_DEVICES_VISIBILE),
    prefetch: canPerformAction(user, availableOperations.PREFETCH_VISIBLE),
    printers: canPerformAction(user, availableOperations.PRINTER_CONFIGURATIONS_VISIBLE),
    printerStatus: canPerformAction(user, availableOperations.PRINTERS_VISIBLE),
    queue: canPerformAction(user, availableOperations.QUEUE_VISIBLE),
    scripts: canPerformAction(user, availableOperations.SCRIPT_CATALOG_VISIBLE),
    userSettings: canPerformAction(user, availableOperations.USERS_VISIBLE),
    watchedFolders: canPerformAction(user, availableOperations.WATCHED_FOLDERS_VISIBLE),
    worklist: canPerformAction(user, availableOperations.WORKLIST_VISIBLE),
  };
}

export function canPerformAction(user: User, operation: string) {
  if (!user.role || !user.role.userActions) {
    return false;
  }
  if (user.role.privileged) {
    return true;
  }
  return user.role.userActions.some(useraction => useraction.actionName === operation);
}
