import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, Row, Col } from 'react-bootstrap';
import useToaster from '../../../actions/useToaster';
import { getNodes, getScripts, getForwardRules } from '../../../reducers/selectors';
import ScriptType from 'components/scripts/ScriptType';

function NewForwardRule({ onClose, onSave }) {
  const toaster = useToaster();
  const nodes = useSelector(getNodes);
  const scripts = useSelector(getScripts);
  const rules = useSelector(getForwardRules);
  const [source, setSource] = useState('-1');
  const [destination, setDestination] = useState('-1');
  const [temporalWindow, setTemporalWindow] = useState('');
  const [queue, setQueue] = useState('Q1');
  const [morphingScript, setMorphingScript] = useState('');
  const [filterScript, setFilterScript] = useState('');

  const changeSource = src => {
    setSource(src);
    if (destination === src) {
      setDestination('-1');
    }
  };

  const changeDestination = dst => {
    setDestination(dst);
    if (dst === source) {
      setSource('-1');
    }
  };

  function ruleAlreadyPresent(sourcePk, destinationPk) {
    const sourceNode = nodes.find(node => node.pk === parseInt(sourcePk, 10));
    const destinationNode = nodes.find(node => node.pk === parseInt(destinationPk, 10));
    return rules.find(
      rule => rule.from === sourceNode!.aeTitle && rule.to === destinationNode!.aeTitle
    );
  }

  const save = () => {
    if (source === '-1' || destination === '-1' || !queue) return;

    if (ruleAlreadyPresent(source, destination)) {
      const sourceNode = nodes.find(node => node.pk === parseInt(source, 10));
      const destinationNode = nodes.find(node => node.pk === parseInt(destination, 10));
      toaster.warning(
        `A rule already exists between ${sourceNode!.aeTitle} and ${destinationNode!.aeTitle}`
      );
      return;
    }

    const rule = {
      source: parseInt(source, 10),
      destination: parseInt(destination, 10),
      window: temporalWindow,
      queue,
      morphingScript: morphingScript ? morphingScript : null,
      filterScript: filterScript ? filterScript : null
    };
    onSave(rule);
  };

  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Source node
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            size="sm"
            as="select"
            value={source}
            onChange={e => changeSource(e.target.value)}
            defaultValue="-1"
            isInvalid={source !== '-1' ? false : true}
          >
            <option value="-1" disabled>
              Select source node...
            </option>
            {nodes
              .filter(node => node.enabled)
              .map((node, index) => (
                <option key={index} value={node.pk}>
                  {node.aeTitle}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Destination node
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            size="sm"
            as="select"
            value={destination}
            onChange={e => changeDestination(e.target.value)}
            isInvalid={destination !== '-1' ? false : true}
          >
            <option value="-1" disabled>
              Select destination node...
            </option>
            {nodes
              .filter(node => node.enabled)
              .filter(node => node.aeTitle !== 'PREFETCH-SERVICE')
              .filter(node => node.pk !== parseInt(source, 10))
              .map((node, index) => (
                <option key={index} value={node.pk}>
                  {node.aeTitle}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Temporal window
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            size="sm"
            type="text"
            placeholder="HH:MM-HH:MM"
            value={temporalWindow}
            onChange={e => setTemporalWindow(e.target.value)}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Queue
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Q1"
            value={queue}
            onChange={e => setQueue(e.target.value)}
            isInvalid={queue ? false : true}
          />
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Morphing script
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            value={morphingScript}
            size="sm"
            onChange={e => setMorphingScript(e.target.value)}
          >
            <option value="">-</option>
            {scripts
              .filter(scr => scr.type === ScriptType.MORPHING.toString())
              .map((scr, index) => (
                <option key={index} value={scr.name}>
                  {scr.name}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Form.Group>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Filter script
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            as="select"
            value={filterScript}
            size="sm"
            onChange={e => setFilterScript(e.target.value)}
          >
            <option value="">-</option>
            {scripts
              .filter(scr => scr.type === ScriptType.FORWARD_FILTER.toString())
              .map((scr, index) => (
                <option key={index} value={scr.name}>
                  {scr.name}
                </option>
              ))}
          </Form.Control>
        </Col>
      </Form.Group>

      <br />
      <p>
        Studies received from the Source node will be forwarded to the Destination node during the (optional)
        temporal window. <br/>
        The metadata will be updated applying the (optional) morphing script. <br/>
        Some instances may be filtered out according to the (optional) forward-filter script.
      </p>

      <div style={{ textAlign: 'right' }}>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button style={{ width: '8em' }} variant="primary" onClick={save}>
          Save
        </Button>
      </div>
    </Form>
  );
}

export default NewForwardRule;
