import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, FormGroup, Row, Col, FormControl } from 'react-bootstrap';
import { getDeletionRulesState, getNodes } from 'reducers/selectors';
import useToaster from '../../actions/useToaster';

function DeletionRulesPopup({ deletionRule, onClose, onSave, isEditing }) {
  const toaster = useToaster();
  const deletionRules = useSelector(getDeletionRulesState);
  const [sourceAeTitle, setSourceAeTitle] = useState(isEditing ? deletionRule.sourceAeTitle : '');
  const [maxStorageDays, setMaxStorageDays] = useState(
    isEditing ? deletionRule.maxStorageDays : ''
  );
  const [deleteOnlyIfForwarded, setDeleteOnlyIfForwarded] = useState(
    isEditing ? deletionRule.deleteOnlyIfForwarded : false
  );
  const [deleteIfFiltered, setDeleteIfFiltered] = useState(
    isEditing ? deletionRule.deleteIfFiltered : false
  );
  const [enabled, setEnabled] = useState(isEditing ? deletionRule.enabled : true);
  const nodes = useSelector(getNodes);

  function deletionRuleAlreadyPresent(aeTitle) {
    return deletionRules.find(tempDeletionRule => tempDeletionRule.sourceAeTitle === aeTitle);
  }

  const save = () => {
    if (sourceAeTitle === '' || maxStorageDays === '') return;
    if (deletionRuleAlreadyPresent(sourceAeTitle) && !isEditing) {
      toaster.warning(`A deletion rule already exists for ${sourceAeTitle}`);
      return;
    }
    const deletionRuleTemp = {
      sourceAeTitle,
      maxStorageDays,
      deleteOnlyIfForwarded,
      deleteIfFiltered,
      enabled,
    };

    onSave(deletionRuleTemp);
  };

  return (
    <Form>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Source AETitle
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            as="select"
            value={sourceAeTitle}
            onChange={e => setSourceAeTitle(e.target.value)}
            disabled={isEditing}
            defaultValue=""
          >
            <option value="" disabled>
              Select a node...
            </option>
            {nodes &&
              !isEditing &&
              nodes.length > 0 &&
              nodes.map(node => (
                <option value={node.aeTitle} key={node.pk}>
                  {node.aeTitle}
                </option>
              ))}
            {isEditing && <option>{deletionRule.sourceAeTitle}</option>}
          </FormControl>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Maximum storage days
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            type="number"
            placeholder="Maximum storage days..."
            value={maxStorageDays}
            onChange={e => {
              const tempMaxStorageDays = e.target.value;
              if (parseInt(tempMaxStorageDays, 10) <= 1) {
                setMaxStorageDays(1);
              } else {
                setMaxStorageDays(tempMaxStorageDays);
              }
            }}
          />
        </Col>
      </FormGroup>
      
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Delete only if forwarded
        </Form.Label>
        <Col sm={8} style={{ paddingTop: '0.7em' }}>
          <Form.Check
            custom
            type="checkbox"
            id="deleteOnlyIfForwarded"
            label=""
            checked={deleteOnlyIfForwarded}
            onChange={() => setDeleteOnlyIfForwarded(!deleteOnlyIfForwarded)}
          />
          {!deleteOnlyIfForwarded && (
            <small style={{ color: '#f86c6b' }}>
              WARNING: the system will delete also studies that have not been forwarded
            </small>
          )}
        </Col>
      </FormGroup>

      {deleteOnlyIfForwarded && (
        <FormGroup as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            Delete also filtered study
          </Form.Label>
          <Col sm={8} style={{ paddingTop: '0.7em' }}>
            <Form.Check
              custom
              type="checkbox"
              id="deleteIfFiltered"
              label=""
              checked={deleteIfFiltered}
              onChange={() => setDeleteIfFiltered(!deleteIfFiltered)}
            />
            {deleteIfFiltered && (
              <small style={{ color: '#f86c6b' }}>
                WARNING: the system will delete studies that may have been partially forwarded
              </small>
            )}
          </Col>
        </FormGroup>
      )}

      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Enabled
        </Form.Label>
        <Col sm={8} style={{ paddingTop: '0.7em' }}>
          <Form.Check
            custom
            type="checkbox"
            id="enableDeletionRule"
            label=""
            checked={enabled}
            onChange={() => setEnabled(!enabled)}
          />
        </Col>
      </FormGroup>
      <div style={{ textAlign: 'right' }}>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button style={{ width: '8em' }} variant="primary" onClick={save}>
          Save
        </Button>
      </div>
    </Form>
  );
}

export default DeletionRulesPopup;
