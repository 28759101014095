import { faSave, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Badge, Button, ButtonGroup, Form, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import AceEditor from 'react-ace';

import 'ace-builds/src-min-noconflict/mode-groovy';
import 'ace-builds/src-min-noconflict/theme-monokai';
import {
  deleteScript,
  forceGetAllScripts,
  getAllScripts,
  loadScript,
  loadSnippet,
  saveNewScript,
  updateScript
} from 'actions/scriptActions';
import { canPerformAction } from 'actions/userActions';
import { useAppDispatch } from 'hooks';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Script } from 'reducers/scriptReducer';
import { availableOperations } from 'reducers/userReducer';
import useToaster from '../../actions/useToaster';
import { getScripts, getUser } from '../../reducers/selectors';
import NewScriptPopup from './NewScriptPopup';
import PeriodicScriptsPopup from './PeriodicScriptsPopup';
import ScriptType from './ScriptType';

const OVERRIDE_CONTENT = '// no content';

function ScriptsPage() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const scripts = useSelector(getScripts);
  const history = useHistory();
  const location = useLocation();

  const [selectedScript, setSelectedScript] = useState<Script | null>(null);
  const [editorHeight, setEditorHeight] = useState(500);
  const [creatingScript, setCreatingScript] = useState<boolean>(false);
  const [updatingScript, setUpdatingScript] = useState<boolean>(false);
  const [deletingScript, setDeletingScript] = useState<boolean>(false);
  const [scriptFilter, setScriptFilter] = useState('ALL');
  const [showPeriodicScriptsPopup, setShowPeriodicScriptsPopup] = useState(false);
  const toaster = useToaster();

  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_SCRIPT);
  const watchedFoldersAuth = canPerformAction(user, availableOperations.WATCHED_FOLDERS_VISIBLE);

  useEffect(() => {
    dispatch(getAllScripts());

    const height = document.documentElement.scrollHeight;
    const { offsetTop } = document.getElementById('groovyEditor') as HTMLElement;
    setEditorHeight(height - offsetTop - 15);

    handleScriptLoading(location);

    const unregister = history.listen(loc => {
      handleScriptLoading(loc);
    });

    return unregister;
  }, []);

  const handleScriptLoading = loc => {
    if (loc.pathname.includes('scripts') && loc.search.includes('name')) {
      const scriptName = loc.search.replaceAll('?', '').replaceAll('name=', '');
      loadScript(scriptName).then(res => {
        setSelectedScript(res);
      });
    }
  };

  function getScriptLabel(script: Script): string {
    switch (script.type) {
      case ScriptType.COOL_DOWN:
        return 'CD';
      case ScriptType.MORPHING:
        return 'MP';
      case ScriptType.SERVLET:
        return 'SE';
      case ScriptType.TIMER:
        return 'TI';
      case ScriptType.FILE_HANDLER:
        return 'FH';
      case ScriptType.FORWARD_FILTER:
        return 'FF';
      default:
        return '?';
    }
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ padding: '1em' }}>
        <h4>Script catalog</h4>
        <Form.Group>
          <Form.Control size="sm" as="select" value={scriptFilter} onChange={e => setScriptFilter(e.target.value)}>
            <option value="ALL">ALL</option>
            {scripts
              .map(script => script.type)
              .filter((value, index, self) => self.indexOf(value) === index) // distinct values only
              .map(type => (
                <option key={type} value={type ? type : ''}>
                  {type}
                </option>
              ))}
          </Form.Control>
        </Form.Group>
        <table className="scripts-table">
          <tbody>
            {scripts
              .filter(script => scriptFilter === 'ALL' || scriptFilter === script.type)
              .map(script => (
                <tr
                  key={script.name}
                  className={selectedScript && selectedScript.name === script.name ? 'script-row selected' : 'script-row'}
                >
                  <td>
                    <Link
                      style={{
                        padding: '8px',
                        textDecoration: 'none',
                        color: 'var(--gray-200)',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                      to={`/scripts?name=${script.name}`}
                    >
                      {script.name}
                      <Badge
                        variant="info"
                        style={{ float: 'right', width: '2.5em' }}
                        title={script.type ? script.type : ''}
                      >
                        {getScriptLabel(script)}
                      </Badge>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <div>
          <Button
            size="sm"
            className="scripts-page-btn"
            variant="success"
            disabled={!authorized}
            onClick={e => setCreatingScript(true)}
            style={{ width: '100%' }}
          >
            New script
          </Button>
          <br />
          <Button
            size="sm"
            className="scripts-page-btn"
            variant="primary"
            disabled={!authorized}
            onClick={() => {
              setShowPeriodicScriptsPopup(true);
            }}
            style={{ width: '100%', marginTop: '1em' }}
          >
            Timers
          </Button>
          <br />
          <Button
            as={Link}
            to="/fileQueue/watchedFolders"
            className="scripts-page-btn"
            size="sm"
            variant="primary"
            disabled={!watchedFoldersAuth}
            style={{ lineHeight: '1.8', width: '100%', marginTop: '1em' }}
          >
            Watched folders
          </Button>
        </div>

        <h5 style={{ marginTop: '3em' }}>Snippets</h5>
        <FormGroup>
          <FormControl
            size="sm"
            as="select"
            onChange={e => {
              const snippetName = e.target.value;
              loadSnippet(snippetName).then(res => {
                setSelectedScript({ name: snippetName, snippet: true, type: null, content: res });
              });
            }}
          >
            <option disabled value="choose" selected>
              select snippet
            </option>
            <option value="find.groovy">find</option>
            <option value="qido.groovy">qido</option>
            <option value="move.groovy">move</option>
            <option value="prefetch.groovy">prefetch</option>
            <option value="morphing.groovy">tag morphing</option>
            <option value="servlet.groovy">servlet</option>
            <option value="cooldown.groovy">cool down</option>
            <option value="cookies.groovy">cookies</option>
            <option value="dicomization.groovy">dicomization</option>
            <option value="forwardfilter.groovy">forward filter</option>
            <option value="updatePatientAPI.groovy">update patient</option>
            <option value="isoMakerAPI.groovy">iso maker</option>
            <option value="burnerAPI.groovy">burner api</option>
            <option value="syncrWithCloud.groovy">sync with cloud</option>
          </FormControl>
        </FormGroup>
      </div>
      <div style={{ flexGrow: 1, padding: '1em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <strong style={{ color: 'white' }}>{selectedScript ? selectedScript.name : 'Select a script'}</strong>
            &nbsp;
            <Badge variant="info">{selectedScript && selectedScript.type}</Badge>
          </div>

          <ButtonGroup size="sm">
            <Button
              variant="outline-secondary"
              title="save"
              disabled={!authorized || !selectedScript || selectedScript.snippet}
              onClick={e => setUpdatingScript(true)}
            >
              <FontAwesomeIcon icon={faSave} />
            </Button>
            <Button
              variant="outline-secondary"
              title="discard changes"
              disabled={!authorized || !selectedScript || selectedScript.snippet}
              onClick={() =>
                loadScript(selectedScript?.name!).then(res => {
                  setSelectedScript(res);
                })
              }
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
              variant="outline-secondary"
              title="delete"
              disabled={!authorized || !selectedScript || selectedScript.snippet}
              onClick={() => setDeletingScript(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </ButtonGroup>
        </div>
        <AceEditor
          style={{ zIndex: 1 }}
          tabSize={2}
          fontSize={14}
          width="100%"
          height={`${editorHeight}px`}
          mode="groovy"
          theme="monokai"
          onChange={value => {
            if (selectedScript) {
              setSelectedScript(curr => {
                return { ...curr!, content: value };
              });
            }
          }}
          value={selectedScript && selectedScript.content ? selectedScript.content : OVERRIDE_CONTENT}
          name="groovyEditor"
          editorProps={{ $blockScrolling: true }}
          minLines={100}
          readOnly={!authorized || !selectedScript || selectedScript.snippet}
          commands={[
            {
              name: 'save',
              bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
              exec: () => setUpdatingScript(true),
            },
          ]}
        />
      </div>

      {showPeriodicScriptsPopup && <PeriodicScriptsPopup onClose={() => setShowPeriodicScriptsPopup(false)} />}

      {creatingScript && (
        <NewScriptPopup
          scripts={scripts}
          onClose={() => setCreatingScript(false)}
          onConfirm={script => {
            const data = {
              name: script.name,
              type: script.type,
              content: `// ${script.name}`,
            };
            console.log('creating new script', data);
            saveNewScript(data)
              .then(() => {
                console.log('script created');
                dispatch(forceGetAllScripts());
                loadScript(script);
                setCreatingScript(false);
                toaster.success('Script created');
              })
              .catch(err => {
                console.error('error creating script', err);
                toaster.error('Error creating script');
              });
          }}
        />
      )}

      {updatingScript && (
        <ConfirmUpdatePopup
          script={selectedScript}
          onClose={() => setUpdatingScript(false)}
          onConfirm={() => {
            if (selectedScript) {
              console.log('updating script', selectedScript);
              updateScript(selectedScript)
                .then(() => {
                  toaster.success('Script updated');
                })
                .catch(err => {
                  console.error('error updating script', err);
                  toaster.error('Error updating script');
                });

              setUpdatingScript(false);
            }
          }}
        />
      )}

      {deletingScript && (
        <ConfirmDeletePopup
          script={selectedScript}
          onClose={() => setDeletingScript(false)}
          onConfirm={() => {
            if (selectedScript) {
              console.log('deleting script', selectedScript);
              deleteScript(selectedScript.name)
                .then(() => {
                  console.log('script deleted');
                  dispatch(forceGetAllScripts());
                  toaster.success('Script deleted');
                })
                .catch(err => {
                  console.error('error deleting script', err);
                  toaster.error('Error deleting script');
                });
              setDeletingScript(false);
              setSelectedScript(null);
            }
          }}
        />
      )}
    </div>
  );
}

const ConfirmUpdatePopup = ({ script, onClose, onConfirm }) => (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Updating script</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        You are updating <strong>{script.name}</strong>
      </p>
      <p>Are you sure?</p>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onConfirm}>
          Save
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

const ConfirmDeletePopup = ({ script, onClose, onConfirm }) => (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Deleting script</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        You are going to delete the script <strong>{script.name}</strong>
      </p>
      <p>Please verify that no services rely on this script before proceding</p>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Confirm delete
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default ScriptsPage;
