export default function toggle<T>(array: T[], item: T) {
  function remove(i: T) {
    const copy = [...array];
    const index = copy.indexOf(i);
    copy.splice(index, 1);
    return copy;
  }

  function add(i: T) {
    const copy = [...array];
    copy.push(i);
    return copy;
  }

  return array.includes(item) ? remove(item) : add(item);
}
