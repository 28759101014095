import { Dispatch } from 'redux';
import { Condition } from '../reducers/nodesReducer';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetConditions = (dispatch: Dispatch) => http.get<Condition>(actions.GET_CONDITIONS, dispatch);
const debouncedGetConditions = defaultDebounce(regularGetConditions);

export const getConditions = () => debouncedGetConditions;
export const forceGetConditions = () => regularGetConditions;

export function insertCondition(condition: Condition) {
  return http.post<void>(actions.INSERT_CONDITION, condition);
}

export function updateCondition(condition: Condition) {
  return http.post<void>(actions.UPDATE_CONDITION, condition);
}

export function deleteCondition(condition: Condition) {
  return http.post<void>(actions.DELETE_CONDITION, condition);
}
