import { faTrash, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DELETE_EVERY_ACTION } from 'actions/actionCatalog';
import { deleteRole, updateRole } from 'actions/userActions';
import useToaster from 'actions/useToaster';
import { useAppDispatch } from 'hooks';
import React, { useState } from 'react';
import { Badge, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUser } from 'reducers/selectors';
import { Role, UserAction } from 'reducers/userReducer';
import UsersSettingsRolePopup from './UsersSettingsRolePopup';

type UserSettingsRoleTableType = {
  roles: Role[],
  actions: UserAction[],
  getRoles: () => void
}

function UserSettingsRoleTable({ roles, actions, getRoles }: UserSettingsRoleTableType) {
  const currentUser = useSelector(getUser);

  // show only the role of the current user (unless privileged)
  if (!currentUser.role?.privileged)
    roles = roles.filter(role => role.name === currentUser.role?.name)

  return (
    <table className="queue-table">
      <thead>
        <tr>
          <th style={{ width: '17%' }}>Role name</th>
          <th style={{ width: '10%' }}>Privileged</th>
          <th>Page actions</th>
          <th>Field actions</th>
          <th style={{ width: '10%' }}>Operations</th>
        </tr>
      </thead>
      <tbody>
        {roles &&
          roles.length > 0 &&
          roles.map(editingRole => (
            <RoleTableRow
              roles={roles}
              key={editingRole.name}
              actions={actions}
              editingRole={editingRole}
              getRoles={getRoles}
            />
          ))}
      </tbody>
    </table>
  );
}

function RoleTableRow({ roles, actions, editingRole, getRoles }) {
  const toaster = useToaster();
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);

  const deleteEveryAction = () => dispatch({ type: DELETE_EVERY_ACTION });
  const privileged = user.role?.privileged;

  const [showEditRole, setShowEditRole] = useState(false);
  const [showDeleteRole, setShowDeleteRole] = useState(false);

  const pageActions = editingRole.userActions!.filter(
    tempAction => tempAction.actionScope === 'PAGE'
  );
  const fieldActions = editingRole.userActions!.filter(
    tempAction => tempAction.actionScope === 'FIELD'
  );
  const pageActionsTooltip = (
    <Tooltip id="page-actions-tooltip" style={{ fontSize: '13px' }}>
      {pageActions.map((action, index) => {
        const actionName =
          action.actionName.charAt(0) + action.actionName.slice(1).toLowerCase().replace(/_/g, ' ');
        return (
          <React.Fragment key={action.actionName}>
            {actionName}
            {pageActions.length === index + 1 ? '' : ', '}
          </React.Fragment>
        );
      })}
    </Tooltip>
  );
  const fieldActionsTooltip = (
    <Tooltip id="field-actions-tooltip" style={{ fontSize: '13px' }}>
      {fieldActions.map((action, index) => {
        const actionName =
          action.actionName.charAt(0) + action.actionName.slice(1).toLowerCase().replace(/_/g, ' ');
        return (
          <React.Fragment key={action.actionName}>
            {actionName}
            {fieldActions.length === index + 1 ? '' : ', '}
          </React.Fragment>
        );
      })}
    </Tooltip>
  );

  return (
    <tr>
      <td>{editingRole.name}</td>
      <td>
        {editingRole.privileged ? (
          <Badge variant="success">True</Badge>
        ) : (
          <Badge variant="danger">False</Badge>
        )}
      </td>
      <OverlayTrigger
        delay={{ show: 400, hide: 100 }}
        rootClose
        placement="top"
        overlay={
          pageActions.length > 0 ? (
            pageActionsTooltip
          ) : (
            <Tooltip style={{ opacity: '0' }} id="none" />
          )
        }
      >
        <td>
          {pageActions &&
            pageActions.length > 0 &&
            pageActions.map((action, index) => {
              const actionName =
                action.actionName.charAt(0) +
                action.actionName.slice(1).toLowerCase().replace(/_/g, ' ');
              return (
                <React.Fragment key={action.actionName}>
                  {actionName}
                  {pageActions.length === index + 1 ? '' : ', '}
                </React.Fragment>
              );
            })}
        </td>
      </OverlayTrigger>
      <OverlayTrigger
        delay={{ show: 400, hide: 100 }}
        rootClose
        placement="top"
        overlay={
          fieldActions.length > 0 ? (
            fieldActionsTooltip
          ) : (
            <Tooltip style={{ opacity: '0' }} id="none" />
          )
        }
      >
        <td>
          {fieldActions &&
            fieldActions.length > 0 &&
            fieldActions.map((action, index) => {
              const actionName =
                action.actionName.charAt(0) +
                action.actionName.slice(1).toLowerCase().replace(/_/g, ' ');
              return (
                <React.Fragment key={action.actionName}>
                  {actionName}
                  {fieldActions.length === index + 1 ? '' : ', '}
                </React.Fragment>
              );
            })}
        </td>
      </OverlayTrigger>
      <td>
        {privileged && (
          <React.Fragment>
            <FontAwesomeIcon
              icon={faWrench}
              className="clickable-icon"
              onClick={() => setShowEditRole(true)}
            />
            <FontAwesomeIcon
              icon={faTrash}
              className="clickable-icon"
              onClick={() => setShowDeleteRole(true)}
            />
          </React.Fragment>
        )}
      </td>
      {showEditRole && (
        <EditRolePopup
          roles={roles}
          editingRole={editingRole}
          actions={actions}
          onClose={() => {
            setShowEditRole(false);
            deleteEveryAction();
          }}
          onSave={editedRole => {
            setShowEditRole(false);
            updateRole(editedRole)
              .then(() => {
                getRoles();
                if (editedRole.roleName === user.role?.name) {
                  toaster.info(
                    'You updated your role. Next time you log in or refresh it will change'
                  );
                }
                toaster.success('Role updated successfully');
              })
              .catch(error => {
                console.error(error);
                toaster.error(error.message);
              });
            deleteEveryAction();
          }}
        />
      )}
      {showDeleteRole && (
        <DeleteRolePopup
          editingRole={editingRole}
          onClose={() => setShowDeleteRole(false)}
          onSave={() => {
            setShowDeleteRole(false);
            deleteRole(editingRole.pk)
              .then(() => {
                getRoles();
                toaster.success('Role deleted successfully');
              })
              .catch(error => {
                console.error(error);
                toaster.error(error.message);
              });
          }}
        />
      )}
    </tr>
  );
}

function EditRolePopup({ roles, editingRole, actions, onClose, onSave }) {
  return (
    <Modal show size="lg" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          Updating role <strong>{editingRole.name}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UsersSettingsRolePopup
          roles={roles}
          actions={actions}
          onClose={onClose}
          onSave={onSave}
          isInEditMode
          editingRole={editingRole}
        />
      </Modal.Body>
    </Modal>
  );
}

function DeleteRolePopup({ editingRole, onClose, onSave }) {
  return (
    <Modal show onHide={onClose}>
      <Modal.Header>
        <Modal.Title>
          Deleting role <strong>{editingRole.name}</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete permanently this role?</p>
        <small>Before deletion check if there is a user with this role and change it</small>
        <div style={{ textAlign: 'right' }}>
          <br />
          <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
            Cancel
          </Button>
          <Button style={{ width: '8em' }} variant="danger" onClick={onSave}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UserSettingsRoleTable;
