import React, { useState } from 'react';
import { Button, Form, FormGroup, FormControl, Col, Row } from 'react-bootstrap';

function ConditionPopup({ isInEditMode, condition, rules, onClose, onSave }) {
  const tempProp = isInEditMode
    ? condition.property.charAt(0) + condition.property.slice(1).toLowerCase().replace(/_/g, ' ')
    : '-1';
  const tempCrit = isInEditMode
    ? condition.criteria.charAt(0) + condition.criteria.slice(1).toLowerCase()
    : '-1';

  const [source, setSource] = useState(isInEditMode ? condition.sourceNodeFk : '-1');
  const [destination, setDestination] = useState(isInEditMode ? condition.destinationNodeFk : '-1');
  const [property, setProperty] = useState(tempProp);
  const [criteria, setCriteria] = useState(tempCrit);
  const [referenceValue, setReferenceValue] = useState(isInEditMode ? condition.refValue : '');
  const [enabled, setEnabled] = useState(isInEditMode ? condition.enabled : false);

  const changeSourceDestination = srcDst => {
    const src = srcDst.split(',')[0];
    const dst = srcDst.split(',')[1];
    setSource(src);
    setDestination(dst);
  };

  const criterias = ['Contains', 'Equals', 'Regex'];
  const properties = [
    'Study description',
    'Modalities in study',
    'Any series description',
    'All series descriptions',
    'Accession number',
    'Patient ID',
    'ID issuer',
  ];

  const save = () => {
    if (
      !isInEditMode &&
      (source === '-1' ||
        destination === '-1' ||
        property === '-1' ||
        criteria === '-1' ||
        referenceValue === '')
    )
      return;
    const prop = property.toUpperCase().replace(/ /g, '_');
    const crit = criteria.toUpperCase();

    const temp = {
      pk: isInEditMode ? condition.pk : '',
      sourceNodeFk: typeof source === 'string' ? parseInt(source, 10) : condition.sourceNodeFk,
      destinationNodeFk:
        typeof destination === 'string' ? parseInt(destination, 10) : condition.destinationNodeFk,
      property: prop,
      criteria: crit,
      refValue: referenceValue,
      enabled,
    };

    onSave(temp);
  };

  return (
    <Form>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Forward rule
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            as="select"
            onChange={e => changeSourceDestination(e.target.value)}
            isInvalid={source === '-1' && destination === '-1'}
            defaultValue="-1"
          >
            {isInEditMode ? (
              <option value="-1" disabled>
                {condition.sourceNodeAet} → {condition.destinationNodeAet}
              </option>
            ) : (
              <option value="-1" disabled>
                Select nodes...
              </option>
            )}

            {rules.map((rule, index) => (
              <option key={index} value={[rule.fromPk, rule.toPk]}>
                {rule.from} → {rule.to}
              </option>
            ))}
          </FormControl>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Properties
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            as="select"
            value={property}
            onChange={e => setProperty(e.target.value)}
            isInvalid={property === '-1'}
          >
            {isInEditMode ? (
              <option value={property} disabled>
                {property}
              </option>
            ) : (
              <option value="-1" disabled>
                Select property...
              </option>
            )}

            {properties.map((prop, index) => (
              <option key={index} value={prop}>
                {prop}
              </option>
            ))}
          </FormControl>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Criteria
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            as="select"
            value={criteria}
            onChange={e => setCriteria(e.target.value)}
            isInvalid={criteria === '-1'}
          >
            {isInEditMode ? (
              <option value={criteria} disabled>
                {criteria}
              </option>
            ) : (
              <option value="-1" disabled>
                Select criteria...
              </option>
            )}

            {criterias.map((crit, index) => (
              <option key={index} value={crit}>
                {crit}
              </option>
            ))}
          </FormControl>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Reference value
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            type="text"
            placeholder="Reference value..."
            value={referenceValue}
            onChange={e => setReferenceValue(e.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Enable condition
        </Form.Label>
        <Col sm={8}>
          <Form.Check
            custom
            type="checkbox"
            id="enableCondition"
            label=""
            checked={enabled}
            onChange={() => setEnabled(!enabled)}
          />
        </Col>
      </FormGroup>
      <div style={{ textAlign: 'right' }}>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button style={{ width: '8em' }} variant="primary" onClick={save}>
          Save
        </Button>
      </div>
    </Form>
  );
}

export default ConditionPopup;
