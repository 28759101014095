import { POLLING_OFF, POLLING_ON } from '../actions/actionCatalog';

// eslint-disable-next-line @typescript-eslint/default-param-last
const polling = (state = true, action) => {
  switch (action.type) {
    case POLLING_OFF:
      return false;
    case POLLING_ON:
      return true;
    default:
      return state;
  }
};

export default polling;
