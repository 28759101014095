import React from 'react';
import { Badge } from 'react-bootstrap';
import { BarLoader } from 'react-spinners';
import CustomDate from '../../../CustomDate';
import { ENQUEUED, PROCESSING, ERROR, DONE, FILTERED } from '../../../constants';
import TableActionButton from 'components/reusableComponents/buttons/TableActionButton';
import { useAppDispatch } from 'hooks';
import * as aiQueueActions from '../../../actions/aiQueueActions';

const AiQueueRow = ({ item, modulesNames, authenticated }) => {
  const dispatch = useAppDispatch();
  const reprocessItem = it => {
    dispatch(aiQueueActions.reprocessEnqueuedItem(it));
    dispatch(aiQueueActions.forceGetAiQueue());
  };

  const pauseItem = it => {
    dispatch(aiQueueActions.pauseEnqueuedItem(it));
    dispatch(aiQueueActions.forceGetAiQueue());
  };

  const statusLabel = ({ status, window }) => {
    if (status === PROCESSING) return <BarLoader color="#01bf44" height={4} width={70} />;
    if (status === ERROR) return <Badge variant="danger">{status}</Badge>;
    if (status === ENQUEUED) return <Badge variant="warning">{status}</Badge>;
    if (status === FILTERED) return <Badge variant="info">{status}</Badge>;
    if (status === DONE)
      return (
        <Badge variant="success" title={window}>
          {status}
        </Badge>
      );
    return <Badge variant="secondary">{status}</Badge>;
  };

  return (
    <tr>
      <td style={{ verticalAlign: 'inherit' }}>{item.studyInstanceUid}</td>
      <td style={{ verticalAlign: 'inherit' }}>{modulesNames[item.aiModuleFk]}</td>
      <td style={{ verticalAlign: 'inherit' }}>{CustomDate.fromString(item.createdAt).toDateTimeString()}</td>
      <td style={{ verticalAlign: 'inherit' }}>{CustomDate.fromString(item.updatedAt).toDateTimeString()}</td>
      <td style={{ verticalAlign: 'inherit' }}>{item.attempts}</td>
      <td style={{ verticalAlign: 'inherit' }}>{statusLabel(item)}</td>
      <td style={{ display: 'flex' }}>
        <TableActionButton
          action={reprocessItem}
          args={item}
          disabled={!authenticated || !['DONE', 'PAUSED'].includes(item.status)}
          icon="faRepeat"
          tooltip="Re-process this item"
        />

        <TableActionButton
          action={pauseItem}
          args={item}
          disabled={!authenticated || !['ENQUEUED', 'ERROR'].includes(item.status)}
          icon="faPause"
          tooltip="Pause this item"
        />
      </td>
    </tr>
  );
};

export default AiQueueRow;
