import * as actions from './actionCatalog';
import { StudyToBurn } from '../reducers/studiesToBurnReducer';

export function getStudiesToBurn() {
  return {
    type: actions.GET_STUDIES_TO_BURN,
  };
}

export function deleteStudyToBurn(study: StudyToBurn) {
  return {
    type: actions.DELETE_STUDY_TO_BURN,
    studyToDelete: study,
  };
}

export function addStudyToBurn(study: StudyToBurn) {
  return {
    type: actions.ADD_STUDY_TO_BURN,
    studyToAdd: study,
  };
}

export function deleteAllStudiesToBurn() {
  return {
    type: actions.DELETE_ALL_STUDIES_TO_BURN,
  };
}
