import { Dispatch } from 'redux';
import { CLIENT_ID } from '../App';
import * as actions from './actionCatalog';
import { delay } from './delay';
import * as http from '../http';
import { Proxy } from 'reducers/proxyReducer';
import { Study } from 'reducers/studiesReducer';

export const searchStudies = (filters, searchMode) => dispatch => {
  filters.searchMode = searchMode;
  http.post<Study[]>(actions.SEARCH_STUDIES, filters, dispatch);
};

export const getStudySeries = (studyUid: string, searchMode: string) => (dispatch: Dispatch) => {
  const request = { StudyInstanceUID: studyUid || '', searchMode: searchMode };
  const promisedResponse = http.post<any>(actions.SEARCH_SERIES, request, dispatch);

  return Promise.all([promisedResponse, delay()])
    .then(responses => responses[0])
    .then(response => {
      if (response.warning && response.warning.code === 'CENTRAL_PACS_OFFLINE') {
        dispatch({
          type: 'ERROR',
          message: 'Central PACS not available, showing only local results',
        });
        response.data.forEach(series => {
          series.instanceAvailability = 'READ_ONLY';
        });
      }
    });
};

export const importStudy = (studyUid: string) => {
  http.get<void>(actions.IMPORT_STUDY.path + '?studyUid=' + studyUid + '&clientId=' + CLIENT_ID);
};

export const getProxyConfiguration = (dispatch: Dispatch) => {
  http.get<Proxy>(actions.GET_PROXY_CONFIGURATION, dispatch);
};

export const getStoredInstances = studyUid => {
  return http.get<{ remoteInstances: number; localInstances: number }>(
    actions.GET_STORED_INSTANCES.path + '?studyUid=' + studyUid
  );
};
