import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import * as selectors from '../../reducers/selectors';
import LunitInsightMMGConfiguration from './modules/LunitInsightMMGConfiguration';
import LunitInsightCXRConfiguration from './modules/LunitInsightCXRConfiguration';
import { AiModule } from 'reducers/aiModulesReducer';
import { useParams } from 'react-router-dom';
import * as aiModulesActions from '../../actions/aiModulesActions';
import { useAppDispatch } from '../../hooks';

function AiModuleConfiguration() {
  const dispatch = useAppDispatch();
  // get id from URL
  const { id } = useParams<{ id: string }>();
  // initialize selectedModule (will be null at first)
  let selectedModule: AiModule = useSelector(selectors.getSelectedAiModule);
  // needed when reloading page to allow url params to be used to fetch selected module
  const modules = useSelector(selectors.getAiModules);
  // get the selected module using id url param when selectedModule is null (upon page reload)
  modules.map(aModule => (aModule.pk.toString() === id ? (selectedModule = aModule) : ''));
  useEffect(() => {
    dispatch(aiModulesActions.getAiModules());
  }, []);

  if (selectedModule == null)
    return (
      <Card className="page-panel-common">
        <Card.Body>
          <h3>Selected AI module does not exist</h3>
        </Card.Body>
      </Card>
    );

  let info = JSON.parse(selectedModule.info);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <div style={{ maxWidth: '60rem' }}>
          <h3>{info.name}</h3>

          {info.name === 'Lunit INSIGHT MMG' && <LunitInsightMMGConfiguration module={selectedModule} />}

          {info.name === 'Lunit INSIGHT CXR' && <LunitInsightCXRConfiguration module={selectedModule} />}
        </div>
      </Card.Body>
    </Card>
  );
}

export default AiModuleConfiguration;
