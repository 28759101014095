import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface Prefetch {
  listName: string;
  studyUid: string;
  status: string;
  storedInstances: number;
  studyInstanceUid?: string;
}

export interface PrefetchStudy {
  studyInstanceUid?: string;
  patientId?: string;
  patientName?: string;
  patientBirthDate?: Date;
  studyDescription?: string;
  studyDate?: Date;
  status?: string;
  totalImages?: number;
  storedImages?: number;
  originNode?: string;
  insertedOn?: Date;
}

interface GetPrefetchQueueFulfilled {
  type: typeof actions.GET_PREFETCH_QUEUE_FULFILLED;
  response: AxiosResponse<Prefetch[]>;
}

interface WSEvent {
  type: typeof actions.WS_EVENT;
}

type PrefetchAction = GetPrefetchQueueFulfilled | WSEvent;

// eslint-disable-next-line @typescript-eslint/default-param-last
function prefetchQueue(state: Prefetch[] = [], action: PrefetchAction): Prefetch[] {
  switch (action.type) {
    case actions.GET_PREFETCH_QUEUE_FULFILLED:
      return action.response.data;
    case actions.WS_EVENT:
      return ws(state, action);
    default:
      return state;
  }
}

function ws(state: Prefetch[], action): Prefetch[] {
  const { event } = action.payload;

  if (event === 'PrefetchStudy') {
    const { listName, studyUid, status, storedInstances } = action.payload as Prefetch;
    return state.map(item =>
      item.listName === listName && item.studyInstanceUid === studyUid ? { ...item, status, storedInstances } : item
    );
  }

  // HANDLE 'StudySending' and 'StudyForwarded' here!
  return state;
}

export default prefetchQueue;
