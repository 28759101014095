import React from 'react';
import { Badge } from 'react-bootstrap';
import { BarLoader } from 'react-spinners';
import CustomDate from '../../../CustomDate';
import { URGENT, ENQUEUED, SENDING, ERROR, POSTPONED, DONE, PAUSED, FILTERED } from '../../../constants';
import QueuePlayButton from './QueuePlayButton';
import QueuePauseButton from './QueuePauseButton';
import QueueUrgentButton from './QueueUrgentButton';
import QueueDeleteButton from './QueueDeleteButton';

const QueueRow = ({
  item,
  compact,
  increasePriority,
  resumeEnqueuedItem,
  pauseEnqueuedItem,
  deleteFromQueue,
  selected,
  onSelect,
  authenticated,
}) => {
  const limit = (string, letters) => {
    if (string === null || string === undefined) return '';
    if (string.length <= letters) return string;
    return `${string.substring(0, letters)}...`;
  };

  const splitInTwoRows = value => {
    const date = CustomDate.fromString(value);
    return (
      <div>
        <div>{date.toDateString()}</div>
        <div>{date.toTimeString(true)}</div>
      </div>
    );
  };

  const statusLabel = ({ status, window }) => {
    if (status === SENDING) return <BarLoader color="#01bf44" height={4} width={70} />;
    if (status === ERROR) return <Badge variant="danger">{status}</Badge>;
    if (status === ENQUEUED) return <Badge variant="warning">{status}</Badge>;
    if (status === FILTERED) return <Badge variant="info">{status}</Badge>;
    if (status === POSTPONED)
      return (
        <Badge variant="secondary" title={window}>
          {status}
        </Badge>
      );
    if (status === DONE)
      return (
        <Badge variant="success" title={window}>
          {status}
        </Badge>
      );

    return <Badge variant="secondary">{status}</Badge>;
  };

  return (
    <tr
      onClick={e => {
        onSelect(item);
      }}
      className={selected ? 'selected' : ''}
    >
      {compact ? (
        <td>
          <div>{item.patientId}</div>
        </td>
      ) : (
        <td>
          <div>{item.patientId}</div>
          <div>
            {item.lastName} {item.firstName}
          </div>
        </td>
      )}

      {compact ? (
        <td>{item.studyUid}</td>
      ) : (
        <td>
          <div>
            <span style={{ fontStyle: 'italic', display: 'inline-block', width: '15em' }}>
              {CustomDate.fromString(item.studyDate).toDateString()} {item.studyTime}
            </span>
            <span>{item.modalities}</span>
          </div>
          <div>{limit(item.description, 70)}</div>
        </td>
      )}

      {compact ? (
        <td>{CustomDate.fromString(item.enqueuedOn).toDateTimeString()}</td>
      ) : (
        <td>{splitInTwoRows(item.enqueuedOn)}</td>
      )}

      <td style={{ verticalAlign: 'inherit' }}>{item.destination}</td>
      <td style={{ verticalAlign: 'inherit' }}>{statusLabel(item)}</td>
      <td style={{ verticalAlign: 'inherit' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ marginTop: '0.3em', width: '6em', marginRight: '1.5em' }}>
            <span
              style={{
                fontWeight: item.queue === URGENT ? 'bold' : undefined,
                marginRight: '2em',
              }}
            >
              {item.queue}
            </span>
          </div>
          {item.status !== DONE && (
            <div style={{ position: 'relative' }}>
              {item.status === PAUSED && (
                <QueuePlayButton
                  action={resumeEnqueuedItem}
                  args={item}
                  disabled={!authenticated}
                ></QueuePlayButton>
              )}
              {item.status !== PAUSED && item.status !== FILTERED && (
                <QueuePauseButton
                  action={pauseEnqueuedItem}
                  args={item}
                  disabled={item.status === SENDING || !authenticated}
                ></QueuePauseButton>
              )}
              {item.queue !== URGENT && item.status !== FILTERED && (
                <QueueUrgentButton
                  action={increasePriority}
                  args={item}
                  disabled={!authenticated}
                ></QueueUrgentButton>
              )}
              {item.status !== SENDING && item.status !== DONE && (
                <QueueDeleteButton
                  action={deleteFromQueue}
                  args={item}
                  disabled={!authenticated}
                ></QueueDeleteButton>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
};

export default QueueRow;
