import React from 'react';
import { Col, OverlayTrigger, Tooltip, ProgressBar } from 'react-bootstrap';

type Color = "Magenta" | "Cyan" | "Yellow" | "Light Cyan" | "Light Magenta" | "Black"

type InkLevelProps = {
  color: Color;
  level: number;
}

const InkLevel = ({ color, level }: InkLevelProps) => {

  const tooltipProps = {
    name: `${color}`,
    quantity: level > -1 ? `${level}%` : `N/A`,
  };
  
  return (
    <div>
      <Col className="colorLevelCol" style={{ padding: '0' }}>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={props => (
            <Tooltip id="button-tooltip" {...props}>
              <div style={{ fontSize: '18px' }}>{tooltipProps.name}</div>
              <div style={{ fontSize: '14px' }}>
                Quantity:{' '}
                <strong>{level > -1 ? `${level}%` : 'N/A'}</strong>                
              </div>
            </Tooltip>
          )}
        >
          <ProgressBar now={50} bsPrefix="ink-bar background-bar">
            <ProgressBar
              striped
              now={level > -1 ? level : 0}
              bsPrefix={`ink-bar ${getCssClass(color)}`}
            />
          </ProgressBar>
        </OverlayTrigger>
      </Col>
    </div>
  );
};

function getCssClass(color: Color): string {
  switch (color) {
    case 'Magenta': return 'inkM';
    case 'Cyan': return 'inkC';
    case 'Yellow': return 'inkY';
    case 'Light Cyan': return 'inkLC';
    case 'Light Magenta': return 'inkLM';
    case 'Black': return 'inkB';
  }
  return '';
}

export default InkLevel;
