const ScriptType = {
  MORPHING: 'MORPHING',
  SERVLET: 'SERVLET',
  COOL_DOWN: 'COOL_DOWN',
  TIMER: 'TIMER',
  FILE_HANDLER: 'FILE_HANDLER',
  FORWARD_FILTER: 'FORWARD_FILTER',
};

export default ScriptType;
