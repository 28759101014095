import { Store } from 'redux';
import * as actions from './actions/actionCatalog';

const FeedWebSocket = function (this, store: Store) {
  this.store = store;
  this.status = 'INITIAL';

  this.setStatus = function (newStatus) {
    const prevStatus = this.status;
    this.status = newStatus;

    if (prevStatus === 'INITIAL' && newStatus === 'DISCONNECTED') {
      this.store.dispatch({ type: actions.WS_DISCONNECTED });
    }

    if (prevStatus === 'CONNECTED' && newStatus === 'DISCONNECTED') {
      this.store.dispatch({ type: actions.WS_DISCONNECTED });
    }

    if (prevStatus === 'DISCONNECTED' && newStatus === 'CONNECTED') {
      this.store.dispatch({ type: actions.WS_RECONNECTED });
    }
  };

  this.connect = function () {
    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const { host } = window.location;
    let wsEndpoint = `${protocol}//${host}/feedws`;

    console.log('ws connecting to ', wsEndpoint);
    const ws = new WebSocket(wsEndpoint);

    ws.onopen = event => {
      console.log('web socket successfully connected to ', wsEndpoint);
      this.setStatus('CONNECTED');
    };

    ws.onmessage = event => {
      try {
        const json = JSON.parse(event.data);
        store.dispatch({ type: actions.WS_EVENT, payload: json });
      } catch (e) {
        console.warn('error parsing ws message as json', event.data);
      }
    };

    ws.onerror = event => {
      console.warn('web socket error', event);
    };

    ws.onclose = event => {
      console.log('WebSocket is closed now', event.code);
      this.setStatus('DISCONNECTED');
      setTimeout(() => {
        this.connect(store);
      }, 15000);
    };
  };
};

export default FeedWebSocket;
