import { combineReducers } from 'redux';
import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface CfgServices {
  serviceName: string;
  paramKey: string;
  paramValue: string;
  description: string;
  editable: boolean;
}

type GetCfgServices = {
  type: typeof actions.GET_CFG_SERVICES_FULFILLED;
  response: AxiosResponse<CfgServices[]>;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function cfgServices(state: CfgServices[] = [], action: GetCfgServices) {
  switch (action.type) {
    case actions.GET_CFG_SERVICES_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export interface CfgDicomServices {
  serviceName: string;
  paramKey: string;
  paramValue: string;
}

type GetCfgDicomServices = {
  type: typeof actions.GET_CFG_DICOM_SERVICES_FULFILLED;
  response: AxiosResponse<CfgDicomServices[]>;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function cfgDicomServices(state: CfgDicomServices[] = [], action: GetCfgDicomServices) {
  switch (action.type) {
    case actions.GET_CFG_DICOM_SERVICES_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export default combineReducers({
  cfgServices,
  cfgDicomServices,
});

export type ConfigurationsState = {
  cfgServices: ReturnType<typeof cfgServices>;
  cfgDicomServices: ReturnType<typeof cfgDicomServices>;
};
