import React from 'react';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const resumeTooltip = <Tooltip id="tooltip">Resume the forward of this item(s)</Tooltip>;

const QueuePlayButton = ({ action, args, disabled }) => {
  return (
    <OverlayTrigger placement="top" overlay={resumeTooltip}>
      <Button
        size="sm"
        variant="outline-secondary"
        className="queue-button"
        style={{ marginRight: '0.5em' }}
        disabled={disabled}
        onClick={e => {
          action(args);
          e.currentTarget.blur();
        }}
      >
        <FontAwesomeIcon icon={faPlay} />
      </Button>
    </OverlayTrigger>
  );
};

export default QueuePlayButton;