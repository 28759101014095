import { deleteSeries, seriesDisposeCheck } from 'actions/seriesDisposalActions';
import { useAppDispatch } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'reducers/selectors';
import SeriesDisposalConfirmationPopup from './SeriesDisposalConfirmationPopup';
import SeriesDisposalOutcomeDetailPopup from './SeriesDisposalOutcomeDetailPopup';
import SeriesDisposalProgressPopup from './SeriesDisposalProgressPopup';
import SeriesDisposalStudyInQueuePopup from './SeriesDisposalStudyInQueuePopup';

type SeriesDisposalComponentType = {
  selectedStudy: string;
  selectedSeries: string[];
};

const SeriesDisposalComponent = ({ selectedStudy, selectedSeries }: SeriesDisposalComponentType) => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);

  const disposeSeries = () => {
    dispatch(seriesDisposeCheck(selectedStudy, selectedSeries, user.username!));
  };

  const confirmDelete = () => {
    dispatch(deleteSeries(selectedStudy, selectedSeries, user.username!));
  };

  return (
    <React.Fragment>
      <SeriesDisposalConfirmationPopup onConfirm={disposeSeries} />
      <SeriesDisposalStudyInQueuePopup onDispose={confirmDelete} />
      <SeriesDisposalProgressPopup />
      <SeriesDisposalOutcomeDetailPopup />
    </React.Fragment>
  );
};

export default SeriesDisposalComponent;
