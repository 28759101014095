import { Queue } from 'reducers/queueReducer';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';
import { FeedStatus } from 'reducers/feedStatusReducer';

const regularGetQueue = dispatch => http.get<Queue[]>(actions.GET_QUEUE, dispatch);
const debouncedGetQueue = defaultDebounce(regularGetQueue);

const regularGetFeedStatus = dispatch => http.get<FeedStatus>(actions.GET_FEED_STATUS, dispatch);
const debouncedGetFeedStatus = defaultDebounce(regularGetFeedStatus);

// action creators
export const getQueue = () => debouncedGetQueue;
export const forceGetQueue = () => regularGetQueue;
export const getFeedStatus = () => debouncedGetFeedStatus;
export const increasePriority = item => dispatch => {
  const body = {
    studyUid: item.studyUid,
    destinationFk: item.destinationFk,
  };
  http.post(actions.INCREASE_PRIORITY, body, dispatch);
};

export const resumeEnqueuedItem = item => dispatch => {
  const body = {
    studyUid: item.studyUid,
    destinationFk: item.destinationFk,
  };
  http.post(actions.RESUME_ENQUEUED_ITEM, body, dispatch);
};
export const pauseEnqueuedItem = item => dispatch => {
  const body = {
    studyUid: item.studyUid,
    destinationFk: item.destinationFk,
  };
  http.post(actions.PAUSE_ENQUEUED_ITEM, body, dispatch);
};
export const deleteFromQueue = item => dispatch => {
  const body = {
    studyUid: item.studyUid,
    destinationFk: item.destinationFk,
  };
  http.post(actions.DELETE_ENQUEUED_ITEM, body, dispatch);
};
