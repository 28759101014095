import { PieChart } from 'react-minimal-pie-chart';
import { ClipLoader } from 'react-spinners';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface PieChartCustomProps {
  title: string;
  pending: boolean;
  label: string;
  enqueued: number;
  label2: string;
  paused: number;
  label3: string;
  done: number;
  label4: string;
  error: number;
}

const PieChartQueue = ({
  title,
  pending,
  label,
  enqueued,
  label2,
  paused,
  label3,
  done,
  label4,
  error,
}: PieChartCustomProps) => {
  return (
    <div className="dashboard-widget" style={{ alignItems: 'flex-start', width: '100%' }}>
      <OverlayTrigger
        placement="bottom"
        delay={{ show: 0, hide: 0 }}
        overlay={
          <Tooltip id={title} style={{ display: 'flex', flexDirection: 'column', height: '50px' }}>
            <div className="dashboard-widget-body-tooltip" style={{ height: '3em' }}>
              <div style={{ textAlign: 'start', padding: '0', fontSize: '1.2em' }}>Pie chart keys</div>
            </div>
            <div style={{ paddingBottom: '0.3em' }}>
              <div className="dashboard-widget-body-tooltip">
                <div style={{ fontSize: '12px', lineHeight: '0.8em' }}>No data: </div>
                <div style={{ width: '10px', height: '10px', backgroundColor: '#c8ced3', marginLeft: 'auto' }} />
              </div>
              <div className="dashboard-widget-body-tooltip">
                <div style={{ fontSize: '12px', lineHeight: '0.8em' }}>Enqueued: </div>
                <div style={{ width: '10px', height: '10px', backgroundColor: '#20a8d8', marginLeft: 'auto' }} />
              </div>
              <div className="dashboard-widget-body-tooltip">
                <div style={{ fontSize: '12px', lineHeight: '0.8em' }}>Paused: </div>
                <div style={{ width: '10px', height: '10px', backgroundColor: '#f8cb00', marginLeft: 'auto' }} />
              </div>
              <div className="dashboard-widget-body-tooltip">
                <div style={{ fontSize: '12px', lineHeight: '0.8em' }}>Error: </div>
                <div style={{ width: '10px', height: '10px', backgroundColor: '#f86c6b', marginLeft: 'auto' }} />
              </div>
              <div className="dashboard-widget-body-tooltip">
                <div style={{ fontSize: '12px', lineHeight: '0.8em' }}>Done: </div>
                <div style={{ width: '10px', height: '10px', backgroundColor: '#4dbd74', marginLeft: 'auto' }} />
              </div>
            </div>
          </Tooltip>
        }
      >
        <div className="dashboard-widget-header-storage">
          <PieChart
            className="dashboard-widget-header-storage-dynamic"
            data={
              done + paused + enqueued > 0
                ? [
                    { value: enqueued, color: '#20a8d8' },
                    { value: paused, color: '#f8cb00' },
                    { value: error, color: '#f86c6b' },
                    { value: done, color: '#4dbd74' },
                  ]
                : [{ value: 1, color: '#c8ced3' }]
            }
            background="#3a4149"
            totalValue={enqueued + paused + done + error > 0 ? enqueued + paused + done + error : 1}
            startAngle={-90}
            lengthAngle={-360}
            viewBoxSize={[120, 120]}
            radius={50}
            lineWidth={40}
            paddingAngle={
              done + paused + enqueued + error > 0 &&
              done !== done + paused + enqueued + error &&
              paused !== done + paused + enqueued + error &&
              enqueued !== done + paused + enqueued + error
                ? 1
                : 0
            }
          />
        </div>
      </OverlayTrigger>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          height: '100%',
          width: '100%',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="dashboard-widget-body">
            <div style={{ fontSize: '1.5em' }}>{title}</div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', lineHeight: '2.5em' }}>
              {pending ? <ClipLoader /> : `${label}: `}
            </div>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', fontSize: '1.5em' }}>
              {pending ? <ClipLoader /> : enqueued}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', lineHeight: '2.5em' }}>
              {pending ? <ClipLoader /> : `${label2}: `}
            </div>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', fontSize: '1.5em' }}>
              {pending ? <ClipLoader /> : paused}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', lineHeight: '2.5em' }}>
              {pending ? <ClipLoader /> : `${label4}: `}
            </div>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', fontSize: '1.5em' }}>
              {pending ? <ClipLoader /> : error}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', lineHeight: '2.5em' }}>
              {pending ? <ClipLoader /> : `${label3}: `}
            </div>
            <div className="dashboard-widget-body" style={{ paddingTop: '0', fontSize: '1.5em' }}>
              {pending ? <ClipLoader /> : done}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PieChartQueue;
