import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toaster from './Toaster';

class Popups extends Component<any, any> {
  render() {
    return (
      <div>
        <Toaster toasters={this.props.toasters} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    editNode: state.popups.editNode,
    toasters: state.popups.toasters,
  };
};

export default connect(mapStateToProps)(Popups);
