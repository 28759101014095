import BurnOverlayComponent from 'components/search/burnOverlay/BurnOverlayComponent';
import { formatDicomName } from 'dicom';
import React, { useEffect, useState } from 'react';
import { Badge, OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BurnerJob } from 'reducers/printersReducer';
import { getBurnersJobs } from 'reducers/selectors';
import CustomDate from '../../../CustomDate';
import TableActionButton from "components/reusableComponents/buttons/TableActionButton";

type JobsTableProps = {
  cancelJob: (jobId: string, burner: string) => void;
  filter: string;
};

const JobsTable = ({ cancelJob, filter }: JobsTableProps) => {
  const jobs:BurnerJob[] = useSelector(getBurnersJobs);
  const [filteredJobs, setFilteredJobs] = useState<BurnerJob[]>([]);

  useEffect(() => {
    filterJobs();
  }, [JSON.stringify(jobs), filter]);

  const filterJobs = () => {
    const filtered: BurnerJob[] = jobs.filter(job => {
      if (job.burner && job.burner.trim() !== '') {
        const burnerName: string[] = job.burner.toLowerCase().split(' ');
        if (burnerName.some(name => name.startsWith(filter))) {
          return true;
        }
      }

      if (job.patientName && job.patientName.trim() !== '') {
        const patientName: string[] = formatDicomName(job.patientName.toLowerCase()).split(' ');
        if (patientName.some(name => name.startsWith(filter))) {
          return true;
        }
      }

      return job.studies.some(study => {
        if (study.description && study.description.trim() !== '') {
          const description = study.description.toLowerCase().split(' ');
          return description.some(descr => descr.startsWith(filter));
        }
        return false;
      });
    });

    setFilteredJobs(filtered);
  };

  return (
    <Row>
      <table className="queue-table">
        <thead>
          <tr>
            <th style={{ width: '10%' }}>Printer</th>
            <th style={{ width: '15%' }}>Patient</th>
            <th style={{ width: '30%' }}>Description</th>
            <th style={{ width: '5%' }}>Series</th>
            <th style={{ width: '12%' }}>Date</th>
            <th style={{ width: '10%' }}>Status</th>
            <th style={{ width: '8%' }}>Error</th>
            <th style={{ width: '5%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredJobs.map((job, index) => {
            return (
              <tr key={index}>
                <td>{job.burner}</td>
                <td>{tooltipped(formatDicomName(job.patientName))}</td>
                <td>
                  {job.studies.map(study => {
                    if (study.description?.trim() === "") {
                      // eslint-disable-next-line prettier/prettier, react/jsx-key
                      return <div><i key={study.studyUid}>NO_DESCRIPTION</i></div>;
                    }
                    return <div key={study.studyUid}>{tooltipped(study.description)}</div>;
                  })}
                </td>
                <td>{job.studies.map(study => {
                    if(!study.series || study.series.length === 0)
                      return <div key={study.studyUid}>ALL</div>;  

                    return <div key={study.studyUid}>{"[" + study.series.length + "/" + study.totalSeries + "]"}</div>;
                  })}
                </td>
                <td>{CustomDate.fromString(job.date).toDateTimeString()}</td>
                <td>{tooltipped(job.statusDescription, job.detailedStatus)}</td>
                <td>
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props => (
                      <Tooltip id="job-error-tooltip" {...props}>
                        <div style={{ fontSize: '16px' }}>{job.errorDescription}</div>
                      </Tooltip>
                    )}
                  >
                    <Badge
                      pill
                      variant={job.statusDescription === 'Being processed' ? 'warning' : 'danger'}
                    >
                      {job.errorCode}
                    </Badge>
                  </OverlayTrigger>
                </td>
                <td>
                  <Row style={{ justifyContent: 'center' }}>
                    <JobActions job={job} cancelJob={cancelJob} />
                  </Row>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Row>
  );
};

type JobsActionProps = {
  job: BurnerJob;
  cancelJob: (jobId: string, burner: string) => void;
};

const JobActions = ({ job, cancelJob }: JobsActionProps) => {
  if (job.burner === 'UNKNOWN') return <></>;

  // print again button
  if (job.burnerModel === 'EPSON_P100' &&
      (job.statusDescription === 'Completed' ||
      job.statusDescription === 'Completed abnormally' ||
      job.statusDescription === 'Being not accepted')
  ) {
    return (
      <OverlayTrigger trigger="click" rootClose placement="left" overlay={BurnOverlayPopup(job)}>
        <span style={{ marginRight: '2em' }}>
          <TableActionButton action={""} icon="faRedo" tooltip="Open Redo Overlay" />
        </span>
      </OverlayTrigger>
    );
  }

  if (job.burnerModel === 'RIMAGE_2000I' &&
      (job.statusDescription === 'Successfully completed' ||
      job.statusDescription === 'Rejected' ||
      job.statusDescription === 'Cancelled')
  ) {
    return (
      <OverlayTrigger trigger="click" rootClose placement="left" overlay={BurnOverlayPopup(job)}>
        <span style={{ marginRight: '2em' }}>
          <TableActionButton action={""} icon="faRedo" tooltip="Open Redo Overlay" />
        </span>
      </OverlayTrigger>
    );
  }

  // cancel order button
  if (
    job.statusDescription === 'Being registered or put in standby' ||
    job.statusDescription === 'Being processed' ||
    job.statusDescription === 'Being paused'
  )
    return (
      <span style={{ marginRight: '2em' }}>
        <TableActionButton action={() => cancelJob(job.id, job.burner)} icon="faTimes" tooltip="Cancel Job" />
      </span>
    );

  return <></>;
};

const BurnOverlayPopup = job => (
  <Popover id="forward-popover">
    <BurnOverlayComponent job={job} />
  </Popover>
);

const tooltipped = (text, tooltipText = text) => (
  <OverlayTrigger placement="bottom" overlay={tooltip(tooltipText)} delay={300}>
    <div id="tooltippedBurnOverlay" style={{ width: 'min-content' }}>
      {text}
    </div>
  </OverlayTrigger>
);

const tooltip = text => <Tooltip id="tooltip">{text}</Tooltip>;

export default JobsTable;
