import axios from 'axios';
import { useAppDispatch } from 'hooks';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';

const PdiViewer = () => {
  const pdiLogo = useRef<HTMLImageElement>(null);
  const uploadForm = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [failed, setFailed] = useState(false);
  const dispatch = useAppDispatch();

  const toaster = {
    success: message => dispatch({ type: 'SUCCESS', message }),
    info: message => dispatch({ type: 'INFO', message }),
    warning: message => dispatch({ type: 'WARNING', message }),
    error: message => dispatch({ type: 'ERROR', message }),
  };

  useLayoutEffect(() => {
    getPDILogo();
  }, []);

  const getPDILogo = () => {
    setLoading(true);
    const url = `/rest/react/getPdiLogo`;
    axios
      .get(url, {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(res => {
        if (res.status === 200) {
          setFailed(false);
          pdiLogo.current!.src = 'data:image/jpg;base64,' + res.data;
          toaster.success(`PDILogo succesfully upgraded`);
        }
      })
      .catch(e => {
        setFailed(true);
        toaster.error(e.message);
        console.error(e);
      });
  };

  const submitPDILogo = values => {
    const formData = new FormData();
    formData.append('selectedFile', values ? values : '');
    if (values && values.name) {
      formData.append('fileName', values.name);
    }
    const url = `/rest/react/updatePDILogo`;
    axios
      .post(url, formData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then(res => {
        if (res.status === 200) {
          setFailed(false);
          getPDILogo();
          toaster.success(`PDILogo succesfully upgraded`);
        }
      })
      .catch(e => {
        toaster.error(e.message);
        console.error(e);
      });
  };

  function open() {
    if (pdiLogo) {
      const windowOpen = window.open('');
      windowOpen!.document.write(pdiLogo.current!.outerHTML);
    }
  }

  return (
    <Col style={{ marginLeft: '-15px' }}>
      <Form style={{ paddingTop: '1em' }}>
        <Form.Group as={Row}>
          <Col sm={8}>
            <Button size="sm" onClick={() => uploadForm.current && uploadForm.current.click()}>
              Upload PDI Logo
            </Button>
            <Form.File
              ref={uploadForm}
              name="file"
              type="file"
              accept=".png,.jpg"
              onChange={event => {
                submitPDILogo(event.currentTarget.files![0]);
              }}
              style={{ display: 'none' }}
            />
          </Col>
        </Form.Group>
      </Form>
      {failed ? (
        <div className="spinner" style={{ fontSize: '1em' }}>
          PDI Logo not found
        </div>
      ) : (
        <React.Fragment>
          <div className="spinner" style={{ display: loading ? 'block' : 'none' }}>
            <div style={{ marginTop: '25px' }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ width: '50px', height: '50px' }}
              />
            </div>
          </div>
        </React.Fragment>
      )}
      <img
        ref={pdiLogo}
        onLoad={() => setLoading(false)}
        id="pdiLogoPreview"
        onClick={() => open()}
        style={{ display: loading ? 'none' : 'block' }}
      ></img>
    </Col>
  );
};

export default PdiViewer;
