import { useAppDispatch } from 'hooks';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSeriesDisposal } from 'reducers/selectors';
import * as actions from '../../../actions/actionCatalog';

type SeriesDisposalConfirmationPopupType = {
  onConfirm: () => void;
};

const SeriesDisposalConfirmationPopup = ({ onConfirm }: SeriesDisposalConfirmationPopupType) => {
  const dispatch = useAppDispatch();
  const seriesDisposal = useSelector(getSeriesDisposal);

  return (
    <Modal show={seriesDisposal.popups.showConfirmationPopup}>
      <Modal.Header>
        <Modal.Title>Deleting series</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>You are going to permanently delete the selected series on the local FEED</p>
        <p>Are you sure?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ minWidth: '5em' }}
          variant="outline-secondary"
          onClick={() => dispatch({ type: actions.SERIES_DISPOSAL_ABORT })}
        >
          Cancel
        </Button>
        <Button style={{ minWidth: '5em' }} variant="danger" onClick={onConfirm}>
          Confirm delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SeriesDisposalConfirmationPopup;
