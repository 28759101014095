import { CfgDicomServices, CfgServices } from 'reducers/configurationsReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetCfgServices = (dispatch: Dispatch) => http.get<CfgServices[]>(actions.GET_CFG_SERVICES, dispatch);
const debouncedGetCfgServices = defaultDebounce(regularGetCfgServices);

const regularGetCfgDicomServices = (dispatch: Dispatch) =>
  http.get<CfgDicomServices[]>(actions.GET_CFG_DICOM_SERVICES, dispatch);
const debouncedGetCfgDicomServices = defaultDebounce(regularGetCfgDicomServices);

export const getCfgServices = () => debouncedGetCfgServices;
export const forceGetCfgServices = () => regularGetCfgServices;
export const getCfgDicomServices = () => debouncedGetCfgDicomServices;
export const forceGetCfgDicomServices = () => regularGetCfgDicomServices;

export function updateCfgServices(cfgService: CfgServices) {
  return http.post<void>(actions.UPDATE_CFG_SERVICES, cfgService);
}

export function updateCfgDicomServices(cfgDicomService: CfgDicomServices) {
  return http.post<void>(actions.UPDATE_CFG_DICOM_SERVICES, cfgDicomService);
}

export function restartAll() {
  return http.get<void>(actions.RESTART_ALL);
}

export function isInDocker() {
  return http.get<boolean>(actions.IS_IN_DOCKER);
}

export function restartFeed() {
  http.get(actions.RESTART_FEED);
}
