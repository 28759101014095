import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface Series {
  seriesUid: string;
  modality: string;
  description: string;
  instances: number;
  instanceAvailability: string;
}

type SeriesAction = {
  type: typeof actions.SEARCH_SERIES_FULFILLED;
  response: AxiosResponse<Series[]>;
  request: {
    StudyInstanceUID: string;
  };
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function series(state: Series[] = [], action: SeriesAction): Series[] {
  switch (action.type) {
    case actions.SEARCH_SERIES_FULFILLED:
      return {
        ...state,
        [action.request.StudyInstanceUID]: action.response.data,
      };
    default:
      return state;
  }
}

export default series;
