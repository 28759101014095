import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface DeletionRule {
  sourceAeTitle: string;
  maxStorageDays: number;
  deleteOnlyIfForwarded: boolean;
  enabled: boolean;
}

type GetDeletionRulesFulfilled = {
  type: typeof actions.GET_DELETION_RULES_FULFILLED;
  response: AxiosResponse<DeletionRule[]>;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function deletionRules(state: DeletionRule[] = [], action: GetDeletionRulesFulfilled) {
  switch (action.type) {
    case actions.GET_DELETION_RULES_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export default deletionRules;
