import { AxiosResponse } from 'axios';
import { combineReducers } from 'redux';
import * as actions from '../actions/actionCatalog';

export interface Proxy {
  enabled?: boolean;
  qidoEndpoint?: string;
  wadoEndpoint?: string;
  authorizationHeader?: string;
}

type ProxyAction = {
  type: typeof actions.GET_PROXY_CONFIGURATION_FULFILLED;
  response: AxiosResponse<Proxy>;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function configuration( state: Proxy = {}, action: ProxyAction) {
  switch (action.type) {
    case actions.GET_PROXY_CONFIGURATION_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

// export default nodes;
export default combineReducers({
  configuration,
});
