/* eslint-disable consistent-return */
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { Dispatch } from 'redux';

export const usePolling = (dispatch: Dispatch, callback: any, delay: number) => {
  const pausePolling = useSelector((state: RootState) => state.polling);
  const savedCallback = useEventCallback(callback);
  useEffect(() => {
    dispatch(savedCallback());
  }, [dispatch, savedCallback]);

  useEffect(() => {
    function tick() {
      dispatch(savedCallback());
    }
    if (delay !== null && pausePolling) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [dispatch, savedCallback, delay, pausePolling]);
};

function useEventCallback(func) {
  const ref = useRef(func);

  useEffect(() => {
    ref.current = func;
  });

  // eslint-disable-next-line no-void
  return useCallback((...args) => ref.current.apply(void 0, args), []);
}
