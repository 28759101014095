import * as actions from '../actions/actionCatalog';

export interface Pending {
  queue: boolean;
  feedStatus: boolean;
  studies: boolean;
  series: boolean;
  nodes: boolean;
  prefetch: boolean;
  proxyConfiguration: boolean;
  aiQueue: boolean;
  fileQueue: boolean;
}

const initialState = {
  queue: false,
  feedStatus: false,
  studies: false,
  series: false,
  nodes: false,
  prefetch: false,
  proxyConfiguration: false,
  aiQueue: false,
  fileQueue: false,
};

type PendingAction = {
  type: string;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function pending(state: Pending = initialState, action: PendingAction): Pending {
  switch (action.type) {
    case actions.GET_QUEUE_PENDING:
      return { ...state, queue: true };
    case actions.GET_QUEUE_FULFILLED:
    case actions.GET_QUEUE_REJECTED:
      return { ...state, queue: false };
    case actions.GET_FEED_STATUS_PENDING:
      return { ...state, feedStatus: true };
    case actions.GET_FEED_STATUS_FULFILLED:
    case actions.GET_FEED_STATUS_REJECTED:
      return { ...state, feedStatus: false };
    case actions.SEARCH_STUDIES_PENDING:
      return { ...state, studies: true };
    case actions.SEARCH_STUDIES_FULFILLED:
    case actions.SEARCH_STUDIES_REJECTED:
      return { ...state, studies: false };
    case actions.SEARCH_SERIES_PENDING:
      return { ...state, series: true };
    case actions.SEARCH_SERIES_FULFILLED:
    case actions.SEARCH_SERIES_REJECTED:
      return { ...state, series: false };
    case actions.GET_NODES_PENDING:
      return { ...state, nodes: true };
    case actions.GET_NODES_FULFILLED:
    case actions.GET_NODES_REJECTED:
      return { ...state, nodes: false };
    case actions.GET_PREFETCH_QUEUE_PENDING:
      return { ...state, prefetch: true };
    case actions.GET_PREFETCH_QUEUE_FULFILLED:
    case actions.GET_PREFETCH_QUEUE_REJECTED:
      return { ...state, prefetch: false };
    case actions.GET_PROXY_CONFIGURATION_PENDING:
      return { ...state, proxyConfiguration: true };
    case actions.GET_PROXY_CONFIGURATION_FULFILLED:
    case actions.GET_PROXY_CONFIGURATION_REJECTED:
      return { ...state, proxyConfiguration: false };
    case actions.GET_AI_QUEUE_PENDING:
      return { ...state, aiQueue: true };
    case actions.GET_AI_QUEUE_FULFILLED:
    case actions.GET_AI_QUEUE_REJECTED:
      return { ...state, aiQueue: false };
    case actions.GET_FILE_QUEUE_PENDING:
      return { ...state, fileQueue: true };
    case actions.GET_FILE_QUEUE_FULFILLED:
    case actions.GET_FILE_QUEUE_REJECTED:
      return { ...state, fileQueue: false };
    default:
      return state;
  }
}

export default pending;
