import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

type OverlayTooltipType = {
  text: string | undefined,
}

const OverlayTooltip = ({ text }: OverlayTooltipType) => {
  const tooltip = <Tooltip id="tooltip">{text}</Tooltip>;

  return (
    <OverlayTrigger placement="bottom" overlay={tooltip} delay={300}>
      <div id="tooltippedBurnOverlay">{text}</div>
    </OverlayTrigger>
  );
};

export default OverlayTooltip;
