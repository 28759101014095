import React, { useEffect } from 'react';
import zxcvbn from 'zxcvbn';
import { ProgressBar } from 'react-bootstrap';
import { SET_PASSWORD_SCORE } from 'actions/actionCatalog';
import { useAppDispatch } from 'hooks';

function PasswordStrengthMeter({ password }) {
  const testedPassword = zxcvbn(password);
  const dispatch = useAppDispatch();
  const updateState = payload => dispatch({ type: SET_PASSWORD_SCORE, payload });

  type PasswordStyle = {
    label: string;
    variant: string;
    color: string;
  };

  const createPasswordStyle = (result): PasswordStyle => {
    switch (result.score) {
      case 0:
        return {
          label: 'Weaker',
          variant: 'danger',
          color: '#f86c6b',
        };
      case 1:
        return {
          label: 'Weak',
          variant: 'danger',
          color: '#f86c6b',
        };
      case 2:
        return {
          label: 'Fair',
          variant: 'warning',
          color: '#ffc107',
        };
      case 3:
        return {
          label: 'Good',
          variant: 'info',
          color: '#63c2de',
        };
      case 4:
        return {
          label: 'Strong',
          variant: 'success',
          color: '#4dbd74',
        };
      default:
        return {
          label: 'Weak',
          variant: 'danger',
          color: '#f86c6b',
        };
    }
  };

  useEffect(() => {
    updateState(testedPassword.score);
  }, [testedPassword.score]);

  const { label, variant, color } = createPasswordStyle(testedPassword);

  return (
    <React.Fragment>
      <div className="password-strength-meter">
        <ProgressBar
          now={testedPassword.score}
          max={4}
          className="password-strength-meter-progress"
          variant={variant}
        />
        <p className="password-strength-meter-label">
          {password && (
            <React.Fragment>
              <p style={{ fontSize: '12px', color: `${color}` }}>
                <strong>Password strength:</strong> {label}
              </p>
            </React.Fragment>
          )}
        </p>
      </div>
    </React.Fragment>
  );
}

export default PasswordStrengthMeter;
