import React from 'react';

const EncodingsTable = ({ encodings }) => (
  <table className="encodings-table">
    <thead>
      <tr>
        {/* <th width="34%">Original TS</th> */}
        <th style={{ width: '34%' }}>Original TS</th>
        <th>Offered TS</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>IVRLE</td>
        <td>{encodings.IVRLE.join(', ')}</td>
      </tr>
      <tr>
        <td>EVRLE</td>
        <td>{encodings.EVRLE.join(', ')}</td>
      </tr>
      <tr>
        <td>JPEG_LOSSLESS</td>
        <td>{encodings.JPEG_LOSSLESS.join(', ')}</td>
      </tr>
      <tr>
        <td>JPEG_2000_LOSSLESS</td>
        <td>{encodings.JPEG_2000_LOSSLESS.join(', ')}</td>
      </tr>
      {/* <tr>
        <td>MPEG4</td>
        <td>{encodings.MPEG4.join(', ')}</td>
      </tr>
      <tr>
        <td>JPEG_BASELINE</td>
        <td>{encodings.JPEG_BASELINE.join(', ')}</td>
      </tr> */}
    </tbody>
  </table>
);

export default EncodingsTable;
