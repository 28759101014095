import { Dispatch } from 'redux';
import * as http from '../http';
import * as actions from './actionCatalog';
import * as search from './searchActions';

export const seriesDisposeCheck = (studyUid: string, seriesUids: string[], username: string) => (dispatch: any) => {
  http
    .get<boolean>(`${actions.SERIES_DISPOSE_CHECK.path}?studyUid=${studyUid}`)
    .then(outcome => {
      if (outcome) {
        dispatch({ type: actions.SERIES_DISPOSAL_STUDY_IN_QUEUE, payload: { studyUid, isStudyInQueue: true } });
      } else {
        dispatch(deleteSeries(studyUid, seriesUids, username));
      }
    })
    .catch(error => {
      dispatch({ type: actions.SERIES_DISPOSAL_REJECTED, error });
    });
};

export const deleteSeries = (studyUid: string, seriesUids: string[], username: string) => (dispatch: Dispatch) => {
  dispatch({ type: actions.SERIES_DISPOSAL_PENDING, payload: { seriesUids } });

  const params = seriesUids.map(uid => `seriesUid=${uid}`).join('&');
  const url = `/rest/react/disposeSeries?username=${username}&studyUid=${studyUid}&${params}`;
  http
    .get<any>(url)
    .then(outcome => {
      dispatch({ type: actions.SERIES_DISPOSAL_FULFILLED, payload: { outcome } });
      search.getStudySeries(studyUid, 'local')(dispatch);
    })
    .catch(error => {
      dispatch({ type: actions.SERIES_DISPOSAL_REJECTED, error });
    });
};
