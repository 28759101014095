import { combineReducers } from 'redux';
import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';
import { StudyToBurn } from './studiesToBurnReducer';
import CustomDate from 'CustomDate';

export interface Printer {
  name: string;
  model: string;
  jobsFolderPath: string;
  dataFolderPath: string;
  mappedDataFolderPath: string;
  cdFormat: string;
  dvdFormat: string;
  defaultMediaFormat: string;
  cdSizeThresholdMegaBytes: string;
  labelFileName?: string;
  file?: undefined;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function printers(state: Printer[] = [], action: any): Printer[] {
  switch (action.type) {
    case 'GET_PRINTERS_FULFILLED':
      const mappedPaths = action.response.data.map(p => {
        return { ...p, mappedDataFolderPath: p.mappedDataFolderPath.split('\\\\').join('\\') };
      });

      return mappedPaths;
    default:
      return state;
  }
}

export interface BurnerStatus {
  name: string;
  model: string;
  stackers: {
    stacker1: number;
    stacker2: number;
    stacker3: number;
    stacker4: number;
  };
  inkLevels: {
    cyan: number;
    magenta: number;
    yellow: number;
    lightCyan: number;
    lightMagenta: number;
    black: number;
  };
  errors: [
    {
      code: string;
      description: string;
    }
  ];
}

type GetBurnersStatusFulfilled = {
  type: typeof actions.GET_BURNERS_STATUS_FULFILLED;
  response: AxiosResponse<any>;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function burnersStatus(state: BurnerStatus[] = [], action: GetBurnersStatusFulfilled): BurnerStatus[] {
  switch (action.type) {
    case actions.GET_BURNERS_STATUS_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export interface BurnerJob {
  id: string;
  burner: string;
  burnerModel: string;
  reader: string;
  date: string;
  patientName: string;
  studies: StudyToBurn[];
  statusDescription: string;
  statusCode: string;
  detailedStatus: string;
  errorDescription: string;
  errorCode: string;
}

type GetBurnersJobsFulfilled = {
  type: typeof actions.GET_BURNERS_JOBS_FULFILLED;
  response: AxiosResponse<any>;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
function burnersJobs(state: BurnerJob[] = [], action: GetBurnersJobsFulfilled): BurnerJob[] {
  switch (action.type) {
    case 'GET_BURNERS_JOBS_FULFILLED':
      const burners: BurnerJob[] = action.response.data;
      burners.sort((a, b) => {
        return CustomDate.fromString(a.date).compare(CustomDate.fromString(b.date));
      });
      return burners;
    default:
      return state;
  }
}

export default combineReducers({
  printers,
  burnersStatus,
  burnersJobs,
});

export type PrintersState = {
  printers: ReturnType<typeof printers>;
  burnersStatus: ReturnType<typeof burnersStatus>;
  burnersJobs: ReturnType<typeof burnersJobs>;
};
