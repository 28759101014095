import { useAppDispatch } from 'hooks';
import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as printerActions from '../../actions/printerActions';
import * as readerActions from '../../actions/readerActions';
import { Printer } from '../../reducers/printersReducer';
import { Reader } from '../../reducers/readersReducer';
import { getPrinters, getReaders } from '../../reducers/selectors';
import DeleteModal from './DeleteModal';
import DownloadLabel from './DownloadLabel';
import PdiViewer from './PdiViewer';
import PrinterModal from './PrinterModal';
import ReaderModal from './ReaderModal';

const PrinterPage = () => {
  const dispatch = useAppDispatch();
  const printers: Printer[] = useSelector(getPrinters);
  const readers: Reader[] = useSelector(getReaders);

  useEffect(() => {
    dispatch(printerActions.getPrinters());
    dispatch(readerActions.getReaders());
  }, [dispatch]);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <h3>Printers configuration</h3>
        <Col>
          <Row className="justify-content-end">
            <PrinterModal />
          </Row>
          <Row>
            <table className="queue-table">
              <thead>
                <tr>
                  <th>Printer Name</th>
                  <th>Printer Model</th>
                  <th>Jobs Folder</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {printers.map(printer => (
                  <tr key={printer.name}>
                    <td>{printer.name}</td>
                    <td>{printer.model}</td>
                    <td>{printer.jobsFolderPath}</td>
                    <td>
                      <PrinterModal editMode element={printer} />
                      <DeleteModal element={printer} />
                      {printer.labelFileName && <DownloadLabel printer={printer} />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-end">
            <ReaderModal />
          </Row>
          <Row>
            <table className="queue-table">
              <thead>
                <tr>
                  <th>Reader Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {readers.map(el => (
                  <tr key={el.pk}>
                    <td>{el.displayName}</td>
                    <td>
                      <ReaderModal EditMode element={el} />
                      <DeleteModal element={el} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Row>
        </Col>
        <PdiViewer />
      </Card.Body>
    </Card>
  );
};

export default PrinterPage;
