import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { getPrinterNames } from '../../reducers/selectors';
import * as printerActions from '../../actions/printerActions';
import { useAppDispatch } from 'hooks';
import { Printer } from 'reducers/printersReducer';

const PrinterModal = ({
  editMode = false,
  element = {
    name: '',
    model: 'EPSON_P100',
    jobsFolderPath: '',
    dataFolderPath: '',
    mappedDataFolderPath: '',
    defaultMediaFormat: 'CD',
    cdFormat: '',
    dvdFormat: '',
    cdSizeThresholdMegaBytes: '0',
    labelFileName: '',
    file: undefined,
  },
}: {
  editMode?: boolean;
  element?: Printer;
}) => {
  const isInEditMode: boolean = editMode;
  const names = useSelector(getPrinterNames);
  const dispatch = useAppDispatch();
  const fileRef = useRef<HTMLInputElement>(null);

  const winPathRegExp = RegExp(`^[a-zA-Z]:\\\\[\\\\\\S|*\\S]?.*$`);

  const schemaEdit = yup.object().shape({
    name: yup.string().required('This field is required'),
    jobsFolderPath: yup.string().required('This field is required'),
    dataFolderPath: yup.string().required('This field is required'),
    mappedDataFolderPath: yup
      .string()
      .test('win-path', 'This is not a valid Windows path', value => winPathRegExp.test(value))
      .required('This field is required'),
    defaultMediaFormat: yup.string().required('This field is required'),
    cdSizeThresholdMegaBytes: yup.number('This should be a number, a size in megabytes').required('This field is required'),
  });

  const schemaAdd = yup.object().shape({
    name: yup
      .string()
      .test('test-name', 'This name already exists, choose another one', (val: string) => {
        if (names.indexOf(val) === -1) {
          return true;
        }
        return false;
      })
      .required('This field is required'),
    jobsFolderPath: yup.string().required('This field is required'),
    dataFolderPath: yup.string().required('This field is required'),
    mappedDataFolderPath: yup
      .string()
      .test('win-path', 'This is not a valid Windows path', value => winPathRegExp.test(value))
      .required('This field is required'),
    defaultMediaFormat: yup.string().required('This field is required'),
    cdSizeThresholdMegaBytes: yup.number('This should be a number, a size in megabytes').required('This field is required'),
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const toaster = {
    success: message => dispatch({ type: 'SUCCESS', message }),
    info: message => dispatch({ type: 'INFO', message }),
    warning: message => dispatch({ type: 'WARNING', message }),
    error: message => dispatch({ type: 'ERROR', message }),
  };

  const submitPrinter = values => {
    const formData = new FormData();
    formData.append('name', values.name);
    formData.append('model', values.model);
    formData.append('jobsFolderPath', values.jobsFolderPath);
    formData.append('dataFolderPath', values.dataFolderPath);
    formData.append('mappedDataFolderPath', values.mappedDataFolderPath);
    formData.append('cdFormat', 'JOLIET');
    formData.append('dvdFormat', 'UDF102');
    formData.append('defaultMediaFormat', values.defaultMediaFormat);
    formData.append('cdSizeThresholdMegaBytes', values.cdSizeThresholdMegaBytes);
    if (values.file) {
      formData.append('selectedFile', values.file);
    }
    const url = `/rest/react/${isInEditMode ? 'updateCdBurner' : 'insertCdBurner'}`;
    axios
      .post(url, formData, {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })
      .then(res => {
        if (res.status === 200) {
          dispatch(printerActions.getPrinters());
          toaster.success(`Printer succesfully ${isInEditMode ? 'edited' : 'added'}`);
        }
      })
      .catch(e => toaster.error(e.message));
  };
  return (
    <React.Fragment>
      {isInEditMode ? (
        <FontAwesomeIcon className="printer-table-actions" icon={faWrench} onClick={handleShow} />
      ) : (
        <Button onClick={handleShow} size="sm" variant="primary">
          Add Printer
        </Button>
      )}
      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            {isInEditMode ? (
              <span>You are currently editing the printer named {element.name}</span>
            ) : (
              <span>You are currently adding a new printer</span>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            validateOnChange={false}
            validationSchema={isInEditMode ? schemaEdit : schemaAdd}
            onSubmit={(values, actions) => {
              submitPrinter(values);
              actions.setSubmitting(false);
              handleClose();
            }}
            initialValues={element}
          >
            {({ handleSubmit, handleBlur, handleChange, values, touched, isValid, errors, setFieldValue }) => (
              <Form id="printer-form" noValidate onSubmit={handleSubmit}>
                {isInEditMode ? (
                  <div />
                ) : (
                  <Form.Group as={Row}>
                    <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                      Name
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        size="sm"
                        type="text"
                        name="name"
                        disabled={isInEditMode}
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={touched.name && !!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Model
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as="select"
                      style={{ width: '30%' }}
                      size="sm"
                      name="model"
                      value={values.model}
                      onChange={handleChange}
                    >
                      <option value="EPSON_P100">Epson-PP100</option>
                      <option value="RIMAGE_2000I">Rimage-2000i</option>
                    </Form.Control>
                  </Col>
                </Form.Group>

                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Jobs Folder Path
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="jobsFolderPath"
                      value={values.jobsFolderPath}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={touched.jobsFolderPath && !!errors.jobsFolderPath}
                    />
                    <Form.Control.Feedback type="invalid">{errors.jobsFolderPath}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Data Folder Path
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="dataFolderPath"
                      value={values.dataFolderPath}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={touched.dataFolderPath && !!errors.dataFolderPath}
                    />
                    <Form.Control.Feedback type="invalid">{errors.dataFolderPath}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Mapped Data Folder Path
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      size="sm"
                      type="text"
                      name="mappedDataFolderPath"
                      value={values.mappedDataFolderPath}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={touched.mappedDataFolderPath && !!errors.mappedDataFolderPath}
                    />
                    <Form.Control.Feedback type="invalid">{errors.mappedDataFolderPath}</Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Default Media Format
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as="select"
                      style={{ width: '30%' }}
                      size="sm"
                      name="defaultMediaFormat"
                      value={values.defaultMediaFormat}
                      onChange={handleChange}
                    >
                      <option value="CD">CD</option>
                      <option value="DVD">DVD</option>
                      <option value="CD_DVD">CD_DVD</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
                {values.defaultMediaFormat !== 'DVD' && (
                  <Form.Group as={Row}>
                    <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                      CD size Threshold (in megabytes)
                    </Form.Label>
                    <Col sm={8}>
                      <Form.Control
                        size="sm"
                        type="number"
                        name="cdSizeThresholdMegaBytes"
                        value={values.cdSizeThresholdMegaBytes}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        isInvalid={touched.cdSizeThresholdMegaBytes && !!errors.cdSizeThresholdMegaBytes}
                      />
                      <Form.Control.Feedback type="invalid">{errors.cdSizeThresholdMegaBytes}</Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}
                <Form.Group as={Row}>
                  <Form.Label as={Col} sm={4} md={{ offset: 1 }}>
                    Label image
                  </Form.Label>
                  <Col sm={8}>
                    <Button onClick={() => fileRef.current && fileRef.current.click()} size="sm" variant="outline-primary">
                      Upload label image (.tdd or .btw)
                    </Button>
                    <Form.Label style={{ paddingLeft: '1rem' }}>
                      {values.file ? (
                        <React.Fragment>
                          Uploaded <strong>{(values.file as File).name}</strong>
                        </React.Fragment>
                      ) : values.labelFileName ? (
                        <React.Fragment>
                          Uploaded <strong>{values.labelFileName}</strong>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>No file uploaded</React.Fragment>
                      )}
                    </Form.Label>
                    <Form.File
                      ref={fileRef}
                      style={{ display: 'none' }}
                      name="file"
                      type="file"
                      accept=".tdd,.btw"
                      onChange={event => {
                        setFieldValue('file', event.currentTarget.files![0]);
                      }}
                      onBlur={handleBlur}
                      isInvalid={touched.file && !!errors.file}
                      feedback={errors.file}
                    />
                  </Col>
                </Form.Group>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" form="printer-form">
            {`${isInEditMode ? 'Submit Edit' : 'Add Printer'}`}
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default PrinterModal;
