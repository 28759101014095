import React, { useState, useEffect } from 'react';
import { Col, Row, Form, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { ForwardRule } from '../../reducers/nodesReducer';
import { getNodes as getNodesAction } from 'actions/nodesActions';
import { getNodes as getNodesSelector, getScripts as getScriptsSelector } from 'reducers/selectors';
import useToaster from '../../actions/useToaster';
import { useAppDispatch } from 'hooks';
import { getAllScripts } from 'actions/scriptActions';
import * as http from '../../http';

function ForwardOverlay({ studyUid }) {
  const dispatch = useAppDispatch();
  const nodes = useSelector(getNodesSelector);
  const scripts = useSelector(getScriptsSelector);
  const [favoriteNodes, setFavoriteNodes] = useState<string[]>([]);
  const [forwardRules, setForwardRules] = useState<ForwardRule[]>([]);
  const [selectedNode, setSelectedNode] = useState<string | null>(null);
  const [selectedMorphingScript, setSelectedMorphingScript] = useState<string | null>(null);
  const [selectedFilterScript, setSelectedFilterScript] = useState<string | null>(null);
  const toaster = useToaster();
  dispatch(getNodesAction());
  dispatch(getAllScripts());

  useEffect(() => {
    var favNodes: string[] = [];
    nodes.map(node => {
      if (node.forwardFavorite) {
        favNodes.push(node.aeTitle);
      }
    });
    setFavoriteNodes(favNodes);
  }, [nodes]);

  useEffect(() => {
    http.get<ForwardRule[]>(`/rest/react/getForwardRules?studyUid=${studyUid}`).then(payload => setForwardRules(payload));
  }, [studyUid]);

  if (!forwardRules || !nodes || !scripts) {
    return <div style={{ textAlign: 'center' }}>{specialText('Loading...')}</div>;
  }

  return (
    <div className="forward">
      {forwardRules.length > 0 && (
        <table className="queue-table selectable">
          <thead>
            <tr>
              <th style={{ width: '100%' }}>Forward rules</th>
              <th style={{ width: '100%' }}></th>
            </tr>
          </thead>
          <tbody>
            {forwardRules.map(rule => (
              <tr
                title={`source: ${rule.from}`}
                key={rule.from + rule.to}
                onClick={e => {
                  setSelectedNode(rule.to);
                  setSelectedMorphingScript(rule.morphingScript || null);
                  setSelectedFilterScript(rule.filterScript || null);
                }}
              >
                <td>{rule.to}</td>
                <td>{displayScripts(rule)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {favoriteNodes.length > 0 && (
        <table className="queue-table selectable">
          <thead>
            <tr>
              <th style={{ width: '100%' }}>Favorite Nodes</th>
            </tr>
          </thead>
          <tbody>
            {favoriteNodes.map(node => (
              <tr
                title={node}
                key={'key ' + node}
                onClick={e => {
                  setSelectedNode(node);
                }}
              >
                <td>{node}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 0 }} className="font-weight-bold">
            Destination
          </Form.Label>
          <Col sm={8}>
            <Form.Control as="select" size="sm" onChange={e => setSelectedNode(e.target.value)} value={selectedNode || '-'}>
              <option disabled hidden value="-">
                -
              </option>
              {nodes.map(node => (
                <option key={node.aeTitle} value={node.aeTitle}>
                  {node.aeTitle}
                </option>
              ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 0 }} className="font-weight-bold">
            Morphing script
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              size="sm"
              onChange={e => setSelectedMorphingScript(e.target.value)}
              value={selectedMorphingScript || '-'}
            >
              <option value="-">-</option>
              {scripts
                .filter(script => script.type === 'MORPHING')
                .map(script => (
                  <option key={script.name}>{script.name}</option>
                ))}
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 0 }} className="font-weight-bold">
            Filtering script
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              as="select"
              size="sm"
              onChange={e => setSelectedFilterScript(e.target.value)}
              value={selectedFilterScript || '-'}
            >
              <option value="">-</option>
              {scripts
                .filter(script => script.type === 'FORWARD_FILTER')
                .map(script => (
                  <option key={script.name}>{script.name}</option>
                ))}
            </Form.Control>
          </Col>
        </Form.Group>
      </Form>

      <Button
        disabled={selectedNode == null}
        style={{ float: 'right', marginBottom: '1.5em' }}
        variant="primary"
        size="sm"
        onClick={() => {
          enqueueStudy(studyUid, selectedNode, selectedMorphingScript, selectedFilterScript)
            .then(response => {
              if (response.data.error) {
                toaster.error(`Error enqueuing study ${studyUid}`);
              } else {
                toaster.success(`Study ${studyUid} enqueued`);
                document.body.click(); // close overlay
              }
            })
            .catch(err => toaster.error(`Error enqueuing study ${studyUid}`));
        }}
      >
        Enqueue
      </Button>
    </div>
  );
}

const enqueueStudy = (studyUid, selectedNode, morphingScript, forwardFilter) => {
  const data = {
    studyUid,
    destinationAet: selectedNode,
    queue: 'UI',
    morphingScript,
    forwardFilter,
    temporalWindow: null,
  };

  return axios.post(`/rest/react/enqueueStudy`, data, {
    headers: {
      'Content-type': 'application/json',
    },
  });
};

const displayScripts = rule => {
  if (!rule.morphingScript && !rule.filterScript) return 'no scripts';

  let scripts: String[] = [];
  if (rule.morphingScript) scripts.push(rule.morphingScript);
  if (rule.filterScript) scripts.push(rule.filterScript);
  return scripts.join(', ');
};

const specialText = text => <div style={{ fontSize: '0.9em', fontStyle: 'italic', color: '#AAA' }}>{text}</div>;

export default ForwardOverlay;
