import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canPerformAction } from 'actions/userActions';
import useToaster from 'actions/useToaster';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getGloryMachines, getUser } from 'reducers/selectors';
import { availableOperations } from 'reducers/userReducer';
import * as actions from '../../actions/gloryMachinesActions';
import GloryMachineCard from './GloryMachineCard';
import { GloryMachinePopup, GloryMachinePopupType } from './GloryMachinePopup';

const GloryMachinesPage = () => {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const toaster = useToaster();
  const gloryMachines = useSelector(getGloryMachines);
  const [showNewMachinePopup, setShowNewMachinePopup] = useState<boolean>(false);
  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_PAYMENT_DEVICES);

  useEffect(() => {
    dispatch(actions.getGloryMachines());
  }, []);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <h3 style={{ display: 'inline' }}>Cash machines</h3>
        <div style={{ float: 'right' }}>
          { authorized && 
            <Button size="sm" onClick={() => setShowNewMachinePopup(true)}>
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '5px' }} />
              Add new Glory machine
            </Button>
          }
        </div>
        <div id="glory-machines-wrapper">
          {gloryMachines.map(gloryMachine => (
            <GloryMachineCard key={gloryMachine.name} machine={gloryMachine} />
          ))}
        </div>
        {showNewMachinePopup && (
          <NewGloryMachinePopup
            onClose={() => setShowNewMachinePopup(false)}
            onSave={newMachine => {
              console.log('new machine:', newMachine);
              actions
                .insertGloryMachine(newMachine)
                .then(() => {
                  dispatch(actions.getGloryMachines());
                  toaster.success('New glory machine added');
                })
                .catch(error => {
                  console.error(error);
                  toaster.error('Error inserting new glory machine');
                });
              setShowNewMachinePopup(false);
            }}
          ></NewGloryMachinePopup>
        )}
      </Card.Body>
    </Card>
  );
};

const NewGloryMachinePopup = ({ onClose, onSave }: GloryMachinePopupType) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>New glory machine</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <GloryMachinePopup onClose={onClose} onSave={onSave}></GloryMachinePopup>
    </Modal.Body>
  </Modal>
);

export default GloryMachinesPage;
