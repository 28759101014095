import React, { useEffect, useState } from 'react';
import { Card, FormControl, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faRedo } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import useToaster from '../../actions/useToaster';
import * as http from '../../http';

function LogsPage() {
  const [logs, setLogs] = useState('');
  const [logsList, setLogsList] = useState<{ main: string[]; server: string[]; storage: string[] }>({
    main: [],
    server: [],
    storage: [],
  });
  const [lineCount, setLineCount] = useState(50);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getLogs(100);
    getLogsList();
  }, []);

  function getLogs(lines: number) {
    http
      .get<string>(`/rest/react/getLogs?lines=${lines}`)
      .then(json => {
        setLogs(json);
      })
      .catch(error => {
        console.error('Error loading logs', error);
      });
  }

  function getLogsList() {
    http
      .get<{ main: string[]; server: string[]; storage: string[] }>(`/rest/react/getLogsList`)
      .then(json => {
        var retrievedLogsList = json;
        if (retrievedLogsList) {
          retrievedLogsList.main?.sort();
          retrievedLogsList.server?.sort();
          retrievedLogsList.server?.reverse();
          retrievedLogsList.storage?.sort();
          retrievedLogsList.storage?.reverse();
          setLogsList(retrievedLogsList);
        }
      })
      .catch(error => {
        console.error('Error loading logs list', error);
      });
  }

  const tempLogs: string[] = logs !== undefined ? logs.split('\n') : [];
  tempLogs.pop();
  const formattedLogs = tempLogs.slice(tempLogs.length - lineCount, tempLogs.length);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <h3>Logs</h3>
        <div className="logs-page">
          <div>Presented lines: (max 100 - min 1)</div>
          <FormControl
            size="sm"
            type="number"
            defaultValue="50"
            max="100"
            min="1"
            value={lineCount}
            style={{ width: '38px', textAlign: 'center' }}
            onChange={e => {
              const tempLineCount = parseInt(e.target.value, 10);
              if (tempLineCount >= 100) {
                setLineCount(100);
              } else if (tempLineCount <= 1) {
                setLineCount(1);
              } else {
                setLineCount(tempLineCount);
              }
            }}
          />
          <Button size="sm" onClick={() => getLogs(100)}>
            <FontAwesomeIcon style={{ marginRight: '1em' }} icon={faRedo} />
            Refresh
          </Button>
          <Button size="sm" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon style={{ marginRight: '1em' }} icon={faDownload} />
            Download logs
          </Button>
        </div>
        <Card className="logs-panel">
          <LogsCard formattedLogs={formattedLogs} />
        </Card>
      </Card.Body>

      {showModal && logsList && <DownloadLogsModal logsList={logsList} onClose={() => setShowModal(false)} />}
    </Card>
  );
}

const LogsCard = ({ formattedLogs }) => (
  <Card.Body>
    {formattedLogs &&
      formattedLogs.length > 0 &&
      formattedLogs.map((log, index) => (
        <div key={index} className="log">
          {log}
        </div>
      ))}
  </Card.Body>
);

const DownloadLogsModal = ({ logsList, onClose }) => {
  return (
    <Modal show>
      <Modal.Header>
        <Modal.Title>Download logs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="logs-container">
          <DownloadList logsList={logsList.main} />
          <div className="separator"></div>
          <DownloadList logsList={logsList.server} />
          <div className="separator"></div>
          <DownloadList logsList={logsList.storage} />
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

const DownloadList = ({ logsList }) => {
  const toaster = useToaster();

  function downloadLogs(fileName: string) {
    http
      .get<any>(`/rest/react/downloadLogs?fileName=${fileName}`, undefined, res => res.blob())
      .then(data => {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        saveAs(blob, fileName);
      })
      .catch(error => {
        console.error('Error downloading logs', error);
        toaster.error('Error downloading log file');
      });
  }

  return (
    <React.Fragment>
      {logsList.length > 0 && (
        <div className="queue-table download-log-table">
          {logsList.map(fileName => (
            <div className="log-file-entry" key={fileName}>
              <div>{fileName}</div>
              <Button size="sm" onClick={() => downloadLogs(fileName)}>
                <FontAwesomeIcon icon={faDownload} style={{ marginRight: '1em' }} />
                Download
              </Button>
            </div>
          ))}
        </div>
      )}
    </React.Fragment>
  );
};

export default LogsPage;
