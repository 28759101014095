import { ClipLoader } from 'react-spinners';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface FeedStatusComponentProps {
  title: string;
  pending: boolean;
  label: string;
  studiesCount: string;
  classname: IconDefinition;
  color: string;
  label2: string;
  spaceOccupied: string;
}

export const FeedStatusComponent = ({
  title,
  pending,
  label,
  studiesCount,
  classname,
  color,
  label2,
  spaceOccupied,
}: FeedStatusComponentProps) => (
  <div className="dashboard-widget" style={{ alignItems: 'flex-start', width: '100%' }}>
    <div className="dashboard-widget-header-storage" style={{}}>
      <FontAwesomeIcon icon={classname} color={color} size="5x" className="fa-icon-no-interaction" />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="dashboard-widget-body">
          <div style={{ fontSize: '1.5em' }}>{title}</div>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="dashboard-widget-body" style={{ paddingTop: '0', lineHeight: '2.5em' }}>
            {pending ? <ClipLoader /> : `${label}: `}
          </div>
          <div className="dashboard-widget-body" style={{ paddingTop: '0', fontSize: '1.5em' }}>
            {pending ? <ClipLoader /> : studiesCount}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}></div>
        <div className="dashboard-widget-body" style={{ paddingTop: '0', lineHeight: '2.5em' }}>
          {pending ? <ClipLoader /> : `${label2}: `}
        </div>
        <div className="dashboard-widget-body" style={{ paddingTop: '0', fontSize: '1.5em' }}>
          {pending ? <ClipLoader /> : spaceOccupied}
        </div>
      </div>
    </div>
  </div>
);
