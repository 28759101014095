/* eslint-disable consistent-return */
import React, { Component } from 'react';
import { Button, Form, Col, Row, Popover, OverlayTrigger } from 'react-bootstrap';
import EncodingsTable from './EncodingsTable';
import { Encodings, Node } from '../../reducers/nodesReducer';
import { PROXY_AETITLE } from '../../constants';

interface NodeFormProps {
  node: Partial<Node>;
  encodings?: Encodings;
  aetsAlreadyTaken: string[];
  onClose: any;
  pending: boolean;
  saveButtonLabels: string[];
  onSave: any;
  nodes: string[];
  onDicomEcho: any;
}

interface NodeFormState {
  ip?: string;
  protocol?: 'DICOM' | 'WEB';
  port?: number;
  aeTitle?: string;
  qidoEndpoint?: string;
  stowRsEndpoint?: string;
  wadoEndpoint?: string;
  encodings: Encodings;
  compression: string;
  forwardNode: string;
  authorizationHeader?: string;
  notes?: string;
  forwardFavorite?: boolean;
}

class NodeForm extends Component<NodeFormProps, NodeFormState> {
  originalEncodings: typeof defaultEncodings;

  constructor(props: NodeFormProps) {
    super(props);
    this.state = {
      ...props.node,
      encodings: props.encodings || defaultEncodings,
      compression: getRelatedCompression(props.encodings),
      forwardNode: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.validateAet = this.validateAet.bind(this);    
    this.validateForwardFields = this.validateForwardFields.bind(this);
    this.isProxy = this.isProxy.bind(this);

    this.originalEncodings = props.encodings || defaultEncodings;
  }

  handleChange(e) {
    const field = e.target.name;
    const { value } = e.target;
    this.setState({ [field]: value } as Pick<NodeFormState, keyof NodeFormState>);
  }

  validate() {
    let valid = true;
    valid = valid && this.validateAet();
    valid = valid && this.validateForwardFields()!;
    return valid;
  }

  validateForwardFields() {
    const { protocol, ip, port, stowRsEndpoint } = this.state;
    if (protocol === 'DICOM')
      return ip !== undefined && ip.length > 0 && port !== null && validatePort(port);

    if (protocol === 'WEB') return validateUrl(stowRsEndpoint);
  }

  validateAet() {
    const { aeTitle } = this.state;
    const { aetsAlreadyTaken } = this.props;
    return (
      aeTitle !== undefined &&
      aeTitle.length > 0 &&
      aetsAlreadyTaken.findIndex(aet => aet === aeTitle) === -1
    );
  }

  isProxy() {
    const { aeTitle } = this.state;
    return aeTitle === PROXY_AETITLE
  }

  render() {
    const {
      aeTitle,
      protocol,
      stowRsEndpoint,
      qidoEndpoint,
      wadoEndpoint,
      authorizationHeader,
      compression,
      encodings,
      ip,
      port,
      notes,
      forwardFavorite,
    } = this.state;

    const { onClose, pending, saveButtonLabels, onSave, onDicomEcho } = this.props;
    return (
      // <Form horizontal>
      <Form>
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            AE Title
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              className={this.isProxy() ? 'disabled-input' : ""}
              size="sm"
              type="text"
              name="aeTitle"
              value={aeTitle}
              onChange={this.handleChange}
              isInvalid={!this.validateAet()}
              disabled={this.isProxy()}
            />
            <Form.Control.Feedback type="invalid">
              Field should not be empty or the name is already taken
            </Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            Protocol
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              style={{ width: '30%' }}
              size="sm"
              id="protocol-dropdown"
              as="select"
              value={protocol}
              onChange={e => {
                const protocols: string = e.target.value;
                if (protocols === 'WEB' || protocols === 'DICOM') {
                  this.setState({ protocol: protocols });
                }
              }}
            >
              <option value="DICOM">DICOM</option>
              <option value="WEB">WEB</option>
            </Form.Control>
          </Col>
        </Form.Group>

        {protocol === 'DICOM' ? (
          <DicomFields ip={ip} port={port} handleChange={this.handleChange} />
        ) : (
          <StowRsFields
            stowRsEndpoint={stowRsEndpoint}
            qidoEndpoint={qidoEndpoint}
            wadoEndpoint={wadoEndpoint}
            authorizationHeader={authorizationHeader}
            handleChange={this.handleChange}
          />
        )}

        <br />

        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            Compression settings
          </Form.Label>
          <Col sm={5}>
            <Form.Control
              size="sm"
              as="select"
              value={compression}
              onChange={e => {
                const selectedCompression = e.target.value;
                const newEncodings = getEncodingsFor(selectedCompression, this.originalEncodings);
                this.setState({ compression: e.target.value, encodings: newEncodings });
              }}
            >
              {
                // show this option only if original encodings correspond to a CUSTOM compression setting
                getRelatedCompression(this.originalEncodings) === COMPRESSION.CUSTOM && (
                  <option value={COMPRESSION.CUSTOM}>Custom</option>
                )
              }
              <option value={COMPRESSION.ORIGINAL}>Original</option>
              <option value={COMPRESSION.COMPRESS}>Compress</option>
              <option value={COMPRESSION.DECOMPRESS}>Decompress</option>
            </Form.Control>
          </Col>
          <Col sm={3}>
            <OverlayTrigger
              trigger="click"
              rootClose
              placement="right"
              overlay={EncodingDetailsOverlay(encodings)}
            >
              <Button size="sm" variant="link" style={{ paddingTop: '7px', fontSize: 'smaller' }}>
                Show details
              </Button>
            </OverlayTrigger>
          </Col>
          <Col sm={8} md={{ offset: 4 }}>
            <div style={{ marginTop: '1em', fontSize: 'smaller' }}>
              {compression === COMPRESSION.CUSTOM && (
                <p>Images sent to this node will follow custom encoding rules (see details)</p>
              )}
              {compression === COMPRESSION.ORIGINAL && (
                <p>
                  Images sent to this node will be sent with their original transfer syntax (if
                  possible)
                </p>
              )}
              {compression === COMPRESSION.COMPRESS && (
                <p>Images sent to this node will be compressed (if possible)</p>
              )}
              {compression === COMPRESSION.DECOMPRESS && (
                <p>Images sent to this node will be uncompressed (if possible)</p>
              )}
            </div>
          </Col>
        </Form.Group>

        {this.props.nodes.length > 0 &&
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            Forward to (optional)
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              style={{ width: '30%' }}
              size="sm"
              as="select"
              onChange={e => {
                const selectedNode = e.target.value;
                this.setState({ ...this.state, forwardNode: selectedNode });
              }}
            >

            <option value={""}>&lt;no forward rule&gt;</option>  
            {this.props.nodes.map(aetitle => {
                  // eslint-disable-next-line react/jsx-key
                  return <option value={aetitle}>{aetitle}</option>;
            })} 

            </Form.Control>
          </Col>
        </Form.Group>}
        
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            Favorite for forwarding
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              type={'checkbox'}
              checked={forwardFavorite}
              id={'default'}
              style={{ paddingTop: '7px' }}
              onChange={() => {
                const isForwardFavorite = !forwardFavorite;
                this.setState({ ...this.state, forwardFavorite: isForwardFavorite });
              }}
            />
          </Col>
        </Form.Group>

        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            Notes
          </Form.Label>
          <Col sm={8}>
            <textarea 
              className={'form-control'}
              rows={3} 
              value={notes}
              onChange={e => {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                const notes = e.target.value;
                this.setState({ ...this.state, notes: notes });
              }}> 
            </textarea>
          </Col>
        </Form.Group>

        <br />

        <div style={{ textAlign: 'right' }}>
          <div><strong>NB: </strong>Remember to restart DICOM services to apply changes</div>
          <br />

          {protocol === 'DICOM' ? (
            <Button
              disabled={pending || !this.validate()}
              variant="primary"
              onClick={() => this.validate() && onDicomEcho(this.state)}
              style={{ marginRight: '5px', width: '8em' }}
            >
            Dicom ECHO
            </Button>
          ) : (
            <div></div>
          )}

          <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
            Cancel
          </Button>
          <Button
            style={{ minWidth: '8em' }}
            variant="primary"
            disabled={pending || !this.validate()}
            onClick={() => this.validate() && onSave(this.state)}
          >
            {pending ? saveButtonLabels[1] : saveButtonLabels[0]}
          </Button>
        </div>
      </Form>
    );
  }
}

const EncodingDetailsOverlay = encodings => (
  <Popover id="encodings-popover" style={{ maxWidth: '29em' }}>
    <EncodingsTable encodings={encodings} />
  </Popover>
);

function getEncodingsFor(selectedCompression, customEncodings) {
  // eslint-disable-next-line default-case
  switch (selectedCompression) {
    case COMPRESSION.COMPRESS:
      return compressionEncodings;
    case COMPRESSION.DECOMPRESS:
      return decompressionEncodings;
    case COMPRESSION.ORIGINAL:
      return defaultEncodings;
    case COMPRESSION.CUSTOM:
      return customEncodings;
  }
}

function getRelatedCompression(encodings?: Encodings) {
  if (!encodings) return COMPRESSION.ORIGINAL;

  if (sameEncodings(encodings, defaultEncodings)) return COMPRESSION.ORIGINAL;
  if (sameEncodings(encodings, compressionEncodings)) return COMPRESSION.COMPRESS;
  if (sameEncodings(encodings, decompressionEncodings)) return COMPRESSION.DECOMPRESS;
  return COMPRESSION.CUSTOM;
}

function sameEncodings(first: Encodings, second: Encodings) {
  return (
    sameArray(first.IVRLE, second.IVRLE) &&
    sameArray(first.EVRLE, second.EVRLE) &&
    sameArray(first.JPEG_LOSSLESS, second.JPEG_LOSSLESS) &&
    sameArray(first.JPEG_2000_LOSSLESS, second.JPEG_2000_LOSSLESS)
  );
}

function sameArray(first, second) {
  return JSON.stringify(first) === JSON.stringify(second);
}

const COMPRESSION = {
  CUSTOM: 'CUSTOM',
  ORIGINAL: 'ORIGINAL',
  COMPRESS: 'COMPRESS',
  DECOMPRESS: 'DECOMPRESS',
};

const defaultEncodings = {
  IVRLE: ['IVRLE'],
  EVRLE: ['EVRLE', 'IVRLE'],
  JPEG_LOSSLESS: ['JPEG_LOSSLESS', 'EVRLE', 'IVRLE'],
  JPEG_2000_LOSSLESS: ['JPEG_2000_LOSSLESS', 'EVRLE', 'IVRLE'],
  MPEG4: ['MPEG4'],
  JPEG_BASELINE: ['JPEG_BASELINE'],
  JPEG_LOSSLESS_57: ['JPEG_LOSSLESS_57'],
  RLE_LOSSLESS: ['RLE_LOSSLESS'],
};

const compressionEncodings = {
  IVRLE: ['JPEG_LOSSLESS', 'IVRLE'],
  EVRLE: ['JPEG_LOSSLESS', 'EVRLE', 'IVRLE'],
  JPEG_LOSSLESS: ['JPEG_LOSSLESS', 'EVRLE', 'IVRLE'],
  JPEG_2000_LOSSLESS: ['JPEG_2000_LOSSLESS', 'EVRLE', 'IVRLE'],
  MPEG4: ['MPEG4'],
  JPEG_BASELINE: ['JPEG_BASELINE'],
  JPEG_LOSSLESS_57: ['JPEG_LOSSLESS_57', 'EVRLE', 'IVRLE'],
  RLE_LOSSLESS: ['RLE_LOSSLESS', 'EVRLE', 'IVRLE'],
};

const decompressionEncodings = {
  IVRLE: ['IVRLE'],
  EVRLE: ['EVRLE', 'IVRLE'],
  JPEG_LOSSLESS: ['EVRLE', 'IVRLE'],
  JPEG_2000_LOSSLESS: ['EVRLE', 'IVRLE'],
  MPEG4: ['MPEG4'],
  JPEG_BASELINE: ['JPEG_BASELINE'],
  JPEG_LOSSLESS_57: ['EVRLE', 'IVRLE'],
  RLE_LOSSLESS: ['EVRLE', 'IVRLE'],
};

function validatePort(port?: number): boolean {
  if (port) {
    return port > 0 && port < 65535;
  }
  return false;
}

interface DicomFieldsProps {
  ip?: string;
  port?: number;
  handleChange: (e: any) => void;
}

const DicomFields = ({ ip, port, handleChange }: DicomFieldsProps): JSX.Element => (
  <div>
    <Form.Group as={Row}>
      <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
        IP
      </Form.Label>
      <Col sm={8}>
        <Form.Control
          size="sm"
          isInvalid={ip !== undefined && ip.length > 0 ? false : true}
          type="text"
          name="ip"
          value={ip}
          onChange={handleChange}
        />
        <Form.Control.Feedback type="invalid">Please insert a valid ip</Form.Control.Feedback>
      </Col>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
        Port
      </Form.Label>
      <Col sm={8}>
        <Form.Control
          size="sm"
          type="text"
          name="port"
          value={port}
          onChange={handleChange}
          isInvalid={port && validatePort(port) ? false : true}
        />
        <Form.Control.Feedback type="invalid">Please insert a valid port</Form.Control.Feedback>
      </Col>
    </Form.Group>
  </div>
);

function validateUrl(url?: string): boolean {
  if (url) {
    return url !== null && url.startsWith('http');
  }
  return false;
}

interface StowRsFieldsProps {
  stowRsEndpoint?: string;
  qidoEndpoint?: string;
  wadoEndpoint?: string;
  authorizationHeader?: string;
  handleChange: (e: any) => void;
}

const StowRsFields = ({
  stowRsEndpoint,
  qidoEndpoint,
  wadoEndpoint,
  authorizationHeader,
  handleChange,
}: StowRsFieldsProps): JSX.Element => (
  <div>
    <Form.Group as={Row}>
      <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
        STOW-RS Endpoint
      </Form.Label>
      <Col sm={8}>
        <Form.Control
          size="sm"
          isInvalid={validateUrl(stowRsEndpoint) ? false : true}
          type="text"
          name="stowRsEndpoint"
          value={stowRsEndpoint}
          onChange={handleChange}
        />
        <Form.Control.Feedback />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
        QIDO Endpoint
      </Form.Label>
      <Col sm={8}>
        <Form.Control
          size="sm"
          type="text"
          name="qidoEndpoint"
          value={qidoEndpoint}
          onChange={handleChange}
          isInvalid={validateUrl(qidoEndpoint) ? false : true}
        />
        <Form.Control.Feedback />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
        WADO Endpoint
      </Form.Label>
      <Col sm={8}>
        <Form.Control
          size="sm"
          isInvalid={validateUrl(wadoEndpoint) ? false : true}
          type="text"
          name="wadoEndpoint"
          value={wadoEndpoint}
          onChange={handleChange}
        />
        <Form.Control.Feedback />
      </Col>
    </Form.Group>
    <Form.Group as={Row}>
      <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
        Authorization header
      </Form.Label>
      <Col sm={8}>
        <Form.Control
          size="sm"
          type="text"
          name="authorizationHeader"
          value={authorizationHeader}
          onChange={handleChange}
        />
      </Col>
    </Form.Group>
  </div>
);

export default NodeForm;
