import { ERROR, PAUSED } from '../constants';
import { RootState } from './index';
import { BurnerJob, BurnerStatus } from './printersReducer';
import { ImportInfo } from './importReducer';

// queue table selector
const getQueueTableState = (state: RootState) => state.components!.queueTable;

// prefetch table selector
const getPrefetchTableState = (state: RootState) => state.components!.prefetchTable;

// study table selector
const getStudyTableState = (state: RootState) => state.components!.studyTable;

// selected study selector
export const getSelectedStudy = (state: RootState) => state.components!.studyTable.selectedStudy;

// search form selector
const getSearchFormState = (state: RootState) => state.components!.searchForm;

const getPasswordState = (state: RootState) => state.components!.passwordState;

const getUserActions = (state: RootState) => state.components!.userActionsState;

export const componentsSelector = {
  getQueueTableState,
  getPrefetchTableState,
  getStudyTableState,
  getSelectedStudy,
  getSearchFormState,
  getPasswordState,
  getUserActions,
};

// feed status selector
export const getFeedStatus = (state: RootState) => {
  return state.feedStatus!;
};

// nodes selector
export const getNodes = (state: RootState) => {
  return state.nodes!.nodes;
};

// conditions selector
export const getConditions = (state: RootState) => {
  return state.nodes!.conditions;
};

// encodings selector
export const getNodesEncodings = (state: RootState) => {
  return state.nodes!.encodings;
};

export const isTouchedNode = (state: RootState) => {
  return state.nodes!.isTouchedNode;
};

export const isTouchedRule = (state: RootState) => {
  return state.nodes!.isTouchedRule;
};

// forward rules selector
export const getForwardRules = (state: RootState) => {
  return state.nodes!.forwardRules;
};

// deletion rules selector
export const getDeletionRulesState = (state: RootState) => {
  return state.deletionRules!;
};

export const getAiModules = (state: RootState) => {
  return state.aiModules?.modules!;
};

export const getSelectedAiModule = (state: RootState) => {
  return state.aiModules?.selectedModule!;
};

// scripts selector
export const getScripts = (state: RootState) => {
  // order by script type and name
  return state.scripts!.sort((a, b) => {
    if (a.type!.localeCompare(b.type!) === 0) return a.name.localeCompare(b.name);
    return a.type!.localeCompare(b.type!);
  });
};

export const getScriptsOfType = (state: RootState, type: string) => {
  return getScripts(state).filter(script => script.type === type);
};

// worklist nodes selector
const getWorklistNodes = (state: RootState) => {
  return state.nodes!.worklistNodes;
};

// pending selector
export const getPending = (state: RootState) => {
  return state.pending;
};

// is pending selector
const isPending =
  (state: RootState) =>
  (...what) => {
    return what.map(part => state.pending![part]).reduce((acc, it) => acc || it, false);
  };

// prefetch queue selector
const getPrefetchQueue = (state: RootState) => {
  return state.prefetchQueue!;
};

// proxy configuration selector
function getProxyConfiguration(state: RootState) {
  return state.proxy!.configuration;
}

export const nodesSelectors = {
  getWorklistNodes,
  isPending,
  getPrefetchQueue,
  getProxyConfiguration,
};

// queue selector
export const getQueue = (state: RootState) => {
  return state.queue!;
};

export const getAiQueue = (state: RootState) => {
  return state.aiQueue!;
};

export const getFileQueue = (state: RootState) => {
  return state.fileQueue!;
};

// items in error selector
export const getItemsInError = (state: RootState) => {
  return state.queue!.filter(item => item.status === ERROR).length;
};

// studies in pause selector
export const getStudiesInPause = (state: RootState) => {
  return state.queue!.filter(item => item.status === PAUSED).length;
};

// items in queue selector
export const getItemsInQueue = (state: RootState) => {
  return state.queue!.length;
};

// series selector
export const getSeries = (state: RootState) => studyUID => {
  // const series = state.series[studyUID] || [];
  return state.series![studyUID] || [];
};

// studies selector
export const getStudies = (state: RootState) => {
  // return an array of studies
  return Object.values(state.studies!).sort((a, b) => {
    const da = parseInt(a.studyDate, 10);
    const db = parseInt(b.studyDate, 10);
    return da === db ? 0 : da < db ? 1 : -1;
  });
};

// user selector
export function getUser(state: RootState) {
  return state.user!;
}

// printer selector
export function getPrinters(state: RootState) {
  return state.printers!.printers;
}

export function getPrinterNames(state: RootState) {
  return state.printers!.printers.map(printer => printer.name);
}

export function getBurnersStatus(state: RootState): BurnerStatus[] {
  return state.printers!.burnersStatus;
}

export function getBurnersJobs(state: RootState): BurnerJob[] {
  return state.printers!.burnersJobs;
}

export function getImportInfo(state: RootState): ImportInfo {
  return state.importInfo!;
}

// reader selector
export function getReaders(state: RootState) {
  return state.readers!;
}

export function getReaderNames(state: RootState) {
  return state.readers!.map(reader => reader.displayName);
}

// polling selector
export function getPollingStatus(state: RootState) {
  return state.polling;
}

// configuration services selector
export function getCfgServicesState(state: RootState) {
  return state.configurations!.cfgServices;
}

// dicom configuration services selector
export function getCfgDicomServicesState(state: RootState) {
  return state.configurations!.cfgDicomServices;
}

export function getStudiesToBurn(state: RootState) {
  return state.studiesToBurn!;
}

export function getGloryMachines(state: RootState) {
  return state.gloryMachines!;
}

export function getSeriesDisposal(state: RootState) {
  return state.seriesDisposal!;
}

export function getWatchedFolders(state: RootState) {
  return state.watchedFolders!;
}
