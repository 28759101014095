import { useAppDispatch } from 'hooks';
import React, { useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { forceGetCfgServices, updateCfgServices } from '../../actions/configurationsActions';
import useToaster from '../../actions/useToaster';
import EditableTextCell from '../EditableTextCell';

function CfgServicesTable({ authorized, cfgServices }) {
  return (
    <table className="queue-table">
      <thead>
        <tr>
          <th style={{ width: '10%' }}>Service name</th>
          <th style={{ width: '10%' }}>Parameters key</th>
          <th style={{ width: '30%' }}>Parameters value</th>
          <th style={{ width: '40%' }}>Description</th>
          <th style={{ width: '8%' }} />
        </tr>
      </thead>
      <tbody>
        {cfgServices &&
          cfgServices.length > 0 &&
          cfgServices.map((cfgService, index) => (
            <CfgServicesRow authorized={authorized} key={index} cfgService={cfgService} />
          ))}
      </tbody>
    </table>
  );
}

function CfgServicesRow({ authorized, cfgService }) {
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  const [unsavedValue, setUnsavedValue] = useState(cfgService.paramValue);
  const serviceName =
    cfgService.serviceName.charAt(0) +
    cfgService.serviceName.slice(1).toLowerCase().replace(/_/g, ' ');
  const paramKey =
    cfgService.paramKey.charAt(0) + cfgService.paramKey.slice(1).toLowerCase().replace(/_/g, ' ');
  const description =
    cfgService.description !== undefined
      ? cfgService.description.charAt(0).toUpperCase() + cfgService.description.slice(1)
      : '';

  function save() {
    const temp = {
      serviceName: cfgService.serviceName,
      paramKey: cfgService.paramKey,
      paramValue: (unsavedValue.length > 0 ? unsavedValue : null),
      description: cfgService.description,
      editable: cfgService.editable,
    };
    return temp;
  }

  // eslint-disable-next-line react/no-unescaped-entities
  const tooltip = <Tooltip id="cfgServicesTooltip">You can't modify this parameter</Tooltip>;

  return (
    <tr style={{ height: '2.3em' }}>
      <td>{serviceName}</td>
      <td>{paramKey}</td>
      {cfgService.editable && authorized ? (
        <td>
          <EditableTextCell
            value={unsavedValue}
            onChange={newValue => {
              setUnsavedValue(newValue);
            }}
            onSave={() => {}}
          />
        </td>
      ) : (
        <OverlayTrigger
          delay={{ show: 400, hide: 100 }}
          rootClose
          placement="top"
          overlay={tooltip}
        >
          <td>{unsavedValue}</td>
        </OverlayTrigger>
      )}
      <td>{description}</td>
      {unsavedValue !== cfgService.paramValue ? (
        <td>
          <Button
            size="sm"
            style={{ paddingTop: '1px', paddingBottom: '1px' }}
            onClick={() => {
              const updatedCfgService = save();
              updateCfgServices(updatedCfgService)
                .then(() => {
                  dispatch(forceGetCfgServices());
                  setUnsavedValue(unsavedValue);
                  toaster.success('Configuration updated successfully');
                })
                .catch(error => {
                  console.error(error);
                  toaster.error('Error updating configuration');
                });
            }}
          >
            Save value
          </Button>
        </td>
      ) : (
        <td />
      )}
    </tr>
  );
}

export default CfgServicesTable;
