import PasswordTextBox from 'components/login/PasswordTextBox';
import React, { useState } from 'react';
import { Button, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import { GloryMachine } from 'reducers/gloryMachinesReducer';

export type GloryMachinePopupType = {
  editing?: boolean;
  machine?: GloryMachine | null;
  onClose: () => void;
  onSave: (_: GloryMachine) => void;
};

export const GloryMachinePopup = ({
  editing = false,
  machine = null,
  onClose,
  onSave,
}: GloryMachinePopupType) => {
  const [name, setName] = useState<string>(editing ? machine!.name : '');
  const [ip, setIp] = useState<string>(editing ? machine!.ip : '');
  const [username, setUsername] = useState<string>(editing ? machine!.username : '');
  const [password, setPassword] = useState<string>(editing ? machine!.password : '');

  const save = () => {
    onSave({ name, ip, username, password });
  };

  return (
    <Form>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Name
        </Form.Label>
        <Col sm={8}>
          <FormControl disabled={editing} size="sm" value={name} onChange={event => setName(event.target.value)} />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Ip
        </Form.Label>
        <Col sm={8}>
          <FormControl size="sm" value={ip} onChange={event => setIp(event.target.value)} />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Username
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            value={username}
            onChange={event => setUsername(event.target.value)}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Password
        </Form.Label>
        <Col sm={8}>
          <PasswordTextBox
            placeholder=""
            password={password}
            setPassword={event => setPassword(event.target.value)}
          ></PasswordTextBox>
        </Col>
      </FormGroup>
      <div style={{ textAlign: 'right' }}>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button style={{ width: '8em' }} variant="primary" onClick={save}>
          Save
        </Button>
      </div>
    </Form>
  );
};
