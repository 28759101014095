import * as actions from '../actions/actionCatalog';
import { CLIENT_ID } from '../App';

export interface ImportInfo {
  event: string;
  type: string;
  message: string;
  progress: number;
  clientId: string;
}

interface WSEvent {
  type: typeof actions.WS_EVENT;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function importInfo(state: ImportInfo = { event: '', type: '', message: '', progress: 0, clientId: '' }, action: WSEvent) {
  switch (action.type) {
    case actions.WS_EVENT:
      return ws(state, action);
    default:
      return state;
  }
}

function ws(state: ImportInfo, action) {
  const { event, type, message, progress, clientId } = action.payload as ImportInfo;
  if (event === 'ImportInstance') {
    if (clientId === CLIENT_ID) return (state = { ...state, type, message, progress, clientId });
  }

  return state;
}

export default importInfo;
