// every script retrieved using this method will be deferred so it is necessary to implement an onLoadCallback
export const scriptLoader = (id: string, src: string, onLoadCallback: () => void): void => {
  const script = document.getElementById(id);
  // Prevent script from loading twice
  if (script) {
    onLoadCallback();
    return;
  }
  const newScript = document.createElement('script');
  newScript.id = id;
  newScript.src = src;
  newScript.defer = true;
  newScript.onload = onLoadCallback;
  document.body.appendChild(newScript);
};
