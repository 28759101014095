import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface FeedStatus {
  storageSpace?: string;
  studies?: string;
}

interface SearchStudiesFulfilled {
  type: typeof actions.GET_FEED_STATUS_FULFILLED;
  response: AxiosResponse<FeedStatus>;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
function queue(state: FeedStatus = {}, action: SearchStudiesFulfilled) {
  switch (action.type) {
    case actions.GET_FEED_STATUS_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export default queue;
