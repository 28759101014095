import React, { Component } from 'react';
import * as actions from '../../actions/actionCatalog';
import './Toaster.css';

class Toaster extends Component<any, any> {
  constructor(props, context) {
    super(props, context);
    this.hideToaster = this.hideToaster.bind(this);
  }

  componentDidMount() {}

  hideToaster(id) {
    this.props.dispatch({ type: actions.HIDE_TOASTER, id });
  }

  render() {
    const { toasters } = this.props;

    return toasters.map((toaster, idx) => (
      <ToasterBox
        key={toaster.id}
        {...toaster}
        onTimeout={id => this.hideToaster(id)}
        position={30 + idx * 60}
      />
    ));
  }
}

class ToasterBox extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { opacity: 0 };
  }

  componentDidMount() {
    const { id } = this.props;

    setTimeout(() => {
      this.setState({ opacity: 1 });
    }, 50);

    setTimeout(() => {
      this.setState({ opacity: 0 });
    }, 4000);

    setTimeout(() => {
      this.props.onTimeout(id);
    }, 5000);
  }

  render() {
    const { level, position, text } = this.props;
    const { opacity } = this.state;
    return (
      <div className={`toaster ${level}`} style={{ opacity, bottom: position }}>
        {text}
      </div>
    );
  }
}

export default Toaster;
