import React from 'react';
import { Badge, Button, Col, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { BurnerStatus } from 'reducers/printersReducer';
import InkLevel from './InkLevel';

type BurnerStatusModalProps = {
  burner: BurnerStatus;
  show: boolean;
  onClose: () => void;
};

const BurnerStatusModal = ({ burner, show, onClose }: BurnerStatusModalProps) => {
  const inkLevels = burner.inkLevels;
  return (
    <Modal show={show}>
      <Col style={{ marginTop: '1em' }}>
        <div>
          <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div
              className={
                burner.model === 'RIMAGE_2000I'
                  ? 'printer-widget-header-rimage'
                  : 'printer-widget-header-epson'
              }
            />
            <Col lg={8}>
              <Row style={{ justifyContent: 'center', alignContent: 'center' }}>
                <div style={{ fontSize: '40px', wordBreak: 'break-all' }}>{burner.name}</div>
              </Row>
              <Row style={{ justifyContent: 'center', alignContent: 'center' }}>
                {isOnline(burner) ? (
                  <Badge variant="success" style={{ fontSize: '15px' }}>
                    Online
                  </Badge>
                ) : (
                  <Badge variant="danger" style={{ fontSize: '15px' }}>
                    Offline
                  </Badge>
                )}
              </Row>
              <hr style={{ backgroundColor: 'rgba(255,255,255,.2)' }} />
              <Row style={{ fontSize: '14px', justifyContent: 'center' }}>
                {burner.errors.length > 0 ? (
                  burner.errors.map(err => (
                    <OverlayTrigger
                      key={err.code}
                      placement="bottom"
                      delay={{ show: 250, hide: 400 }}
                      overlay={props => (
                        <Tooltip id="error-tooltip" {...props}>
                          <div style={{ fontSize: '16px' }}>{err.description}</div>
                        </Tooltip>
                      )}
                    >
                      <Badge pill variant="danger" style={{ margin: '4px' }}>
                        {err.code}
                      </Badge>
                    </OverlayTrigger>
                  ))
                ) : (
                  <Badge pill variant="info">
                    NO ERRORS
                  </Badge>
                )}
              </Row>
            </Col>
          </Row>

          <hr style={{ backgroundColor: 'rgba(255,255,255,.2)' }} />
          <Row />
          <Row>
            {burner.stackers && (
              <Col lg={5} className="stacker-levels">
                <Row className="title-row">
                  <strong>Stacker levels</strong>
                </Row>
                <StackerRow name="Stacker 1" level={burner.stackers.stacker1} />
                <StackerRow name="Stacker 2" level={burner.stackers.stacker2} />
                <StackerRow name="Stacker 3" level={burner.stackers.stacker3} />
                <StackerRow name="Stacker 4" level={burner.stackers.stacker4} />
              </Col>
            )}
            {inkLevels && (
              <Col lg={7} className="ink-levels">
                <Row className="title-row">
                  <strong>Ink levels</strong>
                </Row>
                <Row className="ink-row">
                  <Col md={{ offset: 1 }}>
                    <InkLevel color="Magenta" level={inkLevels.magenta} />
                  </Col>
                  <Col>
                    <InkLevel color="Yellow" level={inkLevels.yellow} />
                  </Col>
                </Row>
                <Row className="ink-row">
                  <Col md={{ offset: 1 }}>
                    <InkLevel color="Cyan" level={inkLevels.cyan} />
                  </Col>
                  <Col>
                    <InkLevel color="Light Cyan" level={inkLevels.lightCyan} />
                  </Col>
                </Row>
                <Row className="ink-row">
                  <Col md={{ offset: 1 }}>
                    <InkLevel color="Light Magenta" level={inkLevels.lightMagenta} />
                  </Col>
                  <Col>
                    <InkLevel color="Black" level={inkLevels.black} />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </Col>
      <Modal.Footer style={{ borderTop: '0', paddingTop: '0' }}>
        <Button variant="outline-secondary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const StackerRow = ({ name, level }) => (
  <Row style={{ marginBottom: '1em' }}>
    <Col className="stacker-title" sm={8} md={{ offset: 1 }}>
      {name}
    </Col>
    <Col className="stacker-value" sm={4}>
      {level > -1 ? `${level}%` : 'N/A'}
    </Col>
  </Row>
);

function isOnline(burner: BurnerStatus): boolean {
  return !burner.errors.some(error => error.code === 'HNW000' || error.code === 'HNW001');
}

export default BurnerStatusModal;
