import * as userActions from 'actions/userActions';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import Login from './login/Login';
import JSDButton from './popups/JSDButton';
import SetupPage from './setup/SetupPage';
import * as http from '../http';

function FeedEntryPoint() {
  const [status, setStatus] = useState<'LOADING' | 'READY' | 'ERROR'>('LOADING');
  const [configuration, setConfiguration] = useState({ setupRequired: true });
  const dispatch = useAppDispatch();

  useEffect(() => {
    http.get<any>(`/rest/react/getSetupConfiguration`)
      .then(json => {
        setConfiguration(json);
        userActions
          .sessionTokenLogin()
          .then(res => {
            dispatch({
              type: 'SET_USER',
              payload: {
                ...res,
                authenticated: true,
              },
            });
            setStatus('READY');
          })
          .catch(() => {
            setStatus('READY');
          });
      })
      .catch(error => {
        setStatus('ERROR');
        console.error('error loading system configuration', error);
      });
  }, []);

  if (status === 'LOADING') return <div>Loading...</div>;

  if (status === 'ERROR')
    return (
      <React.Fragment>
        <div>Error loading system configuration</div>
        <JSDButton isVisible={false} />
      </React.Fragment>
    );

  if (configuration.setupRequired)
    return (
      <React.Fragment>
        <SetupPage configuration={configuration} />
        <JSDButton isVisible={false} />
      </React.Fragment>
    );

  return <Login />;
}

export default FeedEntryPoint;
