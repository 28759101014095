import { googleLogin } from 'actions/userActions';
import { scriptLoader } from 'components/scriptLoader';
import { useAppDispatch } from 'hooks';
import React, { useEffect } from 'react';

type GoogleLoginType = {
  setError: (_: { present: boolean; message?: string }) => void;
  setShowGoogleLogin: (_: boolean) => void;
};

const GoogleLogin = ({ setError, setShowGoogleLogin }: GoogleLoginType) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    scriptLoader('google-sign-in-api', 'https://accounts.google.com/gsi/client', googleCallback);
  }, []);

  function googleCallback() {
    const google = window.google;
    if (google) {
      google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_SIGN_IN_CLIENT_ID,
        callback: handleGoogleResponse,
      });
      google.accounts.id.renderButton(document.getElementById('google-sign-in-button'), {
        theme: 'filled_blue',
        size: 'medium',
        text: 'signin_with',
        shape: 'rectangular',
      });
    }
  }

  function handleGoogleResponse(res) {
    const token = res.credential;
    googleLogin(token)
      .then(response => {
        dispatch({
          type: 'SET_USER',
          payload: {
            ...response,
            authenticated: true,
          },
        });
      })
      .catch(() => {
        setError({ present: true, message: 'Unable to login with google' });
      });
    setShowGoogleLogin(false);
  }

  return <div id="google-sign-in-button"></div>;
};

export default GoogleLogin;
