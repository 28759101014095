import React, { useState } from 'react';
import { Button, Row, Form, Col, InputGroup } from 'react-bootstrap';

interface Props {
  node: any;
  onClose: any;
  onSave: any;
  pending?: any;
  saveButtonLabel: any;
}

function WorklistNodeForm({ node, onClose, onSave, pending, saveButtonLabel }: Props) {
  const [displayName, setDisplayName] = useState(node.displayName ? node.displayName : '');
  const [forwardMethod, setForwardMethod] = useState(node.forwardMethod);
  const [upsRsEndpoint, setUpsRsEndpoint] = useState(node.upsRsEndpoint ? node.upsRsEndpoint : '');
  const [headersArray, setHeadersArray] = useState(
    node.headers ? headersToArray(node.headers) : []
  );
  const [dicomUrl, setDicomUrl] = useState(node.dicomUrl ? node.dicomUrl : '');
  const [enabled, setEnabled] = useState(node.enabled);

  function headersToArray(value) {
    let headersMap: Map<string, string> = value as Map<string, string>;
    let newHeadersArray: string[] = [];
    let i = 0;
    for (var key in headersMap) {
      newHeadersArray[i] = key + '=' + headersMap[key];
      i++;
    }
    return newHeadersArray;
  }

  function updateHeader(newValue, index) {
    let newHeadersArray: string[] = [...headersArray];
    newHeadersArray[index] = newValue;
    setHeadersArray(newHeadersArray);
  }

  function deleteHeader(index) {
    let newHeadersArray: string[] = [...headersArray];
    newHeadersArray.splice(index, 1);
    setHeadersArray(newHeadersArray);
  }

  function checkValidHeader(index) {
    if (!headersArray[index]) return true;
    if (!headersArray[index].includes('=')) return true;
    if (
      headersArray[index].substring(0, headersArray[index].indexOf('=')).length < 1 ||
      headersArray[index].substring(headersArray[index].indexOf('=') + 1).length < 1
    )
      return true;
    return false;
  }

  function save() {
    const updatedNode = {
      pk: node.pk,
      displayName,
      forwardMethod,
      upsRsEndpoint: upsRsEndpoint ? upsRsEndpoint : null,
      headers: headersArray ? headersArray : null,
      dicomUrl: dicomUrl ? dicomUrl : null,
      enabled,
    };
    onSave(updatedNode);
  }

  function validate() {
    let valid = true;
    valid = valid && displayName && displayName.length > 0;
    valid = valid && ((upsRsEndpoint && upsRsEndpoint.length > 0) || forwardMethod !== 'UPS_RS');
    valid = valid && ((dicomUrl && dicomUrl.length > 0) || forwardMethod !== 'DICOM');
    for (var i = 0; i < headersArray.length; i++) {
      if (checkValidHeader(i)) valid = false;
    }
    return valid;
  }

  return (
    <Form>
      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Display name
        </Form.Label>
        <Col sm={8} className="worklistNodeForm">
          <Form.Control
            size="sm"
            type="text"
            name="displayName"
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
            isInvalid={displayName ? false : true}
          />
          <Form.Control.Feedback />
        </Col>
      </Form.Group>

      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Protocol
        </Form.Label>
        <Col sm={8} className="worklistNodeForm">
          <Form.Control
            style={{ width: '30%' }}
            size="sm"
            id="protocol-method-dropdown"
            as="select"
            value={forwardMethod}
            onChange={e => setForwardMethod(e.target.value)}
          >
            <option value="DICOM">DICOM</option>
            <option value="UPS_RS">UPS RS</option>
          </Form.Control>
        </Col>
      </Form.Group>

      {forwardMethod === 'UPS_RS' && (
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            UPS-RS Endpoint
          </Form.Label>
          <Col sm={8} className="worklistNodeForm">
            <Form.Control
              size="sm"
              type="text"
              value={upsRsEndpoint}
              onChange={e => setUpsRsEndpoint(e.target.value)}
              isInvalid={forwardMethod !== 'UPS_RS' || upsRsEndpoint ? false : true}
            />
            <Form.Control.Feedback />
          </Col>
        </Form.Group>
      )}

      {forwardMethod === 'UPS_RS' &&
        headersArray.map((header, index) => {
          return (
            <Form.Group as={Row} key={index}>
              {index === 0 ? (
                <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
                  Headers
                </Form.Label>
              ) : (
                <Form.Label column sm={4} md={{ offset: 1 }} />
              )}
              <Col sm={8} className="worklistNodeForm">
                <InputGroup>
                  <Form.Control
                    placeholder="header=value"
                    size="sm"
                    type="text"
                    value={header}
                    onChange={e => updateHeader(e.target.value, index)}
                    isInvalid={checkValidHeader(index)}
                  />
                  <Button id="deleteHeader" variant="danger" onClick={() => deleteHeader(index)}>
                    X
                  </Button>
                </InputGroup>
              </Col>
            </Form.Group>
          );
        })}

      {forwardMethod === 'UPS_RS' && (
        <div style={{ textAlign: 'right' }}>
          <Button
            id="addHeader"
            variant="primary"
            onClick={() => setHeadersArray([...headersArray, ''])}
          >
            Add header
          </Button>
        </div>
      )}

      {forwardMethod === 'DICOM' && (
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            DICOM URL
          </Form.Label>
          <Col sm={8} className="worklistNodeForm">
            <Form.Control
              size="sm"
              type="text"
              value={dicomUrl}
              onChange={e => setDicomUrl(e.target.value)}
              isInvalid={forwardMethod !== 'DICOM' || dicomUrl ? false : true}
            />
            <Form.Control.Feedback />
          </Col>
        </Form.Group>
      )}

      <Form.Group as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Enabled
        </Form.Label>
        <Col sm={8} className="worklistNodeForm">
          <Form.Check
            custom
            id="enabledWorklistNode"
            type="checkbox"
            checked={enabled}
            onChange={e => setEnabled(!enabled)}
          />
        </Col>
      </Form.Group>

      <div style={{ textAlign: 'right', marginTop: '3em' }}>
        <div>
          <strong>NB: </strong>Remember to restart the system to apply changes
        </div>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button
          style={{ width: '8em' }}
          variant="primary"
          disabled={pending}
          onClick={e => validate() && save()}
        >
          {saveButtonLabel}
        </Button>
      </div>
    </Form>
  );
}

export default WorklistNodeForm;
