import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';
import { ENQUEUED, PROCESSING, PAUSED, DONE, ERROR } from '../constants';

export interface AiQueue {
  pk: bigint;
  studyInstanceUid: string;
  aiModuleFk: number;
  createdAt: Date;
  updatedAt: Date;
  status: string;
}

interface GetAiQueueFulfilled {
  type: typeof actions.GET_AI_QUEUE_FULFILLED;
  response: AxiosResponse<AiQueue[]>;
}

interface ReprocessAiQueuedItemFulfilled {
  type: typeof actions.REPROCESS_AI_QUEUED_ITEM_FULFILLED;
  response: AxiosResponse<AiQueue[]>;
  request: { pk: bigint };
}

type AiQueueAction =
  | GetAiQueueFulfilled
  | ReprocessAiQueuedItemFulfilled;
// eslint-disable-next-line @typescript-eslint/default-param-last
function aiQueue(state: AiQueue[] = [], action: AiQueueAction): AiQueue[] {
  let pk, studyInstanceUid, aiModuleFk;

  switch (action.type) {
    case actions.GET_AI_QUEUE_FULFILLED:
      return action.response.data;
    case actions.REPROCESS_AI_QUEUED_ITEM_FULFILLED:
      pk = action.request.pk;
      return action.response.data;
    default:
      return state;
  }
}

export default aiQueue;
