import React from 'react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const urgentTooltip = (
  <Tooltip id="tooltip">
    Mark this item(s) as <strong>URGENT</strong>
  </Tooltip>
);

const QueueUrgentButton = ({ action, args, disabled }) => {
  return (
    <OverlayTrigger placement="top" overlay={urgentTooltip}>
      <Button
        size="sm"
        variant="outline-secondary"
        className="queue-button"
        style={{ marginRight: '0.5em' }}
        disabled={disabled}
        onClick={e => {
          action(args);
          e.currentTarget.blur();
        }}
      >
        <FontAwesomeIcon icon={faChevronUp} />
      </Button>
    </OverlayTrigger>
  );
};

export default QueueUrgentButton;
