import { faUserCog, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DELETE_EVERY_ACTION, SET_STRONG_PASSWORD } from 'actions/actionCatalog';
import {
  canPerformAction,
  getAllActions,
  getAllRoles,
  getAllUsers,
  getStrongPassword,
  insertNewRole,
  insertNewUser,
} from 'actions/userActions';
import useToaster from 'actions/useToaster';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUser } from 'reducers/selectors';
import { availableOperations, Role, User, UserAction } from 'reducers/userReducer';
import UserSettingsRoleTable from './RoleTable/UserSettingsRoleTable';
import UsersSettingsRolePopup from './RoleTable/UsersSettingsRolePopup';
import UserSettingsTable from './UserTable/UserSettingsTable';
import UsersSettingsPopupNewUser from './UserTable/UsersSettingsPopupNewUser';

function UserSettingsPage() {
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  const user = useSelector(getUser);

  const canManageUsers = canPerformAction(user, availableOperations.CAN_MANAGE_USERS);
  const canManageRoles = user.role?.privileged;

  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showCreateRoleModal, setShowCreateRoleModal] = useState(false);

  const [roles, setRoles] = useState<Role[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [actions, setActions] = useState<UserAction[]>([]);

  const updateStrongPasswordState = payload => dispatch({ type: SET_STRONG_PASSWORD, payload });
  const deleteEveryAction = () => dispatch({ type: DELETE_EVERY_ACTION });

  useEffect(() => {
    getAllRoles()
      .then(response => setRoles(response))
      .catch(error => console.error('Error fetching roles', error));
    getAllUsers()
      .then(response => setUsers(response))
      .catch(error => console.error('Error fetching users information', error));
    getAllActions()
      .then(response => setActions(response))
      .catch(error => console.error('Error fetching actions', error));
    getStrongPassword().then(response => updateStrongPasswordState(response));
  }, []);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <div>
          <h3 style={{ display: 'inline' }}>Users</h3>
          <div style={{ float: 'right' }}>
            <Button size="sm" disabled={!canManageUsers} onClick={() => setShowCreateUserModal(true)}>
              <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: '5px' }} />
              Add new user
            </Button>
          </div>
          <UserSettingsTable
            roles={roles}
            users={users}
            getAllUsers={() =>
              getAllUsers()
                .then(response => {
                  setUsers(response);
                })
                .catch(error => console.error('Error fetching users information', error))
            }
          />
        </div>
        <div style={{ marginTop: '3em' }}>
          <h3 style={{ display: 'inline' }}>Roles</h3>
          <div style={{ float: 'right' }}>
            <Button
              size="sm"
              style={{ marginLeft: '1em' }}
              disabled={!canManageRoles}
              onClick={() => setShowCreateRoleModal(true)}
            >
              <FontAwesomeIcon icon={faUserCog} style={{ marginRight: '5px' }} />
              Add new role
            </Button>
          </div>
          <UserSettingsRoleTable
            roles={roles}
            actions={actions}
            getRoles={() => {
              getAllRoles()
                .then(response => setRoles(response))
                .catch(error => console.error('Error fetching roles', error));
            }}
          />
        </div>
      </Card.Body>
      {showCreateUserModal && (
        <CreateUserModal
          currentUser={user}
          users={users}
          roles={roles}
          onClose={() => setShowCreateUserModal(false)}
          onSave={(username, password, role, enabled) => {
            insertNewUser(username, password, role.pk, enabled)
              .then(() => {
                setShowCreateUserModal(false);
                getAllUsers()
                  .then(response => {
                    setUsers(response);
                  })
                  .catch(error => console.error('Error fetching users information', error));
                toaster.success('User added successfully');
              })
              .catch(error => {
                console.error('Error adding user', error);
                toaster.error(error.message);
              });
          }}
        />
      )}
      {showCreateRoleModal && (
        <CreateRoleModal
          roles={roles}
          actions={actions}
          onClose={() => {
            deleteEveryAction();
            setShowCreateRoleModal(false);
          }}
          onSave={newRole => {
            insertNewRole(newRole)
              .then(() => {
                setShowCreateRoleModal(false);
                getAllRoles()
                  .then(response => setRoles(response))
                  .catch(error => console.error('Error fetching roles', error));
                toaster.success('Role added successfully');
              })
              .catch(error => {
                console.error('Error adding role', error);
                toaster.error(error.message);
              });
            deleteEveryAction();
          }}
        />
      )}
    </Card>
  );
}

function CreateUserModal({ currentUser, users, roles, onClose, onSave }) {
  return (
    <Modal show onHide={onClose}>
      <Modal.Header>
        <Modal.Title>New user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UsersSettingsPopupNewUser currentUser={currentUser} users={users} roles={roles} onClose={onClose} onSave={onSave} />
      </Modal.Body>
    </Modal>
  );
}

function CreateRoleModal({ roles, actions, onClose, onSave }) {
  return (
    <Modal show size="lg" onHide={onClose}>
      <Modal.Header>
        <Modal.Title>New role</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <UsersSettingsRolePopup
          roles={roles}
          actions={actions}
          onClose={onClose}
          onSave={onSave}
          isInEditMode={false}
          editingRole={null}
        />
      </Modal.Body>
    </Modal>
  );
}

export default UserSettingsPage;
