import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, FormCheck, FormControl, InputGroup } from 'react-bootstrap';
import { UPDATE_QUEUE_TABLE } from '../../../actions/actionCatalog';
import * as queueActions from '../../../actions/queueActions';
import { canPerformAction } from '../../../actions/userActions';
import { availableOperations } from '../../../reducers/userReducer';
import Pager from '../../Pager';
import QueuePauseButton from './QueuePauseButton';
import QueuePlayButton from './QueuePlayButton';
import QueueRow from './QueueRow';
import QueueUrgentButton from './QueueUrgentButton';
import QueueDeleteButton from './QueueDeleteButton';
import ConfirmationPopup from '../../popups/ConfirmationPopup';

export default function QueueTable({ queue, pending, nodes, pageSize, page, compact, selectedItems, user }) {
  const dispatch = useAppDispatch();
  const [queueFiltered, setQueueFiltered] = useState(queue);
  const [freeFilterValue, setFreeFilterValue] = useState('');
  const [destinationFilterValue, setDestinationFilterValue] = useState('');
  const [statusFilterValue, setStatusFilterValue] = useState('');
  const [deletingItems, setDeletingItems] = useState([]);

  const updateQueueTable = payload => dispatch({ type: UPDATE_QUEUE_TABLE, payload });
  const increasePriority = item => dispatch(queueActions.increasePriority(item));
  const resumeEnqueuedItem = item => dispatch(queueActions.resumeEnqueuedItem(item));
  const pauseEnqueuedItem = item => dispatch(queueActions.pauseEnqueuedItem(item));
  const deleteFromQueue = item => dispatch(queueActions.deleteFromQueue(item));

  const items = queueFiltered.length;
  const pages = Math.ceil(items / pageSize);
  const from = pageSize * page;
  const to = Math.min(items, pageSize * page + pageSize);
  const rows = queueFiltered.slice(from, to);
  const canModifyQueue = canPerformAction(user, availableOperations.CAN_MODIFY_QUEUE);
  const queueToolbarRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    applyFilter(freeFilterValue, destinationFilterValue, statusFilterValue);
  }, [queue]);

  function handleChangePageSize(event) {
    const newPageSize = parseInt(event.target.value, 10);
    updateQueueTable({ pageSize: newPageSize, page: 0 });
  }

  function createDestinationOptions() {
    let options: JSX.Element[] = [
      <option key="any" value="">
        Any destination
      </option>,
    ];
    nodes.forEach(node =>
      options.push(
        <option key={node.aeTitle} value={node.aeTitle}>
          {node.aeTitle}
        </option>
      )
    );
    return options;
  }

  function applyFilter(freeFilter, destinationFilter, statusFilter) {
    setFreeFilterValue(freeFilter);
    setDestinationFilterValue(destinationFilter);
    setStatusFilterValue(statusFilter);

    let i = 0;
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    let filteredQueue = new Array();
    queue.forEach(element => {
      const free = filterFree(element, freeFilter);
      const destination = filterDestination(element, destinationFilter);
      const status = filterStatus(element, statusFilter);
      if (free && destination && status) {
        filteredQueue[i] = element;
        i++;
      }
    });

    setQueueFiltered(filteredQueue);
  }

  function filterFree(element, freeFilter) {
    if (includes(element.description, freeFilter))
      return true;
    if (includes(element.patientId, freeFilter))
      return true;
    if (includes(element.firstName, freeFilter))
      return true;
    if (includes(element.lastName, freeFilter))
      return true;
    if (includes(element.modalities, freeFilter))
      return true;
    if (includes(element.studyUid, freeFilter))
    return false;
  }

  const includes = (param, text) => {
    return param != null && param.toUpperCase().includes(text);
  }

  function filterDestination(element, destinationFilter) {
    if (!destinationFilter || destinationFilter === '') return true;
    if (element.destination === destinationFilter) return true;
    return false;
  }

  function filterStatus(element, statusFilter) {
    if (!statusFilter || statusFilter === '') return true;
    if (element.status === statusFilter) return true;
    return false;
  }

  function selectItem(item) {
    if (isItemSelected(item)) removeItem(item);
    else addItem(item);

    updateQueueTable({ selectedItems: selectedItems });
  }

  function addItem(item) {
    const index = findIndexOfItem(item);
    if (index === -1) selectedItems = [ ...selectedItems, item ]; //do not use 'push' here, react needs a brand new object
  }

  function removeItem(item) {
    const index = findIndexOfItem(item);
    if (index > -1) selectedItems.splice(index, 1);
  }

  function findIndexOfItem(item) {
    for (let i = 0; i < selectedItems.length; i++)
      if (
        selectedItems[i].studyUid === item.studyUid &&
        selectedItems[i].destination === item.destination
      )
        return i;

    return -1;
  }

  function isItemSelected(item) {
    return findIndexOfItem(item) !== -1;
  }

  function selectDeselectAll() {
    queueToolbarRef.current!.classList.toggle('show');
    if (selectedItems.length === 0) {
      selectedItems = [];
      queueFiltered.forEach(item => {
        selectItem(item);
      });
    } else {
      selectedItems = [];
      updateQueueTable({ selectedItems: selectedItems });
    }
  }

  function clearSelection() {
    selectedItems = [];
    updateQueueTable({ selectedItems: selectedItems });
    queueToolbarRef.current!.classList.remove('show');
  }

  function deleteItemsConfirmation(itemsToDelete) {
    setDeletingItems(itemsToDelete);
  }

  function deleteItems() {
    deletingItems.forEach(item => {
      deleteFromQueue(item);
    });

    setDeletingItems([]);
    
    if(rows.length === 1) //if last row of the page, go to the first page
      updateQueueTable({ page: 0 })
  }

  const playAllSelected = () => selectedItems.forEach(item => resumeEnqueuedItem(item));
  const pauseAllSelected = () => selectedItems.forEach(item => pauseEnqueuedItem(item));
  const urgentAllSelected = () => selectedItems.forEach(item => increasePriority(item));
  const deleteAllSelected = itemsToDelete => deleteItemsConfirmation(itemsToDelete);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'inline-block', width: '18em' }}>
            <InputGroup className="mb-3">
              <FormControl
                size="sm"
                aria-label="Default"
                onChange={event => {
                  clearSelection();
                  updateQueueTable({ page: 0 });
                  applyFilter(
                    event.target.value.toUpperCase(),
                    destinationFilterValue,
                    statusFilterValue
                  );
                }}
                aria-describedby="inputGroup-sizing-default"
                id="search-job-text-field"
              />
              <InputGroup.Append>
                <InputGroup.Text className="basic-addon2" id="inputGroup-sizing-default">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>

          <select
            className="selectBoxNum selectFilter"
            defaultValue="any"
            onChange={e => {
              clearSelection();
              updateQueueTable({ page: 0 });
              applyFilter(freeFilterValue, e.target.value, statusFilterValue);
            }}
          >
            {createDestinationOptions()}
          </select>

          <select
            className="selectBoxNum selectFilter"
            defaultValue="any"
            onChange={e => {
              clearSelection();
              updateQueueTable({ page: 0 });
              applyFilter(freeFilterValue, destinationFilterValue, e.target.value);
            }}
          >
            <option value="">Any status</option>
            <option value="PAUSED">PAUSED</option>
            <option value="SENDING">SENDING</option>
            <option value="ENQUEUED">ENQUEUED</option>
            <option value="POSTPONED">POSTPONED</option>
            <option value="DONE">DONE</option>
            <option value="ERROR">ERROR</option>
            <option value="FILTERED">FILTERED</option>
          </select>

          <FormCheck
            custom
            id="compactViewQueue"
            type="checkbox"
            style={{
              display: 'inline-block',
              marginRight: '2em',
              marginLeft: '1em',
              marginTop: '0.3em',
            }}
            onChange={() => updateQueueTable({ compact: !compact })}
            checked={compact}
            label="Compact View"
          />
        </div>
        {pending ? (
          'loading...'
        ) : items > 0 ? (
          <div>
            Showing items {from + 1} - {to} of {items}
          </div>
        ) : (
          <div>No items in queue</div>
        )}
        <div>
          Page size:
          <select className="selectBoxNum" value={pageSize} onChange={e => handleChangePageSize(e)}>
            <option value="12">12</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <Button
          size="sm"
          variant="primary"
          style={{ marginRight: '1em', minWidth: '6em' }}
          onClick={() => dispatch(queueActions.forceGetQueue())}
        >
          Reload
        </Button>
        <Button
          size="sm"
          variant="primary"
          style={{ marginRight: '1em', minWidth: '6em' }}
          onClick={() => { selectDeselectAll(); }}
        >
          {selectedItems.length === 0 ? 'Select All' : 'Deselect All'}
        </Button>

        <div ref={queueToolbarRef} className="queueToolbar">
          <QueuePlayButton
            action={playAllSelected}
            args={null}
            disabled={!canModifyQueue}
          ></QueuePlayButton>
          <QueuePauseButton
            action={pauseAllSelected}
            args={null}
            disabled={!canModifyQueue}
          ></QueuePauseButton>
          <QueueUrgentButton
            action={urgentAllSelected}
            args={null}
            disabled={!canModifyQueue}
          ></QueueUrgentButton>
          <QueueDeleteButton
            action={() => deleteAllSelected(selectedItems)}
            args={null}
            disabled={!canModifyQueue}
          ></QueueDeleteButton>
        </div>
      </div>

      <table className="queue-table">
        <thead>
          <tr>
            <th style={{ width: '18%' }}>Patient</th>
            <th style={{ width: '33%' }}>Study</th>
            <th style={{ width: '10%' }}>Enqueued on</th>
            <th style={{ width: '10%' }}>Destination</th>
            <th style={{ width: '10%' }}>Current status</th>
            <th>Queue</th>
          </tr>
        </thead>
        <tbody>
          {pending ? null : rows.length > 0 ? (
            rows.map((item, idx) => (
              <QueueRow
                key={`${item.studyUid}_${item.destination}`}
                item={item}
                compact={compact}
                increasePriority={increasePriority}
                resumeEnqueuedItem={resumeEnqueuedItem}
                pauseEnqueuedItem={pauseEnqueuedItem}
                deleteFromQueue={() => deleteItemsConfirmation([item])}
                selected={isItemSelected(item)}
                onSelect={() => {
                  return;
                }}
                authenticated={canModifyQueue}
              />
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={6}>
                {freeFilterValue === '' && destinationFilterValue === '' && statusFilterValue === ''
                  ? 'ALL DONE!'
                  : 'NO MATCH!'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pager
        pages={pages}
        currentPage={page + 1}
        onPrev={() => updateQueueTable({ page: Math.max(0, page - 1) })}
        onNext={() => updateQueueTable({ page: Math.min(Math.max(0, pages - 1), page + 1) })}
        setPage={newPage => () => updateQueueTable({ page: newPage })}
      />
      {
        deletingItems.length > 0 && 
        (<ConfirmationPopup
          title="Delete queue items"
          message={"You are deleting " + deletingItems.length + " item(s) from the queue. Are you sure?"}
          onClose={() => setDeletingItems([])} 
          onOk={() => deleteItems()}
        ></ConfirmationPopup>)
      }
    </div>
  );
}
