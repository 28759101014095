import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToaster from 'actions/useToaster';
import { saveAs } from 'file-saver';
import React from 'react';
import { Printer } from 'reducers/printersReducer';
import * as http from '../../http';

const DownloadLabel = ({ printer }: { printer: Printer }) => {
  const toaster = useToaster();

  const download = () => {
    http
      .get(`/rest/react/downloadPrinterLabel?printerName=${printer.name}`, undefined, res => res.blob())
      .then(blob => {
        saveAs(blob, printer.labelFileName);
      })
      .catch(error => {
        console.error('error fetching label image', error);
        toaster.error('Error downloading label image');
      });
  };

  return (
    <React.Fragment>
      <FontAwesomeIcon title="Download this printer's label" icon={faDownload} onClick={download} />
    </React.Fragment>
  );
};

export default DownloadLabel;
