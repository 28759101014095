import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faArrowRight, faWrench, faTrash } from '@fortawesome/free-solid-svg-icons';
import { canPerformAction } from 'actions/userActions';
import { availableOperations } from 'reducers/userReducer';
import NewForwardRule from './forwardRulesPopup/NewForwardRule';
import EditForwardRule from './forwardRulesPopup/EditForwardRule';
import {
  insertForwardRule,
  forceGetForwardRules,
  updateForwardRule,
  deleteForwardRule,
  isTouchedRule,
} from '../../actions/nodesActions';
import useToaster from '../../actions/useToaster';
import { getNodes, getUser } from '../../reducers/selectors';
import { ForwardRule } from '../../reducers/nodesReducer';
import ConditionsTable from './ConditionsTable';
import { useAppDispatch } from 'hooks';

function ForwardRulesTable({ conditions, rules, selectedNode }) {
  const toaster = useToaster();
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const nodes = useSelector(getNodes);

  const [showAllRules, setShowAllRules] = useState(false);
  const [selectedRule, setSelectedRule] = useState<ForwardRule | null>(null);
  const [editingRule, setEditingRule] = useState(null);
  const [newRulePopup, showNewRulePopup] = useState(false);
  const [deletingRule, setDeletingRule] = useState(null);

  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_NODES);

  rules.sort((a, b) => {
    if (a.from > b.from) return 1;

    if (a.from < b.from) return -1;

    if (a.from === b.from) {
      if (a.to > b.to) return 1;

      if (a.to < b.to) return -1;

      return 0;
    }

    return 0;
  });

  const selectedNodeRules = rules
    .filter(() => selectedNode != null)
    .filter(rule => rule.from === selectedNode.aeTitle);

  return (
    <div>
      <div>
        <strong>Forwarding rules</strong>{' '}
        <small>(studies received from node X will be forwarded to node Y)</small>
        <Form.Check
          custom
          id="showRulesForwardRulesTable"
          type="checkbox"
          style={{ float: 'right', margin: '0' }}
          checked={showAllRules}
          onChange={() => setShowAllRules(!showAllRules)}
          label="Show All"
        />
        <table className="queue-table">
          <thead>
            <tr>
              <th style={{ width: '20%' }} />
              <th style={{ width: '6%' }} />
              <th style={{ width: '20%' }} />
              <th />
              <th style={{ width: '6%' }} />
              <th />
              <th style={{ width: '16%' }} />
            </tr>
          </thead>
          <tbody>
            {showAllRules && rules.length === 0 && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={7}>
                  No rule configured
                </td>
              </tr>
            )}

            {!showAllRules && selectedNode == null && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={7}>
                  Select a node
                </td>
              </tr>
            )}

            {!showAllRules && selectedNode && selectedNodeRules.length === 0 && (
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={7}>
                  {selectedNode.aeTitle} has no rule
                </td>
              </tr>
            )}

            {!showAllRules &&
              selectedNode &&
              selectedNodeRules.length > 0 &&
              selectedNodeRules.map((rule, index) => (
                <ForwardRuleRow
                  key={index}
                  rule={rule}
                  onEdit={() => setEditingRule(rule)}
                  onDelete={() => setDeletingRule(rule)}
                  authorized={authorized}
                  onClick={() => {
                    setSelectedRule(rule);
                    dispatch(isTouchedRule());
                  }}
                />
              ))}

            {showAllRules &&
              rules.length > 0 &&
              rules.map((rule, index) => (
                <ForwardRuleRow
                  key={index}
                  rule={rule}
                  onEdit={() => setEditingRule(rule)}
                  onDelete={() => setDeletingRule(rule)}
                  authorized={authorized}
                  onClick={() => {
                    setSelectedRule(rule);
                    dispatch(isTouchedRule());
                  }}
                />
              ))}
          </tbody>
        </table>
        <Button
          size="sm"
          variant="primary"
          style={{
            float: 'right',
            marginLeft: '2em',
          }}
          disabled={!authorized}
          onClick={() => showNewRulePopup(true)}
        >
          Add forwarding rule
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginLeft: '5px' }} />
        </Button>
      </div>

      <ConditionsTable
        rules={rules}
        conditions={conditions}
        selectedNode={selectedNode}
        selectedRule={selectedRule}
      />

      {editingRule && (
        <EditRulePopup
          rule={editingRule}
          onClose={() => setEditingRule(null)}
          onSave={rule => {
            console.log('updating rule', rule);
            updateForwardRule(rule)
              .then(() => {
                dispatch(forceGetForwardRules());
                toaster.success('Forward rule updated');
                setEditingRule(null);
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error updating rule');
              });
          }}
        />
      )}
      {newRulePopup && (
        <NewRulePopup
          onClose={() => showNewRulePopup(false)}
          onSave={rule => {
            console.log('new rule', rule);
            insertForwardRule(rule)
              .then(() => {
                dispatch(forceGetForwardRules());
                showNewRulePopup(false);
                toaster.success('Forward rule created');
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error creating rule');
              });
          }}
        />
      )}
      {deletingRule && (
        <DeleteRulePopup
          rule={deletingRule}
          onClose={() => setDeletingRule(null)}
          onConfirmDelete={() => {
            console.log('deleting rule', deletingRule);
            const sourceNode = nodes.find(node => node.aeTitle === (deletingRule as any).from);
            const destinationNode = nodes.find(node => node.aeTitle === (deletingRule as any).to);
            const rule = {
              source: sourceNode!.pk,
              destination: destinationNode!.pk,
            };

            deleteForwardRule(rule as any)
              .then(() => {
                dispatch(forceGetForwardRules());
                setDeletingRule(null);
                toaster.success('Forward rule deleted');
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error deleting rule, maybe there are some conditions attached');
              });
          }}
        />
      )}
    </div>
  );
}

const ForwardRuleRow = ({ rule, onEdit, onDelete, authorized, onClick }) => (
  <tr className="node-row" onClick={onClick}>
    <td title={rule.from}>{rule.from}</td>
    <td>
      <FontAwesomeIcon icon={faArrowRight} />
    </td>
    <td title={rule.to}>{rule.to}</td>
    <td title={rule.temporalWindow}>{rule.temporalWindow}</td>
    <td>{rule.queue}</td>
    <td title={rule.morphingScript + ' ' + rule.filterScript}>{rule.morphingScript} {rule.filterScript}</td>
    <td>
      <div style={{ width: '100%', textAlign: 'right', overflow: 'visible' }}>
        {authorized && (
          <FontAwesomeIcon icon={faWrench} className="clickable-icon" onClick={onEdit} />
        )}
        {authorized && (
          <FontAwesomeIcon icon={faTrash} className="clickable-icon" onClick={onDelete} />
        )}
      </div>
    </td>
  </tr>
);

const NewRulePopup = ({ onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>New forward rule</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <NewForwardRule onClose={onClose} onSave={onSave} />
    </Modal.Body>
  </Modal>
);

const EditRulePopup = ({ rule, onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>Editing forward rule</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <EditForwardRule rule={rule} onClose={onClose} onSave={onSave} />
    </Modal.Body>
  </Modal>
);

const DeleteRulePopup = ({ rule, onClose, onConfirmDelete }) => (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Deleting forward rule</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Deleting forward rule from {rule.from} to {rule.to}
      </p>
      <p>Are you sure?</p>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirmDelete}>
          Confirm delete
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default ForwardRulesTable;
