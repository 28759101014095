import { combineReducers } from 'redux';
import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface AiModule {
  pk: number;
  info: string;
  configuration: any;
  enabled: boolean;
}

interface AiModulePageState {
  modules: AiModule[],
  selectedModule: AiModule | null
}

type GetAiModulesFulfilled = {
  type: typeof actions.GET_AI_MODULES_FULFILLED;
  response: AxiosResponse<AiModule[]>;
};


const initialAiModulesPageState = {
  modules: [],
  selectedModule: null,
};

type SetAiModuleSelected = {
  type: typeof actions.SET_SELECTED_AI_MODULE;
  payload: AiModule;
} ;

export type AiModulesActions =
  | SetAiModuleSelected
  | GetAiModulesFulfilled

export default function aiModules(state: AiModulePageState = initialAiModulesPageState, action: AiModulesActions) : AiModulePageState {
  switch (action.type) {
    case actions.GET_AI_MODULES_FULFILLED:
      return { ...state, modules: action.response.data }
    case actions.SET_SELECTED_AI_MODULE:
      return { ...state, selectedModule: action.payload } ;
    default:
      return state;
  }
}
