import { useAppDispatch } from 'hooks';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
  forceGetCfgDicomServices,
  updateCfgDicomServices,
} from '../../actions/configurationsActions';
import useToaster from '../../actions/useToaster';
import EditableTextCell from '../EditableTextCell';

function CfgDicomServicesTable({ authorized, cfgDicomServices }) {
  return (
    <table className="queue-table">
      <thead>
        <tr>
          <th style={{ width: '15%' }}>Service name</th>
          <th style={{ width: '15%' }}>Parameters key</th>
          <th style={{ width: '30%' }}>Parameters value</th>
          <th style={{ width: '4%' }} />
          <th style={{ width: '8%' }} />
        </tr>
      </thead>
      <tbody>
        {cfgDicomServices &&
          cfgDicomServices.length > 0 &&
          cfgDicomServices.map((cfgDicomService, index) => (
            <CfgDicomServicesRow
              authorized={authorized}
              key={index}
              cfgDicomService={cfgDicomService}
            />
          ))}
      </tbody>
    </table>
  );
}

function CfgDicomServicesRow({ authorized, cfgDicomService }) {
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  const [unsavedValue, setUnsavedValue] = useState(cfgDicomService.paramValue);
  const serviceName =
    cfgDicomService.serviceName.charAt(0) +
    cfgDicomService.serviceName.slice(1).toLowerCase().replace(/_/g, ' ');
  const paramKey =
    cfgDicomService.paramKey.charAt(0) +
    cfgDicomService.paramKey.slice(1).toLowerCase().replace(/_/g, ' ');

  function save() {
    const temp = {
      serviceName: cfgDicomService.serviceName,
      paramKey: cfgDicomService.paramKey,
      paramValue: unsavedValue,
    };
    return temp;
  }

  return (
    <tr style={{ height: '2.3em' }}>
      <td>{serviceName}</td>
      <td>{paramKey}</td>
      {authorized ? (
        <td>
          <EditableTextCell
            value={unsavedValue}
            onChange={newValue => {
              setUnsavedValue(newValue);
            }}
            onSave={() => {}}
          />
        </td>
      ) : (
        <td>{unsavedValue}</td>
      )}
      <td />
      {unsavedValue !== cfgDicomService.paramValue ? (
        <td>
          <Button
            size="sm"
            style={{ paddingTop: '1px', paddingBottom: '1px' }}
            onClick={() => {
              const updatedCfgDicomService = save();
              updateCfgDicomServices(updatedCfgDicomService)
                .then(() => {
                  dispatch(forceGetCfgDicomServices());
                  setUnsavedValue(unsavedValue);
                  toaster.success('Dicom configuration updated successfully');
                })
                .catch(error => {
                  console.error(error);
                  toaster.error('Error updating dicom configuration');
                });
            }}
          >
            Save value
          </Button>
        </td>
      ) : (
        <td />
      )}
    </tr>
  );
}

export default CfgDicomServicesTable;
