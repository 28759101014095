import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';
import { URGENT, ENQUEUED, SENDING, PAUSED, DONE, FILTERED } from '../constants';

export interface Queue {
  studyUid: string;
  destinationFk: string;
  description: string;
  patientId: string;
  destination?: string;
  logTime?: string;
  studyInstances?: string;
  sentInstances?: string;
  enqueuedOn?: Date;
  window?: string;
  outcome?: string;
  queue?: string;
  status?: string;
}

interface GetQueueFulfilled {
  type: typeof actions.GET_QUEUE_FULFILLED;
  response: AxiosResponse<Queue[]>;
}

interface IncreasePriorityFulfilled {
  type: typeof actions.INCREASE_PRIORITY_FULFILLED;
  response: AxiosResponse<{ studyUid: string; destinationFk: string }>;
}

interface ResumeEnqueuedItemFulfilled {
  type: typeof actions.RESUME_ENQUEUED_ITEM_FULFILLED;
  response: AxiosResponse<{ studyUid: string; destinationFk: string }>;
  request: { studyUid: string; destinationFk: string };
}

interface PauseEnqueuedItemFulfilled {
  type: typeof actions.PAUSE_ENQUEUED_ITEM_FULFILLED;
  response: AxiosResponse<{ studyUid: string; destinationFk: string; status: string }>;
  request: { studyUid: string; destinationFk: string };
}

interface DeleteEnqueuedItemFulfilled {
  type: typeof actions.DELETE_ENQUEUED_ITEM_FULFILLED;
  response: AxiosResponse<{ studyUid: string; destinationFk: string }>;
  request: { studyUid: string; destinationFk: string };
}

interface WSEvent {
  type: typeof actions.WS_EVENT;
}
type QueueAction =
  | GetQueueFulfilled
  | IncreasePriorityFulfilled
  | ResumeEnqueuedItemFulfilled
  | PauseEnqueuedItemFulfilled
  | DeleteEnqueuedItemFulfilled
  | WSEvent;

// eslint-disable-next-line @typescript-eslint/default-param-last
function q(state: Queue[] = [], action: QueueAction): Queue[] {
  let studyUid, destinationFk;

  switch (action.type) {
    case actions.GET_QUEUE_FULFILLED:
      return action.response.data;
    case actions.INCREASE_PRIORITY_FULFILLED:
      studyUid = action.response.data.studyUid;
      destinationFk = action.response.data.destinationFk;
      return state.map(item =>
        item.studyUid === studyUid && item.destinationFk === destinationFk  ? { ...item, queue: URGENT } : item
      );
    case actions.RESUME_ENQUEUED_ITEM_FULFILLED:
      studyUid = action.request.studyUid;
      destinationFk = action.request.destinationFk;
      return state.map(item =>
        item.studyUid === studyUid && item.destinationFk === destinationFk
          ? { ...item, status: item.status === PAUSED ? ENQUEUED : item.status }
          : item
      );
    case actions.PAUSE_ENQUEUED_ITEM_FULFILLED:
      studyUid = action.request.studyUid;
      destinationFk = action.request.destinationFk;
      return state.map(item =>
        item.studyUid === studyUid && item.destinationFk === destinationFk
          ? { ...item, status: item.status !== SENDING && item.status !== DONE && item.status !== FILTERED ? PAUSED : item.status }
          : item
      );
    case actions.DELETE_ENQUEUED_ITEM_FULFILLED:
      studyUid = action.request.studyUid;
      destinationFk = action.request.destinationFk;
      return state.filter(item => {
        if (item.studyUid === studyUid && item.destinationFk === destinationFk) 
          return false;        

        return true;
      });
    case actions.WS_EVENT:
      return ws(state, action);
    default:
      return state;
  }
}

function ws(state: Queue[], action) {
  const { event } = action.payload;

  if (event === 'StudyForwarded') {
    const { studyUid, destinationFk, outcome } = action.payload as Queue;
    return state.map(item =>
      item.studyUid === studyUid && item.destinationFk === destinationFk
        ? { ...item, status: outcome }
        : item
    );
  }

  if (event === 'StudySending') {
    const { studyUid, destinationFk } = action.payload as Queue;
    return state.map(item =>
      item.studyUid === studyUid && item.destinationFk === destinationFk
        ? { ...item, status: SENDING }
        : item
    );
  }

  if (event === 'StudyEnqueued') {
    const { studyUid, destinationFk, window, queue } = action.payload as Queue;
    const idx = state.findIndex(
      item => item.studyUid === studyUid && item.destinationFk === destinationFk
    );

    if (idx !== -1) {
      return state.map(item =>
        item.studyUid === studyUid && item.destinationFk === destinationFk
          ? { ...item, status: ENQUEUED }
          : item
      );
    }

    const newState = [...state];
    const item = {
      studyUid,
      destinationFk,
      enqueuedOn: new Date(),
      queue,
      window,
      status: ENQUEUED,
      description: studyUid,
      patientId: '<NEW>',
    };
    newState.push(item);
    return newState;
  }

  return state;
}

export default q;
