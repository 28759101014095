import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faTrash, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Modal, Badge, Button } from 'react-bootstrap';
import {
  insertDeletionRule,
  updateDeletionRule,
  forceGetDeletionRules,
  deleteDeletionRule,
} from 'actions/deletionRulesActions';
import { DeletionRule } from 'reducers/deletionRulesReducer';
import { getNodes } from 'actions/nodesActions';
import { canPerformAction } from 'actions/userActions';
import { availableOperations } from 'reducers/userReducer';
import DeletionRulesPopup from './DeletionRulesPopup';
import useToaster from '../../actions/useToaster';
import { useAppDispatch } from 'hooks';

const FALLBACK = '$$FALLBACK$$';

function DeletionRulesTable({ user, deletionRules }) {
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  const [showNewDeletionRulePopup, setShowNewDeletionRulePopup] = useState(false);
  const [editingDeletionRule, setEditingDeletionRule] = useState<DeletionRule | null>(null);
  const [deletingDeletionRule, setDeletingDeletionRule] = useState<DeletionRule | null>(null);
  dispatch(getNodes());

  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_DELETION_RULES);

  return (
    <div>
      <table className="queue-table">
        <thead>
          <tr>
            <th style={{ width: '18%' }}>Source AeTitle</th>
            <th style={{ width: '20%' }}>Max storage days</th>
            <th style={{ width: '10%' }}>Delete only if forwarded</th>
            <th style={{ width: '10%' }}>Delete also filtered studies</th>
            <th style={{ width: '10%' }}>Enabled</th>
            <th style={{ width: '10%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {deletionRules &&
            deletionRules.length !== 0 &&
            deletionRules.map(deletionRule => (
              <DeletionRuleRow
                key={deletionRule.sourceAeTitle}
                deletionRule={deletionRule}
                authorized={authorized}
                onEdit={() => setEditingDeletionRule(deletionRule)}
                onDelete={() => setDeletingDeletionRule(deletionRule)}
              />
            ))}
        </tbody>

        {showNewDeletionRulePopup && (
          <NewDeletionRulePopup
            deletionRule={null}
            onClose={() => setShowNewDeletionRulePopup(false)}
            onSave={deletionRule => {
              console.log('new deletion rule', deletionRule);
              insertDeletionRule(deletionRule)
                .then(() => {
                  dispatch(forceGetDeletionRules());
                  setShowNewDeletionRulePopup(false);
                  toaster.success('New deletion rule added');
                })
                .catch(error => {
                  console.log(error);
                  toaster.error('Error creating new deletion rule');
                });
            }}
          />
        )}

        {editingDeletionRule && (
          <EditDeletionRulePopup
            deletionRule={editingDeletionRule}
            onClose={() => setEditingDeletionRule(null)}
            onSave={deletionRule => {
              console.log('updating deletion rule: ', deletionRule);
              updateDeletionRule(deletionRule)
                .then(() => {
                  dispatch(forceGetDeletionRules());
                  setEditingDeletionRule(null);
                  toaster.success('Updated deletion rule');
                })
                .catch(error => {
                  console.error(error);
                  toaster.error('Error updating deletion rule');
                });
            }}
          />
        )}

        {deletingDeletionRule && (
          <DeleteDeletionRulePopup
            deletionRule={deletingDeletionRule}
            onClose={() => setDeletingDeletionRule(null)}
            onConfirmDelete={() => {
              console.log('Deleting deletion rule', deletingDeletionRule);
              const { sourceAeTitle } = deletingDeletionRule;
              const temp = {
                sourceAeTitle,
              };
              deleteDeletionRule(temp as DeletionRule)
                .then(() => {
                  dispatch(forceGetDeletionRules());
                  setDeletingDeletionRule(null);
                  toaster.success('Deleted deletion rule');
                })
                .catch(error => {
                  console.error(error);
                  toaster.error('Error deleting deletion rule');
                });
            }}
          />
        )}
      </table>
      <Button
        size="sm"
        variant="primary"
        style={{
          float: 'right',
          marginLeft: '2em',
        }}
        disabled={!authorized}
        onClick={() => setShowNewDeletionRulePopup(true)}
      >
        Add new deletion rule
        <FontAwesomeIcon icon={faPlusCircle} style={{ marginLeft: '5px' }} />
      </Button>
    </div>
  );
}

const DeletionRuleRow = ({ deletionRule, authorized, onEdit, onDelete }) => (
  <tr>
    <td>
      {deletionRule.sourceAeTitle === FALLBACK ? '(fallback)' : deletionRule.sourceAeTitle}
    </td>
    <td>{deletionRule.maxStorageDays}</td>
    <td>{deletionRule.deleteOnlyIfForwarded ? 'yes' : 'no'}</td>
    <td>{deletionRule.deleteIfFiltered ? 'yes' : 'no'}</td>
    <td>
      {deletionRule.enabled ? (
        <Badge variant="success">Enabled</Badge>
      ) : (
        <Badge variant="warning">Disabled</Badge>
      )}
    </td>
    <td>
      {authorized && (
        <FontAwesomeIcon icon={faWrench} className="clickable-icon" onClick={onEdit} />
      )}
      {authorized && (deletionRule.sourceAeTitle !== FALLBACK) && (
        <FontAwesomeIcon icon={faTrash} className="clickable-icon" onClick={onDelete} />
      )}
    </td>
  </tr>
);

const NewDeletionRulePopup = ({ deletionRule, onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>New deletion rule</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <DeletionRulesPopup
        deletionRule={deletionRule}
        onClose={onClose}
        onSave={onSave}
        isEditing={false}
      />
    </Modal.Body>
  </Modal>
);

const EditDeletionRulePopup = ({ deletionRule, onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>
        Editing deletion rule {FALLBACK === deletionRule.sourceAeTitle ? '"fallback"' : deletionRule.sourceAeTitle}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <DeletionRulesPopup deletionRule={deletionRule} onClose={onClose} onSave={onSave} isEditing />
    </Modal.Body>
  </Modal>
);

const DeleteDeletionRulePopup = ({ deletionRule, onClose, onConfirmDelete }) => (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Deleting deletion rule</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Deleting deletion rule with Source AeTitle <strong>{deletionRule.sourceAeTitle}</strong>
      </p>
      <p>Are you sure?</p>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirmDelete}>
          Confirm delete
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default DeletionRulesTable;
