import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Badge, Form, Button, OverlayTrigger, Tooltip,  InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPause, faRedo, faPlay, faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomDate from '../../CustomDate';
import Pager from '../Pager';
import { componentsSelector, getPending, nodesSelectors } from '../../reducers/selectors';
import { UPDATE_PREFETCH_TABLE } from '../../actions/actionCatalog';
import { Prefetch } from '../../reducers/prefetchReducer';
import * as prefetchActions from '../../actions/prefetchActions';
import { useAppDispatch } from 'hooks';

const PREFETCH_STATUS = {
  DONE: 'DONE',
  ERROR: 'ERROR',
  PROCESSING: 'PROCESSING',
  STUDY_NOT_FOUND: 'STUDY_NOT_FOUND',
  TO_DO: 'TO_DO',
  PAUSE: 'PAUSE',
};

function PrefetchTable() {
  const dispatch = useAppDispatch();
  const updateState = payload => dispatch({ type: UPDATE_PREFETCH_TABLE, payload });
  const { pageSize, page, compact, showAll } = useSelector(
    componentsSelector.getPrefetchTableState
  );
  const pending = useSelector(getPending)?.prefetch;
  const queue = useSelector(nodesSelectors.getPrefetchQueue);
  const [newQueue, setNewQueue] = useState<Prefetch[]>(queue);
  const [lastValue, setLastValue] = useState("");
  const filteredQueue = newQueue.filter(item => showAll || item.status !== PREFETCH_STATUS.DONE);
  const items = filteredQueue.length;
  const pages = Math.ceil(items / pageSize);
  const from = pageSize * page;
  const to = Math.min(items, pageSize * page + pageSize);

  const handleChangePageSize = event => {
    const newPageSize = parseInt(event.target.value, 10);
    updateState({ pageSize: newPageSize, page: 0 });
  };

  useEffect(() => {
    dispatch(prefetchActions.getPrefetchQueue());
  }, []);

  useEffect(() => {
    if (newQueue.length > 0) setNewQueue(filter(queue, lastValue));
    else setNewQueue(queue);
  }, [queue]);

  return (
    <div>
      <div>
        <div style={{ display: 'inline-block' }}>
          <div style={{ display: 'inline-block', marginRight: '3em', width: '20em' }}>
            <InputGroup className="mb-3">
              <Form.Control
                size="sm"
                aria-label="Default"
                onChange={event => {
                  updateState({ page: 0 });
                  setNewQueue(filter(queue, event.target.value));
                  setLastValue(event.target.value.toUpperCase());
                }}
                aria-describedby="inputGroup-sizing-default"
                id="search-job-text-field"
              />
              <InputGroup.Append>
                <InputGroup.Text className="basic-addon2" id="inputGroup-sizing-default">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
          <Form.Check
            custom
            id="compactViewCheckbox"
            type="checkbox"
            style={{ display: 'inline-block', marginRight: '1em' }}
            onChange={() => updateState({ compact: !compact })}
            checked={compact}
            label="Compact View"
          />

          <Form.Check
            custom
            id="showAllCheckbox"
            type="checkbox"
            style={{ display: 'inline-block', marginRight: '1em' }}
            onChange={() => updateState({ showAll: !showAll })}
            checked={showAll}
            label="Show All"
          />
        </div>

        {pending ? (
          'loading...'
        ) : items > 0 ? (
          <div style={{ display: 'inline-block', marginRight: '1em', marginLeft: '3em' }}>
            Showing items {from + 1} - {to} of {items}
          </div>
        ) : (
          <div style={{ display: 'inline-block', marginRight: '1em', marginLeft: '3em%' }}>
            No items
          </div>
        )}
        <div style={{ float: 'right' }}>
          Page size:
          <select className="selectBoxNum" value={pageSize} onChange={e => handleChangePageSize(e)}>
            <option value="10">10</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>
      <table className="queue-table">
        <thead>
          <tr>
            <th style={{ width: '18%' }}>Patient</th>
            <th style={{ width: '38%' }}>Study</th>
            <th style={{ width: '10%' }}>Node</th>
            <th style={{ width: '10%' }}>Instances</th>
            <th style={{ width: '20%' }}>Status</th>
            <th style={{ width: '20%' }} />
          </tr>
        </thead>
        <tbody>
          {filteredQueue.slice(from, to).map(item => (
            <Row key={item.studyInstanceUid} item={item} compact={compact} dispatch={dispatch} />
          ))}
        </tbody>
      </table>
      <Pager
        pages={pages}
        currentPage={page + 1}
        onPrev={() => updateState({ page: Math.max(0, page - 1) })}
        onNext={() => updateState({ page: Math.min(Math.max(0, pages - 1), page + 1) })}
        setPage={newPage => () => updateState({ page: newPage })}
      />
      <Button
        size="sm"
        variant="primary"
        onClick={() => dispatch(prefetchActions.forceGetPrefetchQueue())}
      >
        Reload <FontAwesomeIcon icon={faClock} />
      </Button>
    </div>
  );
}

const Row = ({ item, compact, dispatch }) => (
  <tr>
    {compact ? (
      <td>{item.patientId}</td>
    ) : (
      <td>
        <div>{item.patientId}</div>
        <div>{item.patientName}</div>
      </td>
    )}

    {compact ? (
      <td>{item.studyInstanceUid}</td>
    ) : (
      <td>
        <div>
          <span style={{ fontStyle: 'italic', display: 'inline-block', width: '15em' }}>
            {CustomDate.fromString(item.studyDate).toDateTimeString()}
          </span>
        </div>
        <div>{limit(item.studyDescription, 70)}</div>
      </td>
    )}
    <td>{item.originNode}</td>
    <td>
      {item.storedImages}/{item.totalImages}
    </td>
    <td>{statusLabel(item)}</td>
    <td>
      <OverlayTrigger placement="top" overlay={getOverlay(item)}>
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() =>
            prefetchActions.updateStudy(item).then(() => {
              dispatch(prefetchActions.forceGetPrefetchQueue());
            })
          }
        >
          <FontAwesomeIcon icon={getIcon(item)} />
        </Button>
      </OverlayTrigger>
    </td>
    <td>
    </td>
  </tr>
);

function limit(string, letters) {
  if (string === null || string === undefined) return '';

  if (string.length <= letters) return string;

  return `${string.substring(0, letters)}...`;
}

function statusLabel({ status }) {
  if (status === PREFETCH_STATUS.ERROR) return <Badge variant="danger">{status}</Badge>;

  if (status === PREFETCH_STATUS.STUDY_NOT_FOUND) return <Badge variant="danger">{status}</Badge>;

  if (status === PREFETCH_STATUS.DONE) return <Badge variant="success">{status}</Badge>;

  if (status === PREFETCH_STATUS.PAUSE) return <Badge variant="info">{status}</Badge>;

  if (status === PREFETCH_STATUS.TO_DO) return <Badge variant="primary">{status}</Badge>;

  return <Badge variant="primary">{status}</Badge>;
}

function getIcon(study) {
  if (study.status === PREFETCH_STATUS.DONE) return faRedo;
  if (study.status === PREFETCH_STATUS.PAUSE) return faPlay;
  return faPause;
}

function getOverlay(study) {
  if (study.status === PREFETCH_STATUS.DONE)
    return <Tooltip id="tooltip">Reprocess the prefetch of this study</Tooltip>;
  if (study.status === PREFETCH_STATUS.PAUSE)
    return <Tooltip id="tooltip">Resume the prefetch of this study</Tooltip>;
  return <Tooltip id="tooltip">Pause the prefetch of this study</Tooltip>;
}

function filter(queue, word) {
  if (word.length < 1) return queue;
  // eslint-disable-next-line @typescript-eslint/no-array-constructor
  const newQueue = new Array();
  let i = 0;
  queue.forEach(element => {
    if (doFilter(element, word)) {
      newQueue[i] = element;
      i++;
    }
  });
  return newQueue;
}

function doFilter(element, word) {
  if (element.studyDescription != null) if (element.studyDescription.includes(word)) return true;
  if (element.patientId != null) if (element.patientId.includes(word.toUpperCase())) return true;
  if (element.patientName != null) if (element.patientName.includes(word.toUpperCase())) return true;
  if (element.studyInstanceUid.includes(word) || element.originNode.includes(word)) return true;
  return false;
}

export default PrefetchTable;
