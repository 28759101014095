import React from 'react';
import { Form } from 'react-bootstrap';
import { Printer } from 'reducers/printersReducer';
import { Reader } from 'reducers/readersReducer';

type BurnOptionsType = {
  requestPrinters: Printer[];
  requestReaders: Reader[];
  selectedPrinter: Printer | null;
  setSelectedPrinter: (_: Printer) => void;
  selectedReader: Reader | null;
  setSelectedReader: (_: Reader) => void;
};

const BurnOptions = ({
  requestPrinters,
  requestReaders,
  selectedPrinter,
  setSelectedPrinter,
  selectedReader,
  setSelectedReader,
}: BurnOptionsType) => {
  return (
    <table className="tableBurnOverlay">
      <tbody>
        <tr>
          <td style={{ paddingBottom: '5px' }}>
            <div style={{ fontSize: '15px', width: '20%' }} className="divTableBurnOverlay">
              Reader
            </div>
            <div style={{ width: '80%' }} className="divTableBurnOverlay">
              <Form.Control
                size="sm"
                id="viewer-dropdown-burner"
                value={selectedReader ? selectedReader.displayName : 'No reader found'}
                disabled={requestReaders.length === 0}
                as="select"
                onChange={e => {
                  const selected = requestReaders.find(
                    reader => reader.displayName === e.target.value
                  );
                  setSelectedReader(selected!);
                }}
              >
                {requestReaders ? (
                  requestReaders.map(el => (
                    <option disabled={!el.enabled} key={el.displayName} value={el.displayName}>
                      {el.displayName}
                    </option>
                  ))
                ) : (
                  <p>Loading</p>
                )}
              </Form.Control>
            </div>
          </td>
        </tr>
        <tr>
          <td style={{ paddingBottom: '5px' }}>
            <div style={{ fontSize: '15px', width: '20%' }} className="divTableBurnOverlay">
              Printer
            </div>
            <div style={{ width: '80%' }} className="divTableBurnOverlay">
              <Form.Control
                size="sm"
                id="viewer-dropdown-burner"
                value={selectedPrinter ? selectedPrinter.name : 'No printer found'}
                as="select"
                onChange={e => {
                  const selected = requestPrinters.find(printer => printer.name === e.target.value);
                  setSelectedPrinter(selected!);
                }}
              >
                {requestPrinters ? (
                  requestPrinters.map(el => (
                    <option key={el.name} value={el.name}>
                      {el.name}
                    </option>
                  ))
                ) : (
                  <p>Loading</p>
                )}
              </Form.Control>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BurnOptions;
