import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

import { Encodings, ForwardRule, Node, WorklistNode } from '../reducers/nodesReducer';

const regularGetNodes = (dispatch: Dispatch) => http.get<Node[]>(actions.GET_NODES, dispatch);
const debouncedGetNodes = defaultDebounce(regularGetNodes);

const regularGetNodesEncodings = (dispatch: Dispatch) => http.get<Encodings>(actions.GET_NODES_ENCODINGS, dispatch);
const debouncedGetNodesEncodings = defaultDebounce(regularGetNodesEncodings);

const regularGetForwardRules = (dispatch: Dispatch) => http.get<ForwardRule[]>(actions.GET_FORWARD_RULES, dispatch);
const debouncedGetForwardRules = defaultDebounce(regularGetForwardRules);

const regularGetWorklistNodes = (dispatch: Dispatch) => http.get<WorklistNode[]>(actions.GET_WORKLIST_NODES, dispatch);
const debouncedGetWorklistNodes = defaultDebounce(regularGetWorklistNodes);

export const getNodes = () => debouncedGetNodes;
export const forceGetNodes = () => regularGetNodes;

export const getNodesEncodings = () => debouncedGetNodesEncodings;
export const forceGetNodesEncodings = () => regularGetNodesEncodings;

export const getForwardRules = () => debouncedGetForwardRules;
export const forceGetForwardRules = () => regularGetForwardRules;

export const getWorklistNodes = () => debouncedGetWorklistNodes;
export const forceGetWorklistNodes = () => regularGetWorklistNodes;

export function updateNode(node: Node) {
  return http.post<void>(actions.UPDATE_NODE, node);
}

export function insertNode(node: Node) {
  return http.post<void>(actions.INSERT_NODE, node);
}

export function dicomEcho(node: Node) {
  return http.post<string>(actions.DICOM_ECHO, node);
}

export function insertForwardRule(rule: ForwardRule) {
  return http.post<void>(actions.INSERT_FORWARD_RULE, rule);
}

export function updateForwardRule(rule: ForwardRule) {
  return http.post<void>(actions.UPDATE_FORWARD_RULE, rule);
}

export function deleteForwardRule(rule: ForwardRule) {
  return http.post<void>(actions.DELETE_FORWARD_RULE, rule);
}

export function insertWorklistNode(node: WorklistNode) {
  console.log('inserting worklist node', node);
  return http.post<void>(actions.INSERT_WORKLIST_NODE, node);
}

export function updateWorklistNode(node: WorklistNode) {
  console.log('updating worklist node', node);
  return http.post<void>(actions.UPDATE_WORKLIST_NODE, node);
}

export function deleteWorklistNode(node: WorklistNode) {
  console.log('deleting worklist node', node);
  return http.post<void>(actions.DELETE_WORKLIST_NODE, node);
}

export function restartServices() {
  return http.get<boolean>(actions.RESTART_SERVICES);
}

export function isTouchedNode() {
  return {
    type: actions.IS_LAST_TOUCHED_NODE,
  };
}

export function isTouchedRule() {
  return {
    type: actions.IS_LAST_TOUCHED_RULE,
  };
}
