import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { Card } from 'react-bootstrap';
import { useAppDispatch } from 'hooks';
import { getAiQueue, getUser } from 'reducers/selectors';
import * as selectors from '../../reducers/selectors';
import * as aiModulesActions from "../../actions/aiModulesActions";
import * as aiQueueActions from "../../actions/aiQueueActions";
import { AiModule } from 'reducers/aiModulesReducer';
import AiQueueTable from './queueTable/AiQueueTable';

function AiModulesQueue() {
  // get various params from URL as ?param1=abc&param2=def
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  // initialize selectedModule (will be null at first)
  let selectedModule:AiModule = useSelector(selectors.getSelectedAiModule);
  const dispatch = useAppDispatch();
  const queue = useSelector(getAiQueue);
  const modulesNames = {}; // assigned below
  const user = useSelector(getUser);
  // needed when reloading page to allow url params to be used to fetch selected module
  const modules = useSelector(selectors.getAiModules);
  modules.map(module => modulesNames[module.pk] = JSON.parse(module.info).name);

  useEffect(() => {
      dispatch(aiModulesActions.getAiModules());
      dispatch(aiQueueActions.getAiQueue());
  }, []);
  // get the selected module using "aiModuleFk" url param when selectedModule is null (upon page reload)
  modules.map(module => (
      module.pk.toString() === params.get("aiModuleFk") ? selectedModule = module : ""
  ));
  // render error message when "aiModuleFk" url param does not correspond to any module
  if (params.get("aiModuleFk") && selectedModule == null) {
    return (
        <Card className="page-panel-common">
          <Card.Body>
            <h3>Selected AI module does not exist</h3>
          </Card.Body>
        </Card>
  );}

  return (
    <Card className="page-panel-common" >
      <Card.Body>
        <h3>AI Modules queue</h3>
          <AiQueueTable
              params={ params }
              queue={ queue }
              modules={ modules }
              modulesNames={ modulesNames }
              user={ user }
          />
      </Card.Body>
    </Card>
  );
}

export default AiModulesQueue;
