import { deleteAllStudiesToBurn } from 'actions/burnActions';
import axios from 'axios';
import { formatBytes } from 'components/dashboard/DashboardPage';
import { useAppDispatch } from 'hooks';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { BurnerJob, Printer } from 'reducers/printersReducer';
import { Reader } from 'reducers/readersReducer';
import { getStudiesToBurn } from 'reducers/selectors';

type BurnStudiesButtonType = {
  selectedPrinter: Printer | null;
  selectedReader: Reader | null;
  selectedJob: BurnerJob | null;
  isReprinting: boolean;
};

const BurnStudiesButton = ({
  selectedPrinter,
  selectedReader,
  selectedJob,
  isReprinting,
}: BurnStudiesButtonType) => {
  const dispatch = useAppDispatch();
  const toaster = {
    success: message => dispatch({ type: 'SUCCESS', message }),
    info: message => dispatch({ type: 'INFO', message }),
    warning: message => dispatch({ type: 'WARNING', message }),
    error: message => dispatch({ type: 'ERROR', message }),
  };
  const studiesToBurn = useSelector(getStudiesToBurn);

  const burnDisk = (): void => {
    if (isReprinting) {
      if (selectedJob !== null) {
        sendBurnRequest(selectedJob.studies);
        document.body.click(); // close overlay
      } else {
        toaster.error(`No selected Job`);
      }
    } else {
      sendBurnRequest(studiesToBurn);
      dispatch(deleteAllStudiesToBurn());
    }
  };

  const sendBurnRequest = (studies): void => {
    const data = {
      printer: selectedPrinter!.name,
      reader: selectedReader!.displayName,
      studies: studies,
    };
    toaster.info(`Sending print request`);

    axios
      .post(`/rest/react/cdburner`, data, {
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(() => toaster.success(`Print request sent correctly`))
      .catch(e => toaster.error(e.message));
  };

  const showEstimatedSize = (): boolean => {
    if (isReprinting) {
      if (!doAllStudiesStillExist() || selectedReader === null) {
        return false;
      }
    } else {
      if (!doAllStudiesHaveSize() || selectedReader === null) {
        return false;
      }
    }
    return true;
  };

  const doAllStudiesHaveSize = (): boolean => {
    return studiesToBurn.every(study => study.size);
  };

  const getAllStudiesSize = (): number => {
    let numberBytes = 0;
    if (isReprinting) {
      if (selectedJob !== null) {
        selectedJob.studies.forEach(study => {
          if (study.size) {
            numberBytes += parseFloat(study.size);
          }
        });
      }
    } else {
      studiesToBurn.forEach(element => {
        if (element.size) {
          numberBytes += parseFloat(element.size);
        }
      });
    }
    return numberBytes;
  };

  const doAllStudiesStillExist = (): boolean => {
    return selectedJob!.studies.every(study => study.description !== 'STUDY NOT FOUND');
  };

  const isDiskLargeEnough = (): boolean => {
    return (
      parseFloat(selectedPrinter!.cdSizeThresholdMegaBytes) * (1024 * 1024) >
      parseFloat(getAllStudiesSize() + selectedReader!.size)
    );
  };

  const disablePrintButton = (): boolean => {
    if (selectedPrinter!.defaultMediaFormat === 'CD' && !isDiskLargeEnough()) {
      return true;
    }

    if (isReprinting && selectedJob === null) {
      return true;
    }

    if (isReprinting && selectedJob !== null && !doAllStudiesStillExist()) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <div style={{ paddingBottom: '5px' }}>
        <div
          style={{ fontSize: '15px', width: '20%', textAlign: 'left' }}
          className="divTableBurnOverlay"
        >
          Total Size
        </div>
        <div style={{ width: '35%' }} className="divTableBurnOverlay">
          {showEstimatedSize() ? (
            <div
              style={{
                fontSize: '15px',
              }}
            >
              {formatBytes(getAllStudiesSize() + selectedReader!.size)}
            </div>
          ) : (
            <div>Calculating...</div>
          )}
        </div>
      </div>
      <div>
        {selectedPrinter !== null && selectedReader !== null && (
          <div>
            {selectedPrinter!.defaultMediaFormat !== 'DVD' && !isDiskLargeEnough() && (
              <div className="alertBurnOverlay">
                <div>
                  {`Study size exceeds ${selectedPrinter.cdSizeThresholdMegaBytes} 
                      MB (selected CD size threshold for this printer).`}
                </div>
                {selectedPrinter.defaultMediaFormat === 'CD' ? (
                  <div>{`${selectedPrinter.name} is configured to use CD only, you must change the default media format or choose another printer.`}</div>
                ) : (
                  <div>A DVD will be used.</div>
                )}
              </div>
            )}
            <div id="print-button-container">
              <Button
                disabled={disablePrintButton()}
                variant="success"
                size="sm"
                onClick={() => {
                  burnDisk();
                }}
              >
                Print CD
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BurnStudiesButton;
