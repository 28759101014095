import React from 'react';
import * as fai from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

const TableActionButton = (props: ButtonProps) => {
  // Set tooltip to props value or default to action.name
  const buttonTooltip = <Tooltip id="tooltip">{props.tooltip ? props.tooltip : props.action.name.toString()}</Tooltip>;

  return (
    <OverlayTrigger placement="top" overlay={buttonTooltip}>
      <Button
        size="sm"
        variant={props.variant ? props.variant : 'outline-secondary'}
        className="queue-button"
        style={{ marginRight: '0.5em' }}
        // Enable or disable the button if passed in props, defaults to always active
        disabled={props.disabled ? props.disabled : false}
        // On Click perform the action defined in props with args defined in props
        onClick={e => {
          props.action(props.args);
          e.currentTarget.blur();
        }}
      >
        {/*Display icon before text if present, display prepend and/or append icon if present*/}
        {props.icon ? (
          typeof props.icon == 'string' ? (
            <FontAwesomeIcon icon={fai[props.icon]} />
          ) : (
            props.icon.prepend && <FontAwesomeIcon icon={fai[props.icon.prepend]} />
          )
        ) : (
          ''
        )}
        {props.text && props.text}
        {props.icon
          ? typeof props.icon == 'string'
            ? ''
            : props.icon.append && <FontAwesomeIcon icon={fai[props.icon.append]} />
          : ''}
      </Button>
    </OverlayTrigger>
  );
};

interface ButtonIcon {
  prepend?: string;
  append?: string;
}
interface ButtonProps {
  action: any;
  args?: any;
  disabled?: boolean;
  icon?: ButtonIcon | string;
  text?: string;
  tooltip?: string;
  variant?: ButtonVariant;
}

export default TableActionButton;
