import * as actions from './actionCatalog';

export function pollingOn() {
  return {
    type: actions.POLLING_ON,
  };
}

export function pollingOff() {
  return {
    type: actions.POLLING_OFF,
  };
}
