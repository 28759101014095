import React, { useState, useEffect } from 'react';
import { getImportInfo } from '../../reducers/selectors';
import { useSelector } from 'react-redux';
import { Modal, ProgressBar, Button } from 'react-bootstrap';

function ImportStudyPopup(props) {
  const [errors, setErrors] = useState(0);
  const importInfo = useSelector(getImportInfo);

  useEffect(() => {
    if (importInfo.type === 'ERROR') setErrors(errors + 1);
  }, [JSON.stringify(importInfo)]);

  useEffect(() => {
    if (!props.show) {
      if (importInfo.type === 'FINISHED') setErrors(0);
    }
  }, [props.show]);

  return (
    <Modal show={props.show}>
      <Modal.Header>
        <Modal.Title>Importing study</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{importInfo.message}</p>
        <ProgressBar variant={errors === 0 ? 'success' : 'danger'} now={importInfo.progress} />
        {errors > 0 && <p style={{ marginTop: '1em' }}>Import failed! Errors occurred during import: {errors}</p>}
      </Modal.Body>
      {importInfo.type === 'FINISHED' && (
        <Modal.Footer>
          <Button style={{ minWidth: '5em' }} variant={errors === 0 ? 'success' : 'danger'} onClick={props.onOk} size="sm">
            Ok
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default ImportStudyPopup;
