import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

function PageNotFound() {
  const [hidden, setHidden] = useState(true);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setHidden(false);
    }, 250);
    setTimeout(() => {
      setRedirect(true);
    }, 2000);
  }, []);

  return (
    <React.Fragment>
      {!hidden && (
        <Card className="page-panel-common">
          <Card.Body>
            <h1>This page does not exist or you don&apos;t have the permissions to see it</h1>
            <h2>You are going to be redirected to the dashboard page</h2>
          </Card.Body>
        </Card>
      )}
      {redirect && <Redirect to="/" />}
    </React.Fragment>
  );
}

export default PageNotFound;
