import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import FeedEntryPoint from 'components/FeedEntryPoint';
import './dark.scss';
import FeedWebSocket from './FeedWebSocket';
import store from 'store';

console.log('%cO3-FEED WEB-UI', 'color: blue; font-size: large');
// const store = configureStore();
new FeedWebSocket(store).connect();

declare global {
  interface Window {
    // google apis for the google sign-in.
    // unfortunately there isn't any type that we can use for now for this
    // since they are available only for the previous version of the sign-in apis.
    google: any;
  }
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <Router basename="/feed">
      <FeedEntryPoint />
    </Router>
  </Provider>
);
