import React from 'react';
import { useSelector } from 'react-redux';
import { getUser } from 'reducers/selectors';
import { Role, User } from 'reducers/userReducer';
import UsersSettingsRow from './UsersSettingsRow';

type UsersSettingsTableType = {
  roles: Role[],
  users: User[],
  getAllUsers: () => void,
}

function UserSettingsTable({ roles, users, getAllUsers }: UsersSettingsTableType) {
  const currentUser = useSelector(getUser);

  // can manage only users of current user role (unless privileged)
  if (!currentUser.role?.privileged)
    users = users.filter(user => user.role?.name === currentUser.role?.name);
  
  return (
    <table className="queue-table">
      <thead>
        <tr>
          <th style={{ width: '7%' }}>Username</th>
          <th style={{ width: '13%' }}>Role</th>
          <th style={{ width: '15%' }}>Enabled</th>
        </tr>
      </thead>
      <tbody>
        {users &&
          users.length > 0 &&
          users.map(user => {
            return (
              <UsersSettingsRow
                key={user.username}
                user={user}
                roles={roles}
                currentUser={currentUser}
                getAllUsers={getAllUsers}
              />
            );
          })}
      </tbody>
    </table>
  );
}

export default UserSettingsTable;
