import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface WatchedFolder {
  pk: number | null;
  queue: string;
  folder: string;
  script: string | null;
  threads: number;
  enabled: boolean;
  subfolders: boolean;
  filter: {
    type: 'INCLUDE' | 'EXCLUDE';
    extensions: string;
  };
  reprocess: boolean;
  maxAttempts: number;
}

interface GetWatchedFoldersFulfilled {
  type: typeof actions.GET_WATCHED_FOLDERS_FULFILLED;
  response: AxiosResponse<string>;
}

type WatchedFolderAction = GetWatchedFoldersFulfilled;
function watchedFolders(state: WatchedFolder[] = [], action: WatchedFolderAction) {
  switch (action.type) {
    case actions.GET_WATCHED_FOLDERS_FULFILLED:
      return JSON.parse(action.response.data);
    default:
      return state;
  }
}

export default watchedFolders;
