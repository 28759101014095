import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Modal, Button, Row, Col, InputGroup, Form, OverlayTrigger, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faPlusCircle, faSync, faSearch, faWrench, faStickyNote } from '@fortawesome/free-solid-svg-icons';
import { canPerformAction } from 'actions/userActions';
import { availableOperations } from 'reducers/userReducer';
import * as nodesActions from '../../actions/nodesActions';
import * as scriptActions from '../../actions/scriptActions';
import * as conditionsActions from '../../actions/conditionsActions';
import {
  getNodes,
  getNodesEncodings,
  getForwardRules,
  getUser,
  getConditions,
  isTouchedNode,
} from '../../reducers/selectors';
import NodeForm from './NodeForm';
import ForwardRulesTable from './ForwardRulesTable';

class NodesPage extends Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      editingNode: null,
      selectedNode: null,
      insertNewNode: false,
      enablingNode: null,
      pending: false,
      restartingServices: false,
      filter: '',
      filteredNodes: props.nodes,
    };
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.restartDicomServices = this.restartDicomServices.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(nodesActions.getNodes());
    this.props.dispatch(nodesActions.getNodesEncodings());
    this.props.dispatch(nodesActions.getForwardRules());
    this.props.dispatch(scriptActions.getAllScripts());
    this.props.dispatch(conditionsActions.getConditions());
  }

  onChangeFilter(e) {
    const { value } = e.target;
    this.setState({ filter: value });

    const lvalue = value.toLowerCase();
    const filteredNodes = this.props.nodes.filter(node => node.aeTitle.toLowerCase().indexOf(lvalue) !== -1);
    this.setState({ filteredNodes });
  }

  restartDicomServices(e) {
    this.setState({ restartingServices: true });
    setTimeout(() => {
      nodesActions.restartServices().then(response => {
        this.setState({ restartingServices: false });
        if (response) this.props.dispatch({ type: 'SUCCESS', message: 'services restarted' });
        else this.props.dispatch({ type: 'ERROR', message: 'services not restarted' });
      });
    }, 500);
  }

  forceReload() {
    this.props.dispatch(nodesActions.forceGetNodes());
    this.props.dispatch(nodesActions.forceGetNodesEncodings());
    this.props.dispatch(nodesActions.getForwardRules());
  }

  updateNode(node) {
    console.log('updating node', node);
    this.setState({ pending: true });
    setTimeout(() => {
      nodesActions.updateNode(node).then(
        () => {
          this.forceReload();
          const message = this.state.editingNode ? `Node '${node.aeTitle}' updated` : `Node '${node.aeTitle}' enabled`;
          this.props.dispatch({ type: 'SUCCESS', message });
          this.setState({ pending: false, editingNode: null, enablingNode: null });
        },
        reject => {
          console.error('Error updating node', reject);
          this.setState({ pending: false });
          this.props.dispatch({ type: 'ERROR', message: 'Error updating node' });
        }
      );
    }, 400);
  }

  insertNode(node) {
    console.log('new node', node);
    this.setState({ pending: true });
    setTimeout(() => {
      nodesActions.insertNode(node).then(
        () => {
          this.forceReload();
          this.setState({ pending: false, insertNewNode: false });
          this.props.dispatch({ type: 'SUCCESS', message: `Node '${node.aeTitle}' added` });
        },
        reject => {
          console.error('Error inserting node', reject);
          this.setState({ pending: false });
          this.props.dispatch({ type: 'ERROR', message: 'Error inserting node' });
        }
      );
    }, 400);
  }

  dicomEcho(node) {
    console.log('doing dicom echo for node', node);
    this.props.dispatch({ type: 'INFO', message: 'doing dicom echo for node ' + node.aeTitle });
    setTimeout(() => {
      nodesActions.dicomEcho(node).then(
        code => {
          if (code === 'SUCCESS') {
            console.log('ok', code);
            this.props.dispatch({ type: 'SUCCESS', message: code });
          } else {
            console.error('error', code);
            this.props.dispatch({ type: 'ERROR', message: code });
          }
        },
        reject => {
          console.error('error doing dicom echo', reject);
          this.props.dispatch({ type: 'ERROR', message: reject.data });
        }
      );
    }, 400);
  }

  reloadData() {
    this.props.dispatch(nodesActions.getNodes());
    this.props.dispatch(nodesActions.getNodesEncodings());
    this.props.dispatch(nodesActions.getForwardRules());
    this.props.dispatch(scriptActions.getAllScripts());
    this.props.dispatch(conditionsActions.getConditions());
  }

  render() {
    const authorized = canPerformAction(this.props.user, availableOperations.CAN_MANAGE_NODES);
    const nodes = this.state.filter !== '' ? this.state.filteredNodes : this.props.nodes;
    const rules = this.props.forwardRules || [];
    const conditions = this.props.conditions || [];
    conditions.forEach(condition => {
      condition.destinationNodeAet = rules.find(rule => rule.toPk === condition.destinationNodeFk).to;
      condition.sourceNodeAet = rules.find(rule => rule.fromPk === condition.sourceNodeFk).from;
    });
    nodes.sort();

    return (
      <div>
        <Card className="page-panel-common">
          <Card.Body>
            <h3>Nodes</h3>

            <div className="d-flex align-items-baseline justify-content-between">
              <div className="d-flex align-items-baseline justify-content-around">
                <form onSubmit={e => e.preventDefault()} style={{ marginRight: '0.5em' }}>
                  <Form.Group>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        name="filter"
                        size="sm"
                        value={this.state.filter}
                        onChange={this.onChangeFilter}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="basic-addon2">
                          <FontAwesomeIcon icon={faSearch} />
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </form>

                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => this.reloadData()}
                  style={{ marginLeft: '0.5em', marginRight: '0.5em' }}
                >
                  Reload nodes <FontAwesomeIcon icon={faSync} style={{ marginLeft: '5px' }} />
                </Button>

                <Button
                  style={{
                    marginLeft: '0.5em',
                    marginRight: '0.5em',
                  }}
                  disabled={!authorized}
                  size="sm"
                  variant="primary"
                  onClick={() => this.setState({ insertNewNode: true })}
                >
                  Add new node <FontAwesomeIcon icon={faPlusCircle} style={{ marginLeft: '5px' }} />
                </Button>
              </div>

              <div>
                <Button
                  size="sm"
                  variant="primary"
                  onClick={this.restartDicomServices}
                  disabled={!authorized || this.state.restartingServices}
                >
                  {this.state.restartingServices ? 'Restarting...' : 'Restart DICOM services'}
                  <FontAwesomeIcon icon={faRedo} style={{ marginLeft: '5px' }} />
                </Button>
              </div>
            </div>
            <div style={{ marginTop: '3em' }}>
              <Row>
                <Col sm={6}>
                  <strong>Enabled nodes</strong>
                  <table className="queue-table">
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }} />
                        <th style={{ width: '20%' }} />
                        <th />
                        <th />
                        <th style={{ width: '2em' }} />
                        <th style={{ width: '2em' }} />
                      </tr>
                    </thead>
                    <tbody>
                      {nodes
                        .filter(it => it.enabled && it.aeTitle !== 'PREFETCH-SERVICE')
                        .map(node => (
                          <EnabledNodeRow
                            authorized={authorized}
                            key={node.pk}
                            node={node}
                            onEdit={() => this.setState({ editingNode: node })}
                            onClick={() => {
                              this.setState({ selectedNode: node });
                              this.props.dispatch(nodesActions.isTouchedNode());
                            }}
                          />
                        ))}
                      {nodes
                        .filter(it => it.enabled && it.aeTitle === 'PREFETCH-SERVICE')
                        .map((node, i) => (
                          <tr
                            key={i}
                            className="node-row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.setState({ selectedNode: node })}
                          >
                            <td>
                              <strong title={node.aeTitle}>{node.aeTitle}</strong>
                            </td>
                            <td>-</td>
                            <td>
                              <i>Special node used by the Prefetch Service</i>
                            </td>
                            <td />
                          </tr>
                        ))}
                    </tbody>
                  </table>

                  <strong>Unknown nodes</strong>
                  <table className="queue-table">
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }} />
                        <th style={{ width: '20%' }} />
                        <th style={{ width: '45%' }} />
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {nodes
                        .filter(it => !it.enabled)
                        .map(node => (
                          <DisabledNodeRow
                            authorized={authorized}
                            key={node.pk}
                            node={node}
                            onEnable={() => this.setState({ enablingNode: node })}
                          />
                        ))}
                    </tbody>
                  </table>
                </Col>
                <Col sm={6}>
                  <ForwardRulesTable conditions={conditions} rules={rules} selectedNode={this.state.selectedNode} />
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>

        {this.state.enablingNode && (
          <EnableNodePopup
            node={this.state.enablingNode}
            onClose={() => this.setState({ enablingNode: null })}
            onSave={enabledNode => this.updateNode(enabledNode)}
            pending={this.state.pending}
            aetsAlreadyTaken={nodes
              .filter(node => node.aeTitle !== this.state.enablingNode.aeTitle)
              .map(node => node.aeTitle)}
            onDicomEchoClick={node => this.dicomEcho(node)}
          />
        )}

        {this.state.editingNode && (
          <EditNodePopup
            node={this.state.editingNode}
            encodings={this.props.nodesEncodings[this.state.editingNode.aeTitle]}
            onClose={() => this.setState({ editingNode: null })}
            onSave={editedNode => this.updateNode(editedNode)}
            pending={this.state.pending}
            aetsAlreadyTaken={nodes
              .filter(node => node.aeTitle !== this.state.editingNode.aeTitle)
              .map(node => node.aeTitle)}
            onDicomEchoClick={node => this.dicomEcho(node)}
          />
        )}

        {this.state.insertNewNode && (
          <InsertNodePopup
            onClose={() => this.setState({ insertNewNode: false })}
            onSave={newNode => this.insertNode(newNode)}
            pending={this.state.pending}
            aetsAlreadyTaken={nodes.map(node => node.aeTitle)}
            nodes={nodes.map(node => node.aeTitle)}
            onDicomEchoClick={node => this.dicomEcho(node)}
          />
        )}
      </div>
    );
  }
}

const EnableNodePopup = ({ node, onClose, onSave, pending, aetsAlreadyTaken, onDicomEchoClick }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>Enabling node {node.aeTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <NodeForm
        aetsAlreadyTaken={aetsAlreadyTaken}
        node={{ ...node, enabled: true, forwardFavorite: false }}
        onClose={onClose}
        onSave={onSave}
        pending={pending}
        saveButtonLabels={['Enable node', 'Enabling..']}
        nodes={[] as string[]}
        onDicomEcho={onDicomEchoClick}
      />
    </Modal.Body>
  </Modal>
);

const EditNodePopup = ({ node, encodings, onClose, onSave, pending, aetsAlreadyTaken, onDicomEchoClick }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>Editing node {node.aeTitle}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <NodeForm
        aetsAlreadyTaken={aetsAlreadyTaken}
        node={node}
        encodings={encodings}
        onClose={onClose}
        onSave={onSave}
        pending={pending}
        saveButtonLabels={['Save changes', 'Saving..']}
        nodes={[] as string[]}
        onDicomEcho={onDicomEchoClick}
      />
    </Modal.Body>
  </Modal>
);

const InsertNodePopup = ({ onClose, onSave, pending, aetsAlreadyTaken, nodes, onDicomEchoClick }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>New node</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <NodeForm
        aetsAlreadyTaken={aetsAlreadyTaken}
        node={{ protocol: 'DICOM', enabled: true, forwardFavorite: false }}
        onClose={onClose}
        onSave={onSave}
        pending={pending}
        saveButtonLabels={['Add', 'Adding..']}
        nodes={nodes}
        onDicomEcho={onDicomEchoClick}
      />
    </Modal.Body>
  </Modal>
);

const EnabledNodeRow = ({ node, onEdit, authorized, onClick }) => (
  <tr className="node-row" onClick={onClick} style={{ cursor: 'pointer' }}>
    <td>
      <strong title={node.aeTitle}>{node.aeTitle}</strong>
    </td>
    <td>{node.protocol}</td>
    <td>
      {node.protocol === 'DICOM' ? (
        <div title={`${node.aeTitle}@${node.ip}:${node.port}`}>{`${node.aeTitle}@${node.ip}:${node.port}`}</div>
      ) : (
        <div title={node.stowRsEndpoint}>{node.stowRsEndpoint}</div>
      )}
    </td>
    <td>
      {node.notes && (
        <OverlayTrigger trigger="hover" placement="top" overlay={NotesOverlay(node.notes)}>
          <FontAwesomeIcon icon={faStickyNote} className="clickable-icon clickable-icon-notes" onClick={onEdit} />
        </OverlayTrigger>
      )}
    </td>
    <td>{authorized && <FontAwesomeIcon icon={faWrench} className="clickable-icon" onClick={onEdit} />}</td>
  </tr>
);

const DisabledNodeRow = ({ node, onEnable, authorized }) => (
  <tr className="node-row disabled">
    <td>
      <strong title={node.aeTitle}>{node.aeTitle}</strong>
    </td>
    <td>{node.protocol}</td>
    <td>
      {node.protocol === 'DICOM' ? (
        <div title={`${node.aeTitle}@${node.ip}:${node.port}`}>{`${node.aeTitle}@${node.ip}:${node.port}`}</div>
      ) : (
        <div title={node.stowRsEndpoint}>{node.stowRsEndpoint}</div>
      )}
    </td>
    <td>
      {authorized && (
        <Button size="sm" onClick={onEnable}>
          enable
        </Button>
      )}
    </td>
  </tr>
);

const NotesOverlay = notes => (
  <Popover id="notesOverlay">
    <div style={{ whiteSpace: 'pre-wrap' }}>{notes}</div>
  </Popover>
);

const mapStateToProps = state => {
  return {
    nodes: getNodes(state),
    nodesEncodings: getNodesEncodings(state),
    forwardRules: getForwardRules(state),
    user: getUser(state),
    conditions: getConditions(state),
    isTouchedNode: isTouchedNode(state),
  };
};

export default connect(mapStateToProps)(NodesPage);
