import React, { useState } from 'react';
import { faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Card, OverlayTrigger } from 'react-bootstrap';
import * as pollingActions from '../../../actions/pollingActions';
import { BurnerStatus } from 'reducers/printersReducer';
import BurnerStatusModal from './BurnerStatusModal';
import { useAppDispatch } from 'hooks';

type BurnerMiniCardProps = {
  burner: BurnerStatus;
}

const BurnerMiniCard = ({ burner }: BurnerMiniCardProps) => {
  const dispatch = useAppDispatch();
  const [show, setShow] = useState(false);

  const errorTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      One or more errors are present
    </Tooltip>
  );
  return (
    <React.Fragment>
      <Card
        className="printer-mini-card"
        onClick={() => {
          dispatch(pollingActions.pollingOff());
          setShow(true);
        }}
      >
        <Card.Title>
          {burner.name}{' '}
          {burner.errors.length > 0 ? (
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={errorTooltip}>
              <FontAwesomeIcon className="bs-color-danger" icon={faExclamationCircle} />
            </OverlayTrigger>
          ) : (
            <FontAwesomeIcon className="bs-color-primary" icon={faCheckCircle} />
          )}
        </Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {burner.model === 'EPSON_P100' ? 'Epson-PP100' : 'Rimage-2000I'}
        </Card.Subtitle>
          {isOnline(burner)
            ? <Card.Text className="bs-color-success">online</Card.Text>
            : <Card.Text className="bs-color-danger">offline</Card.Text>
          }
      </Card>

      {show && <Detail
        burner={burner}
        show={show}
        onClose={() => {
          dispatch(pollingActions.pollingOn());
          setShow(false);
        }}
      />}
    </React.Fragment>
  );
};

type DetailProps = {
  burner: BurnerStatus;
  show: boolean;
  onClose: () => void;
}

const Detail = ({ burner, show, onClose }: DetailProps) => (
  <BurnerStatusModal
    burner={burner}
    show={show}
    onClose={onClose}
  />
);

function isOnline(burner: BurnerStatus): boolean {
  return !burner.errors.some(error => error.code === 'HNW000' || error.code === 'HNW001' || error.code === 'CUSTOM001');
}

export default BurnerMiniCard;
