import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteStudyToBurn } from '../../../actions/burnActions';
import { formatBytes } from '../../../components/dashboard/DashboardPage';
import CustomDate from '../../../CustomDate';
import React from 'react';
import { useSelector } from 'react-redux';
import { BurnerJob } from '../../../reducers/printersReducer';
import { getStudiesToBurn } from '../../../reducers/selectors';
import { StudyToBurn } from '../../../reducers/studiesToBurnReducer';
import { fromDicomDate, fromDicomTime } from '../../../dicom';
import OverlayTooltip from './OverlayTooltip';
import { useAppDispatch } from 'hooks';

type StudiesToBeBurntType = {
  selectedJob: BurnerJob | null,
  isReprinting: boolean,
}

const StudiesToBeBurnt = ({ selectedJob, isReprinting }: StudiesToBeBurntType) => {
  const studiesToBurn = useSelector(getStudiesToBurn);

  return (
    <table className="queue-table selectable" style={{ cursor: 'auto' }}>
      <thead>
        <tr>
          <th style={{ width: '40%', textAlign: 'left' }}>Study</th>
          <th style={{ width: '10%', textAlign: 'left' }}>Series</th>
          <th style={{ width: '20%', textAlign: 'left' }}>Date</th>
          <th style={{ width: '12%', textAlign: 'right' }}>Size</th>
          {isReprinting ? <th style={{ width: '1%' }}> </th> : <th style={{ width: '5%' }}> </th>}
        </tr>
      </thead>
      <tbody>
        {isReprinting ? (
          selectedJob !== null ? (
            selectedJob.studies.map(study => (
              <BurnStudyRow key={study.studyUid} study={study} isReprinting={isReprinting} />
            ))
          ) : (
            <div />
          )
        ) : (
          studiesToBurn.map(study => (
            <BurnStudyRow key={study.studyUid} study={study} isReprinting={isReprinting} />
          ))
        )}
      </tbody>
    </table>
  );
};

type BurnStudyRowType = {
  study: StudyToBurn,
  isReprinting: boolean
}

const BurnStudyRow = ({ study, isReprinting }: BurnStudyRowType) => {
  const dispatch = useAppDispatch();

  const handleDeleteStudy = (studyToDelete): void  => {
    dispatch(deleteStudyToBurn(studyToDelete));
  };

  const format = (dicomDate, dicomTime): string => {
    const date = fromDicomDate(dicomDate);
    const time = fromDicomTime(dicomTime);
    if (time && date) {
      date.setHours(time.getHours());
      date.setMinutes(time.getMinutes());
      date.setSeconds(time.getSeconds());
    }
    return CustomDate.fromDate(date).toDateTimeString();
  }

  return (
    <tr className="rowBurnOverlay">
      <td>
        <OverlayTooltip text={study.description} />
      </td>
      <td>
        <div>{study.series!.length}/{study.totalSeries}</div>
      </td>
      <td>
        <div>{format(study.studyDate, study.studyTime)}</div>
      </td>
      <td>
        {study && study.size ? (
          <div style={{ textAlign: 'right' }}>{formatBytes(study.size)}</div>
        ) : (
          <div>Calculating...</div>
        )}
      </td>
      <td style={{ textAlign: 'center' }}>
        {isReprinting ? (
          <div />
        ) : (
          <FontAwesomeIcon icon={faTimesCircle} onClick={() => handleDeleteStudy(study)} />
        )}
      </td>
    </tr>
  );
};

export default StudiesToBeBurnt;