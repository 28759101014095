import React, { useState } from 'react';

const EditableTextCell = ({ value, type = 'text', onChange, onSave }) => {
  const [editing, setEditing] = useState(false);
  function onKeyDown(e) {
    if (e.key === 'Enter') {
      setEditing(false);
      onSave();
    }
  }

  return editing ? (
    <input
      type={type}
      autoFocus
      style={{ width: '90%', maxHeight: '1.5em' }}
      value={value}
      onKeyDown={onKeyDown}
      onBlur={() => {
        setEditing(false);
        onSave();
      }}
      onChange={e => onChange(e.target.value)}
    />
  ) : (
    <div
      tabIndex={0}
      style={{
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      onClick={() => setEditing(true)}
      onFocus={() => setEditing(true)}
    >
      {value === '' ? <i>null</i> : <React.Fragment>{value}</React.Fragment>}
    </div>
  );
};

export default EditableTextCell;
