import {
  ADD_EVERY_ACTION,
  ADD_USER_ACTION,
  DELETE_EVERY_ACTION,
  REMOVE_USER_ACTION,
} from 'actions/actionCatalog';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, FormCheck, FormControl, FormGroup, Row } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { useSelector } from 'react-redux';
import { componentsSelector } from 'reducers/selectors';
import { Role, UserAction } from 'reducers/userReducer';

type UsersSettingsRolePopupType = {
  roles: Role[];
  actions: UserAction[];
  onClose: () => void;
  onSave: (_: any) => void;
  isInEditMode: boolean;
  editingRole: Role | null;
};

function UsersSettingsRolePopup({
  roles,
  actions,
  onClose,
  onSave,
  isInEditMode,
  editingRole,
}: UsersSettingsRolePopupType) {
  const dispatch = useAppDispatch();
  const addEveryAction = payload => dispatch({ type: ADD_EVERY_ACTION, payload });
  const removeEveryAction = payload => dispatch({ type: DELETE_EVERY_ACTION, payload });
  const addAction = payload => dispatch({ type: ADD_USER_ACTION, payload });
  const removeAction = payload => dispatch({ type: REMOVE_USER_ACTION, payload });

  const [roleName, setRoleName] = useState(isInEditMode && editingRole ? editingRole.name : '');
  const [privileged, setPrivileged] = useState<boolean>(
    isInEditMode && editingRole ? editingRole.privileged! : false
  );
  const [selectAll, setSelectAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openTable, setOpenTable] = useState(false);

  const selectedUserActions = useSelector(componentsSelector.getUserActions);

  const pageActions = actions!.filter(tempAction => tempAction.actionScope === 'PAGE');
  const fieldActions = actions!.filter(tempAction => tempAction.actionScope === 'FIELD');

  useEffect(() => {
    if (isInEditMode && editingRole) {
      editingRole.userActions!.map(action => addAction(action));
      setOpenTable(!privileged);
    }
  }, []);

  useEffect(() => {
    if (selectedUserActions.length === actions.length) {
      setSelectAll(true);
    }
    if (selectedUserActions.length === 0) {
      setSelectAll(false);
    }
  }, [selectedUserActions]);

  useEffect(() => {
    setOpenTable(!privileged);
  }, [privileged]);

  function save() {
    if (roleName === '') {
      setErrorMessage('You must provide a name for the new role');
      return;
    }
    if (selectedUserActions.length === 0 && !privileged) {
      setErrorMessage('You must add at least 1 action or set the role to "Privileged"');
      return;
    }
    if (!isInEditMode && roles.find(role => role.name === roleName)) {
      setErrorMessage(`You can't name this role as an already existing role`);
      return;
    }
    const tempRole: any = {
      pk: undefined,
      roleName,
      privileged,
      selectedUserActions,
    };
    if (isInEditMode && editingRole) {
      tempRole.pk = editingRole.pk;
    }
    onSave(tempRole);
  }

  return (
    <Form>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Role name
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            type="text"
            disabled={isInEditMode}
            placeholder="Role name..."
            value={roleName}
            onChange={e => {
              setRoleName(e.target.value);
              setErrorMessage('');
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Col sm={8} md={{ offset: 4 }}>
          <FormCheck
            custom
            id="enablePrivileged"
            type="checkbox"
            onClick={() => {
              setPrivileged(!privileged);
              setErrorMessage('');
            }}
            onChange={() => console.log()}
            checked={privileged}
            label="Privileged"
          />
        </Col>
        <Col sm={3}>
          <FormCheck
            custom
            id="selectAllActions"
            type="switch"
            disabled={privileged}
            onChange={() => {
              setSelectAll(!selectAll);
              if (!selectAll) {
                addEveryAction(actions);
              } else {
                removeEveryAction(actions);
              }
            }}
            checked={selectAll}
            label={selectAll ? 'Deselect all' : 'Select all'}
          />
        </Col>
      </FormGroup>
      <div style={{ display: 'flex' }}>
        <div className="roles-collapsible" style={{ marginRight: '1em' }}>
          <Collapsible trigger="Page actions" open={openTable} triggerDisabled>
            <table className="queue-table">
              <thead>
                <tr>
                  <th style={{ width: '60%' }}>Section</th>
                  <th style={{ width: '40%' }}>Visible</th>
                </tr>
              </thead>
              <tbody>
                {!isInEditMode &&
                  pageActions &&
                  pageActions.length > 0 &&
                  pageActions.map(action => (
                    <NewRoleRow
                      key={action.actionName}
                      addAction={addAction}
                      removeAction={removeAction}
                      action={action}
                      privileged={privileged}
                      selectAll={selectAll}
                    />
                  ))}
                {isInEditMode &&
                  editingRole &&
                  pageActions &&
                  pageActions.length > 0 &&
                  pageActions.map(action => (
                    <EditRoleRow
                      key={action.actionName}
                      addAction={addAction}
                      removeAction={removeAction}
                      action={action}
                      role={editingRole}
                      privileged={privileged}
                      selectAll={selectAll}
                    />
                  ))}
              </tbody>
            </table>
          </Collapsible>
        </div>
        <div className="roles-collapsible">
          <Collapsible trigger="Field actions" open={openTable} triggerDisabled>
            <table className="queue-table">
              <thead>
                <tr>
                  <th style={{ width: '80%' }}>Action name</th>
                  <th style={{ width: '25%' }}>Enable</th>
                </tr>
              </thead>
              <tbody>
                {!isInEditMode &&
                  fieldActions &&
                  fieldActions.length > 0 &&
                  fieldActions.map(action => (
                    <NewRoleRow
                      key={action.actionName}
                      addAction={addAction}
                      removeAction={removeAction}
                      action={action}
                      privileged={privileged}
                      selectAll={selectAll}
                    />
                  ))}
                {isInEditMode &&
                  editingRole &&
                  fieldActions &&
                  fieldActions.length > 0 &&
                  fieldActions.map(action => (
                    <EditRoleRow
                      key={action.actionName}
                      addAction={addAction}
                      removeAction={removeAction}
                      action={action}
                      role={editingRole}
                      privileged={privileged}
                      selectAll={selectAll}
                    />
                  ))}
              </tbody>
            </table>
          </Collapsible>
        </div>
      </div>
      {errorMessage !== '' && <div style={{ color: '#f86c6b' }}>{errorMessage}</div>}
      <div style={{ textAlign: 'right' }}>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button
          style={{ width: '8em' }}
          variant="primary"
          onClick={() => {
            save();
          }}
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

type NewRoleRowType = {
  addAction: (_: any) => void;
  removeAction: (_: any) => void;
  action: UserAction;
  privileged: boolean;
  selectAll: boolean;
};

function NewRoleRow({ addAction, removeAction, action, privileged, selectAll }: NewRoleRowType) {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setChecked(selectAll);
  }, [selectAll]);

  useEffect(() => {
    setDisabled(privileged);
  }, [privileged]);

  const actionName =
    action.actionName!.charAt(0) +
    action.actionName!.slice(1).toLowerCase().replace(/_/g, ' ').replace('visible', '');

  return (
    <tr>
      <td>{actionName}</td>
      <td>
        <Col lg={7}>
          <Form.Check
            type="switch"
            id={`action-switch-${action.actionName}`}
            label=""
            checked={checked}
            disabled={disabled}
            onChange={() => {
              const temp = {
                pk: action.pk,
                actionScope: action.actionScope,
                actionName: action.actionName,
              };
              if (checked) removeAction(temp);
              else addAction(temp);
              setChecked(!checked);
            }}
            style={{ marginTop: '7px' }}
          />
        </Col>
      </td>
    </tr>
  );
}

type EditRoleRowType = {
  addAction: (_: any) => void;
  removeAction: (_: any) => void;
  action: UserAction;
  role: Role;
  privileged: boolean;
  selectAll: boolean;
};

function EditRoleRow({
  addAction,
  removeAction,
  action,
  role,
  privileged,
  selectAll,
}: EditRoleRowType) {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    setChecked(selectAll);
  }, [selectAll]);

  useEffect(() => {
    setDisabled(privileged);
  }, [privileged]);

  useEffect(() => {
    setChecked(role.userActions!.some(useraction => useraction.actionName === action.actionName));
  }, []);

  const actionName =
    action.actionName!.charAt(0) +
    action.actionName!.slice(1).toLowerCase().replace(/_/g, ' ').replace('visible', '');

  return (
    <tr>
      <td>{actionName}</td>
      <td>
        <Col lg={7}>
          <Form.Check
            type="switch"
            id={`action-switch-${action.actionName}`}
            label=""
            checked={checked}
            disabled={disabled}
            onChange={() => {
              const temp = {
                pk: action.pk,
                actionScope: action.actionScope,
                actionName: action.actionName,
              };
              if (checked) {
                removeAction(temp);
              } else {
                addAction(temp);
              }
              setChecked(!checked);
            }}
            style={{ marginTop: '7px' }}
          />
        </Col>
      </td>
    </tr>
  );
}

export default UsersSettingsRolePopup;
