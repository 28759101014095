import { faEquals, faInfo, faNotEqual } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isInteger } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from 'react-bootstrap';
import { Script } from 'reducers/scriptReducer';
import { WatchedFolder } from 'reducers/watchedFoldersReducer';

const DEFAULT_FOLDER: WatchedFolder = {
  enabled: true,
  queue: 'CHANGEME',
  folder: '/opt/watched',
  subfolders: false,
  threads: 1,
  pk: null,
  script: null,
  filter: {
    type: 'EXCLUDE',
    extensions: '',
  },
  reprocess: false,
  maxAttempts: 1
};

const WatchedFolderEditSection = ({
  watchedFolder,
  scripts,
  onCancel,
  onSave,
}: {
  watchedFolder: WatchedFolder | null;
  scripts: Script[];
  onCancel: () => void;
  onSave: (_: WatchedFolder) => void;
}) => {
  const [tempFolder, setTempFolder] = useState<WatchedFolder>(watchedFolder ? watchedFolder : DEFAULT_FOLDER);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setIsValid(validate());
  }, []);

  useEffect(() => {
    setIsValid(validate());
  }, [tempFolder]);

  useEffect(() => {
    if (watchedFolder) {
      setTempFolder(watchedFolder);
    } else {
      setTempFolder(DEFAULT_FOLDER);
    }
  }, [watchedFolder]);

  const validate = (): boolean => {
    if (!validString(tempFolder.folder) || !validString(tempFolder.queue) || !validString(tempFolder.script)) {
      return false;
    }

    if (!validNumber(tempFolder.threads)) {
      return false;
    }

    return true;
  };

  const validString = (param: string | null): boolean => {
    return param !== null && param.trim() !== '';
  };

  const validNumber = (param: number | null): boolean => {
    return param !== null && !isNaN(param) && isInteger(param);
  };

  return (
    <Form style={{ border: '1px solid #23282c', borderRadius: '5px', padding: '1rem' }}>
      <h5 style={{ paddingBottom: '1rem' }}>{`Editing watched folder's settings`}</h5>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Enabled
        </Form.Label>
        <Col>
          <Form.Check
            type="switch"
            id="watched-folder-enabled-switch"
            checked={tempFolder.enabled}
            onChange={evt => {
              setTempFolder(curr => {
                return { ...curr, enabled: evt.target.checked };
              });
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Queue
        </Form.Label>
        <Col xl={5}>
          <InputGroup>
            <FormControl
              type="string"
              size="sm"
              value={tempFolder.queue}
              onChange={evt => {
                setTempFolder(curr => {
                  return { ...curr, queue: evt.target.value };
                });
              }}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Folder
        </Form.Label>
        <Col xl={5}>
          <InputGroup>
            <FormControl
              type="string"
              size="sm"
              value={tempFolder.folder}
              onChange={evt => {
                setTempFolder(curr => {
                  return { ...curr, folder: evt.target.value };
                });
              }}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Subfolders
        </Form.Label>
        <Col>
          <Form.Check
            type="switch"
            id="watched-folder-subfolders-switch"
            checked={tempFolder.subfolders}
            onChange={evt => {
              setTempFolder(curr => {
                return { ...curr, subfolders: evt.target.checked };
              });
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Reprocess files
        </Form.Label>
        <Col>
          <Form.Check
            type="switch"
            id="watched-folder-reprocess-switch"
            checked={tempFolder.reprocess}
            onChange={evt => {
              setTempFolder(curr => {
                return { ...curr, reprocess: evt.target.checked };
              });
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Script
        </Form.Label>
        <Col xl={5}>
          <select
            className="selectBoxNum selectFilter"
            style={{ margin: '0', minWidth: '15em' }}
            onChange={evt => {
              setTempFolder(curr => {
                return { ...curr, script: evt.target.value };
              });
            }}
          >
            <option selected={!tempFolder.script} disabled value="">
              Select one script
            </option>
            {scripts.map(script => (
              <option key={script.name} selected={!!(tempFolder.script === script.name)} value={script.name}>
                {script.name}
              </option>
            ))}
          </select>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Workers
        </Form.Label>
        <Col xl={5}>
          <InputGroup>
            <FormControl
              type="string"
              size="sm"
              value={tempFolder.threads}
              onChange={evt => {
                setTempFolder(curr => {
                  return { ...curr, threads: parseInt(evt.target.value) };
                });
              }}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Max attempts
        </Form.Label>
        <Col xl={5}>
          <InputGroup>
            <FormControl
              type="string"
              size="sm"
              value={tempFolder.maxAttempts}
              onChange={evt => {
                setTempFolder(curr => {
                  return { ...curr, maxAttempts: parseInt(evt.target.value) };
                });
              }}
            />
          </InputGroup>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column xl={2}>
          Extensions filter
        </Form.Label>
        <Col xl={5}>
          <InputGroup>
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 500, hide: 0 }}
              overlay={
                <Tooltip id="tooltip">{tempFolder.filter.type === 'EXCLUDE' ? 'Excluding' : 'Including'} files</Tooltip>
              }
            >
              <Button
                size="sm"
                onClick={() => {
                  if (tempFolder.filter.type === 'EXCLUDE') {
                    setTempFolder(curr => {
                      return { ...curr, filter: { type: 'INCLUDE', extensions: curr.filter.extensions } };
                    });
                  } else {
                    setTempFolder(curr => {
                      return { ...curr, filter: { type: 'EXCLUDE', extensions: curr.filter.extensions } };
                    });
                  }
                }}
                variant="outline-primary"
                style={{ marginRight: '1em' }}
              >
                <FontAwesomeIcon icon={tempFolder.filter.type === 'EXCLUDE' ? faNotEqual : faEquals} />
              </Button>
            </OverlayTrigger>
            <FormControl
              type="string"
              size="sm"
              style={{ borderRadius: '3px' }}
              placeholder="Comma-separated file extensions"
              value={tempFolder.filter.extensions}
              onChange={evt => {
                setTempFolder(curr => {
                  return {
                    ...curr,
                    filter: {
                      type: curr.filter.type,
                      extensions: evt.target.value,
                    },
                  };
                });
              }}
            />
            <InputGroup.Append>
              <OverlayTrigger
                trigger="hover"
                placement="bottom"
                overlay={
                  <Popover
                    id="watched-folders-hint"
                    className="basic-popover"
                    style={{ maxWidth: '50em', color: '#C3C3C3' }}
                  >
                    {!tempFolder.filter.extensions ||
                    (tempFolder.filter.extensions && tempFolder.filter.extensions.trim() === '')
                      ? 'No file is being filtered'
                      : `You are currently ${
                          tempFolder.filter.type === 'EXCLUDE' ? 'excluding' : 'including'
                        } all files having
                    extensions ${' ' + tempFolder.filter.extensions}`}
                  </Popover>
                }
              >
                <InputGroup.Text
                  className="basic-addon2"
                  id="inputGroup-sizing-default"
                  style={{
                    maxWidth: '50em',
                    cursor: 'pointer',
                    backgroundColor: 'var(--gray-800)',
                    borderColor: 'var(--gray-900)',
                    color: 'var(--gray-200)',
                  }}
                >
                  <FontAwesomeIcon icon={faInfo} />
                </InputGroup.Text>
              </OverlayTrigger>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </FormGroup>
      <div style={{ marginTop: '1em', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          size="sm"
          onClick={() => {
            onCancel();
            setTempFolder(DEFAULT_FOLDER);
          }}
          variant="outline-secondary"
          style={{ marginRight: '5px' }}
        >
          Cancel
        </Button>
        <Button
          size="sm"
          onClick={() => {
            if (isValid) {
              onSave(tempFolder);
              setTempFolder(DEFAULT_FOLDER);
            }
          }}
          variant="primary"
          disabled={!isValid}
        >
          Save
        </Button>
      </div>
    </Form>
  );
};

export default WatchedFolderEditSection;
