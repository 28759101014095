import { faChevronDown, faChevronUp, faEquals, faNotEqual, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomDate from 'CustomDate';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getPending } from 'reducers/selectors';
import * as fileQueueActions from '../../../actions/fileQueueActions';
import { canPerformAction } from '../../../actions/userActions';
import { availableOperations } from '../../../reducers/userReducer';
import Pager from '../../Pager';
import FileQueueRow from './FileQueueRow';

enum FileQueueDateOrder {
  ASCENDING,
  DESCENDING,
}

export default function FileQueueTable({ queue, user }) {
  const dispatch = useAppDispatch();
  const [queueFiltered, setQueueFiltered] = useState(queue);
  const [dateOrder, setDateOrder] = useState<FileQueueDateOrder>(FileQueueDateOrder.ASCENDING);
  const [includeFilterToggle, setIncludeFilterToggle] = useState<boolean>(true);
  const [freeFilterValue, setFreeFilterValue] = useState('');
  const [statusFilterValue, setStatusFilterValue] = useState('');
  const [refreshPeriod, setRefreshPeriod] = useState(5);
  const pending = useSelector(getPending)?.aiQueue;

  const [pageSize, setPageSize] = useState(12);
  const [page, setPage] = useState(0);
  const items = queueFiltered.length;
  const pages = Math.ceil(items / pageSize);
  const from = pageSize * page;
  const to = Math.min(items, pageSize * page + pageSize);
  const rows = queueFiltered.slice(from, to);
  const canManageFileQueue = canPerformAction(user, availableOperations.CAN_MANAGE_FILE_QUEUE);

  useEffect(() => {
    applyFilter(freeFilterValue, statusFilterValue);
  }, [queue, dateOrder]);

  useEffect(() => {
    applyFilter(freeFilterValue, statusFilterValue);
  }, [includeFilterToggle]);

  useEffect(() => {
    if (refreshPeriod === 0) return;

    const intervalId = setInterval(() => {
      dispatch(fileQueueActions.forceGetFileQueue());
    }, refreshPeriod * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [refreshPeriod]);

  function handleChangePageSize(event) {
    const newPageSize = parseInt(event.target.value, 10);
    setPageSize(newPageSize);
  }

  function applyFilter(freeFilter, statusFilter) {
    setFreeFilterValue(freeFilter);
    setStatusFilterValue(statusFilter);
    
    let i = 0;
    // eslint-disable-next-line @typescript-eslint/no-array-constructor
    let filteredQueue = new Array();
    if ((freeFilter.split(' ').length > 1 || freeFilter !== '') && includeFilterToggle === false) {
      queue.forEach(element => {
        const free = filterFree(element, freeFilter);
        const status = filterStatus(element, statusFilter);
        if (!free && status) {
          filteredQueue[i] = element;
          i++;
        }
      });
    } else {
      queue.forEach(element => {
        const free = filterFree(element, freeFilter);
        const status = filterStatus(element, statusFilter);
        if (free && status) {
          filteredQueue[i] = element;
          i++;
        }
      });
    }

    filteredQueue.sort((a, b) => {
      if (dateOrder === FileQueueDateOrder.ASCENDING) {
        if (!a.enqueuedOn) {
          return -1;
        } else if (!b.enqueuedOn) {
          return 1;
        } else if (a.enqueuedOn < b.enqueuedOn) {
          return -1;
        } else if (a.enqueuedOn > b.enqueuedOn) {
          return 1;
        } else {
          return 0;
        }
      } else {
        if (!a.enqueuedOn) {
          return 1;
        } else if (!b.enqueuedOn) {
          return -1;
        } else if (a.enqueuedOn < b.enqueuedOn) {
          return 1;
        } else if (a.enqueuedOn > b.enqueuedOn) {
          return -1;
        } else {
          return 0;
        }
      }
    });
    setQueueFiltered(filteredQueue);
  }

  function filterFree(element, freeFilter) {
    if (includes(element.file, freeFilter)) {
      return true;
    }
    if (includes(element.queue, freeFilter)) {
      return true;
    }
    if (includes(element.outcome, freeFilter)) {
      return true;
    }
    const formattedDate = CustomDate.fromString(element.enqueuedOn).toDateTimeString(true);
    if (includes(formattedDate, freeFilter)) {
      return true;
    }
  }

  const includes = (param, text) => {
    return param != null && param.toUpperCase().includes(text);
  };

  function filterStatus(element, statusFilter) {
    if (!statusFilter || statusFilter === '') return true;
    if (element.status === statusFilter) return true;
    return false;
  }

  const countEntries = (status: string): number => {
    const elements = queue.filter(element => element.status === status);
    return elements ? elements.length : 0;
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'inline-block', width: '18em' }}>
            <InputGroup className="mb-3">
              <OverlayTrigger
                placement="bottom"
                delay={{ show: 0, hide: 0 }}
                overlay={<Tooltip id="tooltipFileQueue">{includeFilterToggle === true ? 'Including' : 'Excluding'}</Tooltip>}
              >
                <Button
                  size="sm"
                  onClick={() => {
                    if (includeFilterToggle === true) {
                      setIncludeFilterToggle(false);
                    } else {
                      setIncludeFilterToggle(true);
                    }
                  }}
                  variant="outline-primary"
                  style={{ marginRight: '1em' }}
                  id="toggleFileQueue"
                >
                  <FontAwesomeIcon icon={includeFilterToggle === true ? faEquals : faNotEqual} />
                </Button>
              </OverlayTrigger>
              <FormControl
                size="sm"
                aria-label="Default"
                onChange={event => {
                  setPage(0);
                  applyFilter(event.target.value.toUpperCase(), statusFilterValue);
                }}
                aria-describedby="inputGroup-sizing-default"
                id="search-job-text-field"
              />
              <InputGroup.Append>
                <InputGroup.Text className="basic-addon2" id="inputGroup-sizing-default">
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>

          <select
            className="selectBoxNum selectFilter"
            defaultValue="any"
            onChange={e => {
              setPage(0);
              applyFilter(freeFilterValue, e.target.value);
            }}
          >
            <option value="">Any status</option>
            <option value="ENQUEUED">ENQUEUED</option>
            <option value="SUBMITTED">SUBMITTED</option>
            <option value="PROCESSING">PROCESSING</option>
            <option value="DONE">DONE</option>
            <option value="ERROR">ERROR</option>
          </select>
        </div>
        {items > 0 ? (
          <div style={{ textAlign: 'center' }}>
            <div>
              Showing items {from + 1} - {to} of {items}
            </div>
            <small>
              <span style={{ color: 'var(--success)' }}>Done: {countEntries('DONE')}</span> -{' '}
              <span style={{ color: 'var(--danger)' }}>Error: {countEntries('ERROR')}</span> -{' '}
              <span style={{ color: 'var(--warning)' }}>Enqueued: {countEntries('ENQUEUED')}</span>
            </small>
          </div>
        ) : (
          <div>No items in queue</div>
        )}
        <div>
          Page size:
          <select className="selectBoxNum" value={pageSize} onChange={e => handleChangePageSize(e)}>
            <option value="12">12</option>
            <option value="30">30</option>
            <option value="50">50</option>
          </select>
        </div>
      </div>

      <div style={{ display: 'flex' }}>
        <ButtonGroup size="sm">
          <Button disabled={pending} onClick={() => dispatch(fileQueueActions.forceGetFileQueue())}>
            Refresh
          </Button>
          <DropdownButton
            size="sm"
            as={ButtonGroup}
            title={refreshPeriod === 0 ? 'off' : refreshPeriod + 's'}
            id="bg-nested-dropdown"
          >
            <Dropdown.Item eventKey="1" onClick={e => setRefreshPeriod(0)}>
              off
            </Dropdown.Item>
            <Dropdown.Item eventKey="2" onClick={e => setRefreshPeriod(5)}>
              5s
            </Dropdown.Item>
            <Dropdown.Item eventKey="3" onClick={e => setRefreshPeriod(10)}>
              10s
            </Dropdown.Item>
          </DropdownButton>
        </ButtonGroup>
      </div>

      <table className="queue-table">
        <thead>
          <tr>
            <th style={{ width: '25%' }}>File</th>
            <th style={{ width: '12%' }}>Queue</th>
            <th style={{ width: '15%' }}>
              <div
                onClick={() =>
                  dateOrder === FileQueueDateOrder.ASCENDING
                    ? setDateOrder(FileQueueDateOrder.DESCENDING)
                    : setDateOrder(FileQueueDateOrder.ASCENDING)
                }
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: '3rem', userSelect: 'none' }}
              >
                Enqueued on
                <FontAwesomeIcon
                  icon={dateOrder === FileQueueDateOrder.ASCENDING ? faChevronUp : faChevronDown}
                ></FontAwesomeIcon>
              </div>
            </th>
            <th style={{ width: '19%' }}>Updated on</th>
            <th style={{ width: '5%' }}>Status</th>
            <th style={{ width: '5%' }}>Attempts</th>
            <th style={{}}>Outcome</th>
            <th style={{ width: '5%' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.length > 0 ? (
            rows.map((item, idx) => (
              <FileQueueRow key={`${item.pk}_${item.queue}`} item={item} authenticated={canManageFileQueue} />
            ))
          ) : (
            <tr>
              <td style={{ textAlign: 'center' }} colSpan={6}>
                {freeFilterValue === '' && statusFilterValue === '' ? 'ALL DONE!' : 'NO MATCH!'}
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Pager
        pages={pages}
        currentPage={page + 1}
        onPrev={() => setPage(Math.max(0, page - 1))}
        onNext={() => setPage(Math.min(Math.max(0, pages - 1), page + 1))}
        setPage={newPage => () => setPage(newPage)}
      />
    </div>
  );
}
