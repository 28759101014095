import React, { useEffect } from 'react';
import { useAppDispatch } from 'hooks';
import { Button, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getFileQueue, getUser } from 'reducers/selectors';
import * as fileQueueActions from '../../actions/fileQueueActions';
import FileQueueTable from './table/FileQueueTable';
import { Link } from 'react-router-dom';
import { canPerformAction } from 'actions/userActions';
import { availableOperations } from 'reducers/userReducer';

function FileQueuePage() {
  const dispatch = useAppDispatch();
  const queue = useSelector(getFileQueue);
  const user = useSelector(getUser);
  const watchedFoldersAuth = canPerformAction(user, availableOperations.WATCHED_FOLDERS_VISIBLE);

  useEffect(() => {
    dispatch(fileQueueActions.getFileQueue());
  }, []);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <Button
          as={Link}
          to="/fileQueue/watchedFolders"
          size="sm"
          variant="primary"
          disabled={!watchedFoldersAuth}
          style={{ float: 'right', marginBottom: '1em' }}
        >
          Watched folders
        </Button>
        <h3>Scripts for File Queue</h3>
        <FileQueueTable queue={queue} user={user} />
      </Card.Body>
    </Card>
  );
}

export default FileQueuePage;
