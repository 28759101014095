/* eslint-disable @typescript-eslint/default-param-last */
import { combineReducers } from 'redux';
import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface Node {
  pk: number;
  aeTitle: string;
  port: number;
  enabled: boolean;
  protocol: 'DICOM' | 'WEB';
  qidoEndpoint: string;
  stowRsEndpoint: string;
  wadoEndpoint: string;
  forwardFavorite: boolean;
  notes: string;
}

type GetNodesFulfilled = {
  type: typeof actions.GET_NODES_FULFILLED;
  response: AxiosResponse<Node[]>;
};

function nodes(state: Node[] = [], action: GetNodesFulfilled) {
  switch (action.type) {
    case actions.GET_NODES_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export interface Encodings {
  EVRLE: string[];
  IVRLE: string[];
  JPEG_BASELINE: string[];
  JPEG_2000_LOSSLESS: string[];
  JPEG_LOSSLESS: string[];
  JPEG_LOSSLESS_57: string[];
  RLE_LOSSLESS: string[];
  MPEG4: string[];
}

type GetNodesEncodingsFulfilled = {
  type: typeof actions.GET_NODES_ENCODINGS_FULFILLED;
  response: AxiosResponse<EncodingState>;
};

export interface EncodingState {
  [key: string]: Encodings;
}

function encodings(state: EncodingState = {}, action: GetNodesEncodingsFulfilled): EncodingState {
  switch (action.type) {
    case actions.GET_NODES_ENCODINGS_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export interface ForwardRule {
  fromPK: number;
  toPk: number;
  from: string;
  to: string;
  temporalWindow: string;
  queue: string;
  morphingScript?: string;
  filterScript?: string;
}

type GetForwardRulesFulfilled = {
  type: typeof actions.GET_FORWARD_RULES_FULFILLED;
  response: AxiosResponse<ForwardRule[]>;
};

function forwardRules(state: ForwardRule[] = [], action: GetForwardRulesFulfilled): ForwardRule[] {
  switch (action.type) {
    case actions.GET_FORWARD_RULES_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export interface WorklistNode {
  pk: number;
  forwardMethod: 'UPS_RS' | 'DICOM';
  upsRsEndpoint: string;
  enabled: boolean;
  authHeader: string;
  displayName: string;
  dicomUrl: string;
  headers: Map<string,string>;
}

type GetWorklistNodesFulfilled = {
  type: typeof actions.GET_WORKLIST_NODES_FULFILLED;
  response: AxiosResponse<WorklistNode[]>;
};

function worklistNodes(
  state: WorklistNode[] = [],
  action: GetWorklistNodesFulfilled
): WorklistNode[] {
  switch (action.type) {
    case actions.GET_WORKLIST_NODES_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

export interface Condition {
  pk: number;
  sourceNodeFk: number;
  destinationNodeFk: number;
  property:
    | 'STUDY_DESCRIPTION'
    | 'MODALITIES_IN_STUDY'
    | 'ANY_SERIES_DESCRIPTION'
    | 'ALL_SERIES_DESCRIPTIONS'
    | 'ACCESSION_NUMBER'
    | 'PATIENT_ID'
    | 'ID_ISSUER';
  criteria: 'CONTAINS' | 'EQUALS' | 'REGEX';
  refValue: string;
  enabled: boolean;
  sourceNodeAet: string;
  destinationNodeAet: string;
}

type GetConditionsFulfilled = {
  type: typeof actions.GET_CONDITIONS_FULFILLED;
  response: AxiosResponse<Condition[]>;
};

function conditions(state: Condition[] = [], action: GetConditionsFulfilled) {
  switch (action.type) {
    case actions.GET_CONDITIONS_FULFILLED:
      return action.response.data;
    default:
      return state;
  }
}

const isTouchedNode = (state = false, action) => {
  switch (action.type) {
    case actions.IS_LAST_TOUCHED_NODE:
      return true;
    case actions.IS_LAST_TOUCHED_RULE:
      return false;
    default:
      return state;
  }
};

const isTouchedRule = (state = false, action) => {
  switch (action.type) {
    case actions.IS_LAST_TOUCHED_RULE:
      return true;
    case actions.IS_LAST_TOUCHED_NODE:
      return false;
    default:
      return state;
  }
};

// export default nodes;
export default combineReducers({
  nodes,
  encodings,
  forwardRules,
  worklistNodes,
  conditions,
  isTouchedNode,
  isTouchedRule,
});

export type NodesState = {
  nodes: ReturnType<typeof nodes>;
  encodings: ReturnType<typeof encodings>;
  forwardRules: ReturnType<typeof forwardRules>;
  worklistNodes: ReturnType<typeof worklistNodes>;
  conditions: ReturnType<typeof conditions>;
  isTouchedNode: boolean;
  isTouchedRule: boolean;
};
