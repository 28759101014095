import { forceGetAiModules, updateAiModule } from 'actions/aiModulesActions';
import useToaster from 'actions/useToaster';
import { useAppDispatch } from 'hooks';
import React, { useReducer, useState } from 'react';
import { Form, Button, FormGroup, Row, Col, FormControl, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getCfgServicesState } from 'reducers/selectors';
import RedirectionPopup from './RedirectionPopup';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function LunitInsightCXRConfiguration({ module }) {
  const cfgServices = useSelector(getCfgServicesState);
  const toaster = useToaster();
  const appDispatch = useAppDispatch();
  const [enabled, setEnabled] = useState(module.enabled);
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [configuration, dispatch] = useReducer(
    (state, action) => ({ ...state, [action.name]: action.value }),
    JSON.parse(module.configuration)
  );

  const viewBaseUrl = cfgServices.find(
    cfgService => cfgService.serviceName === 'VIEW' && cfgService.paramKey === 'BASE_URL'
  )?.paramValue;

  function save() {
    const updatedConfiguration = JSON.stringify(configuration);
    const updatedModule = { ...module, configuration: updatedConfiguration, enabled: enabled };
    updateAiModule(updatedModule)
      .catch(error => {
        toaster.error('ai-module not updated');
        return Promise.reject(error);
      })
      .then(response => appDispatch(forceGetAiModules()))
      .then(_ => toaster.success('ai-module updated'));
  }

  return (
    <React.Fragment>
      {confirmationPopUp && (
        <RedirectionPopup
          message="You will be redirected to the configuration page. Any unsaved changes will be lost. Are you sure?"
          path="/configurations"
          onClose={() => setConfirmationPopUp(false)}
        />
      )}
      <Form>
        <FormGroup as={Row}>
          <Col xs={6} md={5} lg={4} xl={3}>
            <Form.Label>Enabled</Form.Label>
          </Col>
          <Col>
            <Form.Check type="switch" id="enabled-switch" checked={enabled} onChange={e => setEnabled(!enabled)} />
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Form.Label column xs={6} md={5} lg={4} xl={3}>
            Lunit INSIGHT CXR API endpoint
          </Form.Label>
          <Col>
            <InputGroup>
              <FormControl
                type="string"
                disabled={false}
                value={configuration.apiEndpoint}
                onChange={e => dispatch({ name: 'apiEndpoint', value: e.target.value })}
              />
              <InputGroup.Append>
                <OverlayTrigger
                  trigger="hover"
                  placement="bottom"
                  overlay={
                    <Popover
                      id="lunit-endpoints-popover"
                      className="basic-popover"
                      style={{ maxWidth: '50em', color: '#C3C3C3' }}
                    >
                      Lunit CXR API endpoints: <br />
                      {configuration.apiEndpoint + '/cxr-v3/dcm/'}
                      <br />
                      {configuration.apiEndpoint + '/cxr-v3/models/latest/predict/'}
                      <br />
                      {configuration.apiEndpoint + '/cxr-v3/predictions/:uuid/score/'}
                      <br />
                      {configuration.apiEndpoint + '/cxr-v3/predictions/:uuid/v2/contour/?merge=false'}
                      <br />
                    </Popover>
                  }
                >
                  <InputGroup.Text
                    className="basic-addon2"
                    id="inputGroup-sizing-default"
                    style={{
                      maxWidth: '50em',
                      cursor: 'pointer',
                      backgroundColor: 'var(--gray-800)',
                      borderColor: 'var(--gray-900)',
                      color: 'var(--gray-200)',
                    }}
                  >
                    <FontAwesomeIcon icon={faInfo} />
                  </InputGroup.Text>
                </OverlayTrigger>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </FormGroup>

        <FormGroup as={Row}>
          <Form.Label column xs={6} md={5} lg={4} xl={3}>
            Lunit API key
          </Form.Label>
          <Col>
            <FormControl
              type="password"
              value={configuration.apiKey}
              onChange={e => dispatch({ name: 'apiKey', value: e.target.value })}
            />
          </Col>
        </FormGroup>
      </Form>

      <FormGroup as={Row}>
        <Form.Label column xs={6} md={5} lg={4} xl={3}>
          Viewer Base URL | Auth header
        </Form.Label>
        <Col>
          <InputGroup>
            <FormControl type="string" disabled={true} value={viewBaseUrl} />
            <FormControl type="password" disabled={true} value="xxxxxxxxxxxxxxxxxxxxxxxx" />
            <InputGroup.Append>
              <InputGroup.Text
                style={{
                  cursor: 'pointer',
                  backgroundColor: 'var(--gray-800)',
                  borderColor: 'var(--gray-900)',
                  color: 'var(--gray-200)',
                }}
                onClick={e => setConfirmationPopUp(true)}
              >
                configure
              </InputGroup.Text>
            </InputGroup.Append>

            <InputGroup.Append>
              <OverlayTrigger
                trigger="hover"
                placement="bottom"
                overlay={
                  <Popover
                    id="lunit-endpoints-popover"
                    className="basic-popover"
                    style={{ maxWidth: '50em', color: '#C3C3C3' }}
                  >
                    Viewer API endpoints: <br />
                    {viewBaseUrl + '/webdv/addmlfindings'}
                    <br />
                    {viewBaseUrl + '/webdv/GraphicAnnotationServlet'}
                    <br />
                  </Popover>
                }
              >
                <InputGroup.Text
                  className="basic-addon2"
                  id="inputGroup-sizing-default"
                  style={{
                    cursor: 'pointer',
                    backgroundColor: 'var(--gray-800)',
                    borderColor: 'var(--gray-900)',
                    color: 'var(--gray-200)',
                  }}
                >
                  <FontAwesomeIcon icon={faInfo} />
                </InputGroup.Text>
              </OverlayTrigger>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </FormGroup>

      <Form style={{ marginTop: '2rem' }}>
        <FormGroup>
          <Form.Label>Study-level filter</Form.Label>
          <FormControl
            rows={4}
            as="textarea"
            size="sm"
            value={configuration.studyFilter}
            onChange={e => dispatch({ name: 'studyFilter', value: e.target.value })}
          />
        </FormGroup>

        <FormGroup>
          <Form.Label>Frontal Chest X-ray filter</Form.Label>
          <FormControl
            rows={4}
            as="textarea"
            size="sm"
            value={configuration.imageFilter}
            onChange={e => dispatch({ name: 'imageFilter', value: e.target.value })}
          />
        </FormGroup>
      </Form>

      <div style={{ textAlign: 'right' }}>
        <br />
        <Button style={{ width: '8em', marginRight: '1em' }} variant="outline-secondary" as={Link} to="/aiModules">
          Cancel
        </Button>
        <Button style={{ width: '8em' }} variant="primary" onClick={e => save()}>
          Save
        </Button>
      </div>
    </React.Fragment>
  );
}

export default LunitInsightCXRConfiguration;
