// possible item statuses
export const ENQUEUED = 'ENQUEUED';
export const POSTPONED = 'POSTPONED';
export const ERROR = 'ERROR';
export const SENDING = 'SENDING';
export const DONE = 'DONE';
export const PAUSED = 'PAUSED';
export const FILTERED = 'FILTERED';
// additional statuses in AiQueue
export const PROCESSING = 'PROCESSING';
// additional statuses in FileQueue
export const SUBMITTED = 'SUBMITTED';

// special queue name
export const URGENT = 'URGENT';

// special proxy aetitle
export const PROXY_AETITLE = 'SYS_PR0XY';
