import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faWrench, faTrash } from '@fortawesome/free-solid-svg-icons';
import { canPerformAction } from 'actions/userActions';
import { availableOperations } from 'reducers/userReducer';
import WorklistNodeForm from './WorklistNodeForm';
import * as nodesActions from '../../actions/nodesActions';
import { nodesSelectors, getUser } from '../../reducers/selectors';
import useToaster from '../../actions/useToaster';
import { WorklistNode } from '../../reducers/nodesReducer';
import { useAppDispatch } from 'hooks';

function WorklistPage() {
  const toaster = useToaster();
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const nodes = useSelector(nodesSelectors.getWorklistNodes);
  const [editingNode, setEditingNode] = useState<WorklistNode | null>(null);
  const [creatingNode, setCreatingNode] = useState(false);
  const [deletingNode, setDeletingNode] = useState<WorklistNode | null>(null);
  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_WORKLIST_NODES);

  useEffect(() => {
    dispatch(nodesActions.getWorklistNodes());
  }, []);

  return (
    <div>
      <Card className="page-panel-common">
        <Card.Body>
          <h3>Worklist</h3>
          <div>
            <span>Worklist queries will be forwarded to the following nodes:</span>
            <Button
              disabled={!authorized}
              size="sm"
              style={{ marginLeft: '2em' }}
              variant="primary"
              onClick={e => setCreatingNode(true)}
            >
              Add new node <FontAwesomeIcon icon={faPlusCircle} style={{ marginLeft: '5px' }} />
            </Button>
          </div>

          <div style={{ marginTop: '3em' }}>
            <strong>Worklist nodes</strong>
          </div>
          <table className="queue-table" style={{ fontSize: '12px', maxWidth: '70em' }}>
            <thead>
              <tr>
                <th style={{ width: '13%' }} />
                <th style={{ width: '13%' }} />
                <th />
                <th style={{ width: '13%' }} />
                <th style={{ width: '13%' }} />
              </tr>
            </thead>
            <tbody>
              {nodes.map((node, index) => (
                <tr key={index} className="node-row" style={{ cursor: 'pointer' }}>
                  <td>
                    <b>{node.displayName}</b>
                  </td>
                  <td>{node.forwardMethod}</td>
                  {'DICOM' === node.forwardMethod && <td title={node.dicomUrl}>{node.dicomUrl}</td>}
                  {'UPS_RS' === node.forwardMethod && <td title={node.upsRsEndpoint}>{node.upsRsEndpoint}</td>}
                  <td>{node.enabled ? 'enabled' : '-'}</td>
                  <td>
                    <div style={{ width: '100%', textAlign: 'right', overflow: 'visible' }}>
                      {authorized && (
                        <FontAwesomeIcon icon={faWrench} className="clickable-icon" onClick={e => setEditingNode(node)} />
                      )}
                      {authorized && (
                        <FontAwesomeIcon icon={faTrash} className="clickable-icon" onClick={e => setDeletingNode(node)} />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Card.Body>
      </Card>

      {editingNode && (
        <EditWorklistNodePopup
          node={editingNode}
          onClose={e => setEditingNode(null)}
          onSave={node => {
            console.log('updating worklist node', node);
            nodesActions
              .updateWorklistNode(node)
              .then(() => {
                dispatch(nodesActions.forceGetWorklistNodes());
                setEditingNode(null);
                toaster.success('Worklist node updated');
              })
              .catch(error => {
                console.log('error updating worklist node', error);
                toaster.error('Error updating worklist node');
              });
          }}
        />
      )}

      {creatingNode && (
        <InsertWorklistNodePopup
          onClose={e => setCreatingNode(false)}
          onSave={node => {
            console.log('creating worklist node', node);
            nodesActions
              .insertWorklistNode(node)
              .then(() => {
                dispatch(nodesActions.forceGetWorklistNodes());
                setCreatingNode(false);
                toaster.success('Worklist node created');
              })
              .catch(error => {
                console.log('error adding worklist node', error);
                toaster.error('Error adding worklist node');
              });
          }}
        />
      )}

      {deletingNode && (
        <DeleteWorklistNodePopup
          node={deletingNode}
          onClose={e => setDeletingNode(null)}
          onConfirm={e => {
            if (deletingNode) {
              nodesActions
                .deleteWorklistNode(deletingNode)
                .then(() => {
                  dispatch(nodesActions.forceGetWorklistNodes());
                  setDeletingNode(null);
                  toaster.error('Worklist node deleted');
                })
                .catch(error => {
                  console.log('error deleting worklist node', error);
                  toaster.error('Error deleting worklist node');
                });
            }
          }}
        />
      )}
    </div>
  );
}

const InsertWorklistNodePopup = ({ onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>New Worklist node</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <WorklistNodeForm
        node={{ forwardMethod: 'UPS_RS', enabled: true }}
        onClose={onClose}
        onSave={onSave}
        saveButtonLabel="Add"
      />
    </Modal.Body>
  </Modal>
);

const EditWorklistNodePopup = ({ node, onClose, onSave }) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>Editing Worklist node {node.displayName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <WorklistNodeForm
        node={node}
        onClose={onClose}
        onSave={onSave}
        saveButtonLabel="Save changes"
      />
    </Modal.Body>
  </Modal>
);

const DeleteWorklistNodePopup = ({ node, onClose, onConfirm }) => (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Deleting Worklist node {node.displayName}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        You are going to delete the Worklist node <strong>{node.displayName}</strong>
      </p>
      <p>Are you sure?</p>
      <div style={{ textAlign: 'right', marginTop: '1em' }}>
        <br />
        <Button variant="outline-secondary" onClick={onClose} style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Confirm delete
        </Button>
      </div>
    </Modal.Body>
  </Modal>
);

export default WorklistPage;
