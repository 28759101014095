import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import * as printerActions from '../../actions/printerActions';
import * as readerActions from '../../actions/readerActions';
import { useAppDispatch } from 'hooks';

const DeleteModal = ({ element }) => {
  const dispatch = useAppDispatch();
  const toaster = {
    success: message => dispatch({ type: 'SUCCESS', message }),
    info: message => dispatch({ type: 'INFO', message }),
    warning: message => dispatch({ type: 'WARNING', message }),
    error: message => dispatch({ type: 'ERROR', message }),
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const deleteType: string = element.jobsFolderPath ? 'printer' : 'reader';

  const handleSubmit = () => {
    if (deleteType === 'printer') {
      axios
        .delete(`/rest/react/deleteCdBurner`, {
          data: {
            name: element.name,
          },
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(res => {
          dispatch(printerActions.getPrinters());
          toaster.success(`Succesfully deleted printer`);
        })
        .catch(e => toaster.error(e.message));
    }
    if (deleteType === 'reader') {
      axios
        .delete(`/rest/react/deleteCdReader`, {
          data: {
            pk: element.pk,
          },
          headers: {
            'Content-type': 'application/json',
          },
        })
        .then(res => {
          dispatch(readerActions.getReaders());
          toaster.success(`Succesfully deleted reader`);
        })
        .catch(e => toaster.error(e.message));
    }
    handleClose();
  };

  return (
    <React.Fragment>
      <FontAwesomeIcon className="printer-table-actions" icon={faTrash} onClick={handleShow} />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            Are you sure you want to delete the {deleteType} named{' '}
            {deleteType === 'printer' ? element.name : element.displayName} ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleSubmit}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default DeleteModal;
