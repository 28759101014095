import React, { useEffect } from 'react';

const JSDButton = ({ isVisible }) => {
  const jsd = document.getElementById('jsd-widget');

  useEffect(() => {
    if (jsd !== null) {
      if (!isVisible) {
        jsd.style.visibility = 'hidden';
      } else {
        jsd.style.visibility = '';
      }
    }
  }, [isVisible]);

  return <div />;
};

export default JSDButton;
