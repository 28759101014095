import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch } from 'hooks';
import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSeriesDisposal } from 'reducers/selectors';
import * as actions from '../../../actions/actionCatalog';

type SeriesDisposalStudyInQueuePopupType = {
  onDispose: () => void;
};

const SeriesDisposalStudyInQueuePopup = ({ onDispose }: SeriesDisposalStudyInQueuePopupType) => {
  const dispatch = useAppDispatch();
  const seriesDisposal = useSelector(getSeriesDisposal);

  return (
    <Modal show={seriesDisposal.popups.showStudyInQueuePopup} size="lg">
      <Modal.Header>
        <Modal.Title>Deleting series</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          The parent study is currently in queue. Are you sure you want to delete the selected
          series?
        </p>
        <p>
          <b>This could lead to images loss so proceed with caution.</b>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <React.Fragment>
          <Button
            variant="outline-secondary"
            onClick={() => dispatch({ type: actions.SERIES_DISPOSAL_ABORT })}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={onDispose}>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ color: 'var(--warning)', paddingRight: '0.3em' }}
            />
            Dispose anyway
          </Button>
        </React.Fragment>
      </Modal.Footer>
    </Modal>
  );
};

export default SeriesDisposalStudyInQueuePopup;
