import * as actions from '../actions/actionCatalog';

export interface User {
  username?: string;
  enabled?: boolean;
  role?: Role;
  authenticated: boolean;
}

export type Role = {
  pk?: number;
  name?: string;
  privileged?: boolean;
  userActions?: UserAction[];
};

export type UserAction = {
  pk?: number;
  actionScope?: string;
  actionName?: string;
};

type UserActionType = {
  type: typeof actions.SET_USER;
  payload: User;
};

export const availableOperations = {
  DASHBOARD_VISIBLE: 'DASHBOARD_VISIBLE',
  QUEUE_VISIBLE: 'QUEUE_VISIBLE',
  CAN_MODIFY_QUEUE: 'CAN_MODIFY_QUEUE',
  DICOM_BROWSER_VISIBLE: 'DICOM_BROWSER_VISIBLE',
  CAN_DELETE_SERIES: 'CAN_DELETE_SERIES',
  CAN_FORWARD_STUDY: 'CAN_FORWARD_STUDY',
  NODES_VISIBLE: 'NODES_VISIBLE',
  CAN_MANAGE_NODES: 'CAN_MANAGE_NODES',
  WORKLIST_VISIBLE: 'WORKLIST_VISIBLE',
  CAN_MANAGE_WORKLIST_NODES: 'CAN_MANAGE_WORKLIST_NODES',
  PREFETCH_VISIBLE: 'PREFETCH_VISIBLE',
  CAN_MODIFY_PREFETCH_CONFIGURATIONS: 'CAN_MODIFY_PREFETCH_CONFIGURATIONS',
  SCRIPT_CATALOG_VISIBLE: 'SCRIPT_CATALOG_VISIBLE',
  FILE_QUEUE_VISIBLE: 'FILE_QUEUE_VISIBLE',
  CAN_MANAGE_SCRIPT: 'CAN_MANAGE_SCRIPT',
  DELETION_RULES_VISIBLE: 'DELETION_RULES_VISIBLE',
  AI_MODULES_VISIBLE: 'AI_MODULES_VISIBLE',
  CAN_MANAGE_DELETION_RULES: 'CAN_MANAGE_DELETION_RULES',
  CAN_MANAGE_AI_MODULES: 'CAN_MANAGE_AI_MODULES',
  CAN_MANAGE_FILE_QUEUE: 'CAN_MANAGE_FILE_QUEUE',
  LOGS_VISIBLE: 'LOGS_VISIBLE',
  CONFIGURATIONS_VISIBLE: 'CONFIGURATIONS_VISIBLE',
  PRINTER_CONFIGURATIONS_VISIBLE: 'PRINTER_CONFIGURATIONS_VISIBLE',
  PRINTERS_VISIBLE: 'PRINTERS_VISIBLE',
  CAN_PRINT: 'CAN_PRINT',
  USERS_VISIBLE: 'USERS_VISIBLE',
  CAN_MANAGE_USERS: 'CAN_MANAGE_USERS',
  CAN_CHANGE_CONFIGURATIONS: 'CAN_CHANGE_CONFIGURATIONS',
  JSD_BUTTON_AVAILABLE: 'JSD_BUTTON_AVAILABLE',
  PAYMENTS_DEVICES_VISIBILE: 'PAYMENTS_DEVICES_VISIBILE',
  CAN_MANAGE_PAYMENT_DEVICES: 'CAN_MANAGE_PAYMENT_DEVICES',
  WATCHED_FOLDERS_VISIBLE: 'WATCHED_FOLDERS_VISIBLE',
  CAN_MANAGE_WATCHED_FOLDERS: 'CAN_MANAGE_WATCHED_FOLDERS',
};

function user(state: User = { authenticated: false }, action: UserActionType): User {
  switch (action.type) {
    case actions.SET_USER:
      return action.payload;
    default:
      return state;
  }
}

export default user;
