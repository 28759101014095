import { AiModule } from 'reducers/aiModulesReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetAiModules = (dispatch: Dispatch) => http.get<AiModule[]>(actions.GET_AI_MODULES, dispatch);
const debouncedGetAiModules = defaultDebounce(regularGetAiModules);

export const getAiModules = () => debouncedGetAiModules;
export const forceGetAiModules = () => regularGetAiModules;

export function updateAiModule(module) {
  return http.post<AiModule[]>(actions.UPDATE_AI_MODULE, module, undefined);
}
