import React, { useState } from 'react';
import { Button, Card, Col, Form, FormLabel } from 'react-bootstrap';
import { encrypt } from '../../actions/userActions';
import * as http from '../../http';

export default function SetupPage({ configuration }) {
  const [aeTitle, setAeTitle] = useState(configuration.feedAet);
  const [storageFolder, setStorageFolder] = useState(configuration.storageFolder);
  const [tempFolder, setTempFolder] = useState(configuration.tempFolder);
  const [disposalFolder, setDisposalFolder] = useState(configuration.disposalFolder);
  const [dicomPort, setDicomPort] = useState(configuration.dicomPort);
  const [worklistPort, setWorklistPort] = useState(configuration.worklistPort);
  const [httpPort, setHttpPort] = useState(configuration.httpPort);
  const [username, setUsername] = useState('Administrator');
  const [password, setPassword] = useState('');
  const [monitoringUrl, setMonitoringUrl] = useState(
    'https://console.zeeromed.it/api/v1/inputs/feed_statuses.json?station=CONSOLE_FEED_NAME'
  );
  const [setupCloudServer, setSetupCloudServer] = useState(!configuration.cloudServerAlreadyPresent);
  const [cloudServer, setCloudServer] = useState('gcloud.zeeromed.it');
  const [enableRedis, setEnableRedis] = useState<boolean>(false);
  const [redisUrl, setRedisUrl] = useState<string>(configuration.redisUrl);
  const [redisTenant, setRedisTenant] = useState<string>(configuration.redisTenant);
  const [gloryEnabled, setGloryEnabled] = useState<boolean>(configuration.gloryEnabled);
  const [inputStreamName, setInputStreamName] = useState<string>(configuration.inputStreamName);
  const [outputStreamName, setOutputStreamName] = useState<string>(configuration.outputStreamName);
  const [feedEndpoint, setFeedEndpoint] = useState<string>(configuration.feedEndpoint);
  const [hl7ForwarderEnabled, setHl7ForwarderEnabled] = useState<boolean>(configuration.hl7ForwarderEnabled);
  const [hl7InputStreamName, setHl7InputStreamName] = useState<string>(configuration.hl7InputStreamName);
  const [destination, setDestination] = useState<string>(configuration.destination);
  const [scriptRedisEnabled, setScriptRedisEnabled] = useState<boolean>(configuration.scriptRedisEnabled);
  const [scriptInputStreamName, setScriptInputStreamName] = useState<string>(configuration.scriptInputStreamName);
  const [scriptOutputStreamName, setScriptOutputStreamName] = useState<string>(configuration.scriptOutputStreamName);

  const { cloudServerAlreadyPresent } = configuration;

  const labelsWidth = 5;
  const inputsWidth = 9;

  const stowRsUrl = `https://${cloudServer}/o3-dpacs-wado/stowRsListener/${aeTitle.split(',')[0]}/studies`;
  const qidoUrl = `https://${cloudServer}/o3-dpacs-wado/qido/${aeTitle.split(',')[0]}`;
  const wadoUrl = `https://${cloudServer}/o3-dpacs-wado/wado`;
  const upsRsUrl = `https://${cloudServer}/o3-dpacs-wado/workitems`;

  function save() {
    const data = {
      aeTitle,
      storageFolder,
      tempFolder,
      disposalFolder,
      setupCloudServer,
      cloudServer,
      dicomPort,
      worklistPort,
      httpPort,
      stowRsUrl,
      qidoUrl,
      wadoUrl,
      upsRsUrl,
      monitoringUrl,
      redisUrl,
      redisTenant,
      gloryEnabled,
      inputStreamName,
      outputStreamName,
      feedEndpoint,
      hl7ForwarderEnabled,
      hl7InputStreamName,
      destination,
      scriptRedisEnabled,
      scriptInputStreamName,
      scriptOutputStreamName,
      username,
      password,
    };

    http.post<string>(`/rest/react/setupConfiguration`, data).then(res => {
      if (res === 'OK') {
        // reload the page and keep any query param
        // eslint-disable-next-line no-self-assign
        window.location.href = window.location.href;
      }
    });
  }

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <h3>Feed setup</h3>

        {/* <Form horizontal="true" style={{ maxWidth: '90rem' }}> */}
        <Form style={{ maxWidth: '90rem' }}>
          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              FEED AET (accepts multiple values separated by “,”)
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control type="text" name="aeTitle" value={aeTitle} onChange={e => setAeTitle(e.target.value)} />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Storage folder
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control
                type="text"
                name="storageFolder"
                value={storageFolder}
                onChange={e => setStorageFolder(e.target.value)}
              />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Temp folder
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control type="text" name="tempFolder" value={tempFolder} onChange={e => setTempFolder(e.target.value)} />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Disposal folder
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control
                type="text"
                name="disposalFolder"
                value={disposalFolder}
                onChange={e => setDisposalFolder(e.target.value)}
              />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Dicom port
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control type="text" name="dicomPort" value={dicomPort} onChange={e => setDicomPort(e.target.value)} />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Worklist port
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control
                type="text"
                name="worklistPort"
                value={worklistPort}
                onChange={e => setWorklistPort(e.target.value)}
              />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              HTTP port
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control type="text" name="httpPort" value={httpPort} onChange={e => setHttpPort(e.target.value)} />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Privileged username
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control type="text" name="adminUsername" onChange={e => setUsername(e.target.value)} />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Privileged user password
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control type="password" name="adminPassword" onChange={e => setPassword(encrypt(e.target.value))} />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          <Form.Group>
            <Col as={FormLabel} sm={labelsWidth}>
              Console url (also known as monitoring url)
            </Col>
            <Col sm={inputsWidth}>
              <Form.Control
                type="text"
                name="monitoringUrl"
                value={monitoringUrl}
                onChange={e => setMonitoringUrl(e.target.value)}
              />
              <Form.Control.Feedback />
            </Col>
          </Form.Group>

          {!cloudServerAlreadyPresent && (
            <Form.Group style={{ paddingTop: '1rem' }}>
              <Col sm={inputsWidth}>
                <Form.Check
                  inline
                  disabled={cloudServerAlreadyPresent}
                  checked={setupCloudServer && !cloudServerAlreadyPresent}
                  onChange={e => setSetupCloudServer(e.target.checked)}
                  label="Setup a cloud server node (O3-DPACS)"
                />
              </Col>
            </Form.Group>
          )}

          {setupCloudServer && !cloudServerAlreadyPresent && (
            <Form.Group>
              <Col as={FormLabel} sm={labelsWidth}>
                Cloud server
              </Col>
              <Col sm={inputsWidth}>
                <Form.Control
                  type="text"
                  name="cloudServer"
                  value={cloudServer}
                  onChange={e => setCloudServer(e.target.value)}
                  disabled={!setupCloudServer}
                />
                <Form.Control.Feedback />

                <ul style={{ paddingLeft: '0.5rem' }}>
                  <ListItem label="STOW-RS" value={stowRsUrl} />
                  <ListItem label="QIDO" value={qidoUrl} />
                  <ListItem label="WADO" value={wadoUrl} />
                  <ListItem label="UPS-RS" value={upsRsUrl} />
                </ul>
              </Col>
            </Form.Group>
          )}

          <Form.Group style={{ paddingTop: '0.5rem' }}>
            <Col sm={inputsWidth}>
              <Form.Check
                inline
                type="checkbox"
                id="enable_redis"
                checked={enableRedis}
                onChange={() => setEnableRedis(!enableRedis)}
                label="Enable redis integration"
              />
            </Col>
          </Form.Group>

          {enableRedis && (
            <React.Fragment>
              <Form.Group>
                <Col as={FormLabel} sm={labelsWidth}>
                  Redis url
                </Col>
                <Col sm={inputsWidth}>
                  <Form.Control type="text" name="redisurl" value={redisUrl} onChange={e => setRedisUrl(e.target.value)} />
                  <Form.Control.Feedback />
                </Col>
              </Form.Group>

              <Form.Group>
                <Col as={FormLabel} sm={labelsWidth}>
                  Redis tenant (used as consumer group)
                </Col>
                <Col sm={inputsWidth}>
                  <Form.Control
                    type="text"
                    name="redistenant"
                    value={redisTenant}
                    onChange={e => setRedisTenant(e.target.value)}
                  />
                  <Form.Control.Feedback />
                </Col>
              </Form.Group>

              <Form.Group style={{ paddingTop: '0.5rem' }}>
                <Col sm={inputsWidth}>
                  <Form.Check
                    inline
                    type="checkbox"
                    id="enable_glory"
                    label="Enable glory cash machines"
                    checked={gloryEnabled}
                    onChange={() => setGloryEnabled(!gloryEnabled)}
                  />
                </Col>
              </Form.Group>

              {gloryEnabled && (
                <React.Fragment>
                  <Form.Group>
                    <Col as={FormLabel} sm={labelsWidth}>
                      Glory redis input stream name
                    </Col>
                    <Col sm={inputsWidth}>
                      <Form.Control
                        type="text"
                        name="glory_stream_name"
                        disabled={!gloryEnabled}
                        value={inputStreamName}
                        onChange={e => setInputStreamName(e.target.value)}
                      />
                      <Form.Control.Feedback />
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Col as={FormLabel} sm={labelsWidth}>
                      Glory redis output stream name
                    </Col>
                    <Col sm={inputsWidth}>
                      <Form.Control
                        type="text"
                        name="glory_stream_name"
                        disabled={!gloryEnabled}
                        value={outputStreamName}
                        onChange={e => setOutputStreamName(e.target.value)}
                      />
                      <Form.Control.Feedback />
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Col as={FormLabel} sm={4}>
                      Feed endpoint for glory events (remember the /glory)
                    </Col>
                    <Col sm={inputsWidth}>
                      <Form.Control
                        type="text"
                        name="glory_stream_name"
                        disabled={!gloryEnabled}
                        value={feedEndpoint}
                        onChange={e => setFeedEndpoint(e.target.value)}
                      />
                      <Form.Control.Feedback />
                    </Col>
                  </Form.Group>
                </React.Fragment>
              )}

              <Form.Group style={{ paddingTop: '0.5rem' }}>
                <Col sm={inputsWidth}>
                  <Form.Check
                    inline
                    type="checkbox"
                    id="enable_hl7_forwarder"
                    label="Enable hl7 forwarder"
                    checked={hl7ForwarderEnabled}
                    onChange={() => setHl7ForwarderEnabled(!hl7ForwarderEnabled)}
                  />
                </Col>
              </Form.Group>

              {hl7ForwarderEnabled && (
                <React.Fragment>
                  <Form.Group>
                    <Col as={FormLabel} sm={labelsWidth}>
                      Hl7 forwarder redis input stream name
                    </Col>
                    <Col sm={inputsWidth}>
                      <Form.Control
                        type="text"
                        name="hl7_forwarder_stream_name"
                        disabled={!hl7ForwarderEnabled}
                        value={hl7InputStreamName}
                        onChange={e => setHl7InputStreamName(e.target.value)}
                      />
                      <Form.Control.Feedback />
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Col as={FormLabel} sm={4}>
                      Mirth endpoint to send the message
                    </Col>
                    <Col sm={inputsWidth}>
                      <Form.Control
                        type="text"
                        name="hl7_forwarder_stream_name"
                        disabled={!hl7ForwarderEnabled}
                        value={destination}
                        onChange={e => setDestination(e.target.value)}
                      />
                      <Form.Control.Feedback />
                    </Col>
                  </Form.Group>
                </React.Fragment>
              )}

              <Form.Group style={{ paddingTop: '0.5rem' }}>
                <Col sm={inputsWidth}>
                  <Form.Check
                    inline
                    type="checkbox"
                    id="enable_script_redis"
                    label="Enable script redis"
                    checked={scriptRedisEnabled}
                    onChange={() => setScriptRedisEnabled(!scriptRedisEnabled)}
                  />
                </Col>
              </Form.Group>

              {scriptRedisEnabled && (
                <React.Fragment>
                  <Form.Group>
                    <Col as={FormLabel} sm={labelsWidth}>
                      Script redis input stream name
                    </Col>
                    <Col sm={inputsWidth}>
                      <Form.Control
                        type="text"
                        name="script_redis_stream_name"
                        disabled={!scriptRedisEnabled}
                        value={scriptInputStreamName}
                        onChange={e => setScriptInputStreamName(e.target.value)}
                      />
                      <Form.Control.Feedback />
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Col as={FormLabel} sm={4}>
                      Script redis output stream name
                    </Col>
                    <Col sm={inputsWidth}>
                      <Form.Control
                        type="text"
                        name="script_redis_stream_name"
                        disabled={!scriptRedisEnabled}
                        value={scriptOutputStreamName}
                        onChange={e => setScriptOutputStreamName(e.target.value)}
                      />
                      <Form.Control.Feedback />
                    </Col>
                  </Form.Group>
                </React.Fragment>
              )}
            </React.Fragment>
          )}

          <Form.Group>
            <Col sm={inputsWidth}>
              <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '2rem' }}>
                <Button variant="primary" onClick={save} disabled={password.length === 0}>
                  Save
                </Button>
                {password.length === 0 && (
                  <div style={{ marginTop: '1rem', marginLeft: '1rem', color: 'red' }}>
                    You need to set an admin password first!
                  </div>
                )}
              </div>
              <div style={{ marginTop: '1rem', fontSize: '0.8em' }}>*Restart the system to apply changes</div>
            </Col>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
}

function ListItem({ label, value }) {
  return (
    <li
      style={{
        whiteSpace: 'nowrap',
        listStylePosition: 'inside',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      }}
      title={value}
    >
      {label}: {value}
    </li>
  );
}
