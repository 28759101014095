import { WatchedFolder } from 'reducers/watchedFoldersReducer';
import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import defaultDebounce from './debouncer';
import * as http from '../http';

const regularGetWatchedFolders = (dispatch: Dispatch) => http.get<WatchedFolder[]>(actions.WATCHED_FOLDERS, dispatch);
const debouncedGetWatchedFolders = defaultDebounce(regularGetWatchedFolders);
export const getWatchedFolders = () => debouncedGetWatchedFolders;
export const forceGetWatchedFolders = () => regularGetWatchedFolders;

export const insertFolder = (folder: WatchedFolder) => {
  return http.post<void>(actions.WATCHED_FOLDERS, folder);
};

export const updateFolder = (folder: WatchedFolder) => {
  return http.put<void>(actions.WATCHED_FOLDERS, folder);
};

export const deleteFolder = (folder: WatchedFolder) => {
  return http.del<void>(`${actions.WATCHED_FOLDERS.path}/${folder.pk}`);
};
