import React from 'react';
import { Pagination } from 'react-bootstrap';
import TableJumper from './TableJumper';

const Pager = ({ pages, currentPage, onPrev, onNext, setPage }) => {
  if (pages < 2) return null;
  const paginationItems = handlePages(pages, currentPage, setPage);
  return (
    <div style={{ textAlign: 'center' }}>
      <Pagination>
        {pages >= 5 && <Pagination.First onClick={setPage(0)} />}
        <Pagination.Prev onClick={onPrev} />
        {paginationItems}
        <Pagination.Next onClick={onNext} />
        {pages >= 5 && <Pagination.Last onClick={setPage(pages - 1)} />}
      </Pagination>
    </div>
  );
};

const handlePages = (
  pages: number,
  currentPage: number,
  setPage: (arg: number) => () => React.Dispatch<React.SetStateAction<number>>
) => {
  const paginationItems: any[] = [];
  if (pages < 5) {
    for (let pageIndex = 1; pageIndex <= pages; pageIndex += 1) {
      paginationItems.push(
        <Pagination.Item key={pageIndex} active={pageIndex === currentPage} onClick={setPage(pageIndex - 1)}>
          {pageIndex}
        </Pagination.Item>
      );
    }
  } else {
    if (currentPage > 2)
      paginationItems.push(
        <TableJumper
          pages={pages}
          setPage={setPage}
          handle={() => handlePages(pages, currentPage, setPage)}
          ellipsisKey="el1"
        />
      );
    else {
      for (let i = 1; i < 4; i += 1) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={setPage(i - 1)}>
            {i}
          </Pagination.Item>
        );
      }
    }
    if (currentPage > 2 && currentPage < pages - 1) {
      for (let i = 0; i < 3; i += 1) {
        const page = currentPage + i - 1;
        paginationItems.push(
          <Pagination.Item key={page} active={page === currentPage} onClick={setPage(page - 1)}>
            {page}
          </Pagination.Item>
        );
      }
    }
    if (currentPage < pages - 1)
      paginationItems.push(
        <TableJumper
          pages={pages}
          setPage={setPage}
          handle={() => handlePages(pages, currentPage, setPage)}
          ellipsisKey="el2"
        />
      );
    else {
      for (let i = pages - 2; i <= pages; i += 1) {
        paginationItems.push(
          <Pagination.Item key={i} active={i === currentPage} onClick={setPage(i - 1)}>
            {i}
          </Pagination.Item>
        );
      }
    }
  }

  return paginationItems;
};

export default Pager;
