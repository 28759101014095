export interface Reader {
  pk: number;
  displayName: string;
  displayOrder: number;
  folderPath: string;
  decompressImages: boolean;
  enabled: boolean;
  size: string;
}

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function readers(state: Reader[] = [], action: any): Reader[] {
  switch (action.type) {
    case 'GET_READERS_FULFILLED':
      return action.response.data;
    default:
      return state;
  }
}

export type ReadersState = {
  readers: ReturnType<typeof readers>;
};
