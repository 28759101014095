import { combineReducers } from 'redux';
import aiModules from './aiModulesReducer';
import aiQueue from './aiQueueReducer';
import components, { ComponentsState } from './componentsReducer';
import configurations, { ConfigurationsState } from './configurationsReducer';
import deletionRules from './deletionRulesReducer';
import feedStatus from './feedStatusReducer';
import fileQueue from './fileQueueReducer';
import gloryMachines from './gloryMachinesReducer';
import importInfo from './importReducer';
import nodes, { NodesState } from './nodesReducer';
import pending from './pendingReducer';
import polling from './pollingReducer';
import popups, { PopupsState } from './popupsReducer';
import prefetchQueue from './prefetchReducer';
import printers from './printersReducer';
import proxy from './proxyReducer';
import q from './queueReducer';
import readers from './readersReducer';
import { seriesDisposal } from './seriesDisposalReducer';
import series from './seriesReducer';
import studies from './studiesReducer';
import studiesToBurn from './studiesToBurnReducer';
import user from './userReducer';
import watchedFolders from './watchedFoldersReducer';
import scripts from './scriptReducer';

export const rootReducer = combineReducers({
  aiModules,
  aiQueue,
  components,
  configurations,
  deletionRules,
  feedStatus,
  fileQueue,
  gloryMachines,
  importInfo,
  nodes,
  pending,
  polling,
  popups,
  prefetchQueue,
  printers,
  proxy,
  queue: q,
  readers,
  scripts,
  series,
  seriesDisposal,
  studies,
  studiesToBurn,
  user,
  watchedFolders,
});

export type RootState = {
  aiModules?: ReturnType<typeof aiModules>;
  aiQueue?: ReturnType<typeof aiQueue>;
  components?: ComponentsState;
  configurations?: ConfigurationsState;
  deletionRules?: ReturnType<typeof deletionRules>;
  fileQueue?: ReturnType<typeof fileQueue>;
  gloryMachines?: ReturnType<typeof gloryMachines>;
  importInfo?: ReturnType<typeof importInfo>;
  nodes?: NodesState;
  pending?: ReturnType<typeof pending>;
  polling?: ReturnType<typeof polling>;
  popups?: PopupsState;
  prefetchQueue?: ReturnType<typeof prefetchQueue>;
  printers?: ReturnType<typeof printers>;
  proxy?: ReturnType<typeof proxy>;
  queue?: ReturnType<typeof q>;
  readers?: ReturnType<typeof readers>;
  scripts?: ReturnType<typeof scripts>;
  series?: ReturnType<typeof series>;
  seriesDisposal?: ReturnType<typeof seriesDisposal>;
  studies?: ReturnType<typeof studies>;
  studiesToBurn?: ReturnType<typeof studiesToBurn>;
  user?: ReturnType<typeof user>;
  watchedFolders?: ReturnType<typeof watchedFolders>;
  feedStatus?: ReturnType<typeof feedStatus>;
};
