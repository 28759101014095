import { AxiosResponse } from 'axios';
import * as actions from '../actions/actionCatalog';

export interface FileQueue {
  pk: bigint;
  file: string;
  queue: string;
  status: string;
  enqueuedOn: Date;
  updatedOn: Date;
  attempts: number;
}

interface GetFileQueueFulfilled {
  type: typeof actions.GET_FILE_QUEUE_FULFILLED;
  response: AxiosResponse<FileQueue[]>;
}

interface ReprocessFileQueuedItemFulfilled {
  type: typeof actions.REPROCESS_FILE_QUEUED_ITEM_FULFILLED;
  response: AxiosResponse<FileQueue[]>;
  request: { pk: bigint };
}

type FileQueueAction = GetFileQueueFulfilled | ReprocessFileQueuedItemFulfilled;
function fileQueue(state: FileQueue[] = [], action: FileQueueAction): FileQueue[] {
  let pk; //, studyInstanceUid, aiModuleFk;

  switch (action.type) {
    case actions.GET_FILE_QUEUE_FULFILLED:
      return action.response.data;
    case actions.REPROCESS_FILE_QUEUED_ITEM_FULFILLED:
      pk = action.request.pk;
      return action.response.data;
    default:
      return state;
  }
}

export default fileQueue;
