import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { getDeletionRulesState, getUser } from 'reducers/selectors';
import { getDeletionRules } from '../../actions/deletionRulesActions';
import DeletionRulesTable from './DeletionRulesTable';
import { useAppDispatch } from 'hooks';

function DeletionRulesPage() {
  const dispatch = useAppDispatch();
  const deletionRules = useSelector(getDeletionRulesState);
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(getDeletionRules());
  }, []);

  return (
    <Card className="page-panel-common">
      <Card.Body>
        <h3>Deletion Rules</h3>
        <DeletionRulesTable user={user} deletionRules={deletionRules} />
      </Card.Body>
    </Card>
  );
}

export default DeletionRulesPage;
