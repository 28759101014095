import React, { useEffect, useState } from 'react';
import { Modal, ProgressBar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getSeriesDisposal } from 'reducers/selectors';

const SeriesDisposalProgressPopup = () => {
  const seriesDisposal = useSelector(getSeriesDisposal);

  const [progress, setProgress] = useState<number>(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const pending = seriesDisposal.pending;
    if (!pending) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      intervalId != null && clearInterval(intervalId);
      setIntervalId(null);
    }
    if (pending && !intervalId) {
      setProgress(0);

      const interval = setInterval(() => {
        setProgress(oldProgress => {
          const increment = (80 - oldProgress) / 40;
          const newProgress = Math.min(oldProgress + increment, 80);
          return newProgress;
        });
      }, 100);
      setIntervalId(interval);
    }
  }, [JSON.stringify(seriesDisposal)]);

  return (
    <Modal show={seriesDisposal.popups.showProgressPopup} size="lg">
      <Modal.Header>
        <Modal.Title>Deleting series</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProgressBar now={progress} />
        <p>Series deletion in progress, please wait until the process is completed</p>
      </Modal.Body>
    </Modal>
  );
};

export default SeriesDisposalProgressPopup;
