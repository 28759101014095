import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canPerformAction } from 'actions/userActions';
import TableActionButton from 'components/reusableComponents/buttons/TableActionButton';
import React from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUser } from 'reducers/selectors';
import { availableOperations } from 'reducers/userReducer';
import { WatchedFolder } from 'reducers/watchedFoldersReducer';

type WatchedFolderRowType = {
  watchedFolder: WatchedFolder;
  setEditParams: (_: { show: boolean; editingFolder: WatchedFolder | null }) => void;
  setDeleteParams: (_: { show: boolean; deletingFolder: WatchedFolder }) => void;
};

const WatchedFolderRow = ({ watchedFolder, setEditParams, setDeleteParams }: WatchedFolderRowType) => {
  const user = useSelector(getUser);
  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_WATCHED_FOLDERS);
  const scriptsAuthorized = canPerformAction(user, availableOperations.SCRIPT_CATALOG_VISIBLE);

  const conditionalBadge = condition => {
    if (condition) {
      return <Badge variant="success">True</Badge>;
    }
    return <Badge variant="danger">False</Badge>;
  };

  return (
    <tr key={watchedFolder.pk} className="watched-folder-row">
      <td>{watchedFolder.queue}</td>
      <td>
        <div
          style={{
            fontFamily: 'monospace',
            background: '#2f353a',
            width: 'fit-content',
            padding: '0 0.5em',
            borderRadius: '5px',
          }}
        >
          {watchedFolder.folder}
        </div>
      </td>
      <td>{watchedFolder.subfolders 
        ? <FontAwesomeIcon icon={faCheck} cursor="default" /> 
        : <FontAwesomeIcon icon={faMinus} cursor="default" />}
      </td>
      <td>{watchedFolder.reprocess
        ? <FontAwesomeIcon icon={faCheck} cursor="default" /> 
        : <FontAwesomeIcon icon={faMinus} cursor="default" />}
      </td>
      <td>
        {scriptsAuthorized ? (
          <Button variant="link" size="sm" as={Link} to={`/scripts?name=${watchedFolder.script}`}>
            {watchedFolder.script}
          </Button>
        ) : (
          <React.Fragment>{watchedFolder.script}</React.Fragment>
        )}
      </td>
      <td>{watchedFolder.threads}</td>
      <td>{conditionalBadge(watchedFolder.enabled)}</td>
      <td style={{ display: 'flex' }}>
        <TableActionButton
          disabled={!authorized}
          action={(clickedFolder: WatchedFolder) => setEditParams({ show: true, editingFolder: clickedFolder })}
          args={watchedFolder}
          icon="faWrench"
          tooltip="Update the settings of this watched folder"
        />
        <TableActionButton
          disabled={!authorized}
          action={(clickedFolder: WatchedFolder) => {
            setEditParams({ show: false, editingFolder: null });
            setDeleteParams({ show: true, deletingFolder: clickedFolder });
          }}
          variant="outline-danger"
          args={watchedFolder}
          icon="faTrash"
          tooltip="Delete this watched folder"
        />
      </td>
    </tr>
  );
};

export default WatchedFolderRow;
