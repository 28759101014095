/* eslint-disable @typescript-eslint/default-param-last */
import { combineReducers } from 'redux';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import * as actions from '../actions/actionCatalog';
import { ObjectAction } from '../actions/actionCatalog';
import { Queue } from './queueReducer';
import { Study } from './studiesReducer';
import { UserAction } from './userReducer';

interface QueueTable {
  pageSize: number;
  page: number;
  compact: boolean;
  selectedItems: Queue[] | null;
}
const queueTableInitialState = {
  pageSize: 12,
  page: 0,
  compact: false,
  selectedItems: [],
};

function queueTable(state: QueueTable = queueTableInitialState, action: ObjectAction<QueueTable>): QueueTable {
  switch (action.type) {
    case actions.UPDATE_QUEUE_TABLE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

interface PasswordState {
  strongPasswordEnabled: boolean;
  passwordScore: number;
}

const passwordInitialState = {
  strongPasswordEnabled: true,
  passwordScore: 0,
};

interface SetPasswordState {
  type: typeof actions.SET_STRONG_PASSWORD;
  payload: boolean;
}

interface SetPasswordScore {
  type: typeof actions.SET_PASSWORD_SCORE;
  payload: number;
}

type Password = SetPasswordState | SetPasswordScore;

function passwordState(state: PasswordState = passwordInitialState, action: Password): PasswordState {
  switch (action.type) {
    case actions.SET_STRONG_PASSWORD:
      return {
        ...state,
        strongPasswordEnabled: action.payload,
      };
    case actions.SET_PASSWORD_SCORE:
      return {
        ...state,
        passwordScore: action.payload,
      };
    default:
      return state;
  }
}

interface AddUserAction {
  type: typeof actions.ADD_USER_ACTION;
  payload: UserAction;
}

interface RemoveUserAction {
  type: typeof actions.REMOVE_USER_ACTION;
  payload: UserAction;
}

interface DeleteEveryAction {
  type: typeof actions.DELETE_EVERY_ACTION;
}

interface AddEveryAction {
  type: typeof actions.ADD_EVERY_ACTION;
  payload: UserAction[];
}

type SetUserActions = AddUserAction | RemoveUserAction | DeleteEveryAction | AddEveryAction;

function userActionsState(state: UserAction[] = [], action: SetUserActions): UserAction[] {
  switch (action.type) {
    case actions.ADD_USER_ACTION:
      state = state.slice();
      if (!_.includes(state, action.payload)) {
        state.push(action.payload);
      }
      return state;
    case actions.REMOVE_USER_ACTION:
      state = state.slice();
      state = state.filter(obj => obj.pk !== action.payload.pk);
      return state;
    case actions.DELETE_EVERY_ACTION:
      state = state.slice();
      state = [];
      return state;
    case actions.ADD_EVERY_ACTION:
      state = state.slice();
      state = [];
      action.payload.map(temp => state.push(temp));
      return state;
    default:
      return state;
  }
}

interface PrefetchTable {
  pageSize: number;
  page: number;
  compact: boolean;
  showAll: boolean;
}

const prefetchTableInitialState = {
  pageSize: 10,
  page: 0,
  compact: false,
  showAll: false,
};

function prefetchTable(
  state: PrefetchTable = prefetchTableInitialState,
  action: ObjectAction<PrefetchTable>
): PrefetchTable {
  switch (action.type) {
    case actions.UPDATE_PREFETCH_TABLE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

interface StudyTable {
  page: number;
  selectedStudy?: Study | null;
}

const initialStudyTableState = {
  page: 0,
  selectedStudy: null,
};

interface SetSelectedStudy {
  type: typeof actions.SET_SELECTED_STUDY;
  payload: Study;
}

interface SetSelectedStudySize {
  type: typeof actions.SET_SELECTED_STUDY_SIZE;
  payload: string;
}

interface SetStudyTablePage {
  type: typeof actions.SET_STUDY_TABLE_PAGE;
  payload: number;
}

interface SearchStudiesPending {
  type: typeof actions.SEARCH_STUDIES_PENDING;
}

interface SearchStudiesFulfilled {
  type: typeof actions.SEARCH_STUDIES_FULFILLED;
  response: AxiosResponse<Study[]>;
}

export type StudyTableAction =
  | SetSelectedStudy
  | SetStudyTablePage
  | SearchStudiesPending
  | SearchStudiesFulfilled
  | SetSelectedStudySize;

function studyTable(state: StudyTable = initialStudyTableState, action: StudyTableAction): StudyTable {
  switch (action.type) {
    case actions.SET_SELECTED_STUDY:
      return { ...state, selectedStudy: action.payload };
    case actions.SET_SELECTED_STUDY_SIZE:
      return { ...state, selectedStudy: { ...state.selectedStudy!, size: action.payload } };
    case actions.SET_STUDY_TABLE_PAGE:
      return { ...state, page: action.payload };
    case actions.SEARCH_STUDIES_PENDING:
      return initialStudyTableState;
    case actions.SEARCH_STUDIES_FULFILLED:
      const selectedStudy =
        action.response && action.response.data
          ? action.response.data.length > 0
            ? action.response.data[0]
            : null
          : undefined;
      return { ...state, selectedStudy };
    default:
      return state;
  }
}

interface SearchForm {
  firstName: string;
  lastName: string;
  studyDate: Date;
  modality: string;
  studyUid: string;
  patientId: string;
  accNum: string;
  advanced: boolean;
}

const searchFormInitialState = {
  firstName: '',
  lastName: '',
  studyDate: new Date(),
  modality: '',
  studyUid: '',
  patientId: '',
  accNum: '',
  advanced: false,
};

type SearchFormAction = {
  type: typeof actions.SET_SEARCH_FIELD;
  payload: {
    field: string;
    value: string;
  };
};

function searchForm(state: SearchForm = searchFormInitialState, action: SearchFormAction): SearchForm {
  switch (action.type) {
    case actions.SET_SEARCH_FIELD:
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    default:
      return state;
  }
}

export default combineReducers({
  queueTable,
  studyTable,
  searchForm,
  prefetchTable,
  passwordState,
  userActionsState,
});

export type ComponentsState = {
  queueTable: ReturnType<typeof queueTable>;
  studyTable: ReturnType<typeof studyTable>;
  searchForm: ReturnType<typeof searchForm>;
  prefetchTable: ReturnType<typeof prefetchTable>;
  passwordState: ReturnType<typeof passwordState>;
  userActionsState: ReturnType<typeof userActionsState>;
};
