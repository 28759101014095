import 'react-datepicker/dist/react-datepicker.css';
import './dark.scss';
// dark.scss MUST be the first style to be imported since it contains the references for every color variable used in App.css
import {
  faChevronLeft,
  faChevronRight,
  faCloud,
  faCompactDisc,
  faUser,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCfgDicomServices, getCfgServices } from 'actions/configurationsActions';
import AiModuleConfiguration from 'components/ai/AiModuleConfiguration';
import AiModulesPage from 'components/ai/AiModulesPage';
import AiModulesQueue from 'components/ai/AiModulesQueue';
import WatchedFoldersPage from 'components/fileQueue/watchedFolders/WatchedFoldersPage';
import GloryMachinesPage from 'components/glory/GloryMachinesPage';
import UserSettingsPage from 'components/userSettings/UserSettingsPage';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup, Nav, Navbar, OverlayTrigger, Popover } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { IdleTimerProvider } from 'react-idle-timer';
import { useSelector } from 'react-redux';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, Switch } from 'react-router-dom';
import { StudyToBurn } from 'reducers/studiesToBurnReducer';
import { v4 as uuid } from 'uuid';
import * as userActions from './actions/userActions';
import { getPagesVisibility, logout } from './actions/userActions';
import './App.css';
import ConfigurationsPage from './components/configurations/ConfigurationsPage';
import DashboardPage from './components/dashboard/DashboardPage';
import DeletionRulesPage from './components/deletionRules/DeletionRulesPage';
import FileQueuePage from './components/fileQueue/FileQueuePage';
import ChangePasswordForm from './components/login/ChangePasswordForm';
import LogsPage from './components/logs/LogsPage';
import NodesPage from './components/nodes/NodesPage';
import PageNotFound from './components/PageNotFound';
import JSDButton from './components/popups/JSDButton';
import Popups from './components/popups/Popups';
import PrefetchPage from './components/prefetch/PrefetchPage';
import PrinterPage from './components/printer/PrinterPage';
import StatusPage from './components/printer/statusPage/StatusPage';
import QueuePage from './components/queue/QueuePage';
import ScriptsPage from './components/scripts/ScriptsPage';
import BurnOverlayComponent from './components/search/burnOverlay/BurnOverlayComponent';
import SearchPage from './components/search/SearchPage';
import WorklistPage from './components/worklist/WorklistPage';
import './queue-table.css';
import { getCfgDicomServicesState, getCfgServicesState, getStudiesToBurn, getUser } from './reducers/selectors';
import { User } from './reducers/userReducer';

export const CLIENT_ID = uuid();
const SESSION_TIMEOUT = 30 * 60 * 1000;

function App({ dispatch }: { dispatch: (_: any) => void }) {
  const user = useSelector(getUser);
  const studiesToBurn = useSelector(getStudiesToBurn);
  const [sidebar, setSidebar] = useState(true);
  const sidebarClassNames = sidebar ? 'sidebar' : 'sidebar closed';
  const appClassNames = sidebar ? 'app-body' : 'app-body expanded';

  const allowedActions = getPagesVisibility(user);

  return (
    <IdleTimerProvider
      timeout={SESSION_TIMEOUT}
      onAction={() => {
        userActions.refreshSession();
      }}
      debounce={3 * 1000}
      onIdle={() => userActions.logout()}
    >
      <div className={appClassNames}>
        <Topbar user={user} dispatch={dispatch} studiesToBurn={studiesToBurn} />
        <div className="sidebar-toggle" onClick={() => setSidebar(!sidebar)}>
          {sidebar ? (
            <FontAwesomeIcon icon={faChevronLeft} style={{ color: '#ebedef' }} />
          ) : (
            <FontAwesomeIcon icon={faChevronRight} style={{ color: '#ebedef' }} />
          )}
        </div>

        <div className={sidebarClassNames}>
          <Sidebar allowedActions={allowedActions} />
        </div>

        <div>
          <div style={{ padding: '0' }}>
            <div>
              <Switch>
                {allowedActions.dashboard && <Route exact path="/" render={() => <DashboardPage />} />}
                {allowedActions.queue && <Route exact path="/queue" render={() => <QueuePage />} />}
                {allowedActions.browser && <Route exact path="/search" render={() => <SearchPage />} />}
                {allowedActions.nodes && <Route exact path="/nodes" render={() => <NodesPage />} />}
                {allowedActions.worklist && <Route exact path="/worklist" render={() => <WorklistPage />} />}
                {allowedActions.scripts && <Route exact path="/scripts" render={() => <ScriptsPage />} />}
                {allowedActions.fileQueue && <Route exact path="/fileQueue" render={() => <FileQueuePage />} />}
                {allowedActions.watchedFolders && (
                  <Route exact path="/fileQueue/watchedFolders" render={() => <WatchedFoldersPage />} />
                )}
                {allowedActions.prefetch && <Route exact path="/prefetch" render={() => <PrefetchPage />} />}
                {allowedActions.printers && <Route exact path="/printer" component={PrinterPage} />}
                {allowedActions.printerStatus && <Route exact path="/printersStatus" render={() => <StatusPage />} />}
                {allowedActions.deletionRules && <Route exact path="/deletion" render={() => <DeletionRulesPage />} />}
                {allowedActions.aiModules && <Route exact path="/aiModules" render={() => <AiModulesPage />} />}
                {allowedActions.aiModules && (
                  <Route exact path="/aiModuleConfiguration/:id" component={AiModuleConfiguration} />
                )}
                {allowedActions.aiModules && <Route path="/aiModulesQueue" component={AiModulesQueue} />}
                {allowedActions.configurations && (
                  <Route exact path="/configurations" render={() => <ConfigurationsPage />} />
                )}
                {allowedActions.logs && <Route exact path="/logs" render={() => <LogsPage />} />}
                {allowedActions.userSettings && <Route exact path="/users" render={() => <UserSettingsPage />} />}
                {allowedActions.paymentDevices && <Route exact path="/glory" render={() => <GloryMachinesPage />} />}
                <Route render={() => <PageNotFound />} />
              </Switch>
            </div>
          </div>
        </div>
        <JSDButton isVisible={allowedActions.jsdButton} />
        <Popups />
      </div>
    </IdleTimerProvider>
  );
}

type TopbarType = {
  user: User;
  dispatch: (_: any) => void;
  studiesToBurn: StudyToBurn[];
};

const Topbar = ({ user, dispatch, studiesToBurn }: TopbarType) => {
  const cdfServices = useSelector(getCfgServicesState);
  const cfgDicomServices = useSelector(getCfgDicomServicesState);
  const [feedStationName, setFeedStationName] = useState<string | null>(null);
  const [feedAet, setFeedAet] = useState<string | null>(null);

  useEffect(() => {
    dispatch(getCfgServices());
    dispatch(getCfgDicomServices());
  }, []);

  useEffect(() => {
    configureFeedStationName();
    configureFeedAET();
  }, [JSON.stringify(cdfServices), JSON.stringify(cfgDicomServices)]);

  const configureFeedStationName = () => {
    if (cdfServices && cdfServices.length > 0) {
      const consoleUrl = cdfServices.find(conf => conf.paramKey === 'COLLECTOR_URL' && conf.serviceName === 'MONITORING');
      if (consoleUrl && consoleUrl.paramValue) {
        try {
          const url = new URL(consoleUrl.paramValue);
          const stationName = url.searchParams.get('station');
          setStationName(stationName);
        } catch (error) {
          setStationName(null);
        }
      } else {
        setStationName(null);
      }
    }
  };

  const setStationName = (name: string | null): void => {
    setFeedStationName(name);
    document.title = 'FEED' + (name && name !== '' ? ' - ' + name : '');
  };

  const configureFeedAET = () => {
    if (cfgDicomServices && cfgDicomServices.length > 0) {
      const aets = cfgDicomServices.find(conf => conf.paramKey === 'CALLED_AETS' && conf.serviceName === 'GLOBAL');
      if (aets) {
        const aet = aets.paramValue.split(',')[0];
        setFeedAet(aet);
      }
    }
  };

  return (
    <Navbar className="justify-content-between" fixed="top" style={{ marginLeft: '0px', backgroundColor: '#23232e' }}>
      <Navbar.Brand>
        <FontAwesomeIcon id="feed-logo" icon={faCloud} style={{ color: '#55b7ff', marginRight: '5px' }} />
        <span style={{ color: 'white' }}>
          {feedAet !== null && (
            <React.Fragment>
              {feedStationName} [{feedAet}]
            </React.Fragment>
          )}
        </span>
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        {studiesToBurn.length < 1 ? (
          <div />
        ) : (
          <OverlayTrigger trigger="click" rootClose placement="left" overlay={BurnOverlayPopup()}>
            <span className="fa-layers fa-fw" style={{ marginRight: '2em' }}>
              <span className="fa-layers-counter" id="counterBurnStudy">
                {studiesToBurn.length}
              </span>
              <FontAwesomeIcon icon={faCompactDisc} size="1x" style={{ position: 'absolute' }} />
            </span>
          </OverlayTrigger>
        )}
        <UserPopover user={user} dispatch={dispatch} />
      </Navbar.Collapse>
    </Navbar>
  );
};

const UserPopover = ({ user, dispatch }: { user: User; dispatch: (_: any) => void }) => {
  const popover = (
    <Popover id="user-popover">
      <Popover.Content>
        <div style={{ color: '#f3f4f5', fontSize: '2em' }}>Settings</div>
        <hr className="user-hr" />
        <Form>
          <FormGroup>
            <p className="user-popover-form">
              <strong>Username</strong>: {user.username}
              <strong style={{ paddingLeft: '2em' }}>Role</strong>: {user.role!.name}{' '}
            </p>
            {userActions.isGoogleSession(user) && <div className="google-session"></div>}
          </FormGroup>
          {!userActions.isGoogleSession(user) && (
            <React.Fragment>
              <hr className="user-hr" />
              <FormGroup id="change-password-collapsible">
                <Collapsible trigger="Change password">
                  <ChangePasswordForm user={user} />
                </Collapsible>
              </FormGroup>
            </React.Fragment>
          )}
          <hr className="user-hr" />
          <FormGroup>
            <Button
              size="sm"
              variant="outline-secondary"
              onClick={() => {
                dispatch({ type: 'SET_USER', payload: { authenticated: false } });
                logout();
              }}
            >
              Logout
            </Button>
          </FormGroup>
        </Form>
      </Popover.Content>
    </Popover>
  );

  return (
    <OverlayTrigger rootClose trigger="click" placement="bottom" overlay={popover}>
      <Button size="sm" variant="outline-primary">
        {user.role!.privileged ? (
          <FontAwesomeIcon icon={faUserShield} style={{ marginRight: '1em' }} />
        ) : (
          <FontAwesomeIcon icon={faUser} style={{ marginRight: '1em' }} />
        )}
        {user.username}
      </Button>
    </OverlayTrigger>
  );
};

const BurnOverlayPopup = () => (
  <Popover id="forward-popover">
    <BurnOverlayComponent job={null} />
  </Popover>
);
// Sidebar menu
function Sidebar({ allowedActions }) {
  // Displayed name, path, and allowedAction that is required to display the entry in the sidebar menu
  const pages = [
    { name: 'Dashboard', path: '/', allowedAction: allowedActions.dashboard },
    { name: 'DICOM Browser', path: '/search', allowedAction: allowedActions.browser },
    { name: 'DICOM Queue', path: '/queue', allowedAction: allowedActions.queue },
    { name: 'DICOM Nodes', path: '/nodes', allowedAction: allowedActions.nodes },
    { name: 'DICOM Worklist', path: '/worklist', allowedAction: allowedActions.worklist },
    { name: 'DICOM Prefetch', path: '/prefetch', allowedAction: allowedActions.prefetch },
    { name: 'File Queue', path: '/fileQueue', allowedAction: allowedActions.fileQueue },
    { name: 'Scripts', path: '/scripts', allowedAction: allowedActions.scripts },
    { name: 'CD Printers', path: '/printersStatus', allowedAction: allowedActions.printerStatus },
    { name: 'Cash machines', path: '/glory', allowedAction: allowedActions.paymentDevices },
    { name: 'Deletion rules', path: '/deletion', allowedAction: allowedActions.deletionRules },
    { name: 'AI Modules', path: '/aiModules', allowedAction: allowedActions.aiModules },
    { name: 'Users & Roles', path: '/users', allowedAction: allowedActions.userSettings },
    { name: 'Logs', path: '/logs', allowedAction: allowedActions.logs },
    { name: 'Configurations', path: '/configurations', allowedAction: allowedActions.configurations },
  ];
  // Build an array with all menu entries
  const links = pages.map((page, i) => <SidebarLink key={'sidebar_link_' + i} link={page} eventKey={i} />);
  // Build each entry in the menu
  function SidebarLink({ link, eventKey }) {
    return (
      <React.Fragment>
        {link.allowedAction && (
          <LinkContainer to={link.path}>
            <Nav.Link eventKey={eventKey} href="#">
              {link.name}
            </Nav.Link>
          </LinkContainer>
        )}
      </React.Fragment>
    );
  }

  return (
    <Nav className="flex-column">
      <React.Fragment>{links}</React.Fragment>
    </Nav>
  );
}

export default App;
