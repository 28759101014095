import { Dispatch } from 'redux';
import * as actions from './actionCatalog';
import * as http from '../http';
import { BurnerJob, Printer } from 'reducers/printersReducer';

const regularGetPrinters = (dispatch: Dispatch) => http.get<Printer[]>(actions.GET_PRINTERS, dispatch);

export const getPrinters = () => regularGetPrinters;

const regularGetBurnersStatus = (dispatch: Dispatch) => http.get<Printer[]>(actions.GET_BURNERS_STATUS, dispatch);

export const getBurnersStatuses = () => regularGetBurnersStatus;

const regularGetBurnersJobs = (dispatch: Dispatch) => http.get<BurnerJob[]>(actions.GET_BURNERS_JOBS, dispatch);

export const getBurnersJobs = () => regularGetBurnersJobs;
