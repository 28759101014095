import { combineReducers } from 'redux';
import * as actions from '../actions/actionCatalog';
import { Node } from './nodesReducer';

interface EditNode {
  show: boolean;
  node: Node | null | undefined;
}

const nodeInitialState = {
  show: false,
  node: null,
};

type NodePopupAction = {
  type: string;
  payload?: Node;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const editNode = (state: EditNode = nodeInitialState, action: NodePopupAction): EditNode => {
  switch (action.type) {
    case 'SHOW_NODE_POPUP':
      return {
        ...state,
        show: true,
        node: action.payload,
      };
    case 'HIDE_NODE_POPUP':
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};

class ToasterModerator {
  lastOne: {
    toaster: Toaster;
    timestamp: number;
  } | null;

  toaster: ToasterCreator;

  constructor() {
    this.lastOne = null;
    this.toaster = new ToasterCreator();
  }

  handle(state: Toaster[], text: string, level: ToasterLevel): Toaster[] {
    if (
      this.lastOne &&
      this.lastOne.toaster.level === level &&
      this.lastOne.toaster.text === text &&
      new Date().getTime() - this.lastOne.timestamp < 6000
    ) {
      return state;
    }
    this.lastOne = {
      toaster: this.toaster.create(text, level),
      timestamp: new Date().getTime(),
    };

    return [...state, this.lastOne.toaster];
  }
}

type ToasterLevel = 'danger' | 'warning' | 'info' | 'success';

interface Toaster {
  id: number;
  level: ToasterLevel;
  text: string;
}

class ToasterCreator {
  counter: number;

  constructor() {
    this.counter = 0;
  }

  // // levels: info, success, warning, danger
  create(text: string, level: ToasterLevel): Toaster {
    this.counter += 1;
    return {
      id: this.counter,
      level: level ? level : 'info',
      text,
    };
  }
}

const toaster = new ToasterModerator();

type ToasterAction = {
  type: string;
  id?: number;
  message?: string;
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const toasters = (state: Toaster[] = [], action: ToasterAction) => {
  switch (action.type) {
    case actions.HIDE_TOASTER:
      return state.filter(it => it.id !== action.id);
    case actions.SEARCH_STUDIES_REJECTED:
    case actions.SEARCH_SERIES_REJECTED:
    case actions.GET_QUEUE_REJECTED:
    case actions.GET_FEED_STATUS_REJECTED:
    case actions.INCREASE_PRIORITY_REJECTED:
    case actions.SERIES_DISPOSAL_REJECTED:
      return toaster.handle(state, action.type, 'danger');
    case actions.WS_DISCONNECTED:
      return toaster.handle(state, 'Web socket disconnected', 'danger');
    case actions.WS_RECONNECTED:
      return toaster.handle(state, 'Web socket reconnected', 'success');
    case 'ERROR':
      return toaster.handle(state, action.message!, 'danger');
    case 'WARNING':
      return toaster.handle(state, action.message!, 'warning');
    case 'INFO':
      return toaster.handle(state, action.message!, 'info');
    case 'SUCCESS':
      return toaster.handle(state, action.message!, 'success');
    default:
      return state;
  }
};

export default combineReducers({
  editNode,
  toasters,
});

export type PopupsState = {
  editNode: ReturnType<typeof editNode>;
  toasters: ReturnType<typeof toasters>;
};
