import internal from 'stream';
import * as actions from '../actions/actionCatalog';

export interface StudyToBurn {
  studyUid?: string;
  patientId?: string;
  patientName?: string;
  birthDate?: string;
  accessionNumber?: string;
  studyDate?: string;
  description?: string;
  size?: string;
  studyTime?: string;
  series?: string[];
  totalSeries?: number;
}

interface GetStudiesToBurn {
  type: typeof actions.GET_STUDIES_TO_BURN;
}

interface AddStudyToBurn {
  type: typeof actions.ADD_STUDY_TO_BURN;
  studyToAdd: StudyToBurn;
}

interface DeleteStudyToBurn {
  type: typeof actions.DELETE_STUDY_TO_BURN;
  studyToDelete: StudyToBurn;
}

interface DeleteAllStudiesToBurn {
  type: typeof actions.DELETE_ALL_STUDIES_TO_BURN;
}

type StudiesToBurnAction =
  | GetStudiesToBurn
  | AddStudyToBurn
  | DeleteStudyToBurn
  | DeleteAllStudiesToBurn;

// eslint-disable-next-line @typescript-eslint/default-param-last
function studiesToBurn(state: StudyToBurn[] = [], action: StudiesToBurnAction): StudyToBurn[] {
  let study;
  switch (action.type) {
    case actions.GET_STUDIES_TO_BURN:
      return state;
    case actions.ADD_STUDY_TO_BURN:
      study = action.studyToAdd;
      const newStateAdd = [...state];
      if (checkAllStudies(state, study)) {
        newStateAdd.push(study);
      } else {
        const keyOldStudy = sameStudyUid(state, study);
        if (!newStateAdd[keyOldStudy].size) newStateAdd[keyOldStudy] = study;
      }
      return newStateAdd;
    case actions.DELETE_STUDY_TO_BURN:
      study = action.studyToDelete;
      let newStateDel = [...state];
      newStateDel = newStateDel.filter(function (value) {
        return value.studyUid !== study.studyUid;
      });
      return newStateDel;
    case actions.DELETE_ALL_STUDIES_TO_BURN:
      const newState = [];
      return newState;
    default:
      return state;
  }
}

function checkAllStudies(studies, study) {
  let canAdd = true;
  if (studies.length < 1) return canAdd;
  studies.forEach(element => {
    if (element.studyUid === study.studyUid || element.patientId !== study.patientId) canAdd = false;
  });
  return canAdd;
}

function sameStudyUid(studies, study) {
  let key = 0;
  for (let i = 0; i < studies.length; i++) {
    if (studies[i].studyUid === study.studyUid) key = i;
  }
  return key;
}

export default studiesToBurn;
