import React from 'react';
import { Button, Modal, ProgressBar } from 'react-bootstrap';
import Collapsible from 'react-collapsible';
import { useSelector } from 'react-redux';
import * as actions from '../../../actions/actionCatalog';
import { getSeriesDisposal } from 'reducers/selectors';
import { useAppDispatch } from 'hooks';

const SeriesDisposalOutcomeDetailPopup = () => {
  const dispatch = useAppDispatch();
  const seriesDisposal = useSelector(getSeriesDisposal);

  const isSuccessfull = () => {
    const outcome = seriesDisposal.outcome;
    if (!outcome) {
      return false;
    }

    let success = true;
    success = success && outcome.studyPaused;
    success = success && outcome.series.every(series => series.deleted);
    success = success && outcome.studyResumed;
    return success;
  };

  return (
    <Modal show={seriesDisposal.popups.showRecapPopup} size="lg">
      <Modal.Header>
        <Modal.Title>Deleting series</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {seriesDisposal.outcome &&
          (isSuccessfull() ? (
            <React.Fragment>
              <ProgressBar now={100} variant="success" />
              <p>Successfully deleted {seriesDisposal.seriesUids.length} series</p>
            </React.Fragment>
          ) : !seriesDisposal.outcome.studyPaused ? (
            <React.Fragment>
              <p>Sorry, but the series cannot be deleted right now, the parent study is currently being forwarded.</p>
              <p>You can try again later.</p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <ProgressBar now={100} variant="danger" />
              <p>There have been errors</p>
            </React.Fragment>
          ))}

        {seriesDisposal.isStudyInQueue && (
          <p>
            <b>Forced dispose of series</b>
          </p>
        )}

        <Collapsible trigger="Series dispose recap" triggerStyle={{ fontSize: '1em' }}>
          {seriesDisposal.outcome && !seriesDisposal.outcome.studyResumed && (
            <p>
              Study <b>NOT</b> resumed!
            </p>
          )}
          <table className="queue-table">
            <thead>
              <tr>
                <th>Series UID</th>
                <th>Deleted</th>
              </tr>
            </thead>
            <tbody>
              {seriesDisposal.outcome &&
                seriesDisposal.outcome.series.map((it, index) => (
                  <tr key={index}>
                    <td>{it.uid}</td>
                    <td>{it.deleted ? 'yes' : <b>NO</b>}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </Collapsible>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => dispatch({ type: actions.SERIES_DISPOSAL_ACKNOWLEDGED })}>OK</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SeriesDisposalOutcomeDetailPopup;
