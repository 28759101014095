import { OverlayTrigger, Pagination, Popover } from 'react-bootstrap';
import React, { useRef, useState } from 'react';

const TableJumper = ({
  pages,
  setPage,
  handle,
  ellipsisKey,
}: {
  pages: number;
  setPage: (arg: number) => () => React.Dispatch<React.SetStateAction<number>>;
  handle: Function;
  ellipsisKey: string;
}) => {
  const [inputValue, setInputValue] = useState<number>();
  const [showPopover, setShowPopover] = useState<boolean>(false);
  const inputRef = useRef(null);

  const handleInputChange = event => {
    setInputValue(event.target.value);
    if (event.target.classList.contains('is-invalid')) event.target.classList.remove('is-invalid');
  };
  function handleKeyDown(event: { key: string }): void {
    if (event.key === 'Enter') {
      if (inputValue !== undefined) {
        if (inputValue > 0 && inputValue <= pages) {
          toggleShow();
          setPage(inputValue - 1)();
          handle();
        } else {
          (inputRef.current! as HTMLDivElement).classList.add('is-invalid');
        }
      } else {
        (inputRef.current! as HTMLDivElement).classList.add('is-invalid');
      }
    }
  }

  let range: string = ('1 - ' + pages) as string;
  const popover = (
    <Popover
      id="popover-basic"
      className="modal-content table-jumper-popover"
      style={{
        maxWidth: '100px',
      }}
    >
      <Popover.Title
        className="table-jumper-popover-header no-before"
        style={{
          backgroundColor: '--gray-700: #3a4149',
          textAlign: 'center',
        }}
        as="h4"
      >
        Go to page :{' '}
      </Popover.Title>
      <Popover.Content
        className="table-jumper-popover-body"
        style={{
          alignContent: 'center',
        }}
      >
        <input
          ref={inputRef}
          className="form-control form-control-sm"
          id={ellipsisKey + ' ellipsis input'}
          key={ellipsisKey + ' ellipsis input'}
          type="number"
          placeholder={range}
          aria-label="page jumper"
          onChange={handleInputChange}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          style={{
            textAlign: 'center',
            fontSize: '14px',
          }}
          onKeyDown={handleKeyDown}
        />
      </Popover.Content>
    </Popover>
  );
  const toggleShow = () => {
    setShowPopover(!showPopover);
  };

  return (
    <OverlayTrigger trigger="click" rootClose={true} placement="bottom" overlay={popover}>
      <Pagination.Ellipsis key={ellipsisKey} onClick={toggleShow} />
    </OverlayTrigger>
  );
};

export default TableJumper;
