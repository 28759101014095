import React, { useState } from 'react';
import { Button, Col, Row, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import useToaster from '../../actions/useToaster';

function PrefetchConfigurationForm({ configuration, onClose }) {
  const toaster = useToaster();
  const [saving, setSaving] = useState(false);
  const [unsaved, update] = useState(configuration);

  return (
    <React.Fragment>
      <p>
        This service can be used to prefetch studies from remote nodes.
        <br />
        It is possible to add studies to the prefetch list through the Prefetch APIs.
      </p>
      <Form style={{ maxWidth: '50em' }}>
        <Form.Group as={Row}>
          <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
            Service enabled
          </Form.Label>
          <Col sm={8}>
            <Form.Check
              custom
              type="checkbox"
              id="serviceEnabledPrefetch"
              label=""
              checked={unsaved.enabled}
              onChange={() => {
                update(state => ({ ...state, enabled: !state.enabled }));
              }}
            />
          </Col>
        </Form.Group>
        <OverlayTrigger placement="right" overlay={tooltip('How often the service should run')}>
          <Form.Group as={Row}>
            <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
              Period (minutes)
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                size="sm"
                type="text"
                name="periodInMinutes"
                disabled={!unsaved.enabled}
                value={unsaved.periodInMinutes}
                onChange={e => {
                  const minutes = e.target.value;
                  update(state => ({ ...state, periodInMinutes: minutes }));
                }}
              />
            </Col>
          </Form.Group>
        </OverlayTrigger>
        <OverlayTrigger
          placement="right"
          overlay={tooltip(
            'Temporal window in which the service is allowed to run (empty means always allowed)'
          )}
        >
          <Form.Group as={Row}>
            <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
              Time interval
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                size="sm"
                type="text"
                name="timeInterval"
                placeholder="HH:MM-HH:MM"
                disabled={!unsaved.enabled}
                value={unsaved.timeInterval}
                onChange={e => {
                  const val = e.target.value;
                  update(state => ({ ...state, timeInterval: val }));
                }}
              />
            </Col>
          </Form.Group>
        </OverlayTrigger>
      </Form>

      <br />
      <p style={{ textAlign: 'right' }}>
        <strong>NB:</strong> restart the system to apply the new configuration
      </p>
      <div style={{ textAlign: 'right', marginTop: '2em' }}>
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button
          style={{ width: '8em' }}
          variant="primary"
          disabled={saving}
          onClick={() => {
            setSaving(true);
            saveConfiguration(unsaved).then(
              response => {
                setSaving(false);
                update(response);
                toaster.success('Configuration saved');
                onClose();
              },
              () => {
                setSaving(false);
                toaster.error('Error saving configuration');
              }
            );
          }}
        >
          Save changes
        </Button>
      </div>
    </React.Fragment>
  );
}

const tooltip = text => <Tooltip id="tooltip">{text}</Tooltip>;

const saveConfiguration = async configuration => {
  const url = `/rest/react/updatePrefetchConfiguration`;
  const response = await axios.post(url, configuration, {
    headers: {
      'Content-type': 'application/json',
    },
  });
  if (response.data.error) {
    console.error('error updating configuration', response);
    throw response.data.error.message;
  }
  return response.data.data;
};

export default PrefetchConfigurationForm;
