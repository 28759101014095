import PasswordStrengthMeter from 'components/login/PasswordStrengthMeter';
import PasswordTextBox from 'components/login/PasswordTextBox';
import React, { useState } from 'react';
import { Button, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { componentsSelector, getUser } from 'reducers/selectors';
import { Role, User } from 'reducers/userReducer';

type UsersSettingsPopupNewUserType = {
  currentUser: User,
  users: User[],
  roles: Role[],
  onClose: () => void,
  onSave: (username: string, password: string, role: Role, enabled: boolean) => void,
}

function UsersSettingsPopupNewUser({ currentUser, users, roles, onClose, onSave }: UsersSettingsPopupNewUserType) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState<Role>(currentUser.role!);
  const [enabled, setEnabled] = useState(true);
  const [error, setError] = useState('');

  const { strongPasswordEnabled, passwordScore } = useSelector(componentsSelector.getPasswordState);

  function save() {
    if (username === '' || password === '') {
      setError('You must insert the credentials');
      return;
    }
    if (strongPasswordEnabled && passwordScore <= 2) {
      setError('Your password is not strong enough');
      return;
    }
    if (users.find(tempUser => tempUser.username === username)) {
      setError(`You can't name this user as an already existing user`);
      return;
    }
    onSave(username, password, role, enabled);
  }

  return (
    <Form>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Username
        </Form.Label>
        <Col sm={8}>
          <FormControl
            size="sm"
            type="text"
            placeholder="Username..."
            value={username}
            onChange={e => {
              setError('');
              setUsername(e.target.value);
            }}
          />
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Password
        </Form.Label>
        <Col sm={8}>
          <PasswordTextBox
            placeholder="Password..."
            password={password}
            setPassword={e => {
              setPassword(e.target.value);
            }}
          />
          {strongPasswordEnabled && <PasswordStrengthMeter password={password} />}
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Role
        </Form.Label>
        <Col sm={8}>
          <FormControl
            as="select"
            size="sm"
            value={role.name}
            disabled={!currentUser.role?.privileged}
            onChange={e => {
              setRole(roles.find(roleTemp => roleTemp.name === e.target.value)!);
              setError('');
            }}
          >
            {roles &&
              roles.length > 0 &&
              roles.map(roleTemp => (
                <option key={roleTemp.name} value={roleTemp.name}>
                  {roleTemp.name}
                </option>
              ))}
          </FormControl>
        </Col>
      </FormGroup>
      <FormGroup as={Row}>
        <Form.Label column sm={4} md={{ offset: 1 }} className="font-weight-bold">
          Enabled
        </Form.Label>
        <Col sm={8}>
          <Form.Check
            custom
            type="checkbox"
            id="enableUser"
            label=""
            checked={enabled}
            onChange={() => {
              setEnabled(!enabled);
              setError('');
            }}
          />
          <div style={{ color: '#f86c6b', paddingTop: '1em' }}>{error}</div>
        </Col>
      </FormGroup>
      <div style={{ textAlign: 'right' }}>
        <br />
        <Button onClick={onClose} variant="outline-secondary" style={{ marginRight: '5px' }}>
          Cancel
        </Button>
        <Button
          style={{ width: '8em' }}
          variant="primary"
          onClick={() => {
            setError('');
            save();
          }}
        >
          Save
        </Button>
      </div>
    </Form>
  );
}

export default UsersSettingsPopupNewUser;
