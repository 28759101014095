import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as aiModulesActions from '../../actions/aiModulesActions';
import * as selectors from '../../reducers/selectors';
import { useAppDispatch } from 'hooks';
import { SET_SELECTED_AI_MODULE } from 'actions/actionCatalog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrainCircuit } from '@fortawesome/pro-solid-svg-icons';

function AiModulesPage() {
  const dispatch = useAppDispatch();
  const modules = useSelector(selectors.getAiModules);

  useEffect(() => {
    dispatch(aiModulesActions.getAiModules());
  }, []);

  return (
    <Card className="page-panel-common" >
      <Card.Body>
        <h3>AI Modules</h3>
        <div style={{ display: 'flex', gap: '1rem' }}>
          {modules.map(module => (
            <ModuleBox 
            key={module.pk} 
            module={module} 
            />
          )) }
        </div>
      </Card.Body>
    </Card>
  );
}

function ModuleBox({ module }) {
  const dispatch = useAppDispatch();
  
  let info = JSON.parse(module.info);

  return (
    <Card 
      key={module.pk}
      bg="dark"
      text="white"
      border="secondary"
      style={{ width: '30%' }}
      className="mb-2"
      >
        <Card.Header style={{ display: 'flex', justifyContent: 'space-between' }} className='ai-module-title'>
          {info.vendor} 
          <FontAwesomeIcon 
            title={module.enabled ? 'Enabled' : 'Not enabled'} 
            icon={faBrainCircuit} 
            className={module.enabled ? 'brain-icon' : 'brain-icon disabled'} />
        </Card.Header>
        <Card.Body>
          <Card.Title>
            {info.name}
          </Card.Title>
          <Card.Text >
            <div style={{ paddingBottom: '3rem' }}>
              {getDescription(module)}
            </div>
            <div style={{ display: 'flex', gap: '0.5rem', position: 'absolute', bottom: '1rem', right: '1rem' }}>
              <Button 
              as={Link}
              to={"/aiModuleConfiguration/" + module.pk}
              variant="outline-secondary" 
              onClick={() => dispatch({ type: SET_SELECTED_AI_MODULE , payload: module })}>
                Configure
              </Button>
              <Button 
                as={Link}
                to={"/aiModulesQueue?aiModuleFk=" + module.pk}
                variant="outline-secondary" >
                See queue
              </Button>
            </div>
          </Card.Text>
        </Card.Body>
    </Card>
  );
}

function getDescription(module) {
  switch (module.pk) {
    case 1:
      return `Lunit INSIGHT MMG is a Computer-Assisted Detection/Diagnosis
      (CADe/x) software device based on an artificial intelligence algorithm
      intended to aid in the detection, localization, identification, and
      characterization of suspicious areas for breast cancer on mammograms`
    
      case 2:
        return `The Lunit INSIGHT CXR is a Computer-Assisted Detection (CADe)
        software device based on an artificial intelligence algorithm intended for
        use as a concurrent reading aid for interpreting physicians reading chest
        radiographs, to detect, localize, identify, and characterize suspicious
        abnormal radiological findings`
  
    default:
      return 'missing description'
  }
}

export default AiModulesPage;
