import { faQuestion, faSignal, faSlash, faTrash, faWrench, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canPerformAction } from 'actions/userActions';
import useToaster from 'actions/useToaster';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Button, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { trackPromise, usePromiseTracker } from 'react-promise-tracker';
import { useSelector } from 'react-redux';
import { GloryMachine } from 'reducers/gloryMachinesReducer';
import { getUser } from 'reducers/selectors';
import { availableOperations } from 'reducers/userReducer';
import * as actions from '../../actions/actionCatalog';
import * as machineActions from '../../actions/gloryMachinesActions';
import { GloryMachinePopup, GloryMachinePopupType } from './GloryMachinePopup';

const GloryMachineCard = ({ machine }: { machine: GloryMachine }) => {
  const user = useSelector(getUser);
  const [statusIconName, setStatusIconName] = useState<IconDefinition>({
    iconName: 'question',
    prefix: faQuestion.prefix,
    icon: faQuestion.icon,
  });
  const [showEditMachine, setShowEditMachine] = useState<boolean>(false);
  const [showDeleteMachine, setShowDeleteMachine] = useState<boolean>(false);
  const toaster = useToaster();
  const dispatch = useAppDispatch();
  const testPaymentProgress = usePromiseTracker({ area: `test_payment_${machine.name}` });
  const authorized = canPerformAction(user, availableOperations.CAN_MANAGE_PAYMENT_DEVICES);

  useEffect(() => {
    const interval = setInterval(() => {
      if (machine.lastHeartBeat) {
        var icon: IconDefinition | null = null;
        if (Date.now() - machine.lastHeartBeat > 60_000) {
          icon = { iconName: 'slash', prefix: faSlash.prefix, icon: faSlash.icon };
        } else {
          icon = { iconName: 'signal', prefix: faSignal.prefix, icon: faSignal.icon };
        }
        setStatusIconName(icon);
      }
    }, 20_000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (machine.lastHeartBeat) {
      const icon: IconDefinition = {
        iconName: 'signal',
        prefix: faSignal.prefix,
        icon: faSignal.icon,
      };
      setStatusIconName(icon);
    }
  }, [machine.lastHeartBeat]);

  const formatHeartBeat = (heartBeat: number): string => {
    const lastBeatDate = new Date(heartBeat);
    const hours = lastBeatDate.getHours();
    const minutes = lastBeatDate.getMinutes();
    const seconds = lastBeatDate.getSeconds();
    return (
      String(hours).padStart(2, '0') +
      ':' +
      String(minutes).padStart(2, '0') +
      ':' +
      String(seconds).padStart(2, '0')
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const heartBeatTooltip = (machine: GloryMachine) => (
    <Tooltip id={machine.name} className="glory-machine-tooltip">
      Last heartbeat: {machine.lastHeartBeat ? formatHeartBeat(machine.lastHeartBeat) : 'unknown'}
    </Tooltip>
  );

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const statusTooltip = (machine: GloryMachine) => (
    <Tooltip id={machine.name} className="glory-machine-tooltip">
      <span style={{ textAlign: 'left' }}>
        <div>Status: {machine.status!.status}</div>
        <div>Description: {machine.status!.description}</div>
      </span>
    </Tooltip>
  );

  const testPayment = (gloryMachine: GloryMachine) => {
    trackPromise(
      axios
        .post(
          actions.TEST_PAYMENT.path,
          { ...gloryMachine },
          {
            headers: {
              'Content-type': 'application/json',
            },
          }
        )
        .then(res => {
          console.log(res.data);
          toaster.success('Payment test successful');
        })
        .catch(err => {
          console.error(err);
          toaster.error('Error testing payment');
        }),
      `test_payment_${gloryMachine.name}`
    );
  };

  const testCancelPayment = (gloryMachine: GloryMachine) => {
    axios
      .post(
        actions.TEST_CANCEL_PAYMENT.path,
        { ...gloryMachine },
        {
          headers: {
            'Content-type': 'application/json',
          },
        }
      )
      .then(res => {
        console.log(res.data);
        toaster.success('Cancel issued');
      })
      .catch(err => {
        console.error(err);
        toaster.error('Error issuing cancel operation');
      });
  };

  return (
    <div className="glory-machine-card">
      <div>
        <div className="glory-machine-title">
          <h3>{machine.name}</h3>
          <OverlayTrigger delay={{ show: 400, hide: 100 }} rootClose placement="right" overlay={heartBeatTooltip(machine)}>
            <FontAwesomeIcon icon={statusIconName} style={{ color: '#ebedef' }} />
          </OverlayTrigger>
        </div>
        <div>
          <h6>Ip: </h6>
          {machine.ip}
        </div>
        {machine.status ? (
          <OverlayTrigger delay={{ show: 400, hide: 100 }} rootClose placement="right" overlay={statusTooltip(machine)}>
            <span>
              <h6>Last status: </h6>
              {machine.status ? machine.status.description : 'unknown'}
            </span>
          </OverlayTrigger>
        ) : (
          <span>
            <h6>Last status: </h6>unknown
          </span>
        )}
        <div className="glory-operations">
          {testPaymentProgress.promiseInProgress ? (
            <Button size="sm" variant="outline-primary" disabled style={{ marginRight: '5px' }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{ width: '0.7rem', height: '0.7rem', verticalAlign: '-0.25em' }}
              />
            </Button>
          ) : (
            <Button
              onClick={() => testPayment(machine)}
              size="sm"
              variant="outline-primary"
              style={{ marginRight: '5px' }}
            >
              Pay
            </Button>
          )}
          <Button
            onClick={() => testCancelPayment(machine)}
            size="sm"
            variant="outline-primary"
            style={{ marginRight: '5px' }}
          >
            Cancel
          </Button>
        </div>
      </div>
      {authorized && 
        <div className="glory-machine-op">
            <FontAwesomeIcon
              onClick={() => setShowEditMachine(true)}
              icon={faWrench}
              style={{ color: '#ebedef' }}
            />
            <FontAwesomeIcon
              onClick={() => setShowDeleteMachine(true)}
              icon={faTrash}
              style={{ color: '#ebedef' }}
            />
        </div>
      }

      {showEditMachine && (
        <EditGloryMachinePopup
          machine={machine}
          onClose={() => setShowEditMachine(false)}
          onSave={gloryMachine => {
            console.log(gloryMachine);
            machineActions
              .updateGloryMachine(gloryMachine)
              .then(() => {
                dispatch(machineActions.getGloryMachines());
                toaster.success('Updated glory machine correctly');
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error updating glory machine');
              });
            setShowEditMachine(false);
          }}
        />
      )}

      {showDeleteMachine && (
        <DeleteGloryMachinePopup
          machine={machine}
          onClose={() => setShowDeleteMachine(false)}
          onDelete={gloryMachine => {
            console.log('deleting ', gloryMachine);
            machineActions
              .deleteGloryMachine(gloryMachine)
              .then(() => {
                dispatch(machineActions.getGloryMachines());
                toaster.success('Deleted glory machine correctly');
              })
              .catch(error => {
                console.error(error);
                toaster.error('Error deleting glory machine');
              });
            setShowDeleteMachine(false);
          }}
        />
      )}
    </div>
  );
};

const EditGloryMachinePopup = ({ machine, onClose, onSave }: GloryMachinePopupType) => (
  <Modal show size="lg">
    <Modal.Header>
      <Modal.Title>Editing glory machine {machine!.name}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <GloryMachinePopup
        editing
        machine={machine}
        onClose={onClose}
        onSave={onSave}
      ></GloryMachinePopup>
    </Modal.Body>
  </Modal>
);

type GloryMachineDeletePopupType = {
  machine: GloryMachine;
  onClose: () => void;
  onDelete: (_: GloryMachine) => void;
};

const DeleteGloryMachinePopup = ({ machine, onClose, onDelete }: GloryMachineDeletePopupType) => (
  <Modal show>
    <Modal.Header>
      <Modal.Title>Are you sure you want to delete the glory machine {machine.name} ?</Modal.Title>
    </Modal.Header>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button variant="danger" onClick={() => onDelete(machine)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
);

export default GloryMachineCard;
