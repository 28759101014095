import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { canPerformAction } from 'actions/userActions';
import useToaster from 'actions/useToaster';
import axios from 'axios';
import { useAppDispatch } from 'hooks';
import React, { useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { availableOperations } from 'reducers/userReducer';
import * as printerActions from '../../../actions/printerActions';
import { usePolling } from '../../../hooks/PollingHook';
import { getBurnersStatus, getUser } from '../../../reducers/selectors';
import BurnerMiniCard from './BurnerMiniCard';
import JobsTable from './JobsTable';

function StatusPage() {
  const dispatch = useAppDispatch();
  const toaster = useToaster();
  usePolling(dispatch, printerActions.getBurnersStatuses, 10000);
  usePolling(dispatch, printerActions.getPrinters, 10000);
  usePolling(dispatch, printerActions.getBurnersJobs, 10000);

  const user = useSelector(getUser);
  const burnersStatus = useSelector(getBurnersStatus);

  const [jobsFilter, setJobsFilter] = useState<string>('');

  const authorized = canPerformAction(user, availableOperations.PRINTER_CONFIGURATIONS_VISIBLE);

  const handleCancelJob = (jobId: string, burner: string): void => {
    axios
      .get(`/rest/react/cancelJob`, {
        params: {
          jobId,
          printer: burner,
        },
        headers: {
          'Content-type': 'application/json',
        },
      })
      .then(() => toaster.success('Job cancelled correctly'))
      .catch(e => toaster.error(e.message));
  };

  const handleKeyPress = event => {
    const filter = event.target.value.toString().toLowerCase();
    setJobsFilter(filter);
  };

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <Row className="justify-content-center" style={{ margin: '0' }}>
          <Card as={Col} sm className="page-panel-common">
            <Card.Body>
              <Row>
                <Col lg={9}>
                  <h3>Jobs</h3>
                </Col>
                <Col lg={3}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      size="sm"
                      aria-label="Default"
                      onKeyUp={handleKeyPress}
                      aria-describedby="inputGroup-sizing-default"
                      id="search-job-text-field"
                    />
                    <InputGroup.Append>
                      <InputGroup.Text className="basic-addon2" id="inputGroup-sizing-default">
                        <FontAwesomeIcon icon={faSearch} />
                      </InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Col>
              </Row>
              <Col>
                <JobsTable cancelJob={handleCancelJob} filter={jobsFilter} />
              </Col>
            </Card.Body>
          </Card>
          <Col
            sm
            style={{
              marginTop: '1.5em',
              minWidth: '12em',
              maxWidth: '12em',
            }}
          >
            {authorized ? (
              <Button style={{ marginBottom: '2em' }} size="sm" as={Link} to="/printer">
                Printers configuration
              </Button>
            ) : (
              <div />
            )}
            <h3>Printers</h3>
            {burnersStatus.length === 0 ? (
              <div style={{ fontSize: '1em' }}>No printers found</div>
            ) : (
              burnersStatus.map(burner => <BurnerMiniCard key={burner.name} burner={burner} />)
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default StatusPage;
